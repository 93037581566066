import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import SettingLayout from 'layout/SettingLayout';
import { PaymentProvider } from 'contexts/PaymentContext';
// account
const HowIGrowSetting = Loadable(lazy(() => import('pages/setting/account/how_i_grow')));
const CloseAccountSetting = Loadable(lazy(() => import('pages/setting/account/close_account')));
const MembershipSetting = Loadable(lazy(() => import('pages/setting/account/membership')));
const NotificationSetting = Loadable(lazy(() => import('pages/setting/account/notification')));
const LoginSecuritySetting = Loadable(lazy(() => import('pages/setting/account/login_security')));
const PersonalSetting = Loadable(lazy(() => import('pages/setting/account/personal')));
const AvatarSetting = Loadable(lazy(() => import('pages/setting/account/avatar')));
const LightingPlanSetting = Loadable(lazy(() => import('pages/setting/account/lighting')));
const Link4Setting = Loadable(lazy(() => import('pages/setting/account/link4')));
// payment
const PaymentMethodSetting = Loadable(lazy(() => import('pages/setting/payment/method')));
const PaymentHistorySetting = Loadable(lazy(() => import('pages/setting/payment/history')));

// ==============================|| MAIN ROUTING ||============================== //

const SettingRoutes = {
  path: 'setting',
  element: (
    <SettingLayout>
      <Outlet />
    </SettingLayout>
  ),
  children: [
    {
      path: 'account',
      children: [
        {
          path: 'login_security',
          element: <LoginSecuritySetting />
        },
        {
          path: 'personal',
          element: <PersonalSetting />
        },
        {
          path: 'avatar',
          element: <AvatarSetting />
        },
        {
          path: 'membership',
          element: (
            <PaymentProvider>
              <MembershipSetting />
            </PaymentProvider>
          )
        },
        {
          path: 'how_i_grow',
          element: <HowIGrowSetting />
        },
        {
          path: 'lighting',
          element: <LightingPlanSetting />
        },
        {
          path: 'link4',
          element: <Link4Setting />
        },
        {
          path: 'notification',
          element: <NotificationSetting />
        },
        {
          path: 'close_account',
          element: <CloseAccountSetting />
        }
      ]
    },
    {
      path: 'payment',
      children: [
        {
          path: 'method',
          element: (
            <PaymentProvider>
              <PaymentMethodSetting />
            </PaymentProvider>
          )
        },
        {
          path: 'history',
          element: (
            <PaymentProvider>
              <PaymentHistorySetting />
            </PaymentProvider>
          )
        }
      ]
    }
  ]
};

export default SettingRoutes;
