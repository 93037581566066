// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function MuiCalendarPicker(theme) {
  return {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiPickersCalendarHeader-root': {
            borderBottom: `solid 1px ${theme.palette.primary[300]}`,
            paddingBottom: '16px',
            maxHeight: '50px'
          }
        }
      }
    }
  };
}
