import { VENDOR_TO_EDIT, VENDOR_LIST_ALL, VENDOR_TYPE_LIST } from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  vendorToEdit: null,
  vendorList: [],
  vendorTypeList: []
};

// ==============================|| AUTH REDUCER ||============================== //

const vendor = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_LIST_ALL: {
      const { vendorList } = action.payload;
      return {
        ...state,
        vendorList
      };
    }
    case VENDOR_TYPE_LIST: {
      const { vendorTypeList } = action.payload;
      return {
        ...state,
        vendorTypeList,
        isInitialized: true
      };
    }
    case VENDOR_TO_EDIT: {
      const { vendorToEdit } = action.payload;
      return {
        ...state,
        vendorToEdit
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default vendor;
