import {
  SCHEDULE_NETWORK_LIST_ALL,
  SCHEDULE_NETWORK_ID,
  SCHEDULE_PUSHER_DATA,
  SCHEDULE_LIGHTING_PLAN_FOR_LOG,
  SCHEDULE_CONSOLE_LOG,
  SCHEDULE_RUNNING_CROP_PLAN_ID,
  SCHEDULE_CALENDAR_TYPE,
  SCHEDULE_CROP_PLAN_COLOR
} from './actions';
import { CalendarType } from 'utils/constants/schedule';

// initial state
export const initialState = {
  isInitialized: false,
  networkList: [],
  networkID: 0,
  pusherData: null,
  lightingData: '',
  consoleData: '',
  runningCropPlanID: 0,
  runningCropPlanColors: {},
  calendarType: CalendarType.Week
};

// ==============================|| AUTH REDUCER ||============================== //

const schedule = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_NETWORK_LIST_ALL: {
      const { networkList } = action.payload;
      return {
        ...state,
        networkList,
        isInitialized: true
      };
    }
    case SCHEDULE_NETWORK_ID: {
      const { networkID } = action.payload;
      return {
        ...state,
        networkID
      };
    }
    case SCHEDULE_PUSHER_DATA: {
      const { pusherData } = action.payload;
      return {
        ...state,
        pusherData
      };
    }
    case SCHEDULE_LIGHTING_PLAN_FOR_LOG: {
      const { lightingData } = action.payload;
      return {
        ...state,
        lightingData
      };
    }
    case SCHEDULE_CONSOLE_LOG: {
      const { consoleData } = action.payload;
      return {
        ...state,
        consoleData
      };
    }
    case SCHEDULE_RUNNING_CROP_PLAN_ID: {
      const { runningCropPlanID } = action.payload;
      return {
        ...state,
        runningCropPlanID
      };
    }
    case SCHEDULE_CALENDAR_TYPE: {
      const { calendarType } = action.payload;
      return {
        ...state,
        calendarType
      };
    }
    case SCHEDULE_CROP_PLAN_COLOR: {
      const { runningCropPlanColors } = action.payload;
      return {
        ...state,
        runningCropPlanColors
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default schedule;
