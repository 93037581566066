import { ListCategory, LightingPlanVersion } from 'utils/constants/list';
import {
  LIST_MANAGEMENT_CATEGORY,
  LIST_MANAGEMENT_ISINITIALIZED,
  LIST_MANAGEMENT_SEED_LIST,
  LIST_MANAGEMENT_SEED_TO_EDIT,
  LIST_MANAGEMENT_MICROGREEN_LIST,
  LIST_MANAGEMENT_MICROGREEN_TO_EDIT,
  LIST_MANAGEMENT_MUSHROOM_LIST,
  LIST_MANAGEMENT_MUSHROOM_TO_EDIT,
  LIST_MANAGEMENT_CUTTING_LIST,
  LIST_MANAGEMENT_CUTTING_TO_EDIT,
  LIST_MANAGEMENT_LIGHTING_PLAN_LIST,
  LIST_MANAGEMENT_LIGHTING_PLAN_TO_EDIT,
  LIST_MANAGEMENT_NEW_LIGHTING_PLAN_LIST,
  LIST_MANAGEMENT_NEW_LIGHTING_PLAN_TO_EDIT,
  LIST_MANAGEMENT_ENVIRONMENT_PLAN_LIST,
  LIST_MANAGEMENT_ENVIRONMENT_PLAN_TO_EDIT,
  LIST_MANAGEMENT_IRRIGATION_PLAN_LIST,
  LIST_MANAGEMENT_IRRIGATION_PLAN_TO_EDIT,
  LIST_MANAGEMENT_SEED_SEEDING_GROUP_LIST,
  LIST_MANAGEMENT_SEED_PLANT_GROUP_LIST,
  LIST_MANAGEMENT_SEED_PLANT_VARIETY,
  LIST_MANAGEMENT_SEED_PLANT_TYPE,
  LIST_MANAGEMENT_SEED_PHPREFS,
  LIST_MANAGEMENT_SEED_ZONE_COUNTRY,
  LIST_MANAGEMENT_SEED_ZONES,
  LIST_MANAGEMENT_SEED_VENDORS,
  LIST_MANAGEMENT_MUSHROOM_VARIETY,
  LIST_MANAGEMENT_CUTTING_GRAFTING,
  LIST_MANAGEMENT_LIGHTING_PLAN_STEPS,
  LIST_MANAGEMENT_LIGHTING_PLAN_STEP_TO_EDIT,
  List_MANAGEMENT_LIGHTING_PLAN_VERSION
} from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  // category
  category: ListCategory.Seeds,
  // seed
  privateSeedList: [],
  publicSeedList: [],
  seedToEdit: null,
  seedingGroupList: [],
  plantGroupList: [],
  plantVarieties: [],
  plantTypes: [],
  PhPrefs: [],
  zoneCountries: [],
  zones: [],
  vendors: [],
  // microgreens
  privateMicrogreenList: [],
  publicMicrogreenList: [],
  microgreenToEdit: null,
  // cutting
  privateCuttingList: [],
  publicCuttingList: [],
  cuttingToEdit: null,
  graftingList: [],
  // mushroom
  privateMushroomList: [],
  publicMushroomList: [],
  mushroomToEdit: null,
  mushroomVarieties: [],
  // lighting plan
  privateLightingPlanList: [],
  publicLightingPlanList: [],
  privateNewLightingPlanList: [],
  publicNewLightingPlanList: [],
  lightingPlanToEdit: null,
  lightingSteps: [],
  lightingStepToEdit: null,
  newLightingPlanToEdit: null,
  lightingPlanVersion: LightingPlanVersion.Old, //old
  // environment plan
  privateEnvironmentPlanList: [],
  publicEnvironmentPlanList: [],
  environmentPlanToEdit: null,
  // irrigation plan
  privateIrrigationPlanList: [],
  publicIrrigationPlanList: [],
  irrigationPlanToEdit: null
};

// ==============================|| AUTH REDUCER ||============================== //

const vendor = (state = initialState, action) => {
  switch (action.type) {
    case LIST_MANAGEMENT_ISINITIALIZED: {
      const { isInitialized } = action.payload;
      return {
        ...state,
        isInitialized
      };
    }
    case LIST_MANAGEMENT_CATEGORY: {
      const { category } = action.payload;
      return {
        ...state,
        category
      };
    }
    case LIST_MANAGEMENT_SEED_LIST: {
      const { privateSeedList, publicSeedList } = action.payload;
      return {
        ...state,
        privateSeedList,
        publicSeedList,
        isInitialized: true
      };
    }
    case LIST_MANAGEMENT_SEED_TO_EDIT: {
      const { seedToEdit } = action.payload;
      return {
        ...state,
        seedToEdit
      };
    }
    case LIST_MANAGEMENT_MICROGREEN_LIST: {
      const { privateMicrogreenList, publicMicrogreenList } = action.payload;
      return {
        ...state,
        privateMicrogreenList,
        publicMicrogreenList,
        isInitialized: true
      };
    }
    case LIST_MANAGEMENT_MICROGREEN_TO_EDIT: {
      const { microgreenToEdit } = action.payload;
      return {
        ...state,
        microgreenToEdit
      };
    }
    case LIST_MANAGEMENT_CUTTING_LIST: {
      const { privateCuttingList, publicCuttingList } = action.payload;
      return {
        ...state,
        privateCuttingList,
        publicCuttingList,
        isInitialized: true
      };
    }
    case LIST_MANAGEMENT_CUTTING_TO_EDIT: {
      const { cuttingToEdit } = action.payload;
      return {
        ...state,
        cuttingToEdit
      };
    }
    case LIST_MANAGEMENT_CUTTING_GRAFTING: {
      const { graftingList } = action.payload;
      return {
        ...state,
        graftingList
      };
    }
    case LIST_MANAGEMENT_MUSHROOM_LIST: {
      const { privateMushroomList, publicMushroomList } = action.payload;
      return {
        ...state,
        privateMushroomList,
        publicMushroomList,
        isInitialized: true
      };
    }
    case LIST_MANAGEMENT_MUSHROOM_TO_EDIT: {
      const { mushroomToEdit } = action.payload;
      return {
        ...state,
        mushroomToEdit
      };
    }
    case LIST_MANAGEMENT_LIGHTING_PLAN_LIST: {
      const { privateLightingPlanList, publicLightingPlanList } = action.payload;
      return {
        ...state,
        privateLightingPlanList,
        publicLightingPlanList,
        isInitialized: true
      };
    }
    case LIST_MANAGEMENT_NEW_LIGHTING_PLAN_LIST: {
      const { privateNewLightingPlanList, publicNewLightingPlanList } = action.payload;
      return {
        ...state,
        privateNewLightingPlanList,
        publicNewLightingPlanList,
        isInitialized: true
      };
    }
    case LIST_MANAGEMENT_LIGHTING_PLAN_TO_EDIT: {
      const { lightingPlanToEdit, lightingSteps, lightingStepToEdit } = action.payload;
      return {
        ...state,
        lightingPlanToEdit,
        lightingSteps,
        lightingStepToEdit
      };
    }
    case LIST_MANAGEMENT_NEW_LIGHTING_PLAN_TO_EDIT: {
      const { newLightingPlanToEdit } = action.payload;
      return {
        ...state,
        newLightingPlanToEdit
      };
    }
    case List_MANAGEMENT_LIGHTING_PLAN_VERSION: {
      const { lightingPlanVersion } = action.payload;
      return {
        ...state,
        lightingPlanVersion
      };
    }
    case LIST_MANAGEMENT_LIGHTING_PLAN_STEPS: {
      const { lightingSteps, lightingStepToEdit } = action.payload;
      return {
        ...state,
        lightingSteps,
        lightingStepToEdit
      };
    }
    case LIST_MANAGEMENT_LIGHTING_PLAN_STEP_TO_EDIT: {
      const { lightingStepToEdit } = action.payload;
      return {
        ...state,
        lightingStepToEdit
      };
    }
    case LIST_MANAGEMENT_ENVIRONMENT_PLAN_LIST: {
      const { privateEnvironmentPlanList, publicEnvironmentPlanList } = action.payload;
      return {
        ...state,
        privateEnvironmentPlanList,
        publicEnvironmentPlanList,
        isInitialized: true
      };
    }
    case LIST_MANAGEMENT_ENVIRONMENT_PLAN_TO_EDIT: {
      const { environmentPlanToEdit } = action.payload;
      return {
        ...state,
        environmentPlanToEdit
      };
    }
    case LIST_MANAGEMENT_IRRIGATION_PLAN_LIST: {
      const { privateIrrigationPlanList, publicIrrigationPlanList } = action.payload;
      return {
        ...state,
        privateIrrigationPlanList,
        publicIrrigationPlanList,
        isInitialized: true
      };
    }
    case LIST_MANAGEMENT_IRRIGATION_PLAN_TO_EDIT: {
      const { irrigationPlanToEdit } = action.payload;
      return {
        ...state,
        irrigationPlanToEdit
      };
    }
    case LIST_MANAGEMENT_SEED_SEEDING_GROUP_LIST: {
      const { seedingGroupList } = action.payload;
      return {
        ...state,
        seedingGroupList
      };
    }
    case LIST_MANAGEMENT_SEED_PLANT_GROUP_LIST: {
      const { plantGroupList } = action.payload;
      return {
        ...state,
        plantGroupList
      };
    }
    case LIST_MANAGEMENT_SEED_PLANT_VARIETY: {
      const { plantVarieties } = action.payload;
      return {
        ...state,
        plantVarieties
      };
    }
    case LIST_MANAGEMENT_SEED_PLANT_TYPE: {
      const { plantTypes } = action.payload;
      return {
        ...state,
        plantTypes
      };
    }
    case LIST_MANAGEMENT_SEED_PHPREFS: {
      const { PhPrefs } = action.payload;
      return {
        ...state,
        PhPrefs
      };
    }
    case LIST_MANAGEMENT_SEED_ZONE_COUNTRY: {
      const { zoneCountries } = action.payload;
      return {
        ...state,
        zoneCountries
      };
    }
    case LIST_MANAGEMENT_SEED_ZONES: {
      const { zones } = action.payload;
      return {
        ...state,
        zones
      };
    }
    case LIST_MANAGEMENT_SEED_VENDORS: {
      const { vendors } = action.payload;
      return {
        ...state,
        vendors
      };
    }
    case LIST_MANAGEMENT_MUSHROOM_VARIETY: {
      const { mushroomVarieties } = action.payload;
      return {
        ...state,
        mushroomVarieties
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default vendor;
