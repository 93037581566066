import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection8 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Crop Durations',
      description:
        'Choose from crop durations of 3, 6, 9, or 12 months, allowing you to plan your harvests according to your schedule and preferences.'
    },
    {
      title: 'Dirty Hands Mode',
      description:
        'For those who prefer a hands-on approach, Khôra Home offers a "Dirty Hands Mode," where you can actively participate in the seeding, transplanting, and growing steps, with each stage managed and tracked within the system.'
    },
    {
      title: 'Clean Hands Mode',
      description:
        'Alternatively, Khôra Home offers a "Clean Hands Mode," where Khôra Cubes are inserted into the unit, and plants are harvested automatically when they are ready. This mode minimizes manual labor and simplifies the growing process.'
    },
    {
      title: 'Customized Plant Selection',
      description:
        'Specify the number of plants or a percentage of the entire crop you wish to grow, ensuring that you only cultivate what you need to minimize waste.'
    },
    {
      title: 'Growing Frequency',
      description:
        'Choose whether you want plants to be grown just once, repeatedly (with the process restarting automatically after harvesting), or continuously for consistent weekly harvests, ensuring a steady supply of fresh produce without interruption.'
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">Can Khôra Home grow exactly what I want without waste?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Grid container spacing={2.5}>
            {contentData?.map((item, idx) => {
              return (
                <Grid key={`content_info_6_${idx}`} item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    spacing={1.25}
                    sx={{
                      p: 2.5,
                      borderRadius: '6px',
                      border: `solid 1px ${theme.palette.primary[300]}`,
                      backgroundColor: 'common.white',
                      height: '100%'
                    }}
                  >
                    <Stack spacing={1.25}>
                      <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                        {item?.title}
                      </Typography>
                      <Typography variant="p2">{item?.description}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQKhoraHomeSection8;
