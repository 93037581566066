// material-ui
// ==============================|| OVERRIDES - TOOLTIP ||============================== //

export default function YearPicker() {
  return {
    MuiYearPicker: {
      styleOverrides: {
        root: {
          maxHeight: 280
        }
      }
    }
  };
}
