import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';

// reducer - state management
import { STATUS_INITIALIZE, NETWORK, USER_SETUP } from 'store/reducers/actions';
import setupReducer, { initialState } from 'store/reducers/_setup';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { setSetupStatus } from 'store/reducers/setupStatus';
// parent context
import { SetupStep } from 'utils/constants/setup';

const SetupContext = createContext(null);

export const SetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(setupReducer, initialState);
  const dispatchForSetupStatus = useDispatch();

  const getCurrentStatus = useCallback(async () => {
    const response = await axios.get('/status');
    const data = response?.data;
    const status = data?.setup_status;
    const step = data?.current_step;
    const network = {
      id: data?.current_network?.id,
      name: data?.current_network?.name
    };
    const location = {
      id: data?.current_network?.current_location?.id,
      name: data?.current_network?.current_location?.name,
      tax: data?.current_network?.current_location?.tax,
      delivery: data?.current_network?.current_location?.delivery
    };
    const locationType = data?.current_network?.current_location?.type;
    const khoraID = {
      id: data?.current_network?.current_location?.khora_id?.id,
      khora_id: data?.current_network?.current_location?.khora_id?.khora_id,
      status: data?.current_network?.current_location?.khora_id?.status
    };
    const khoraNames = data?.current_network?.current_location?.khoras;
    const khoraCount = data?.current_network?.current_location?.khora_count;
    const addresses = data?.current_network?.current_location?.addresses;
    const accessory = data?.current_network?.current_location?.khora_accessory;
    const shelf = data?.current_network?.current_location?.shelving_setting;
    dispatch({
      type: STATUS_INITIALIZE,
      payload: {
        status,
        step,
        network,
        location,
        locationType,
        khoraID,
        khoraNames,
        khoraCount,
        addresses,
        accessory,
        shelf
      }
    });
    dispatchForSetupStatus(
      setSetupStatus({
        status: response.data?.setup_status,
        step: response.data?.current_step
      })
    );
  }, [dispatchForSetupStatus]);

  const getNetworkList = useCallback(async () => {
    try {
      const res = await axios.get('/network');
      const networkList = res?.data;
      let network_count = networkList?.length;
      let location_count = 0;
      let address_count = 0;
      let khora_count = 0;
      networkList?.forEach((net) => {
        location_count += net?.locations?.length;
        net?.locations?.forEach((loc) => {
          address_count += loc?.addresses?.length;
          khora_count += loc?.khora_count;
        });
      });
      dispatch({
        type: NETWORK,
        payload: {
          networkList,
          summary: { network_count, location_count, address_count, khora_count }
        }
      });
    } catch (err) {
      dispatch({
        type: NETWORK,
        payload: {
          networkList: [],
          summary: { network_count: 0, location_count: 0, address_count: 0, khora_count: 0 }
        }
      });
    }
  }, []);

  const getUserSetupSummary = useCallback(async () => {
    try {
      const res = await axios.get('/user_setup/grow_strategy');
      const { grow_what, grow_prefer, get_cube_how, grow_auto, grow_interest, people_count, buffer_percentage } = res.data;
      dispatch({
        type: USER_SETUP,
        payload: {
          userSetup: { grow_what, grow_prefer, get_cube_how, grow_auto, grow_interest, people_count, buffer_percentage }
        }
      });
    } catch (err) {
      dispatch({
        type: USER_SETUP,
        payload: {
          userSetup: {
            grow_what: 0,
            grow_prefer: 0,
            get_cube_how: 0,
            grow_auto: 0,
            grow_interest: 0,
            people_count: 0,
            buffer_percentage: 0
          }
        }
      });
    }
  }, []);

  const reinitializeStatus = useCallback(async () => {
    await getCurrentStatus();
    await getNetworkList();
  }, [getCurrentStatus, getNetworkList]);

  useEffect(() => {
    getUserSetupSummary();
    reinitializeStatus();
  }, [reinitializeStatus, getUserSetupSummary]);

  const createNetwork = useCallback(
    async (values) => {
      try {
        await axios.put('/network', { ...values, user_status: { current_step: SetupStep.Location } });
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const updateNetwork = useCallback(
    async (networkID, values) => {
      try {
        await axios.post(`/network/${networkID}`, values);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const updateNetworkWithStatus = useCallback(
    async (networkID, values) => {
      try {
        await axios.post(`/network/${networkID}`, { ...values, user_status: { current_step: SetupStep.Location } });
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const deleteNetwork = useCallback(
    async (networkID) => {
      try {
        await axios.delete(`/network/${networkID}`);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const changeCurrentNetwork = useCallback(
    async (networkID) => {
      try {
        await axios.post(`/network/change/current/${networkID}`);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const createLocation = useCallback(
    async (networkID, values) => {
      try {
        await axios.put(`/location/${networkID}`, { ...values, user_status: { current_step: SetupStep.Accessory } });
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const getLocationList = useCallback(async (networkID) => {
    try {
      const res = await axios.get(`/location/${networkID}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getOneLocation = useCallback(async (LocationID) => {
    try {
      const res = await axios.get(`/location/detail/${LocationID}`);
      return res?.data;
    } catch (err) {
      return {};
    }
  }, []);

  const changeCurrentLocation = useCallback(
    async (locationID) => {
      try {
        await axios.post(`/location/change/current/${locationID}`);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const updateLocation = useCallback(
    async (networkID, locationID, values, isSetup = false) => {
      let data = null;
      if (isSetup) {
        data = { ...values, user_status: { current_step: SetupStep.Accessory } };
      } else {
        data = values;
      }
      try {
        await axios.post(`/location/${networkID}/${locationID}`, data);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const updateLocationWithStatus = useCallback(
    async (networkID, locationID, values) => {
      try {
        await axios.post(`/location/${networkID}/${locationID}`, { ...values, user_status: { current_step: SetupStep.Accessory } });
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const deleteLocation = useCallback(
    async (networkID, locationID) => {
      try {
        await axios.delete(`/location/${networkID}/${locationID}`);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const updateAccessory = useCallback(
    async (locationID, values, isSetup) => {
      let data = null;
      if (isSetup) {
        data = { ...values, user_status: { current_step: SetupStep.NameKhora } };
      } else {
        data = values;
      }
      try {
        await axios.post(`/khora_setup/accessory/${locationID}`, data);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const updateStacking = useCallback(
    async (locationID, values) => {
      try {
        await axios.post(`/khora_setup/shelf_setting/update/${locationID}`, {
          ...values,
          user_status: { current_step: SetupStep.SetShelves }
        });
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const getShelfStatus = useCallback(async (locationID) => {
    try {
      const res = await axios.get(`/khora_setup/shelf/status/${locationID}`);
      return res.data;
    } catch (err) {
      return { off: [], seeding: [] };
    }
  }, []);

  const updateShelfStatus = useCallback(async (locationID, values) => {
    try {
      await axios.post(`/khora_setup/shelf/status/${locationID}`, values);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getShelvesForLocation = useCallback(async (locationID) => {
    try {
      const res = await axios.get(`/khora_setup/shelf/main/${locationID}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const updateSetShelves = useCallback(
    async (locationID, values) => {
      try {
        await axios.post(`/khora_setup/shelf_setting/generate/${locationID}`, {
          ...values,
          user_status: { current_step: SetupStep.Another }
        });
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  // const getKhoraSetupSummary = useCallback(async () => {
  //   try {
  //     const res = await axios.get('/khora_setup/summary');
  //     return res.data;
  //   } catch (err) {
  //     return {
  //       network_count: 0,
  //       location_count: 0,
  //       khora_count: 0
  //     };
  //   }
  // }, []);

  const createAddress = useCallback(
    async (locationID, values) => {
      try {
        await axios.put(`/khora_setup/address/${locationID}`, values);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const getAddressList = useCallback(async (locationID) => {
    try {
      const res = await axios.get(`/khora_setup/address/${locationID}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const updateAddress = useCallback(
    async (locationID, addressID, values) => {
      try {
        await axios.post(`/khora_setup/address/${locationID}/${addressID}`, values);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const deleteAddress = useCallback(
    async (locationID, addressID) => {
      try {
        await axios.delete(`/khora_setup/address/${locationID}/${addressID}`);
        await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const updateUserSetupSummary = useCallback(async (values) => {
    try {
      const res = await axios.post('/user_setup/grow_strategy', values);
      const { grow_what, grow_prefer, get_cube_how, grow_auto, grow_interest, people_count, buffer_percentage } = res.data;
      dispatch({
        type: USER_SETUP,
        payload: {
          userSetup: { grow_what, grow_prefer, get_cube_how, grow_auto, grow_interest, people_count, buffer_percentage }
        }
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const nextToContinue = useCallback(
    async (current_step, reinitialize = true) => {
      try {
        await axios.post('/status', { current_step });
        if (reinitialize) await reinitializeStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [reinitializeStatus]
  );

  const getNotificationEmailSetting = useCallback(async () => {
    try {
      const res = await axios.get('/setting/notification');
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setOneNotificationSetting = useCallback(async (ID, noti_flag) => {
    try {
      const res = await axios.post(`/setting/notification/one/${ID}`, { noti_flag });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setAllNotificationSetting = useCallback(async (noti_flag) => {
    try {
      const res = await axios.post(`/setting/notification/all`, { noti_flag });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setOneEmailSetting = useCallback(async (ID, email_flag) => {
    try {
      const res = await axios.post(`/setting/notification/one/${ID}`, { email_flag });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setAllEmailSetting = useCallback(async (email_flag) => {
    try {
      const res = await axios.post(`/setting/notification/all`, { email_flag });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const voteSeed = useCallback(async (ID) => {
    try {
      await axios.post(`/list/seed/vote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const unvoteSeed = useCallback(async (ID) => {
    try {
      await axios.post(`/list/seed/unvote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const voteCutting = useCallback(async (ID) => {
    try {
      await axios.post(`/list/cutting/vote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const unvoteCutting = useCallback(async (ID) => {
    try {
      await axios.post(`/list/cutting/unvote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const voteMushroom = useCallback(async (ID) => {
    try {
      await axios.post(`/list/mushroom/vote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const unvoteMushroom = useCallback(async (ID) => {
    try {
      await axios.post(`/list/mushroom/unvote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const voteMicrogreen = useCallback(async (ID) => {
    try {
      await axios.post(`/list/microgreen/vote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const unvoteMicrogreen = useCallback(async (ID) => {
    try {
      await axios.post(`/list/microgreen/unvote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const voteGrowingPlan = useCallback(async (ID) => {
    try {
      await axios.post(`/growing_plan/vote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const unvoteGrowingPlan = useCallback(async (ID) => {
    try {
      await axios.post(`/growing_plan/unvote/${ID}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getSensorInfo = useCallback(async () => {
    try {
      const res = await axios.get('/network/sensors');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <SetupContext.Provider
      value={{
        ...state,
        createNetwork,
        updateNetwork,
        updateNetworkWithStatus,
        getNetworkList,
        changeCurrentNetwork,
        deleteNetwork,
        createLocation,
        getLocationList,
        getOneLocation,
        changeCurrentLocation,
        updateLocation,
        updateLocationWithStatus,
        deleteLocation,
        updateAccessory,
        updateStacking,
        updateSetShelves,
        getShelfStatus,
        getShelvesForLocation,
        updateShelfStatus,
        createAddress,
        getAddressList,
        updateAddress,
        deleteAddress,
        // getKhoraSetupSummary,
        getUserSetupSummary,
        updateUserSetupSummary,
        nextToContinue,
        reinitializeStatus,
        getNotificationEmailSetting,
        setOneNotificationSetting,
        setAllNotificationSetting,
        setOneEmailSetting,
        setAllEmailSetting,
        voteSeed,
        unvoteSeed,
        voteCutting,
        unvoteCutting,
        voteMushroom,
        unvoteMushroom,
        voteMicrogreen,
        unvoteMicrogreen,
        voteGrowingPlan,
        unvoteGrowingPlan,
        getSensorInfo
      }}
    >
      {children}
    </SetupContext.Provider>
  );
};

SetupProvider.propTypes = {
  children: PropTypes.node
};

export default SetupContext;
