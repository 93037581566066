// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function MenuItem() {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          paddingTop: '12px',
          paddingBottom: '12px',
          whiteSpace: 'inherit',
          '&.Mui-selected': {
            backgroundColor: '#E9E9E9',
            '&:hover': {
              backgroundColor: '#E9E9E9'
            }
          }
        }
      }
    }
  };
}
