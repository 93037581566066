import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection5 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Plants with High Space Requirements and Low Yields',
      description:
        'Species such as coconut trees, wheat, rice, and corn demand significant space to grow but offer relatively low yields in indoor settings. These plants may not be practical for cultivation within the confined space of a Khôra Home unit.'
    },
    {
      title: 'Slow-Growing Varieties',
      description:
        'Certain plants, such as fig trees, have a prolonged growth period before they begin to produce fruit. For gardeners seeking more immediate results, the extended waiting time associated with slow-growing varieties may be less appealing.'
    },
    {
      title: 'Malodorous Plants',
      description:
        'Some plants are notorious for their strong and unpleasant odors, which may not be conducive to a pleasant indoor environment. For example, the Corpse Flower emits a foul smell that may deter gardeners from growing it alongside more fragrant herbs like basil or mint.'
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">What should not be grown in a Khôra Home?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Stack spacing={3.75} direction="row" alignItems="center" sx={{ maxWidth: 914 }}>
            <Stack justifyContent="space-around" spacing={1.25} sx={{ height: { xs: 'inherit', md: 160 } }}>
              <Typography variant="p2">
                While Khôra Home offers a versatile platform for indoor gardening, there are certain types of plants that may not be
                well-suited for cultivation within this environment.
              </Typography>
              <Typography variant="p2">Here are some examples of what should not be grown in a Khôra Home:</Typography>
            </Stack>
            <Box sx={{ height: 160, display: { xs: 'none', md: 'block' } }}>
              <img src={'/assets/images/faq/khorahome_7.png'} alt={'khorahome_7'} style={{ width: 'auto', height: '160px' }} />
            </Box>
          </Stack>
          <Box>
            <Grid container spacing={2.5}>
              {contentData?.map((item, idx) => {
                return (
                  <Grid key={`content_info_${idx}`} item xs={12} md={4}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      spacing={1.25}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.error[500] }}>
                          {item?.title}
                        </Typography>
                        <Divider />
                        <Typography variant="p2">{item?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Typography variant="p2">
            By avoiding these types of plants, Khôra Home users can optimize their indoor gardening experience and focus on cultivating
            varieties that thrive within the controlled environment of their unit.
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQKhoraHomeSection5;
