// third-party
import { FormattedMessage } from 'react-intl';
// icons
// import ContactUsIcon from '@mui/icons-material/PhoneOutlined';
// const icons = { ContactUsIcon };

// icons
import ContactusIcon from 'assets/icons/contact_us/Contact_1.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const contactus = {
  id: 'group-contactus',
  type: 'group',
  children: [
    {
      id: 'contactus',
      title: <FormattedMessage id="Contact Us" />,
      type: 'item',
      url: '/contact_us',
      icon: ContactusIcon
    }
  ]
};
const contactus_ = {
  id: 'group-contactus',
  type: 'group',
  children: [
    {
      id: 'contactus',
      title: <FormattedMessage id="Contact Us" />,
      type: 'collapse',
      icon: ContactusIcon,
      children: [
        {
          id: 'contactus_',
          title: <FormattedMessage id="Contact Us" />,
          type: 'item',
          url: '/contact_us'
        }
      ]
    }
  ]
};

export { contactus, contactus_ };
