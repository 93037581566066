export const TeamMembers = [
  {
    name: 'Avi Moskovitz',
    role: 'Founder and Chief Innovation Officer',
    path: '/assets/images/about/avi.png',
    description: [
      'Avi Moskovitz brings over two decades of experience in successfully designing and managing the build-out of highly complex global technology projects. With cross-functional teams of over 100 members and projects valued at over $100 million USD, Avi has led efforts in strategic planning, digital transformation, roadmap development, prioritization, program planning, vendor and product acquisition, technical and data architecture, infrastructure and facilities build-out, requirements gathering, full life cycle development, quality assurance, production roll-out, and training.',
      "Known for her persistence, determination, communication skills, and unwavering belief in Khôra's mission, Avi is a driving force behind the company's success. Beyond her professional endeavors, Avi is also an exceptional chef who focuses on creating healthy meals and fostering memorable experiences for friends and loved ones to share."
    ]
  },
  {
    name: 'David Hang',
    role: 'Senior Software Engineer and Architect',
    path: '/assets/images/about/david.png',
    description: [
      'David Hang is a remarkable Software Engineer with over 9 years of experience leading full-stack development in complex technical environments. He specializes in high-performance, large-scale backend systems, and excels in designing responsive websites using industry-adopted languages and frameworks. Daiwei is exceptionally adept at tackling difficult challenges, integrating unknown technologies, and learns quickly.',
      'Daiwei leads cross-functional teams and manages the full Software Development Life Cycle from requirements to maintenance. He thrives as a collaborator, communicates confidently, and creatively innovates with ease.',
      "When Daiwei finds free time, he grabs his soccer ball and meets his friends for a spirted game while showing off his impressive deking and finishing skills. He rules on the field and loves the feeling when he scores a goal. When possible, he loves to watch Argentina's top professional player, Lionel Messi, do his magic on the field."
    ]
  },
  {
    name: 'Haotian Song',
    role: 'Lead Full-Stack Engineer',
    path: '/assets/images/about/haotian.png',
    description: [
      'Haotian Song is a talented full-stack engineer with over 7 years of diverse experience using HTML, CSS, JavaScript, Nodejs, PHP, MySQL, MongoDB, and various frameworks such as Angular, React, Laravel. Haotian takes pride in aligning aesthetics with functionality while crafting visually captivating websites that optimize user journeys with seamless interactions.',
      "With a keen eye for detail and his joy in tackling complex challenges, Haotian is consistently a significant contributor to his team and always makes a difference. What drives Haotian is the strong belief in Khôra's success, the ability to combine technical expertise with creativity, and the power of clean code and modular architecture.",
      "When Haotian has free time, he enjoys researching software development trends and understanding how the new technology could impact the world and the systems being designed and developed today. He has a thirst for knowledge and enjoys reviewing other's code to improve his technical know-how and to be able to leverage the knowledge in his future work."
    ]
  },
  {
    name: 'Yifeng Wang',
    role: 'Lead Software Engineer',
    path: '/assets/images/about/yifeng.png',
    description: [
      'Yifeng Wang has over 7 years of experience as a system administrator and full-stack developer with a strong focus on optimizing software performance, combining technical expertise with creativity, improving user experiences, and reducing development costs through efficient workflows and coding practices.',
      "Yifeng greatest strengths are data structures and algorithms, complex out-of-the box problem-solving, and optimizing skills. He leads the design and implementation of Khôra Home's ever growing and extraordinarily complex algorithms, which supports users enjoying the benefits of Khôra Home without having agricultural knowledge.",
      'In his spare time, Yifeng can be found gripping a tennis racquet and challenging the world to a demanding and competitive game of tennis. He loves playing singles or doubles and demonstrating his incredible teamwork skills while at the net and maintaining a healthy fitness regime. When he is not smacking or chasing the tennis ball, he can be found swimming and enjoying the peacefulness of the water, the focus it brings to him, and the dedicated time he has to himself.'
    ]
  },
  {
    name: 'Donika Canaj',
    role: 'Senior UX/UI Designer & Lead',
    path: '/assets/images/about/donika.png',
    description: [
      "Donika Canaj is a seasoned UX/UI Lead Designer with over a decade of vast experiences with many fantastic companies. She always puts the user's experience at the heart of everything she does and seamlessly blends creativity with practicality while adapting her approach to meet each project's unique demands.",
      "Donika has an incredible ability to take complex business processes and simplify them into elegant, simple, and self-evident processes for any level user. She has a keen eye for beauty, design, and creating a clean and refined user experience. Her original style has set the branding for Khôra Home, Sprout - The Grower's Platform, Khôra Marketplace, and many key social media and marketing items. Her inner beauty shines through her work and her contributions have been significant in making the highly complex algorithms and features of Khôra Home effortless for the world.",
      'When Donika is not immersed in designing wireframes and prototypes, she is exploring new places, nose deep in a good book, or lost in the world of cinema.'
    ]
  },
  {
    name: 'Janurrie Castillo',
    role: 'Senior Illustrator and Animator',
    path: '/assets/images/about/aiie.png',
    description: [
      'Janurrie Castillo is an illustrator and animator with over a decade of experience working on various types of project ranging from TV production, web design, games, and education technology. A Jill of creative trades, she has worn different hats and always looks forward to the endless possibilities of bringing ideas to life.',
      "Janurrie's unbelievable creativity, originality, and vast imagination creates the Khôra Home's global branding. She brings joy and laughter to people with her ardent humor and relatable and lovable anthropomorphic characters.",
      "Janurrie believes in the Japanese concept of Ikigai, that doing something you love and are good at should serve a greater purpose and addresses what the world needs. As varied as the hats she wear, Janurrie's time outside of work is spent on studying machine learning and AI and training in Brazilian jiujitsu and muay thai."
    ]
  }
];
