import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
// render - Dashboard
import { DashboardProvider } from 'contexts/DashboardContext';
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

// render - Notification Center
const NotificationCenter = Loadable(lazy(() => import('pages/notification')));

// render - Todo Center
import { TodoProvider } from 'contexts/TodoContext';
const TodoManagement = Loadable(lazy(() => import('pages/todo_list_management')));
// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
  path: 'dashboard',
  element: (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ),
  children: [
    {
      path: '',
      element: (
        <DashboardProvider>
          <Dashboard />
        </DashboardProvider>
      )
    },
    {
      path: 'notification',
      element: <NotificationCenter />
    },
    {
      path: 'todo_list',
      element: (
        <TodoProvider>
          <TodoManagement />
        </TodoProvider>
      )
    }
  ]
};

export default DashboardRoutes;
