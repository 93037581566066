// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (fontFamily) => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  fontWeightBolder: 700,
  title: {
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '30px'
  },
  dt1: {
    fontWeight: 500,
    fontSize: '72px',
    lineHeight: '90px'
  },
  dt2: {
    fontWeight: 500,
    fontSize: '52px',
    lineHeight: '70px'
  },
  h1: {
    fontWeight: 500,
    fontSize: '48px',
    lineHeight: '60px'
  },
  h2: {
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '42px'
  },
  h3: {
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '52px'
  },
  h4: {
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '48px'
  },
  h5: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px'
  },
  p1: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px'
  },
  p2: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px'
  },
  p3: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
  },
  p4: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px'
  },
  p1m: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px'
  },
  p2m: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px'
  },
  p3m: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px'
  },
  p4m: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px'
  },
  l1: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px'
  },
  l2: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px'
  },
  l3: {
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px'
  },
  body1: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
  },
  body2: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px'
  },
  button: {
    textTransform: 'none'
  }
});

export default Typography;
