import { createSlice } from '@reduxjs/toolkit';
import { GuideCheck, SetupStep } from 'utils/constants/setup';
const initialState = {
  status: GuideCheck.NeverChecked,
  step: SetupStep.Initial
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const setupStatus = createSlice({
  name: 'setupStatus',
  initialState,
  reducers: {
    setSetupStatus(state, action) {
      const { status, step } = action.payload;
      state.status = status;
      state.step = step;
    }
  }
});

export default setupStatus.reducer;

export const { setSetupStatus } = setupStatus.actions;
