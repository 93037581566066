import LandingContactSection from 'sections/landing/LandingContact';
import { Helmet } from 'react-helmet-async';

const LandingContact = () => {
  return (
    <>
      <Helmet title="Contact Us - Khôra Home" />
      <LandingContactSection />
    </>
  );
};
export default LandingContact;
