import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  useTheme,
  Box,
  Grid,
  Divider,
  useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection5 = () => {
  const theme = useTheme();
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  const contentData = [
    {
      title: 'Complex Logic',
      description:
        'Sprout utilizes sophisticated algorithms that consider various factors such as lighting requirements, environmental conditions, and watering needs of different plants. This complex logic enables Sprout to determine the most suitable arrangement of plants on a shelf.'
    },
    {
      title: 'Similarity of Needs',
      description:
        'Plants with similar lighting, environmental, and watering needs are grouped together to ensure optimal growth conditions. By clustering plants with comparable requirements, Sprout minimizes competition for resources and maximizes the potential for successful cultivation.'
    },
    {
      title: 'Changing Plant Sizes',
      description:
        'As plants grow and reach different stages of maturity, Sprout dynamically adjusts their placement to accommodate their changing size requirements. This ongoing monitoring and adjustment process ensures that plants receive the necessary space throughout their life cycle, promoting healthy growth and development.'
    },
    {
      title: 'Space Optimization',
      description:
        'Plant placement is optimized within either a full shelf tray or four smaller standard trays. These trays are designed to be easier to lift and move, facilitating management of the growing environment. Within each tray, Sprout combines various size Khôra Cubes and nursery pots to maximize space utilization and increase harvest amounts.'
    },
    {
      title: 'Endless Configurations',
      description:
        'The configurations of Khôra Cubes and nursery pots on a shelf are highly customizable, allowing for endless possibilities to support specialized crop plans with diverse needs. This flexibility enables growers to adapt the layout of their cultivation space according to the specific requirements of their crops.'
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">How does Sprout automate placing different size plants on a shelf?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Typography variant="p2">
            Sprout employs advanced automation techniques to optimize the placement of different size plants on a shelf, ensuring efficient
            use of space and maximizing harvest yields. Here{"'"}s how Sprout automates this process:
          </Typography>
          <Box>
            <Grid container spacing={2.5}>
              {contentData?.map((item, idx) => {
                return (
                  <Grid key={`content_info_7_${idx}`} item xs={12}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      spacing={1.25}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                          {item?.title}
                        </Typography>
                        <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        <Typography variant="p2">{item?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  spacing={1.25}
                  sx={{
                    p: 2.5,
                    borderRadius: '6px',
                    border: `solid 1px ${theme.palette.primary[300]}`,
                    backgroundColor: 'common.white',
                    height: '100%'
                  }}
                >
                  <Stack spacing={1.25}>
                    <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                      Various Size Trays, Cubes, and Pots
                    </Typography>
                    <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                    <Stack spacing={2.5}>
                      <Typography variant="p2">
                        By providing a diverse selection of container sizes, Sprout empowers growers with limitless combinations and
                        unparalleled flexibility in planting to suit their specific requirements. The following sizes are included:
                      </Typography>
                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <Stack spacing={2}>
                          <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                            Khôra Tray
                          </Typography>
                          <Typography variant="p3">
                            Standard 1020 tray
                            <br />
                            Full Shelf tray 2{"'"}x4{"'"} allows space for plants to grow
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="center">
                          <Divider
                            orientation={downMD ? 'horizontal' : 'vertical'}
                            sx={{ border: `solid 1px ${theme.palette.primary[300]}`, height: '100%', width: '100%' }}
                          />
                        </Stack>
                        <Stack spacing={2}>
                          <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                            Cubes
                          </Typography>
                          <Typography variant="p3">
                            <b>Small Khôra Cubes</b>: Standard 1020 holds 18
                            <br />
                            <b>Medium Khôra Cubes</b>: Standard 1020 tray holds 10
                            <br />
                            <b>Large Khôra Cubes</b>: Standard 1020 tray holds 8
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="center">
                          <Divider
                            orientation={downMD ? 'horizontal' : 'vertical'}
                            sx={{ border: `solid 1px ${theme.palette.primary[300]}`, height: '100%', width: '100%' }}
                          />
                        </Stack>
                        <Stack spacing={2}>
                          <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                            Round Nursery Pots
                          </Typography>
                          <Typography variant="p3">
                            <b>1 Gallon</b>: 15 pots per full shelf tray
                            <br />
                            <b>3 Gallon</b>: 5 pots per full shelf tray
                            <br />
                            <b>5 Gallon</b>: 5 pots per full shelf tray
                            <br />
                            <b>10 Gallon</b>: 2 pots per full shelf tray
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQSproutSection5;
