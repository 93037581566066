// third-party
import { FormattedMessage } from 'react-intl';
// icons
import PlantPlacementIcon from 'assets/icons/plant_placement/plant_placement.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const plant_placement = {
  id: 'group-plant_placement',
  type: 'group',
  children: [
    {
      id: 'plant_placement',
      title: <FormattedMessage id="Harvest & Plant Placement" />,
      type: 'item',
      url: '/plant_placement',
      icon: PlantPlacementIcon
    }
  ]
};
const plant_placement_ = {
  id: 'group-plant_placement',
  type: 'group',
  children: [
    {
      id: 'plant_placement',
      title: <FormattedMessage id="Harvest & Plant Placement" />,
      type: 'collapse',
      icon: PlantPlacementIcon,
      children: [
        {
          id: 'plant_placement_',
          title: <FormattedMessage id="Harvest & Plant Placement" />,
          type: 'item',
          url: '/plant_placement'
        }
      ]
    }
  ]
};

export { plant_placement, plant_placement_ };
