const PublishStatus = {
  1: { name: 'Submitted', color: '#1998E0' },
  2: { name: 'Under Review', color: '#FFC933' },
  3: { name: 'Accepted for Trials', color: '#50A18E' },
  4: { name: 'In Growing Trials', color: '#AD7133' },
  5: { name: 'Accepted', color: '#039855' },
  6: { name: 'Declined', color: '#D92D20' }
};
const StepperSteps = [
  { label: 'Select Plant' },
  { label: 'Transplant Steps' },
  { label: 'Growing Steps' },
  { label: 'Lighting Plan' },
  { label: 'Irrigation Plan' },
  { label: 'Environment Plan' }
];
const GrowingStep = {
  'Select Plant': 0,
  'Transplant Steps': 1,
  'Growing Steps': 2,
  'Lighting Plan': 3,
  'Irrigation Plan': 4,
  'Environment Plan': 5
};

const MeasurementTypeSort = {
  Metric: 1,
  Standard: 2,
  Insect: 3
};

const ContainerTypeSort = {
  SeedingTray: 1,
  Microgreen: 2,
  ShelfTray: 3,
  KhoraCube: 4,
  RoundPot: 5
};

const ContainerTypeSortString = {
  1: 'Seeding Trays or Flats',
  2: 'Microgreen Trays',
  3: 'Shelf Trays',
  4: 'Khôra Cubes',
  5: 'Round Nursery Pots'
};

const GrowingStepMethods = ['None', 'Air Release', 'Per Plant', 'Via Irrigation', 'Independent'];

export { PublishStatus, StepperSteps, GrowingStep, MeasurementTypeSort, ContainerTypeSort, ContainerTypeSortString, GrowingStepMethods };
