import FAQWrapper from 'sections/landing/faq/Wrapper';
import LandingFAQKhoraHomeSection from 'sections/landing/faq/KhoraHome';
import { Helmet } from 'react-helmet-async';

const LandingFAQKhoraHome = () => {
  return (
    <>
      <Helmet title="FAQ - Khôra Home" />
      <FAQWrapper>
        <LandingFAQKhoraHomeSection />
      </FAQWrapper>
    </>
  );
};
export default LandingFAQKhoraHome;
