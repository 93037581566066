const MembershipLevel = {
  No: 1,
  Limited: 2,
  Full: 3
};

const MembershipDetail = {
  Free: {
    title: 'Free',
    price: 0,
    price_description: '',
    description: 'Automated with Clean Hands',
    tag: '-',
    tagColor: '#555555'
  },
  Seed: {
    title: 'Seed',
    price: 4.99,
    price_description: '',
    description: 'Automated',
    tag: 'Simplified',
    tagColor: '#1998E0'
  },
  Grow: {
    title: 'Grow',
    price: 9.99,
    price_description: '',
    description: 'Manual',
    tag: 'Self-sufficient',
    tagColor: '#1998E0'
  },
  Bloom: {
    title: 'Bloom',
    price: 14.99,
    price_description: '',
    description: 'Advanced',
    tag: 'Popular',
    tagColor: '#1998E0'
  },
  Harvest: {
    title: 'Harvest',
    price: 19.99,
    price_description: '',
    description: 'All',
    tag: 'Expert',
    tagColor: '#59B39E'
  },
  Master: {
    title: 'Master',
    price: 24.99,
    price_description: 'Plus Tiered Pricing based on Marketplace Volume and ranking',
    description: 'All and Marketplace Seller',
    tag: 'Wizard',
    tagColor: '#59B39E'
  }
};

const MembershipData = [
  {
    title: 'My Khôra - Network & Shelf Management',
    tooltip: '',
    value: {
      Free: MembershipLevel.Full,
      Seed: MembershipLevel.Full,
      Grow: MembershipLevel.Full,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    }
  },
  {
    title: 'Schedule',
    tooltip: '',
    value: {
      Free: MembershipLevel.Full,
      Seed: MembershipLevel.Full,
      Grow: MembershipLevel.Full,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    }
  },
  {
    title: 'Harvest & Plant Placement in Khôra',
    tooltip: '',
    value: {
      Free: MembershipLevel.Full,
      Seed: MembershipLevel.Full,
      Grow: MembershipLevel.Full,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    }
  },
  {
    title: 'Favorites and Voting up',
    tooltip: '',
    value: {
      Free: MembershipLevel.Full,
      Seed: MembershipLevel.Full,
      Grow: MembershipLevel.Full,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    }
  },
  {
    title: 'Dashboard',
    tooltip: '',
    value: {
      Free: MembershipLevel.Limited,
      Seed: MembershipLevel.Full,
      Grow: MembershipLevel.Full,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    },
    children: [
      {
        title: 'To-Do & Notifications',
        tooltip: '',
        value: {
          Free: MembershipLevel.Full,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.Full,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      },
      {
        title: 'Growing Analytics',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.Full,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      },
      {
        title: ' Live Camera',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.Full,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      }
    ]
  },
  {
    title: 'Searches',
    tooltip: '',
    value: {
      Free: MembershipLevel.Limited,
      Seed: MembershipLevel.Limited,
      Grow: MembershipLevel.Limited,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    },
    children: [
      {
        title: 'Public Library',
        tooltip: '',
        value: {
          Free: MembershipLevel.Full,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      },
      {
        title: 'Private Library',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.No,
          Grow: MembershipLevel.Full,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      }
    ]
  },
  {
    title: 'Crop Modeling and Planning',
    tooltip: '',
    value: {
      Free: MembershipLevel.Limited,
      Seed: MembershipLevel.Limited,
      Grow: MembershipLevel.Limited,
      Bloom: MembershipLevel.Limited,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    },
    children: [
      {
        title: 'Strategy',
        tooltip: '',
        value: {
          Free: MembershipLevel.Limited,
          Seed: MembershipLevel.Limited,
          Grow: MembershipLevel.Limited,
          Bloom: MembershipLevel.Limited,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        },
        children: [
          {
            title: 'Automated',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.No,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Advanced',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.No,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Manual',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.No,
              Grow: MembershipLevel.Full,
              Bloom: MembershipLevel.No,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'How to Place Plants',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.No,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          }
        ]
      },
      {
        title: 'How to Grow',
        tooltip: '',
        value: {
          Free: MembershipLevel.Limited,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        },
        children: [
          {
            title: 'Clean Hands',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Dirty Hands',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          }
        ]
      },
      {
        title: 'Growing Method',
        tooltip: '',
        value: {
          Free: MembershipLevel.Limited,
          Seed: MembershipLevel.Limited,
          Grow: MembershipLevel.Limited,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        },
        children: [
          {
            title: 'Grow Once',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.Full,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Repeat',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.Full,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Continuous',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Remove Gaps in Continuous Growing',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.No,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          }
        ]
      },
      {
        title: 'Assignment Method',
        tooltip: '',
        value: {
          Free: MembershipLevel.Full,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.Limited,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        },
        children: [
          {
            title: 'Number of Shelves',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.Full,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Shelf Percentage',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          }
        ]
      },
      {
        title: 'Grow Plants',
        tooltip: '',
        value: {
          Free: MembershipLevel.Limited,
          Seed: MembershipLevel.Limited,
          Grow: MembershipLevel.Limited,
          Bloom: MembershipLevel.Limited,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        },
        children: [
          {
            title: 'Seeds',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.Full,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Microgreens',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.Full,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Cuttings',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.Full,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Cannabis',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.No,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.No,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Mushrooms',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.No,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.No,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          }
        ]
      },
      {
        title: 'Crop Model Analysis',
        tooltip: '',
        value: {
          Free: MembershipLevel.Limited,
          Seed: MembershipLevel.Limited,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.Limited,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        },
        children: [
          {
            title: 'Upcoming Harvesting Schedule',
            tooltip: '',
            value: {
              Free: MembershipLevel.Full,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Cost and profitability analysis',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.No,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.No,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Crop Plan Details',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          },
          {
            title: 'Crop Plan Summary',
            tooltip: '',
            value: {
              Free: MembershipLevel.No,
              Seed: MembershipLevel.Full,
              Grow: MembershipLevel.No,
              Bloom: MembershipLevel.Full,
              Harvest: MembershipLevel.Full,
              Master: MembershipLevel.Full
            }
          }
        ]
      }
    ]
  },
  {
    title: 'Restocking',
    tooltip: '',
    value: {
      Free: MembershipLevel.Limited,
      Seed: MembershipLevel.Full,
      Grow: MembershipLevel.No,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    },
    children: [
      {
        title: 'Shopping List',
        tooltip: '',
        value: {
          Free: MembershipLevel.Full,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      },
      {
        title: 'Seeding List',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      }
    ]
  },
  {
    title: 'Vendor Management',
    tooltip: '',
    value: {
      Free: MembershipLevel.No,
      Seed: MembershipLevel.Limited,
      Grow: MembershipLevel.Full,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    },
    children: [
      {
        title: 'Public',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.Full,
          Grow: MembershipLevel.Full,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      },
      {
        title: 'Private',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.No,
          Grow: MembershipLevel.Full,
          Bloom: MembershipLevel.Full,
          Harvest: MembershipLevel.Full,
          Master: MembershipLevel.Full
        }
      }
    ]
  },
  {
    title: 'Growing Plan Management',
    tooltip: '',
    value: {
      Free: MembershipLevel.No,
      Seed: MembershipLevel.No,
      Grow: MembershipLevel.Full,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    }
  },
  {
    title: 'List Management',
    tooltip: '',
    value: {
      Free: MembershipLevel.No,
      Seed: MembershipLevel.No,
      Grow: MembershipLevel.No,
      Bloom: MembershipLevel.Full,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    }
  },
  {
    title: 'Observations with captured pictures',
    tooltip: '',
    value: {
      Free: MembershipLevel.No,
      Seed: MembershipLevel.No,
      Grow: MembershipLevel.Full,
      Bloom: MembershipLevel.No,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    }
  },
  {
    title: 'Cost Management',
    tooltip: '',
    value: {
      Free: MembershipLevel.No,
      Seed: MembershipLevel.No,
      Grow: MembershipLevel.No,
      Bloom: MembershipLevel.No,
      Harvest: MembershipLevel.Full,
      Master: MembershipLevel.Full
    }
  },
  {
    title: 'Contract Production Farming',
    tooltip: '',
    value: {
      Free: MembershipLevel.No,
      Seed: MembershipLevel.No,
      Grow: MembershipLevel.No,
      Bloom: MembershipLevel.No,
      Harvest: MembershipLevel.No,
      Master: MembershipLevel.Full
    },
    children: [
      {
        title: 'Crop Plan Estimates',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.No,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.No,
          Harvest: MembershipLevel.No,
          Master: MembershipLevel.Full
        }
      },
      {
        title: 'Crop Plan Invoices',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.No,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.No,
          Harvest: MembershipLevel.No,
          Master: MembershipLevel.Full
        }
      },
      {
        title: 'Customer Payments',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.No,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.No,
          Harvest: MembershipLevel.No,
          Master: MembershipLevel.Full
        }
      },
      {
        title: 'Contract Generation',
        tooltip: '',
        value: {
          Free: MembershipLevel.No,
          Seed: MembershipLevel.No,
          Grow: MembershipLevel.No,
          Bloom: MembershipLevel.No,
          Harvest: MembershipLevel.No,
          Master: MembershipLevel.Full
        }
      }
    ]
  },
  {
    title: 'Customer Management',
    tooltip: '',
    value: {
      Free: MembershipLevel.No,
      Seed: MembershipLevel.No,
      Grow: MembershipLevel.No,
      Bloom: MembershipLevel.No,
      Harvest: MembershipLevel.No,
      Master: MembershipLevel.Full
    }
  }
];

export { MembershipDetail, MembershipLevel, MembershipData };
