import { Box, useTheme } from '@mui/material';

import NewLandingSection1 from './Section1';
import NewLandingSection2 from './Section2';
import NewLandingSection3 from './Section3';
import NewLandingSection4 from './Section4';
import NewLandingSection5 from './Section5';
import NewLandingSection6 from './Section6';
import NewLandingSection7 from './Section7';
// import NewLandingSection8 from './Section8';
import NewLandingSection9 from './Section9';
import NewLandingSection10 from './Section10';
import NewLandingSection11 from './Section11';
import NewLandingSection12 from './Section12';
import NewLandingSection13 from './Section13';

const NewLandingHomeSection = () => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ overflow: 'hidden' }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            backgroundColor: theme.palette.primary[50],
            overflow: 'hidden',
            zIndex: 0
          }}
        >
          {new Array(30)
            .fill()
            .map((_, index) => index)
            ?.map((value) => {
              if (value % 2 === 0) {
                return (
                  <Box
                    key={`circle_${value}`}
                    sx={{
                      position: 'absolute',
                      bottom: `${-40 + 70 * value}vw`,
                      left: '40vw',
                      width: '100vw',
                      pt: '100vw',
                      borderRadius: '100%',
                      backgroundColor: theme.palette.primary[100],
                      zIndex: 1
                    }}
                  />
                );
              } else {
                return (
                  <Box
                    key={`circle_${value}`}
                    sx={{
                      position: 'absolute',
                      bottom: `${-40 + 70 * value}vw`,
                      right: '40vw',
                      width: '100vw',
                      pt: '100vw',
                      borderRadius: '100%',
                      backgroundColor: theme.palette.primary[25],
                      zIndex: 1
                    }}
                  />
                );
              }
            })}
          <Box sx={{ position: 'relative', top: 0, left: 0, zIndex: 2, maxWidth: 1440, margin: 'auto' }}>
            <NewLandingSection1 />
            <Box sx={{ height: 50 }} />
            <NewLandingSection2 />
            <Box sx={{ height: 100 }} />
            <NewLandingSection3 />
            <Box sx={{ height: { xs: 40, md: 50 } }} />
            <NewLandingSection4 />
            <Box sx={{ height: { xs: 40, md: 50 } }} />
            <NewLandingSection5 />
            <Box sx={{ height: { xs: 40, md: 50 } }} />
            <NewLandingSection6 />
            <Box sx={{ height: 100 }} />
            <NewLandingSection7 />
            {/* <NewLandingSection8 /> */}
            <Box sx={{ height: { xs: 40, md: 100 } }} />
            <NewLandingSection9 />
            <NewLandingSection10 />
            <NewLandingSection11 />
            <NewLandingSection13 />
            <Box sx={{ height: 160 }} />
          </Box>
        </Box>
      </Box>
      <NewLandingSection12 />
    </>
  );
};
export default NewLandingHomeSection;
