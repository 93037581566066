import { useNavigate } from 'react-router-dom';
import { Stack, Box, Typography, Button, useTheme, Grid } from '@mui/material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

const NewLandingSection3 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box>
      <Grid container spacing={{ xs: 3.75, md: 3.75, lg: 7.5 }}>
        <Grid item xs={12} md={5.7} lg={5}>
          <Stack
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent="center"
            sx={{ px: { xs: 2, md: 0 }, width: '100%', height: '100%' }}
          >
            <Box sx={{ maxWidth: { xs: 435, md: 'fit-content' } }}>
              <img src={'/assets/images/new_landing/image2_1.png'} alt={'image2_1.png'} style={{ width: '100%', height: '100%' }} />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6.3} lg={7}>
          <Stack justifyContent="center" sx={{ px: 2, width: '100%', height: '100%' }}>
            <Stack alignItems="flex-start" spacing={3.75}>
              <Typography variant="h4">Khôra Home</Typography>
              <Typography sx={{ ...theme.typography.p2 }}>
                Khôra Home revolutionary soil-based technology features a composite-based modular structure meticulously designed to create
                the optimal environment to nurture herbs, leafy vegetables, tubers, microgreens, mushrooms, and cuttings. Crafted from
                recyclable lightweight materials, the Khôra Home shell provides unprecedented food safety, security, and seamless
                accessibility.
              </Typography>
              <Typography sx={{ ...theme.typography.p2 }}>
                Designed to combat mold and mildew effectively, the materials undergo rigorous fire-testing to guarantee ultimate
                durability. With a remarkable lifespan of 100 years, Khôra Home commits to sustainability for generations to come.
              </Typography>
              <Button size="large" endIcon={<ArrowRightAltOutlinedIcon />} sx={{ p: 0 }} onClick={() => navigate('/faqs/khora_home')}>
                Learn More
              </Button>
              <Button
                size="large"
                endIcon={<ArrowRightAltOutlinedIcon />}
                sx={{ p: 0 }}
                onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}/product/khora_home`, '_blank')}
              >
                Buy Khôra Home
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NewLandingSection3;
