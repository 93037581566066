import { SHOPPING_NETWORK_LIST_ALL, SHOPPING_NETWORK_ID } from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  networkShoppingList: [],
  networkID: 0
};

// ==============================|| AUTH REDUCER ||============================== //

const shopping = (state = initialState, action) => {
  switch (action.type) {
    case SHOPPING_NETWORK_LIST_ALL: {
      const { networkShoppingList } = action.payload;
      return {
        ...state,
        networkShoppingList,
        isInitialized: true
      };
    }
    case SHOPPING_NETWORK_ID: {
      const { networkID } = action.payload;
      return {
        ...state,
        networkID
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default shopping;
