// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionDetails(theme) {
  return {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '20px',
          backgroundColor: theme.palette.primary[50],
          borderLeft: `solid 1px ${theme.palette.primary[50]}`,
          borderRight: `solid 1px ${theme.palette.primary[50]}`,
          borderBottom: `solid 1px ${theme.palette.primary[50]}`,
          borderRadius: '0px 0px 10px 10px'
        }
      }
    }
  };
}
