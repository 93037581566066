import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';

const CommunitySection2 = () => {
  const theme = useTheme();
  const communityContent = [
    {
      icon: 'icon1.png',
      title: 'Fostering Collaborative Knowledge Sharing',
      description:
        "We encourage a culture of collaboration where community members freely exchange insights, best practices, and experiences to support each other's growth and success through Sprout's Public Libraries."
    },
    {
      icon: 'icon2.png',
      title: 'Enhancing Gardening Skills Through Growing',
      description:
        "Khôra Home and Sprout's cutting-edge smart technology with extensive automation capabilities, enables users to learn about agriculture through growing, enhancing their skills along the way, and adding features through membership upgrades."
    },
    {
      icon: 'icon3.png',
      title: 'Providing Access to Resources',
      description:
        'Members have access to a rich array of resources, including Growing Plans, educational materials, and support networks, equipping them with the necessary tools and information to excel in their cultivation pursuits.'
    },
    {
      icon: 'icon4.png',
      title: 'Maintaining High Standards',
      description:
        'We uphold stringent standards of quality and integrity across all our operations, ensuring that products, services, and resources provided to the Khôra Community meet the highest criteria for excellence and reliability.'
    },
    {
      icon: 'icon5.png',
      title: 'Offering Certification Programs',
      description:
        'We offer certification programs like the Certified Khôra Master Grower program to recognize and elevate members who demonstrate outstanding skill, dedication, and adherence to our principles and standards.'
    },
    {
      icon: 'icon6.png',
      title: 'Continuously Improving',
      description:
        'We are committed to continuous improvement, actively seeking feedback, insights, and emerging trends to refine and enhance our offerings. This ensures that we remain agile and responsive to the evolving needs and aspirations of our community members.'
    }
  ];
  return (
    <Box sx={{ backgroundColor: theme.palette.grey[100] }}>
      <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', py: { xs: 5, md: 10 }, px: 2.5 }}>
        <Stack spacing={3.75}>
          <Typography variant="h3">Khôra Home Community</Typography>
          <Stack spacing={1.25}>
            <Typography variant="p1m">How do we ensure Khôra Home Community success?</Typography>
            <Typography variant="p2">
              To ensure the success of the Khôra Home Community, we implement a series of essential strategies:
            </Typography>
          </Stack>
          <Box>
            <Grid container spacing={3.75}>
              {communityContent?.map((item, idx) => {
                return (
                  <Grid key={`community_content_${idx}`} item xs={12} sm={6} md={4}>
                    <Stack spacing={2} sx={{ p: 2, backgroundColor: 'common.white', borderRadius: '6px', height: '100%' }}>
                      <img
                        src={`/assets/images/faq/${item?.icon}`}
                        alt={`community_content_image_${idx}`}
                        style={{ width: 50, height: 50 }}
                      />
                      <Typography variant="p2m">{item?.title}</Typography>
                      <Typography variant="p3">{item?.description}</Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
export default CommunitySection2;
