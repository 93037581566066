// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function MuiDateCalendar(theme) {
  return {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px'
        }
      }
    }
  };
}
