import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const MyKhoraEditNetwork = Loadable(lazy(() => import('pages/my_khora/network/EditNetwork')));
const MyKhoraEditLocation = Loadable(lazy(() => import('pages/my_khora/network/EditLocation')));
const MyKhoraEditPlantShipAddress = Loadable(lazy(() => import('pages/my_khora/network/EditPlantShipAddress')));
const MyKhoraSeeAccessory = Loadable(lazy(() => import('pages/my_khora/network/SeeAccessory')));
const MyKhoraSeeAdditionalAddress = Loadable(lazy(() => import('pages/my_khora/network/SeeAdditionalAddress')));
const MyKhoraSeeAllKhora = Loadable(lazy(() => import('pages/my_khora/network/SeeAllKhora')));

// ==============================|| MAIN ROUTING ||============================== //

const MyKhoraRoutes = {
  path: 'my_khora',
  element: (
    <MainLayout layout={false} networkShow={false}>
      <Outlet />
    </MainLayout>
  ),
  children: [
    {
      path: 'network',
      element: <MyKhoraEditNetwork />
    },
    {
      path: 'location',
      element: <MyKhoraEditLocation />
    },
    {
      path: 'plant_ship_address',
      element: <MyKhoraEditPlantShipAddress />
    },
    {
      path: 'accessories',
      element: <MyKhoraSeeAccessory />
    },
    {
      path: 'addresses',
      element: <MyKhoraSeeAdditionalAddress />
    },
    {
      path: 'khoras',
      element: <MyKhoraSeeAllKhora />
    }
  ]
};

export default MyKhoraRoutes;
