import FAQWrapper from 'sections/landing/faq/Wrapper';
import LandingFAQSproutSection from 'sections/landing/faq/Sprout';
import { Helmet } from 'react-helmet-async';

const LandingFAQSprout = () => {
  return (
    <>
      <Helmet title="FAQ - Sprout" />
      <FAQWrapper>
        <LandingFAQSproutSection />
      </FAQWrapper>
    </>
  );
};
export default LandingFAQSprout;
