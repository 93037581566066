import { Stack, Typography } from '@mui/material';
const NewLandingSection1 = () => {
  return (
    <>
      {/* <Stack
        sx={{
          position: 'relative',
          ml: '-780px',
          mt: '-2320px',
          width: '2880px',
          height: '2880px',
          borderRadius: '100%',
          overflow: 'hidden',
          backgroundColor: theme.palette.darkgreen[300],
          zIndex: 0
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            mt: '-30px',
            ml: '70px',
            width: '2880px',
            height: '2880px',
            borderRadius: '100%',
            overflow: 'hidden',
            zIndex: 1
          }}
        > */}
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          // mt: '2320px',
          // ml: '710px',
          maxWidth: 1440,
          width: '100%',
          height: 600,
          backgroundImage: 'url("/assets/images/new_landing/image1.png")',
          backgroundPosition: 'center, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <Stack alignItems="center" spacing={1} sx={{ mt: '-60px', p: 3 }}>
          <Typography
            textAlign="center"
            sx={{
              fontSize: { xs: '48px', sm: '56px', md: '68px' },
              lineHeight: { xs: '60px', sm: '72px', md: '86px' },
              fontWeight: { xs: 500, md: 400 },
              color: 'common.white',
              maxWidth: 720
            }}
          >
            Smell, taste, and see the difference!
          </Typography>
          <Typography
            textAlign="center"
            sx={{
              maxWidth: 933,
              fontSize: { xs: '16px', md: '20px' },
              lineHeight: { xs: '24px', md: '30px' },
              fontWeight: { xs: 400, md: 500 },
              color: 'common.white'
            }}
          >
            Transform your farm-to-table experience with Khôra Home and Marketplace.
            <br />
            Redefine home gardening and culinary excellence while joining a vibrant community dedicated to supporting sustainable
            agriculture with local farmers.
          </Typography>
        </Stack>
      </Stack>
      {/* </Stack>
      </Stack> */}
    </>
  );
};
export default NewLandingSection1;
