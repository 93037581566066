// third-party
import { combineReducers } from 'redux';

import snackbarReducer from './snackbar';
import landingMenu from './landingMenu';
import setupProgress from './setupProgress';
import setupStatus from './setupStatus';
import menu from './menu';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  snackbar: snackbarReducer,
  landingMenu,
  setupProgress,
  setupStatus,
  menu
});

export default reducers;
