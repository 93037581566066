import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardContent, Stack, Button, Modal, Typography, useTheme } from '@mui/material';
import AlertCard from 'components/AlertCard';
import { SeedTypeForCost } from 'utils/constants/cost';
import { normalizeString } from 'utils/common';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';

import costReducer, { initialState } from 'store/reducers/_cost';
import {
  CURRENT_CATEGORY,
  CURRENT_LOCATION,
  TAX_DELIVERY,
  WHOLESALE_RETAIL_PRICE_LIST,
  GROWING_PLAN_LIST_FOR_PRICE,
  GROWING_PLAN_COST_TO_EDIT,
  TOTAL_CATEGORY,
  GROWING_TYPE,
  COST_LIST,
  SEED_LIST_FOR_COST,
  CUTTING_LIST_FOR_COST,
  COST_TO_EDIT,
  VENDOR_LIST,
  COST_CATEGORY_LIST,
  CURRENT_RUNNING_CROP_PLAN,
  INITIALIZE
} from 'store/reducers/actions';

import axios from 'utils/axios';

import useSetup from 'hooks/useSetup';

const CostContext = createContext(null);

export const CostProvider = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatchForSnackbar = useDispatch();
  const [state, dispatch] = useReducer(costReducer, initialState);
  const { networkList, network, location, updateLocation, reinitializeStatus } = useSetup();

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const handleErrorAlertOpen = () => setOpenErrorAlert(true);
  const [errTitle, setErrTitle] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const setIsInitialized = useCallback((isInitialized) => {
    dispatch({
      type: INITIALIZE,
      payload: { isInitialized }
    });
  }, []);

  useEffect(() => {
    if (!network?.id) {
      setErrTitle('No network found!');
      setErrMsg('You have no network. Please add your first network');
      setIsInitialized(true);
      handleErrorAlertOpen();
      return;
    }
    if (!location?.id) {
      setErrTitle('No location found!');
      setErrMsg('You have no location. Please add your first location');
      setIsInitialized(true);
      handleErrorAlertOpen();
      return;
    }
  }, [network, location, setIsInitialized]);

  useEffect(() => {
    if (errTitle && !state.isInitialized) setIsInitialized(true);
  }, [state.isInitialized, errTitle, setIsInitialized]);

  const setTaxDelivery = useCallback((values) => {
    dispatch({
      type: TAX_DELIVERY,
      payload: values
    });
  }, []);

  const setNetworkLocationID = useCallback(
    (values) => {
      const network = networkList?.find((item) => item?.id === values.networkID);
      const location = network?.locations?.find((item) => item?.id === values.locationID);
      dispatch({
        type: CURRENT_LOCATION,
        payload: values
      });
      setTaxDelivery({ tax: location?.tax || 0, delivery: location?.delivery || 0 });
    },
    [networkList, setTaxDelivery]
  );

  const setWholesaleRetailPriceList = useCallback((wholesaleRetailPriceList, isInitialized) => {
    dispatch({
      type: WHOLESALE_RETAIL_PRICE_LIST,
      payload: { wholesaleRetailPriceList, isInitialized }
    });
  }, []);

  const setGrowingPlanListForPrice = useCallback((growingPlanListForPrice) => {
    dispatch({
      type: GROWING_PLAN_LIST_FOR_PRICE,
      payload: { growingPlanListForPrice }
    });
  }, []);

  const setGrowingPlanToEdit = useCallback((growingPlanToEdit) => {
    dispatch({
      type: GROWING_PLAN_COST_TO_EDIT,
      payload: { growingPlanToEdit }
    });
  }, []);

  const setCostList = useCallback((costList, isInitialized) => {
    dispatch({
      type: COST_LIST,
      payload: { costList, isInitialized }
    });
  }, []);

  const setSeedListForCost = useCallback((seedListForCost) => {
    dispatch({
      type: SEED_LIST_FOR_COST,
      payload: { seedListForCost }
    });
  }, []);

  const setCuttingListForCost = useCallback((cuttingListForCost) => {
    dispatch({
      type: CUTTING_LIST_FOR_COST,
      payload: { cuttingListForCost }
    });
  }, []);

  const setCostToEdit = useCallback((costToEdit) => {
    dispatch({
      type: COST_TO_EDIT,
      payload: { costToEdit }
    });
  }, []);

  const setCurRunningCropPlan = useCallback((curRunningCropPlan) => {
    dispatch({
      type: CURRENT_RUNNING_CROP_PLAN,
      payload: { curRunningCropPlan }
    });
  }, []);

  const getGrowingPlanListForPrice = useCallback(
    async (locationID, category) => {
      try {
        const res = await axios.get(`/cost/sale/growing_plan/list/${locationID}/${category}`);
        setGrowingPlanListForPrice(res?.data);
        return res?.data;
      } catch (err) {
        dispatchForSnackbar(
          openSnackbar({
            open: true,
            message: err?.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          })
        );
        return [];
      }
    },
    [dispatchForSnackbar, setGrowingPlanListForPrice]
  );

  const getGrowingPlanWholeSaleRetailPriceList = useCallback(
    async (locationID, category, needInitial = true) => {
      try {
        if (needInitial) {
          setWholesaleRetailPriceList([], false);
        }
        const res = await axios.get(`/cost/sale/growing_plan/${locationID}/${category}`);
        setWholesaleRetailPriceList(res?.data, true);
      } catch (err) {
        dispatchForSnackbar(
          openSnackbar({
            open: true,
            message: err?.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          })
        );
        setWholesaleRetailPriceList([], true);
      }
    },
    [setWholesaleRetailPriceList, dispatchForSnackbar]
  );

  const setCategory = useCallback((category) => {
    dispatch({
      type: CURRENT_CATEGORY,
      payload: {
        category
      }
    });
  }, []);

  const createGrowingPlanWholeSaleRetailPrice = useCallback(
    async (locationID, values) => {
      try {
        const res = await axios.put(`/cost/sale/growing_plan/${locationID}/${state.category}`, values);
        setWholesaleRetailPriceList(res.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setWholesaleRetailPriceList(state.wholesaleRetailPriceList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.category, state.wholesaleRetailPriceList, setWholesaleRetailPriceList]
  );

  const updateGrowingPlanWholeSaleRetailPrice = useCallback(
    async (locationID, ID, values) => {
      try {
        const res = await axios.post(`/cost/sale/growing_plan/${locationID}/${state.category}/${ID}`, values);
        setWholesaleRetailPriceList(res.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setWholesaleRetailPriceList(state.wholesaleRetailPriceList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.category, state.wholesaleRetailPriceList, setWholesaleRetailPriceList]
  );

  const copyGrowingPlanWholeSaleRetailPrice = useCallback(
    async (locationID, ID) => {
      try {
        const res = await axios.post(`/cost/sale/growing_plan/copy/${locationID}/${state.category}/${ID}`);
        setWholesaleRetailPriceList(res.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setWholesaleRetailPriceList(state.wholesaleRetailPriceList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.category, state.wholesaleRetailPriceList, setWholesaleRetailPriceList]
  );

  const deleteGrowingPlanWholeSaleRetailPrice = useCallback(
    async (locationID, ID) => {
      try {
        const res = await axios.delete(`/cost/sale/growing_plan/${locationID}/${state.category}/${ID}`);
        setWholesaleRetailPriceList(res.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setWholesaleRetailPriceList(state.wholesaleRetailPriceList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.category, state.wholesaleRetailPriceList, setWholesaleRetailPriceList]
  );

  const getCost = useCallback(
    async (locationID, costType, seedType) => {
      setCostList([], false);
      try {
        const res = await axios.get(`/cost/manage/${locationID}/${costType}/${seedType}`);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList([], true);
        return { status: false, data: err?.message };
      }
    },
    [setCostList]
  );

  const createCost = useCallback(
    async (locationID, costType, seedType, values) => {
      try {
        const res = await axios.put(`/cost/manage/${locationID}/${costType}/${seedType}`, values);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList]
  );

  const updateCost = useCallback(
    async (locationID, costType, seedType, costID, values) => {
      try {
        const res = await axios.post(`/cost/manage/${locationID}/${costType}/${seedType}/${costID}`, values);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList]
  );

  const deleteCost = useCallback(
    async (locationID, costType, seedType, costID) => {
      try {
        const res = await axios.delete(`/cost/manage/${locationID}/${costType}/${seedType}/${costID}`);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList]
  );

  const setTotalCategory = useCallback((type, value) => {
    dispatch({
      type: TOTAL_CATEGORY,
      payload: {
        cubeCategory: type === SeedTypeForCost.SmallKhoraCube ? value : 1,
        microgreenCategory: type === SeedTypeForCost.MicrogreenTray ? value : 1,
        mushroomCategory: type === SeedTypeForCost.Mushroom ? value : 1,
        cuttingCategory: type === SeedTypeForCost.Cutting ? value : 1
      }
    });
  }, []);

  const setGrowingMode = useCallback((value) => {
    dispatch({
      type: GROWING_TYPE,
      payload: { growingMode: value }
    });
  }, []);

  const getSeedListForCost = useCallback(
    async (locationID, seedType) => {
      setSeedListForCost([]);
      try {
        const res = await axios.get(`/cost/assembly/seed_list/${locationID}/${seedType}`);
        setSeedListForCost(
          res?.data?.sort((a, b) => {
            if (seedType === SeedTypeForCost.Mushroom) {
              if (
                normalizeString(a?.mushroom_variety?.title + ' - ' + a.nickname) >
                normalizeString(b?.mushroom_variety?.title + ' - ' + b.nickname)
              )
                return 1;
              if (
                normalizeString(a?.mushroom_variety?.title + ' - ' + a.nickname) <
                normalizeString(b?.mushroom_variety?.title + ' - ' + b.nickname)
              )
                return -1;
              return 0;
            } else {
              if (
                normalizeString(a?.plant_variety?.title + ' - ' + a.nickname) >
                normalizeString(b?.plant_variety?.title + ' - ' + b.nickname)
              )
                return 1;
              if (
                normalizeString(a?.plant_variety?.title + ' - ' + a.nickname) <
                normalizeString(b?.plant_variety?.title + ' - ' + b.nickname)
              )
                return -1;
              return 0;
            }
          })
        );
      } catch (err) {
        dispatchForSnackbar(
          openSnackbar({
            open: true,
            message: err?.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          })
        );
        setSeedListForCost([]);
      }
    },
    [setSeedListForCost, dispatchForSnackbar]
  );

  const getAssemblyCost = useCallback(
    async (locationID, seedType, needInitial = true) => {
      try {
        if (needInitial) setCostList([], false);
        const res = await axios.get(`/cost/assembly/${locationID}/${seedType}`);
        await getSeedListForCost(locationID, seedType);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList([], true);
        return { status: false, data: err?.message };
      }
    },
    [setCostList, getSeedListForCost]
  );

  const createAssemblyCost = useCallback(
    async (locationID, seedType, values) => {
      try {
        const res = await axios.put(`/cost/assembly/${locationID}/${seedType}`, values);
        await getSeedListForCost(locationID, seedType);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList, getSeedListForCost]
  );

  const updateAssemblyCost = useCallback(
    async (locationID, seedType, costID, values) => {
      try {
        const res = await axios.post(`/cost/assembly/${locationID}/${seedType}/${costID}`, values);
        await getSeedListForCost(locationID, seedType);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList, getSeedListForCost]
  );

  const deleteAssemblyCost = useCallback(
    async (locationID, seedType, costID) => {
      try {
        const res = await axios.delete(`/cost/assembly/${locationID}/${seedType}/${costID}`);
        await getSeedListForCost(locationID, seedType);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList, getSeedListForCost]
  );

  const getCostSum = useCallback(async (locationID, costType, seedType) => {
    try {
      const res = await axios.get(`/cost/manage/sum/${locationID}/${costType}/${seedType}`);
      return res.data;
    } catch (err) {
      return { value: 0 };
    }
  }, []);

  const saveTaxDelivery = useCallback(
    async (values) => {
      try {
        if (parseInt(values?.locationID) !== 0) {
          const res = await updateLocation(values?.networkID, values?.locationID, values);
          if (!res?.status) return res;
        }
        if (parseInt(values?.networkID) !== 0 && parseInt(values?.locationID) === 0) {
          await axios.post(`cost/tax_delivery/${values?.networkID}`, values);
          await reinitializeStatus();
        }
        if (parseInt(values?.networkID) === 0 && parseInt(values?.locationID) === 0) {
          await axios.post(`cost/tax_delivery`, values);
          await reinitializeStatus();
        }
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [updateLocation, reinitializeStatus]
  );

  const getAllSeedCost = useCallback(async (seedType) => {
    try {
      const res = await axios.get(`/cost/assembly/${seedType}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getCuttingListForCost = useCallback(
    async (locationID) => {
      setCuttingListForCost([]);
      try {
        const res = await axios.get(`/cost/cutting/cutting_list/${locationID}`);
        setCuttingListForCost(
          res?.data?.sort((a, b) => {
            if (
              normalizeString(a?.plant_variety?.title + ' - ' + a.nickname) > normalizeString(b?.plant_variety?.title + ' - ' + b.nickname)
            )
              return 1;
            if (
              normalizeString(a?.plant_variety?.title + ' - ' + a.nickname) < normalizeString(b?.plant_variety?.title + ' - ' + b.nickname)
            )
              return -1;
            return 0;
          })
        );
      } catch (err) {
        dispatchForSnackbar(
          openSnackbar({
            open: true,
            message: err?.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          })
        );
      }
    },
    [setCuttingListForCost, dispatchForSnackbar]
  );

  const getCuttingAssemblyCost = useCallback(
    async (locationID, needInitial = true) => {
      try {
        if (needInitial) setCostList([], false);
        const res = await axios.get(`/cost/cutting/${locationID}`);
        await getCuttingListForCost(locationID);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList([], true);
        return { status: false, data: err?.message };
      }
    },
    [setCostList, getCuttingListForCost]
  );

  const createCuttingAssemblyCost = useCallback(
    async (locationID, values) => {
      try {
        const res = await axios.put(`/cost/cutting/${locationID}`, values);
        await getCuttingListForCost(locationID);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList, getCuttingListForCost]
  );

  const updateCuttingAssemblyCost = useCallback(
    async (locationID, costID, values) => {
      try {
        const res = await axios.post(`/cost/cutting/${locationID}/${costID}`, values);
        await getCuttingListForCost(locationID);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList, getCuttingListForCost]
  );

  const deleteCuttingAssemblyCost = useCallback(
    async (locationID, costID) => {
      try {
        const res = await axios.delete(`/cost/cutting/${locationID}/${costID}`);
        await getCuttingListForCost(locationID);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList(state.costList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.costList, setCostList, getCuttingListForCost]
  );

  const getAllCuttingCost = useCallback(async () => {
    try {
      const res = await axios.get(`/cost/cutting`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setVendorList = useCallback((vendorList) => {
    dispatch({
      type: VENDOR_LIST,
      payload: {
        vendorList
      }
    });
  }, []);

  const getVendorList = useCallback(async () => {
    try {
      const res = await axios.get('/list/vendor/by_name');
      setVendorList(res?.data);
    } catch (err) {
      setVendorList([]);
    }
  }, [setVendorList]);

  useEffect(() => {
    getVendorList();
  }, [getVendorList]);

  const setAllCostCategoryLists = useCallback(async (costCategoryList) => {
    dispatch({
      type: COST_CATEGORY_LIST,
      payload: {
        costCategoryList
      }
    });
  }, []);

  const getAllCostCategoryLists = useCallback(async () => {
    try {
      const res = await axios.get('/type/cost_category');
      setAllCostCategoryLists(res?.data);
    } catch (err) {
      setAllCostCategoryLists([]);
    }
  }, [setAllCostCategoryLists]);

  useEffect(() => {
    getAllCostCategoryLists();
  }, [getAllCostCategoryLists]);

  const getCurRunningCropPlan = useCallback(async () => {
    try {
      const res = await axios.get('/crop_plan/main/running');
      setCurRunningCropPlan(res?.data);
    } catch (err) {
      setCurRunningCropPlan(null);
    }
  }, [setCurRunningCropPlan]);

  const getCostForTotal = useCallback(
    async (locationID, seedType, needInitial = true) => {
      if (needInitial) setCostList([], false);
      try {
        const res = await axios.get(`/cost/total/${locationID}/${seedType}`);
        setCostList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCostList([], true);
        return { status: true, data: err?.message };
      }
    },
    [setCostList]
  );

  const getCostSummaryInfo = useCallback(async () => {
    try {
      const res = await axios.get(`/cost/manage/summary_info`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const importCostData = useCallback(async (locationID, values) => {
    try {
      const res = await axios.post(`/cost/manage/apply/${locationID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  useEffect(() => {
    if (state.isInitialized && network?.id && location?.id) {
      getCurRunningCropPlan();
    }
  }, [network, location, state.isInitialized, getCurRunningCropPlan]);

  return (
    <CostContext.Provider
      value={{
        ...state,
        setIsInitialized,
        setCategory,
        setNetworkLocationID,
        setGrowingPlanToEdit,
        setCostToEdit,
        setCurRunningCropPlan,
        getGrowingPlanListForPrice,
        getGrowingPlanWholeSaleRetailPriceList,
        createGrowingPlanWholeSaleRetailPrice,
        updateGrowingPlanWholeSaleRetailPrice,
        deleteGrowingPlanWholeSaleRetailPrice,
        copyGrowingPlanWholeSaleRetailPrice,
        setTotalCategory,
        setGrowingMode,
        getCost,
        createCost,
        updateCost,
        deleteCost,
        getAssemblyCost,
        createAssemblyCost,
        updateAssemblyCost,
        deleteAssemblyCost,
        getCostSum,
        getAllSeedCost,
        getCuttingAssemblyCost,
        createCuttingAssemblyCost,
        updateCuttingAssemblyCost,
        deleteCuttingAssemblyCost,
        getAllCuttingCost,
        getVendorList,
        getAllCostCategoryLists,
        saveTaxDelivery,
        getCurRunningCropPlan,
        getCostForTotal,
        getCostSummaryInfo,
        importCostData
      }}
    >
      {children}
      <Modal open={openErrorAlert}>
        <AlertCard title={errTitle} sx={{ width: { xs: '90%', md: '640px' } }}>
          <CardContent>
            <Typography gutterBottom sx={theme.typography.p2}>
              {errMsg}
            </Typography>
          </CardContent>
          <Stack direction="row" spacing={3} justifyContent="center" sx={{ p: 2.5 }}>
            <Button variant="contained" size="large" onClick={() => navigate('/my_khora')}>
              Yes
            </Button>
          </Stack>
        </AlertCard>
      </Modal>
    </CostContext.Provider>
  );
};

CostProvider.propTypes = {
  children: PropTypes.node
};

export default CostContext;
