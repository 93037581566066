import { useSelector } from 'react-redux';

// material-ui
import { Box, Divider, Stack, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
// project import
import NavGroup from './NavGroup';
import menuItems from 'menuItems';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;
  const { user } = useAuth();

  const items = menuItems(user?.membership);

  const navGroups = items.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  const navGroups_ = items.items_.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  const extraGroups = items.extras.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  const extraGroups_ = items.extras_.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Stack direction="column">
      <Box sx={{ minHeight: 'calc(100vh - 290px)' }}>{drawerOpen ? navGroups : navGroups_}</Box>
      <Divider sx={{ my: 2.5 }} />
      <Box>{drawerOpen ? extraGroups : extraGroups_}</Box>
    </Stack>
  );
};

export default Navigation;
