export const TodoStatus = {
  Current: 2,
  Future: 3,
  Past: 1
};
export const TodoType = {
  Planting: { value: 1, image: 'plant_placement.svg' },
  Transplanting: { value: 2, image: 'transplant_steps.svg' },
  Growing: { value: 3, image: 'growing_steps.svg' }
};
export const TodoTaskType = {
  Maintenance: 1,
  Growing: 2,
  Transplanting: 3,
  Action: 4
};
