// react
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
// material-ui
import { Box, Fab } from '@mui/material';
// third party
import { useSelector } from 'store';
// icon
import ArrowUpOutlined from '@mui/icons-material/ArrowUpwardOutlined';

// ==============================|| LANDING - WRAPPER ||============================== //

const LandingWrapper = (props) => {
  const { showButton, setShowButton, children } = props;
  const { menuOpen } = useSelector((state) => state.landingMenu);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset > 90 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener('scroll', handleScrollButtonVisibility);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, [setShowButton]);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box>
      {children}
      {showButton && !menuOpen ? (
        <Fab
          onClick={handleScrollTop}
          sx={{
            position: 'fixed',
            bottom: { xs: 58, md: 82, lg: 110 },
            right: { xs: 40, md: 80 },
            width: 64,
            height: 64,
            borderRadius: '50%'
          }}
        >
          <ArrowUpOutlined />
        </Fab>
      ) : null}
    </Box>
  );
};

LandingWrapper.propTypes = {
  showButton: PropTypes.bool,
  setShowButton: PropTypes.func,
  children: PropTypes.node
};

export default memo(LandingWrapper);
