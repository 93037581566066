import { TodoStatus } from 'utils/constants/todo';
import { TODO_TO_EDIT, TODO_LIST, TODO_STATUS } from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  todoToEdit: null,
  todoList: [],
  todoStatus: TodoStatus.Current
};

// ==============================|| AUTH REDUCER ||============================== //

const todo = (state = initialState, action) => {
  switch (action.type) {
    case TODO_LIST: {
      const { todoList } = action.payload;
      return {
        ...state,
        todoList,
        isInitialized: true
      };
    }
    case TODO_TO_EDIT: {
      const { todoToEdit } = action.payload;
      return {
        ...state,
        todoToEdit
      };
    }
    case TODO_STATUS: {
      const { todoStatus } = action.payload;
      return {
        ...state,
        todoStatus
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default todo;
