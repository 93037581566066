import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';
import ReactPlayer from 'react-player';

const LandingSellersSection = () => {
  const theme = useTheme();
  const titleStyle = {
    fontSize: { xs: '32px', md: '42px' },
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: { xs: '44px', md: '60px' }
  };
  const contentStyle = { maxWidth: 1140, width: '100%', margin: 'auto', px: '20px', pt: { xs: '40px', md: '60px' } };

  return (
    <>
      <Box sx={{ width: '100%', background: theme.palette.common.white, pb: { xs: '40px', md: '60px' } }}>
        <Box
          sx={{
            backgroundImage: 'url("/assets/images/landing/background.jpg")',
            backgroundPosition: 'center, center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%'
          }}
        >
          <Box sx={{ ...contentStyle, pb: { xs: '40px', md: '60px' } }}>
            <Stack spacing={3.75} justifyContent="center">
              <Typography sx={{ ...titleStyle, color: theme.palette.common.white }}>
                Khôra Home Growers Can Become Khôra Marketplace Sellers
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box sx={{ ...contentStyle, px: { xs: '20px', md: '80px' } }}>
          <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" spacing={{ xs: 3.75, md: 7 }}>
            <Stack spacing={4}>
              <Typography variant="p2m">
                Khôra Marketplace is an online farmers market that connects you to fresh, local foods, unique finds, and city-wide events.
                It simplifies traditional farmers market shopping with a convenient, all-in-one platform.
              </Typography>
              <Typography variant="p2">
                Integrated with Khôra Marketplace, Khôra Home facilitates the sale of surplus to dedicated farmers market supporters,
                fostering more self-reliant and self-sustaining communities.
              </Typography>
            </Stack>
            <img src="/assets/images/landing/lemon.png" alt="lemon" style={{ width: '100%', maxWidth: '253px', height: 'auto' }} />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ width: '100%', backgroundColor: theme.palette.primary[100], pb: { xs: '40px', md: '60px' } }}>
        <Box sx={contentStyle}>
          <Box sx={{ maxWidth: 620, margin: 'auto' }}>
            <ReactPlayer
              config={{
                file: {
                  attributes: {
                    controls: true,
                    autoPlay: false,
                    muted: false
                  }
                }
              }}
              url={'/assets/video/marketplace.mp4'}
              width={'100%'}
              height={'100%'}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default LandingSellersSection;
