import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { VendorProvider } from 'contexts/VendorContext';
import { CustomerProvider } from 'contexts/CustomerContext';
import { SearchProvider } from 'contexts/SearchContext';
import { PlantPlacementProvider } from 'contexts/PlantPlacementContext';

// render - My Khôra
const MyKhora = Loadable(lazy(() => import('pages/my_khora')));
// render - Contact Us
const ContactUs = Loadable(lazy(() => import('pages/contact_us')));
// render - PlantPlacement
const PlantPlacement = Loadable(lazy(() => import('pages/plant_placement')));
// render - Vendor Management
const VendorManagement = Loadable(lazy(() => import('pages/vendor_management')));
// render - Customer Management
const CustomerManagement = Loadable(lazy(() => import('pages/customer_management')));
// render - Search
const Searches = Loadable(lazy(() => import('pages/search')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '',
  element: (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ),
  children: [
    {
      path: 'my_khora',
      element: <MyKhora />
    },
    {
      path: 'contact_us',
      element: <ContactUs />
    },
    {
      path: 'plant_placement',
      element: (
        <PlantPlacementProvider>
          <PlantPlacement />
        </PlantPlacementProvider>
      )
    },
    {
      path: 'vendor',
      element: (
        <VendorProvider>
          <VendorManagement />
        </VendorProvider>
      )
    },
    {
      path: 'customer',
      element: (
        <CustomerProvider>
          <CustomerManagement />
        </CustomerProvider>
      )
    },
    {
      path: 'search',
      element: (
        <SearchProvider>
          <Searches />
        </SearchProvider>
      )
    }
  ]
};

export default MainRoutes;
