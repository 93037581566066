import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

import { CropPlanProvider } from 'contexts/CropPlanContext';
import { ScheduleProvider } from 'contexts/ScheduleContext';
import { PlantPlacementProvider } from 'contexts/PlantPlacementContext';

// render - Schedule
const ScheduleV2 = Loadable(lazy(() => import('pages/schedule_v2')));
const ScheduleCalendar = Loadable(lazy(() => import('pages/schedule_v2/calendar')));
// ==============================|| MAIN ROUTING ||============================== //

const ScheduleV2Routes = {
  path: 'schedule',
  element: (
    <CropPlanProvider>
      <ScheduleProvider>
        <PlantPlacementProvider>
          <MainLayout networkShow={false}>
            <Outlet />
          </MainLayout>
        </PlantPlacementProvider>
      </ScheduleProvider>
    </CropPlanProvider>
  ),
  children: [
    {
      path: '',
      element: <ScheduleV2 />
    },
    {
      path: 'calendar',
      element: <ScheduleCalendar />
    }
  ]
};

export default ScheduleV2Routes;
