import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
// material-ui
import { Grid, Box, Typography, Stack } from '@mui/material';
import { handleScrollUp } from 'utils/common';

const LogoImage = '/assets/images/khora_logo_final_white.svg';

// ==============================|| LANDING - FOOTER ||============================== //

const PolicyLinkList = [
  { name: 'All rights reserved', url: '/' },
  { name: 'Terms of Use', url: '/' },
  { name: 'Privacy Policy', url: '/' }
];

const LandingFooter = () => {
  const theme = useTheme();
  const location = useLocation();
  const { pathname } = location;

  const fontStyle = { ...theme.typography.p3, color: theme.palette.grey[25], textDecoration: 'none' };

  const linkStyle = {
    ...theme.typography.p3,
    textDecoration: 'none',
    color: theme.palette.common.white,
    cursor: 'pointer'
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: theme.palette.darkgreen[900] }}>
      <Box
        sx={{
          maxWidth: 1140,
          width: '100%',
          margin: 'auto',
          px: '20px',
          pb: { xs: '48px', md: '60px' },
          pt: {
            xs: pathname === '/' ? '348px' : '60px',
            sm: pathname === '/' ? '248px' : '80px'
          }
        }}
      >
        <Grid container spacing={3.75}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleScrollUp('/')}>
              <img src={LogoImage} alt="logo" style={{ height: '20px', width: 'auto' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Stack spacing={3.75}>
              <Stack spacing={1.25}>
                <Typography sx={{ ...fontStyle }}>3504 Scenic Overlook Trail, Austin, TX 78734 USA</Typography>
                <Typography sx={{ ...fontStyle }}>+1 (312) 399-0680</Typography>
                <Typography sx={{ ...fontStyle }}>contact@khorahome.com</Typography>
              </Stack>
              <Box>
                <Grid container spacing={3}>
                  <Grid item>
                    <Link to="https://www.facebook.com/profile.php?id=100094737557476" target="_blank">
                      <img src={'/assets/icons/social/facebook.png'} alt="facebook" style={{ width: '28px', height: 'auto' }} />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="https://www.instagram.com/khorahome" target="_blank">
                      <img src={'/assets/icons/social/instagram.png'} alt="instagram" style={{ width: '28px', height: 'auto' }} />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="https://www.reddit.com/user/KhoraHome" target="_blank">
                      <img src={'/assets/icons/social/reddit.png'} alt="reddit" style={{ width: '28px', height: 'auto' }} />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="https://www.youtube.com/channel/UCj_0ORks-7AdxYkRQJS5yGQ" target="_blank">
                      <img src={'/assets/icons/social/youtube.png'} alt="youtube" style={{ width: '28px', height: 'auto' }} />
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2.5}>
                  <Grid item>
                    <Link to="/login" style={linkStyle}>
                      Sprout - The Grower{"'"}s Platform
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/membership" style={linkStyle}>
                      Sprout{"'"}s Memberships
                    </Link>
                  </Grid>
                  <Grid item>
                    <Typography style={linkStyle} onClick={() => window.open(process.env['REACT_APP_MARKETPLACE_URL'], '_blank')}>
                      Khôra Marketplace
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2.5}>
                  <Grid item>
                    <Link to="/journey" style={linkStyle} onClick={handleScrollUp}>
                      Our Journey
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/mascot" style={linkStyle} onClick={handleScrollUp}>
                      Our Khôra Mascots
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/community" style={linkStyle} onClick={handleScrollUp}>
                      Our Khôra Community
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/news/top" style={linkStyle} onClick={handleScrollUp}>
                      Khôra News
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2.5}>
                  <Grid item>
                    <Typography
                      onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}/product/khora_home`, '_blank')}
                      style={linkStyle}
                    >
                      Khôra Home Release Status
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link to="/faqs/khora_home" style={linkStyle} onClick={handleScrollUp}>
                      FAQ
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/about" style={linkStyle} onClick={handleScrollUp}>
                      About Us
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/contact" style={linkStyle} onClick={handleScrollUp}>
                      Contact Us
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: { xs: 'none', sm: 'block' }, mb: '10px' }}>
              {PolicyLinkList?.map((item, idx) => {
                return (
                  <span key={'policy_' + idx}>
                    <Typography component={Link} sx={fontStyle} to={item?.url}>
                      {item?.name}
                    </Typography>
                    {idx < PolicyLinkList?.length - 1 ? (
                      <span style={{ padding: '20px', color: theme.palette.common.white }}>|</span>
                    ) : null}
                  </span>
                );
              })}
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              {PolicyLinkList?.map((item, idx) => {
                return (
                  <Box key={'policy_xs_' + idx} sx={{ mb: '10px' }}>
                    <Typography component={Link} sx={fontStyle} to={item?.url}>
                      {item?.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default LandingFooter;
