import { SetupURL, SetupRedirectURL, SetupStep } from 'utils/constants/setup';

const SetupConfig = {
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.Network]}`]: {
    progressStep: 0,
    showProgress: true
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.Location]}`]: {
    progressStep: 1,
    showProgress: true
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.Accessory]}`]: {
    progressStep: 2,
    showProgress: true
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.NameKhora]}`]: {
    progressStep: 2,
    showProgress: true
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.Stacking]}`]: {
    progressStep: 2,
    showProgress: true
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.SetShelves]}`]: {
    progressStep: 3,
    showProgress: true
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.Another]}`]: {
    progressStep: 0,
    showProgress: false
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.ShelfManagement]}`]: {
    progressStep: 3,
    showProgress: true
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.KhoraSetupComplete]}`]: {
    progressStep: 0,
    showProgress: false
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.GrowInterest]}`]: {
    progressStep: 0,
    showProgress: false
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.GrowAuto]}`]: {
    progressStep: 0,
    showProgress: false
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.GrowWhat]}`]: {
    progressStep: 0,
    showProgress: false
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.GrowPrefer]}`]: {
    progressStep: 0,
    showProgress: false
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.UserSetupComplete]}`]: {
    progressStep: 0,
    showProgress: false
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.MyKhora]}`]: {
    progressStep: 0,
    showProgress: false
  },
  [`/${SetupURL}/${SetupRedirectURL[SetupStep.AdditionalAddress]}`]: {
    progressStep: 0,
    showProgress: false
  }
};

export default SetupConfig;
