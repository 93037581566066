import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection6 = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">Can Sprout grow different plants together?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={8}>
            <Stack justifyContent="center" spacing={1.25} sx={{ height: '100%' }}>
              <Typography variant="p2">
                Yes, Sprout leverages the individual Growing Plan for each plant and integrates them into a single comprehensive Crop Plan.
                By utilizing sophisticated algorithms, Sprout ensures that each plant{"'"}s specific requirements are accommodated.
              </Typography>
              <Typography variant="p2">Users can create, save, and schedule Crop Plans at their convenience.</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                minHeight: 160,
                height: '100%',
                width: 'auto',
                backgroundImage: 'url("/assets/images/faq/sprout_12.png")',
                backgroundPosition: 'center, center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQSproutSection6;
