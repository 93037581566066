import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
// project import
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { openDrawer } from 'store/reducers/menu';
import { DRAWER_WIDTH, MAIN_HEADER_HEIGHT } from 'config';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;
  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);

  useEffect(() => {
    if (matchDownLG) dispatch(openDrawer({ drawerOpen: false }));
  }, [matchDownLG, dispatch]);

  return (
    <Box component="nav">
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open} sx={{ position: 'relative' }}>
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              backgroundColor: theme.palette.common.white,
              paddingTop: `${MAIN_HEADER_HEIGHT - 40}px`,
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              backgroundImage: 'none',
              boxShadow: 'inherit'
            }
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  window: PropTypes.object,
  handleDrawerToggle: PropTypes.func
};

export default MainDrawer;
