import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  ClickAwayListener,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  IconButton,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery,
  Stack,
  FormControlLabel,
  Switch
} from '@mui/material';
// assets
import AlertIcon from '@mui/icons-material/NotificationsOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import SimpleBar from 'components/third-party/SimpleBar';

import { formatDate, getTimeDifferent } from 'utils/common';
import { NotificationCategories } from 'utils/constants/notification';

import useAuth from 'hooks/useAuth';

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const iconButtonStyle = { '&:hover': { backgroundColor: 'transparent' } };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const {
    notifications,
    notificationCount,
    readAllNotification,
    readOneNotification,
    unReadOneNotification,
    viewAllNotification,
    setNotificationCount,
    deleteOneNotification
  } = useAuth();

  const [notificationsToShow, setNotificationsToShow] = useState([]);
  const [unRead, setUnRead] = useState(false);

  const handleToggle = () => {
    if (!open) {
      setNotificationCount(0);
      viewAllNotification();
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const markAllAsRead = () => {
    readAllNotification();
    setNotificationsToShow(notificationsToShow?.map((item) => ({ ...item, read: true })));
  };

  const markAsReadNotification = (ID) => {
    readOneNotification(ID);
    setNotificationsToShow(
      notificationsToShow?.map((item) => {
        return { ...item, read: item?.id === ID ? true : item?.read };
      })
    );
  };

  const markAsUnReadNotification = (ID) => {
    unReadOneNotification(ID);
    setNotificationsToShow(
      notificationsToShow?.map((item) => {
        return { ...item, read: item?.id === ID ? false : item?.read };
      })
    );
  };

  useEffect(() => {
    setNotificationsToShow(
      notifications?.map((item) => {
        return {
          ...item,
          read: !!item?.read_at
        };
      })
    );
  }, [notifications]);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ ...iconButtonStyle, alignSelf: 'center' }}
      >
        <Badge badgeContent={notificationCount}>
          <AlertIcon sx={{ color: theme.palette.grey[900] }} />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesMD ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesMD ? -5 : 0, 9]
              }
            }
          ]
        }}
        sx={{ width: { xs: '90%', sm: '460px' } }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                border: `solid 1px ${theme.palette.grey[300]}`,
                boxShadow: theme.customShadows.z1,
                width: '100%'
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title={<Typography variant="h6">Notifications</Typography>}
                  elevation={0}
                  border={false}
                  content={false}
                  divider={false}
                  secondary={
                    <Button sx={{ fontSize: theme.typography.p3, px: 1.5, py: 0.5 }} onClick={markAllAsRead}>
                      Mark all as read
                    </Button>
                  }
                >
                  <SimpleBar
                    sx={{
                      maxHeight: '50vh',
                      overflowX: 'hidden',
                      '& .simplebar-content': {
                        display: 'flex',
                        flexDirection: 'column'
                      }
                    }}
                  >
                    <Stack spacing={0.5}>
                      {notificationsToShow
                        ?.filter((item) => (unRead ? !item?.read : true))
                        ?.map((notification, idx) => {
                          const notificationCategory = Object.keys(NotificationCategories).find(
                            (item) => NotificationCategories[item].value == notification?.data?.message_type
                          );
                          return (
                            <Box key={`notification_item_${idx}`} sx={{ '&:hover': { backgroundColor: theme.palette.grey[50] } }}>
                              <ListItem
                                display="flex"
                                alignItems="center"
                                onClick={(e) => {
                                  navigate('/dashboard/notification');
                                  handleClose(e);
                                }}
                                sx={{ px: 2.5, cursor: 'pointer' }}
                              >
                                <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>
                                  <img
                                    src={`/assets/icons/notification/${NotificationCategories[notificationCategory].image}`}
                                    alt={`category_image_${notificationCategory}`}
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      border: `solid 2px ${theme.palette.common.white}`,
                                      borderRadius: '100px'
                                    }}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        ...theme.typography.p3,
                                        color: notification?.data?.standard_type === 18 ? theme.palette.error.main : 'inherit'
                                      }}
                                    >
                                      {notification?.data?.title}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography sx={{ ...theme.typography.p4, mt: 0.25 }}>
                                      {`${formatDate(notification?.created_at, false)} (${getTimeDifferent(notification?.created_at)})`}
                                    </Typography>
                                  }
                                  sx={{ my: 0 }}
                                />
                                <Stack direction="row" alignItems="center" spacing={0.25}>
                                  <Tooltip title={notification?.read ? 'Mark as unread' : 'Mark as read'}>
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (notification?.read) {
                                          markAsUnReadNotification(notification?.id);
                                        } else {
                                          markAsReadNotification(notification?.id);
                                        }
                                      }}
                                      sx={{ borderRadius: '100px' }}
                                    >
                                      {notification?.read ? (
                                        <Box
                                          sx={{
                                            height: 16,
                                            width: 16,
                                            borderRadius: '100px',
                                            border: `solid 2px ${theme.palette.primary[400]}`
                                          }}
                                        />
                                      ) : (
                                        <Box
                                          sx={{
                                            height: 16,
                                            width: 16,
                                            borderRadius: '100px',
                                            border: `solid 2px ${theme.palette.darkgreen[300]}`,
                                            backgroundColor: theme.palette.darkgreen[300]
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        deleteOneNotification(notification?.id);
                                        setNotificationsToShow(notificationsToShow?.filter((item) => item?.id !== notification?.id));
                                      }}
                                    >
                                      <DeleteIcon style={{ color: theme.palette.grey[400] }} />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </ListItem>
                            </Box>
                          );
                        })}
                    </Stack>
                  </SimpleBar>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2.5, py: 2.5 }}>
                    <FormControlLabel
                      control={<Switch size="small" checked={unRead} onChange={(e) => setUnRead(e.target.checked)} sx={{ m: 0 }} />}
                      label="Only show unread"
                      sx={{
                        p: 0,
                        '& .MuiFormControlLabel-label': {
                          fontSize: theme.typography.p3
                        }
                      }}
                    />
                    <Typography
                      onClick={() => {
                        setOpen(false);
                        navigate('/dashboard/notification');
                      }}
                      sx={{ ...theme.typography.p3, textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      See all notifications
                    </Typography>
                  </Stack>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
