import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box } from '@mui/material';
// project import
import CommonHeader from 'layout/CommonHeader';
import { MAIN_HEADER_HEIGHT } from 'config';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ networkShow = true }) => {
  const theme = useTheme();
  // header content
  const headerLayout = useMemo(() => <CommonHeader networkShow={networkShow} />, [networkShow]);
  // common header
  const mainHeader = (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        py: '16px',
        px: '20px',
        width: '100%',
        height: MAIN_HEADER_HEIGHT
      }}
    >
      {headerLayout}
    </Box>
  );

  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `2px solid ${theme.palette.grey[100]}`,
      backgroundColor: theme.palette.common.white,
      zIndex: theme.zIndex.drawer + 1
    }
  };

  return <AppBar {...appBar}>{mainHeader}</AppBar>;
};
Header.propTypes = {
  networkShow: PropTypes.bool
};
export default Header;
