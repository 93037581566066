import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';
import { SetupURL, SetupRedirectURL, GuideCheck, SetupStep } from 'utils/constants/setup';
import { AuthURL } from 'utils/constants/auth';
import { APP_DEFAULT_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const getRedirectUrl = useCallback(() => {
    if (user?.setup_status === GuideCheck.NeverChecked) return '/guide';
    if (user?.current_step !== SetupStep.KhoraSetupComplete) return `/${SetupURL}/${SetupRedirectURL[user?.current_step]}`;
    if (!path || path.length === 0) return APP_DEFAULT_PATH;
    if (AuthURL.indexOf(path.substring(1, path.length)) > -1) return APP_DEFAULT_PATH;
    return path;
  }, [user, path]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(getRedirectUrl(), { replace: true });
    }
  }, [isLoggedIn, navigate, getRedirectUrl]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
