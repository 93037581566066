import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQMarketplaceSection3 = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">Is there a way to fast-track to become a Certified Khôra Master Grower?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={8}>
            <Stack justifyContent="center" spacing={2.5} sx={{ height: '100%' }}>
              <Typography variant="p2">
                Certainly! There is a fast-track option available for commercial-level growers who are actively building and establishing
                Khôra Home farms. As part of the Khôra Home consulting services provided during the farm build-out process, these growers
                will be expedited through the certification process to become Certified Khôra Master Growers.
              </Typography>
              <Typography variant="p2">
                This comprehensive process ensures that Certified Khôra Master Growers meet the highest standards of quality, commitment,
                and expertise, enabling them to thrive on the Khôra Marketplace and contribute to the success of the Khôra Home Community.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                minHeight: 220,
                height: '100%',
                width: 'auto',
                backgroundImage: 'url("/assets/images/faq/marketplace_3.png")',
                backgroundPosition: 'center, center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQMarketplaceSection3;
