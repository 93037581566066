import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQMarketplaceSection2 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Join the Khôra Home Community',
      description: 'Transition from your Sprout Membership trial to become a member of the Khôra Home Community.'
    },
    {
      title: 'Submit a Growing Plan',
      description: 'Share your best Growing Plan for review to be included in Khôra Home Public Library.'
    },
    {
      title: 'Complete the Khôra Home Review',
      description:
        "A Khôra Home Growing Specialist will evaluate your submission based on established criteria to determine if it's suitable for growing trials."
    },
    {
      title: "Complete Khôra Home's Growing Trials",
      description:
        'Khôra Home Concept Farm will conduct multiple growing trials using the seeds and materials specified in your Growing Plan.'
    },
    {
      title: 'Gain Growing Plan Acceptance',
      description:
        'If your Growing Plan is accepted into Khôra Home Public Library, it will be accessible to the global community of Khôra Home growers, establishing you as the creator.'
    },
    {
      title: 'Accumulate Favorite Votes',
      description:
        "The more favorite votes your Growing Plan receives, the quicker you'll rise as a top grower and be eligible to become a Certified Khôra Master Grower."
    },
    {
      title: 'Apply for Certification',
      description:
        "Complete the necessary coursework, demonstrate adherence to Khôra's strict growing standards, use approved materials and technology, and ensure your plants meet quality standards and government regulatory requirements for sale."
    },
    {
      title: 'List Products on Marketplace',
      description:
        'Once certified, you can list your products on the Khôra Marketplace, showcasing your premium-quality produce and goods to the Khôra Home Community.'
    },
    {
      title: 'Maintain Certification Annually',
      description:
        'Renew your certification annually and stay updated on educational courses to keep pace with advancements in Khôra Home, Sprout, and the Marketplace.'
    }
  ];

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">How do you become a Certified Khôra Master Grower to sell plants on Khôra Marketplace?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2.5}>
            <Stack spacing={3.75} direction={{ xs: 'column', sm: 'row' }} alignItems="center" sx={{ maxWidth: 914 }}>
              <Stack justifyContent="space-around" spacing={1.25}>
                <Typography variant="p2">
                  Here{"'"}s a step-by-step guide on how to become a Certified Khôra Master Grower and sell plants on the Khôra Marketplace:
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3.75}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    borderRadius: '10px',
                    cursor: 'pointer',
                    backgroundImage: 'url("/assets/images/faq/marketplace_2.png")',
                    backgroundPosition: 'center, center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: 220,
                    height: 160
                  }}
                />
              </Stack>
            </Stack>
            <Box>
              <Stack
                sx={{
                  p: 2.5,
                  borderRadius: '6px',
                  border: `solid 1px ${theme.palette.primary[300]}`,
                  backgroundColor: 'common.white',
                  height: '100%'
                }}
              >
                <Grid container spacing={2}>
                  {contentData?.map((item, idx) => {
                    return [
                      <Grid key={`content_info_${idx}`} item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1.25}>
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ width: 50, height: 78, backgroundColor: theme.palette.primary[50] }}
                          >
                            <Typography variant="h4" sx={{ color: theme.palette.darkgreen[300] }}>
                              {idx + 1}
                            </Typography>
                          </Stack>
                          <Stack spacing={1.25} sx={{ width: `calc(100% - 50px)` }}>
                            <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                              {item?.title}
                            </Typography>
                            <Typography variant="p2">{item?.description}</Typography>
                          </Stack>
                        </Stack>
                      </Grid>,
                      idx < contentData?.length - 1 && (
                        <Grid key={`content_info_divider_${idx}`} item xs={12}>
                          <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        </Grid>
                      )
                    ];
                  })}
                </Grid>
              </Stack>
            </Box>
            <Typography variant="p2">
              This comprehensive process ensures that Certified Khôra Master Growers meet the highest standards of quality, commitment, and
              expertise, enabling them to thrive on the Khôra Marketplace and contribute to the success of the Khôra Home Community.
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FAQMarketplaceSection2;
