import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid, Divider } from '@mui/material';
import SpotItem from 'components/SpotItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQMarketplaceSection4 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Buyer',
      category: 1,
      description: ['Individuals who purchase products from the Khôra Marketplace for their personal or business use.']
    },
    {
      title: 'Seller',
      category: 2,
      description: [
        'Providers of hard goods such as lights, seeds, trellises, heating pads, and other non-plant items.',
        'Providers of professional services related to cultivation, gardening, or other relevant expertise.'
      ]
    },
    {
      title: 'Reseller',
      category: 2,
      description: [
        'Providers of hard goods such as lights, seeds, trellises, heating pads, sourced from growers or suppliers.',
        'Providers of plants obtained through private farming contracts with Certified Khôra Master Growers.'
      ]
    },
    {
      title: 'Certified Khôra Master Grower',
      category: 1,
      description: ['Certified individuals authorized to grow and sell plants on the Marketplace.']
    },
    {
      title: 'MG-Seller (Certified Khôra Master Grower and Seller)',
      category: 2,
      description: [
        'Certified Khôra Master Growers who grow and sell plants on the Marketplace.',
        'Providers of hard goods such as lights, seeds, trellises, heating pads, and other non-plant items.',
        'Providers of professional services related to cultivation, gardening, or other relevant expertise.'
      ]
    }
  ];

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">What are the different roles on Khôra Marketplace?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2.5}>
            <Typography variant="p2">
              The Khôra Marketplace encompasses various roles, each serving a specific function within the platform:
            </Typography>
            <Box>
              <Stack
                sx={{
                  p: 2.5,
                  borderRadius: '6px',
                  border: `solid 1px ${theme.palette.primary[300]}`,
                  backgroundColor: 'common.white',
                  height: '100%'
                }}
              >
                <Grid container spacing={2}>
                  {contentData?.map((item, idx) => {
                    return [
                      <Grid key={`content_info_${idx}`} item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1.25}>
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ width: 50, height: 78, backgroundColor: theme.palette.primary[50] }}
                          >
                            <Typography variant="h4" sx={{ color: theme.palette.darkgreen[300] }}>
                              {idx + 1}
                            </Typography>
                          </Stack>
                          <Stack spacing={1.25} sx={{ width: `calc(100% - 50px)` }}>
                            <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                              {item?.title}
                            </Typography>
                            <Stack>
                              {item?.description?.map((text, idx1) => {
                                if (item?.category === 1) {
                                  return (
                                    <Typography key={`marketplace_content_${idx}_${idx1}`} variant="p2">
                                      {text}
                                    </Typography>
                                  );
                                } else {
                                  return <SpotItem key={`marketplace_content_${idx}_${idx1}`} variant="p2" text={text} />;
                                }
                              })}
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>,
                      idx < contentData?.length - 1 && (
                        <Grid key={`content_info_divider_${idx}`} item xs={12}>
                          <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        </Grid>
                      )
                    ];
                  })}
                </Grid>
              </Stack>
            </Box>
            <Typography variant="p2">
              All individuals or entities seeking to sell on the Khôra Marketplace undergo an evaluation of their products and services. A
              dedicated Khôra Sales member oversees the onboarding process and assists sellers in joining the Khôra Marketplace.
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FAQMarketplaceSection4;
