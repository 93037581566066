// react
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
// material UI
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack } from '@mui/material';
import SimpleBar from 'components/third-party/SimpleBar';
// sub pages
import SettingHeader from './SettingHeader';
import SettingConfig from './SettingConfig';
// config

const SettingLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  const settingHeader = useMemo(() => <SettingHeader />, []);
  const [title, setTitle] = useState('');

  useEffect(() => {
    SettingConfig?.forEach((parent) => {
      parent?.children?.forEach((child) => {
        if (location.pathname === `/setting/${parent?.url}/${child?.url}`) setTitle(parent?.title);
      });
    });
  }, [location.pathname]);

  return (
    <Box sx={{ width: '100%', backgroundColor: theme.palette.grey[50], pt: 12.5, minHeight: '100vh' }}>
      {settingHeader}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ p: 3.75 }}>
          <Box sx={{ width: '285px', position: 'fixed' }}>
            <Typography variant="h5" sx={{ pl: 2.5, pb: 3.75 }}>
              Settings
            </Typography>
            <SimpleBar
              sx={{
                px: 1.5,
                maxHeight: `calc(100vh - 226px)`,
                overflowX: 'hidden',
                '& .simplebar-content': {
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
            >
              <Box sx={{ borderLeft: `solid 2px ${theme.palette.primary[300]}` }}>
                <Stack direction="column" spacing={3.75} sx={{ ml: '-2px' }}>
                  {SettingConfig?.map((parent, idx) => {
                    return (
                      <Box key={'parent_' + idx}>
                        <Typography sx={{ ...theme.typography.p1m, pb: 2.5, pl: 2.5 }}>{parent?.title}</Typography>
                        <Stack direction="column" spacing={1.25}>
                          {parent?.children?.map((child, idx1) => {
                            return (
                              <Typography
                                key={'child_' + idx + '_' + idx1}
                                component={Link}
                                to={`/setting/${parent?.url}/${child?.url}`}
                                sx={{
                                  ...theme.typography.p2m,
                                  textDecoration: 'none',
                                  pl: location.pathname === `/setting/${parent?.url}/${child?.url}` ? 4 : 4.5,
                                  color:
                                    location.pathname === `/setting/${parent?.url}/${child?.url}`
                                      ? theme.palette.darkgreen[300]
                                      : 'inherit',
                                  borderLeft:
                                    location.pathname === `/setting/${parent?.url}/${child?.url}`
                                      ? `solid 6px ${theme.palette.darkgreen[300]}`
                                      : `solid 2px ${theme.palette.primary[300]}`
                                }}
                              >
                                {child?.title}
                              </Typography>
                            );
                          })}
                        </Stack>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </SimpleBar>
          </Box>
          <Box sx={{ width: `calc(100% - 300px)`, marginLeft: '300px' }}>
            <Typography variant="h5" sx={{ mb: 3.75 }}>
              {title}
            </Typography>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingLayout;
