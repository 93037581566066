import { useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Box, Grid, Modal, IconButton } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { useTheme } from '@mui/material/styles';

import ReactPlayer from 'react-player';
import VideoCard from 'components/VideoCard';

const FAQKhoraHomeSection11 = () => {
  const theme = useTheme();
  const data = [
    'Plant placement in trays',
    'Harvest largest plants first',
    'Harvest in checkerboard pattern',
    'Make more space to allow plants to grow bigger',
    'Use buffered space if harvesting slower than scheduled times or going on vacation',
    'Restocking'
  ];

  const [open, setOpen] = useState(false);
  const handleVideoOpen = () => setOpen(true);
  const handleVideoClose = () => setOpen(false);

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">Want to learn more about plant placement?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={4} sx={{ px: { xs: 2, sm: 4 }, maxWidth: 1140, width: '100%', margin: 'auto' }}>
            <Typography sx={{ ...{ fontWeight: 400, fontSize: '32px', lineHeight: '48px' } }}>Want to learn more?</Typography>
            <Typography>
              Check out the Plant Placement video that demonstrates how to place plants within Khôra Home. Once you{"'"}ve finalized a
              personalized Crop Plan, you will get a Plant Shopping List to purchase items from Khôra Marketplace or local retailers. When
              scheduling your Crop Plan, follow Sprout{"'"}s Plant Placement instructions and confirm all of the plants have been placed
              inside Khôra Home. The video highlights the following:
            </Typography>
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={7}>
                  <Stack spacing={2} justifyContent="center" sx={{ height: '100%' }}>
                    {data?.map((text, idx) => {
                      return (
                        <Stack key={`want_to_learn_more_${idx}`} direction="row" alignItems="center" spacing={2}>
                          <Box
                            sx={{
                              width: '14px',
                              height: 14,
                              borderRadius: '14px',
                              backgroundColor: theme.palette.darkgreen.main,
                              zIndex: 1
                            }}
                          />
                          <Typography variant="p2m" sx={{ width: 'fit-content' }}>
                            {text}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Stack alignItems="center">
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleVideoOpen}
                      sx={{
                        maxWidth: 480,
                        width: '100%',
                        aspectRatio: '954 / 692',
                        backgroundImage: `url('/assets/images/products/plant_placement.png')`,
                        backgroundPosition: 'center, center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '6px',
                        cursor: 'pointer'
                      }}
                    >
                      <Box sx={{ height: { xs: 50, sm: 75 } }}>
                        <img src={'/assets/icons/play.svg'} alt="heb video" style={{ width: 'auto', height: '100%' }} />
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Modal open={open}>
        <VideoCard
          className="video-size-landing"
          title="How to Place Plants"
          secondary={
            <IconButton onClick={handleVideoClose} sx={{ borderRadius: '100px' }}>
              <CloseOutlined />
            </IconButton>
          }
        >
          <Box className="video-size-small">
            <ReactPlayer
              config={{
                file: {
                  attributes: {
                    controls: true,
                    autoPlay: true,
                    muted: false
                  }
                }
              }}
              url={'/assets/video/plant_placement.mp4'}
              width={'100%'}
              height={'100%'}
              style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
            />
          </Box>
        </VideoCard>
      </Modal>
    </>
  );
};
export default FAQKhoraHomeSection11;
