// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function Stepper(theme) {
  return {
    MuiStepper: {
      styleOverrides: {
        root: {
          '& .MuiStepConnector-root': {
            '& .MuiStepConnector-line': {
              height: '40px'
            },
            '&.Mui-completed .MuiStepConnector-line': {
              borderColor: theme.palette.common.white
            },
            '&.Mui-active .MuiStepConnector-line': {
              borderColor: theme.palette.common.white
            },
            '&.Mui-disabled .MuiStepConnector-line': {
              borderColor: theme.palette.primary[800]
            }
          },
          '& .MuiStep-root': {
            '& .MuiStepLabel-root': {
              paddingTop: '2px',
              paddingBottom: '2px',
              '& .MuiStepLabel-labelContainer': {
                '& .Mui-completed': {
                  ...theme.typography.p2,
                  color: theme.palette.common.white
                },
                '& .Mui-active': {
                  ...theme.typography.p2,
                  color: theme.palette.common.white
                },
                '& .Mui-disabled': {
                  ...theme.typography.p2,
                  color: theme.palette.primary[800]
                }
              },
              '& .MuiStepLabel-iconContainer': {
                '& .MuiStepIcon-root': {
                  color: theme.palette.primary[800]
                },
                '& .Mui-completed': {
                  color: theme.palette.common.white
                },
                '& .Mui-active': {
                  color: theme.palette.common.white
                }
              }
            }
          }
        }
      }
    }
  };
}
