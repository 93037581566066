import { Box, Grid, Stack, Typography } from '@mui/material';

const NewLandingSection13 = () => {
  const data = [
    {
      label: 'Private Contract Farming',
      image: '1.png'
    },
    {
      label: 'Leased Farming',
      image: '2.png'
    },
    {
      label: 'Khôra Home Consulting Services',
      image: '3.png'
    },
    {
      label: 'Agricultural Education',
      image: '4.png'
    }
  ];
  return (
    <Stack alignItems="center" sx={{ pt: { xs: 7.5, md: 10 }, pb: { xs: 22.5, sm: 18.5, md: 10 } }}>
      <Box sx={{ maxWidth: 1172, px: 2 }}>
        <Grid container spacing={{ xs: 2.5, sm: 3.75 }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Additional Khôra Services
            </Typography>
          </Grid>
          {data?.map((item, idx) => {
            return (
              <Grid key={`highlight_${idx}`} item xs={12} sm={6} md={3}>
                <Stack spacing={1.25} alignItems="center">
                  <Box
                    sx={{
                      height: 180,
                      maxWidth: 260,
                      width: '100%',
                      backgroundImage: `url('/assets/images/landing/highlights/${item?.image}')`,
                      backgroundPosition: 'center, center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      borderRadius: '6px'
                    }}
                  />
                  <Typography variant="h6" sx={{ textAlign: 'center', maxWidth: 260, width: '100%' }}>
                    {item?.label}
                  </Typography>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Stack>
  );
};
export default NewLandingSection13;
