import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack, Grid } from '@mui/material';
import { TeamMembers } from 'utils/constants/about';

const LandingAboutSection = () => {
  const theme = useTheme();
  const titleStyle = {
    fontSize: { xs: '32px', md: '42px' },
    lineHeight: { xs: '44px', md: '60px' },
    fontFamily: 'Poppins',
    fontWeight: 400
  };
  const contentStyle = { backgroundColor: theme.palette.grey[50], borderRadius: '20px', p: 3.75, height: '100%' };

  return (
    <>
      <Box sx={{ width: '100%', background: theme.palette.common.white }}>
        <Box
          sx={{
            backgroundImage: 'url("/assets/images/landing/background.jpg")',
            backgroundPosition: 'center, center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            py: { xs: '40px', md: '60px' }
          }}
        >
          <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', px: '20px', height: '100%' }}>
            <Stack spacing={3.75} justifyContent="center" sx={{ height: '100%' }}>
              <Typography sx={{ ...titleStyle, color: theme.palette.common.white }}>About Us</Typography>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="flex-start" spacing={2.5}>
                <img src="/assets/images/landing/khorahome.svg" alt="khorahome" style={{ width: 'auto', height: 'auto' }} />
                <img src="/assets/images/landing/khorasprout.svg" alt="khorahome" style={{ width: 'auto', height: 'auto' }} />
                <img src="/assets/images/landing/khoramarketplace.svg" alt="khorahome" style={{ width: 'auto', height: 'auto' }} />
              </Stack>
              <Stack spacing={2.5}>
                <Typography sx={{ ...theme.typography.p2, color: theme.palette.common.white }}>
                  The Khôra Family of technologies is proudly owned by AVI Consulting, Inc., a women-owned company based in Austin, Texas.
                  Established in April 2000, AVI Consulting, Inc. boasts over 20 years of expertise in conceptualizing, designing, and
                  implementing highly innovative and intricate technology solutions for global markets.
                </Typography>
                <Typography sx={{ ...theme.typography.p2, color: theme.palette.common.white }}>
                  With a diverse client base including top 100 global companies in insurance, healthcare, and financial services, as well as
                  foreign and domestic militaries, and various levels of government in the United States, AVI Consulting, Inc. is recognized
                  for its excellence in delivering cutting-edge technology solutions tailored to meet the unique needs of each client.
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: { xs: '40px', md: '60px' } }}>
        <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', px: '20px' }}>
          <Stack spacing={3.75} justifyContent="center">
            <Typography sx={{ ...titleStyle }}>Our IT Team</Typography>
            <Typography sx={{ ...theme.typography.p1 }}>
              The Khôra IT Team is passionate about changing the world one bite at a time. We create novel technologies that provide truly
              clean food quickly, simply, conveniently, abundantly, and sustainably.
            </Typography>
          </Stack>
          <Box sx={{ pt: { xs: '40px', md: '60px' } }}>
            <Grid container spacing={3.75}>
              {TeamMembers?.map((member, idx) => {
                return (
                  <Grid key={`team_member_${idx}`} item xs={12} sm={6}>
                    <Box sx={{ ...contentStyle }}>
                      <img
                        src={member.path}
                        alt={member.name}
                        style={{ maxWidth: '160px', borderRadius: '50%', width: '100%', height: 'auto' }}
                      />
                      <Stack sx={{ mt: 1.25 }}>
                        <Typography variant="p1">{member.name}</Typography>
                        <Typography variant="p2" sx={{ color: theme.palette.grey[400] }}>
                          {member.role}
                        </Typography>
                        <Stack spacing={2.5} pt={2.5}>
                          {member.description?.map((content, idx1) => {
                            return (
                              <Typography key={`description_${idx}_${idx1}`} variant="p2">
                                {content}
                              </Typography>
                            );
                          })}
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default LandingAboutSection;
