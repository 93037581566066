import LandingSellersSection from 'sections/landing/LandingSellers';
import { Helmet } from 'react-helmet-async';

const LandingSellers = () => {
  return (
    <>
      <Helmet title="Sellers - Khôra Home" />
      <LandingSellersSection />
    </>
  );
};
export default LandingSellers;
