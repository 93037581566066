// material-ui
// ==============================|| OVERRIDES - TOOLTIP ||============================== //

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      defaultProps: {
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          ...theme.typography.p3,
          color: theme.palette.common.black,
          backgroundColor: theme.palette.grey[50],
          border: `solid 1px ${theme.palette.grey[500]}`,
          maxWidth: '450px',
          padding: '12px',
          borderRadius: '6px',
          '& .MuiTooltip-arrow': {
            color: theme.palette.grey[50],
            '&:before': {
              border: `solid 1px ${theme.palette.grey[500]}`
            }
          }
        }
      }
    }
  };
}
