import LandingCommunitySection from 'sections/landing/community';
import { Helmet } from 'react-helmet-async';

const LandingCommunity = () => {
  return (
    <>
      <Helmet title="Community - Khôra Home" />
      <LandingCommunitySection />
    </>
  );
};
export default LandingCommunity;
