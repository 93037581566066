import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import { Provider as ReduxProvider } from 'react-redux';

// scroll bar
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';
import 'assets/third-party/phone-number-material.css';
import 'assets/third-party/swiper.css';
import 'assets/third-party/image-loading.css';
import 'assets/third-party/video-button.css';
import 'assets/third-party/custom.css';
import 'video-react/dist/video-react.css';

// font
import 'assets/font/Poppins-bold';
import 'assets/font/Poppins-bolditalic';
import 'assets/font/Poppins-normal';
import 'assets/font/Poppins-italic';

// react-image
import 'react-image-crop/dist/ReactCrop.css';

import App from './App';
import { store } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <Helmet titleTemplate="%s" defaultTitle="Khôra Sprout - The Grower's Platform" />
    <ReduxProvider store={store}>
      <ConfigProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </ReduxProvider>
  </HelmetProvider>
);
reportWebVitals();
