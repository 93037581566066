import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import shoppingReducer, { initialState } from 'store/reducers/_shopping';
import { SHOPPING_NETWORK_LIST_ALL, SHOPPING_NETWORK_ID } from 'store/reducers/actions';

import axios from 'utils/axios';
const ShoppingContext = createContext(null);

export const ShoppingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(shoppingReducer, initialState);

  const setNetworkShoppingList = useCallback((networkShoppingList) => {
    dispatch({
      type: SHOPPING_NETWORK_LIST_ALL,
      payload: {
        networkShoppingList
      }
    });
  }, []);

  const setNetworkID = useCallback((networkID) => {
    dispatch({
      type: SHOPPING_NETWORK_ID,
      payload: {
        networkID
      }
    });
  }, []);

  const getNetworkShoppingList = useCallback(async () => {
    try {
      const res = await axios.get('/shopping_list/all');
      setNetworkShoppingList(res?.data);
    } catch (err) {
      setNetworkShoppingList([]);
    }
  }, [setNetworkShoppingList]);

  useEffect(() => {
    getNetworkShoppingList();
  }, [getNetworkShoppingList]);

  return (
    <ShoppingContext.Provider
      value={{
        ...state,
        setNetworkShoppingList,
        setNetworkID,
        getNetworkShoppingList
      }}
    >
      {children}
    </ShoppingContext.Provider>
  );
};

ShoppingProvider.propTypes = {
  children: PropTypes.node
};

export default ShoppingContext;
