import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import ArrowIcon from '@mui/icons-material/ArrowRightAltOutlined';

const NewLandingSection12 = () => {
  const theme = useTheme();
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ position: 'relative', height: 0, width: '100%' }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
        sx={{
          py: 3,
          px: { xs: 5, md: 7.5 },
          position: 'absolute',
          zIndex: 1,
          width: { xs: '80%', lg: '100%' },
          '--div-width': '1140px',
          maxWidth: `var(--div-width)`,
          height: { xs: 560, sm: 440, md: `calc(var(--div-width) * 298 / 1140)` },
          backgroundColor: 'common.white',
          borderRadius: '10px',
          border: `solid 1px ${theme.palette.primary.main}`
        }}
      >
        <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
          <Typography variant={downMD ? 'h4' : 'h3'} sx={{ color: theme.palette.darkgreen.main }}>
            Find answers on our FAQ page!
          </Typography>
          <Button variant="contained" size="large" endIcon={<ArrowIcon />} onClick={() => navigate('/faqs/khora_home')}>
            Learn More
          </Button>
        </Stack>
        <img src={'/assets/images/new_landing/image6_1.png'} alt={'image6_1'} style={{ width: 'auto', height: 280 }} />
      </Stack>
    </Stack>
  );
};
export default NewLandingSection12;
