import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Box, Grid, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection10 = () => {
  const KhoraHomeAppliancesCoolScienceData = [
    {
      title: 'Ideal growing environment',
      description:
        'Khôra Home, an innovative MIT-designed structure, revolutionizes soil-based farming with enhanced safety and effectiveness, surpassing traditional greenhouses and indoor farms.',
      image: '1.png'
    },
    {
      title: 'Adaptive Lighting System',
      description:
        'The innovative lighting system within Khôra Home offers tailored wavelengths, intensities, and durations to support different plant types and growth stages. With customizable settings, it enables year-round cultivation and accommodates diverse shelf configurations, allowing you to simulate any place or season for optimal plant growth.',
      image: '2.png'
    },
    {
      title: 'Space Optimization for Increased Yield',
      description:
        'By dynamic ally allocating space to accommodate plants of various sizes throughout their lifecycles, including utilizing unused space as seedlings mature, this innovative approach significantly boosts harvesting potential compared to traditional spacing methods based solely on anticipated adult plant size.',
      image: '3.png'
    },
    {
      title: 'Efficient Automation: Growing Plans Streamline Cultivation',
      description:
        'Comprehensive Growing Plans provide step-by-step instructions for each plant, covering everything from germination to transplanting, growing, and harvesting.',
      image: '4.png'
    },
    {
      title: 'Efficient Water Wicking Irrigation',
      description:
        'Unlike conventional hydroponic and irrigation methods, Khôra Home utilizes a specialized water mat system that leverages the natural hydrotropism of plants. This innovative approach promotes accelerated and robust root growth, ensuring healthier and more vigorous plants.',
      image: '5.png'
    },
    {
      title: 'Premium Enriched Soil',
      description:
        "The symbiotic relationship between plants and soil microbes enhances nutrient absorption and improves crop quality. Khôra Home's blend of USDA Organic soil and organic kitchen supplements creates a mineral-rich mixture, boosting biomass by 30 percent.",
      image: '6.png'
    }
  ];
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">About Khôra Home Science</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={4} sx={{ px: { xs: 2, sm: 4 }, maxWidth: 1140, width: '100%', margin: 'auto' }}>
            <Typography sx={{ ...{ fontWeight: 400, fontSize: '32px', lineHeight: '48px' } }}>
              Khôra Home{"'"}s magic is simply cool science.
            </Typography>
            <Typography>
              Khôra Home seamlessly blends magic and science. Designed by a team of engineers, scientists, agriculturalists, and MIT tech
              pioneers, it nurtures a wide array of plants, from vegetables to microgreens, with precision and care. Its innovative
              technology enhances your gardening experience, delivering produce that is fresher, tastier, and healthier than ever before.
            </Typography>
            <Divider sx={{ borderColor: 'gray.lighter' }} />
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h4">Experience the science behind Khôra Home.</Typography>
                </Grid>
                {KhoraHomeAppliancesCoolScienceData?.map((item, idx) => {
                  return (
                    <Grid key={`cool_science_${idx}`} item xs={12} md={6}>
                      <Stack alignItems="flex-start" direction="row" spacing={2}>
                        <img src={`/assets/images/products/${item?.image}`} alt={item?.image} style={{ width: '40px', height: '40px' }} />
                        <Stack spacing={1}>
                          <Typography sx={{ ...{ fontWeight: 500, fontSize: '16px', lineHeight: '28px' } }}>{item?.title}</Typography>
                          <Typography>{item?.description}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FAQKhoraHomeSection10;
