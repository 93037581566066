export const Tips = {
  TaxDelivery: ['Ability to add standard tax and delivery costs for items being purchased for a location.'],
  ImportExportCost: [
    "User's ability to take the costs for any location in any network and import them into one or all of the user's locations.  This assumes a 100% overwrite of existing data and the user can go in and change the few values that may be location-specific."
  ],
  KEG: [
    'Khôra Efficiency Gains is based on the reduction of time it takes to grow a plant in Khôra Home versus land based farming. If growing in Khôra Home takes half the time, then the 50% KEG can be used in Cost Management calculation to determine projected profitability for a Crop Plan over time.',
    'Even small KEG increases have significant impacts on harvest amounts and profitability while reducing costs.'
  ],
  Favorite: {
    PlantSelection: [
      'Plants with Growing Plans, which were favorited in your private library or in Khôra Home Public Library will appear with this filter.'
    ],
    PlantSelectionForFree: ['Plants with Growing Plans, which were favorited in Khôra Home Public Library will appear with this filter.'],
    GrowingPlan: [
      'Growing Plans, which were favorited in your private library or in Khôra Home Public Library will appear with this filter.'
    ],
    LibraryForGrowingPlan: {
      Private: ['Growing Plans that have been favorited in your private library will appear with this filter'],
      Public: ['Growing Plans that have been favorited in Khôra Home Public Library will appear with this filter']
    },
    LibraryForPlant: {
      Private: ['Plants that have been favorited in your private library will appear with this filter'],
      Public: ['Plants that have been favorited in Khôra Home Public Library will appear with this filter']
    }
  },
  GrowingPlan: {
    MissingCostData: [
      'List of public and private Growing Plans that do not have costs created, thus, showing the value of "No Data" in Sale Prices & Profits.'
    ],
    PriceProfit: [
      'Breakdown of various types of costs to grow for Khôra Marketplace. Costs are the basis for determining retail and wholesale prices and the profit margins projected within Crop Modeling.'
    ]
  },
  CropPlan: {
    Gap: [
      'Khôra Home Crop Plans with the Continuous Growing Method calculate growing for consistent weekly harvests. Some plants may be ready to harvest on the first day of the week, which creates a gap when the shelf is empty until the next week begins.',
      'This gap can be removed resulting in growing more over the course of the Crop Plan duration while maintaining the weekly harvested amounts. While Crop Modeling, please see the bonus amount in Total Grown column of Crop Volumes.',
      'Please Note: If a seed has the value of 7 Days to Harvest Adult Plant or any multiple of 7, then there will be no benefit for removing the gap. In this case, harvest and planting will occur on the same day.'
    ],
    SpaceBuffer: [
      'The defaulted value for the percentage of space allocated for spreading out plants as they grow. If wide spreading plants are grown, increase Space Buffer value for the location. However, if microgreens are grown, set the value to 0%. The default value can be changed in Settings->How I Grow.'
    ],
    Exist: ['Import an existing crop plan from another location to this location for Crop Modeling.']
  },
  Khora: {
    Seeding: [
      'Seeding dedicates the shelf to germinating and growing seeds until the first transplant. The shelf will not be available for growing within Crop Plans.'
    ],
    Off: [
      'Off removes the shelf from all growing activities and allows maintenance activities to occur. Impacts will occur if a shelf is turned off while growing a Crop Plan.'
    ]
  },
  SmallKhoraCube: {
    Title: [
      'Costs to seed, germinate, and grow Small Khôra Cubes for Khôra Marketplace. Small Khôra Cubes only grow to the established Age to Market and not to full adult plants ready to harvest.'
    ],
    Seed: [
      'Seed Cost is for all seeds used in a Small Khôra Cube. If multiple cell plugs of seedlings are placed in a single Small Khôra Cube, then indicate the total number of seeds used in the field Seeds per Cube.'
    ],
    Germination: [
      'Germination to 1st Transplant includes the costs of seeding a cell tray, germinating seeds, growing costs, transplanting seedlings into small Khôra Cubes, assembly, and other miscellaneous costs. The total is the Small Khôra Cube cost without seeds.'
    ],
    DailyGrowing: [
      'Daily Growing Cost starts after the the first transplant and until the plant goes to market as a Small Khôra Cube or an Adult Plant ready to be harvested.'
    ],
    HarvestAndDelivery: [
      'Harvest & Delivery Cost includes labor, packaging, labeling, sales commissions, delivery, and other miscellaneous fees to sell Small Khôra Cubes or Adult Plants in Khôra Marketplace.'
    ],
    Total: [
      'Total Seed Cost includes seeds, germination, transplanting, daily growing costs, harvest, delivery, materials, labor, and miscellaneous items to grow any seeds as Small Khôra Cubes or adult plants to be sold in Khôra Marketplace.'
    ]
  },
  Microgreen: {
    Title: [
      'Microgreen Cost includes materials and labor to grow full microgreen trays, which can be divided and used to sell clams in Khôra Marketplace.'
    ],
    Seed: [
      'Seed Cost is the total number of seeds used to grow a full microgreen tray, which may be hundreds or thousands of seeds per ounce. Should a microgreen tray be divided into clams for retail or wholesale sale, the costs will be divided into the number of clams per tray during Crop Modeling.'
    ],
    TrayAssembly: ['Tray Assembly Cost includes the materials and labor to prepare a microgreen tray for germination.'],
    DailyGrowing: [
      'Daily Growing Cost includes the first day the microgreen tray is placed in Khôra Home until the microgreen tray is ready for Khôra Marketplace. The microgreen tray can be sold as a full tray, harvested and divided into clams, or sold as Small Khôra Cubes.'
    ],
    HarvestAndDeliveryTray: [
      'Harvest & Delivery - Tray Cost includes the labor, packaging, labeling, sales commissions, delivery, and other expenses required to sell a full microgreen tray in Khôra Marketplace.'
    ],
    HarvestAndDeliveryClam: [
      'Harvest & Delivery - Clam Cost includes the labor, packaging, labeling, sales commissions, delivery, and other expenses required to divide a full microgreen tray into clams to sell in Khôra Marketplace.'
    ],
    Total: [
      'Total Microgreen Costs includes seeds, materials and labor to grow full microgreen trays, harvested clams, or Small Khôra Cubes to be sold in Khôra Marketplace.'
    ]
  },
  Mushroom: {
    Title: [
      'Mushroom Costs are for growing a full shelf tray full of bags of mushroom compost to continuously inoculate, grow, and harvest various types of mushrooms.'
    ],
    Spore: ['The cost of mushroom spores regardless if the culture type is liquid, slant, or plant based.'],
    Medium: ['The cost of growing medium, equipment, and miscellaneous items used for growing mushrooms.'],
    DailyGrowing: [
      'Daily Growing Cost starts when the inoculated medium is placed in Khôra Home and grows through the last harvest. Costs can include labor, utilities, facilities fees, and other miscellaneous items.'
    ],
    HarvestAndDelivery: [
      'Harvest & Delivery Cost includes the labor, packaging, labeling, sales commissions, delivery, and other expenses required to sell mushrooms in Khôra Marketplace.'
    ],
    Total: [
      'Total Mushroom Costs include spores, growing medium, labor, and miscellaneous items to sell a bag of compost growing mushrooms in Khôra Marketplace.'
    ]
  },
  Cutting: {
    Title: [
      'Cutting Costs are for any type of plants that were grafted together and may include fruit trees, roses, cactus, cannabis, etc.'
    ],
    Cutting: ['Cutting Cost includes rootstock cost plus the cost of each scion attached.'],
    GraftAssmebly: [
      'Graft Assembly Cost includes labor, equipment, and other costs to complete grafting and assemble plants to be placed in Khôra Home.'
    ],
    DailyGrowing: [
      'Daily Growing Cost includes growing medium, labor fertilizers, soil enhancements, equipment, other costs beginning when the plant is placed in Khôra Home until it is removed.'
    ],
    HarvestAndDelivery: [
      'Harvest & Delivery Cost includes the labor, packaging, labeling, sales commissions, delivery, and other expenses required to sell cuttings in Khôra Marketplace.'
    ],
    Total: [
      'Total Cutting Cost includes rootstock, scions, grafting assembly, daily growing, harvest, delivery, and miscellaneous items to sell Small Khôra Cubes in Khôra Marketplace.'
    ]
  },
  Presoak: 'Soak seeds 24 hours before planting.',
  Payment: {
    AmountDue:
      "Final amount due at this time for this invoice. If the invoice's total is smaller than the minimum charge amount, for example, or if there is account credit that can be applied to the invoice, the amount due may be 0.",
    Total: 'Total after discounts and taxes.'
  }
};
