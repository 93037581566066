// ==============================|| OVERRIDES - DIALOG CONTENT TEXT ||============================== //

export default function Paper() {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&:has( > .MuiCalendarOrClockPicker-root)': {
            borderRadius: '20px'
          }
        }
      }
    }
  };
}
