import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuOpen: false
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const landingMenu = createSlice({
  name: 'landingMenu',
  initialState,
  reducers: {
    handleClose(state) {
      state.menuOpen = false;
    },
    handleToggle(state) {
      state.menuOpen = !state.menuOpen;
    }
  }
});

export default landingMenu.reducer;

export const { handleClose, handleToggle } = landingMenu.actions;
