import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader } from '@mui/material';

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const AlertCard = forwardRef(
  ({ children, title = '', secondary, warning = true, checkpoint = false, content = true, sx = {}, contentSx = {}, ...others }, ref) => {
    const theme = useTheme();

    return (
      <Card
        elevation={0}
        ref={ref}
        {...others}
        sx={{
          border: 'none',
          borderRadius: '20px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: `calc( 100% - 50px)`, sm: 'auto' },
          '& .MuiCardContent-root': {
            overflowY: 'auto',
            minHeight: 'auto',
            maxHeight: `calc(100vh - 200px)`
          },
          backgroundColor: theme.palette.common.white,
          ...sx
        }}
      >
        <CardHeader
          sx={{
            px: content ? 5 : 3,
            py: 2,
            backgroundColor: warning ? theme.palette.error.main : checkpoint ? theme.palette.info[300] : theme.palette.primary[900],
            color: theme.palette.primary.contrastText
          }}
          titleTypographyProps={{ variant: 'p1m', color: theme.palette.common.white }}
          title={warning ? (title ? `WARNING: ${title}` : 'WARNING') : title}
          action={secondary}
        />
        <CardContent
          sx={{
            p: content ? { xs: 3, md: 4, lg: 5 } : 0,
            textAlign: 'center',
            ...contentSx
          }}
        >
          {children}
        </CardContent>
      </Card>
    );
  }
);

AlertCard.propTypes = {
  children: PropTypes.node,
  warning: PropTypes.bool,
  checkpoint: PropTypes.bool,
  content: PropTypes.bool,
  sx: PropTypes.object,
  contentSx: PropTypes.object,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default AlertCard;
