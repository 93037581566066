import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect } from 'react';
import searchReducer, { initialState } from 'store/reducers/_search';
import {
  SEARCH_CATEGORY,
  SEARCH_CROP_PLAN_LIST,
  SEARCH_GROWING_PLAN_LIST,
  SEARCH_SEED_LIST,
  SEARCH_CUTTING_LIST,
  SEARCH_MUSHROOM_LIST,
  SEARCH_MICROGREEN_LIST,
  SEARCH_VENDOR_LIST
} from 'store/reducers/actions';

import axios from 'utils/axios';

const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  const setCategory = useCallback((category) => {
    dispatch({
      type: SEARCH_CATEGORY,
      payload: {
        category
      }
    });
  }, []);

  const setCropPlanList = useCallback((cropPlanList, isInitialized) => {
    dispatch({
      type: SEARCH_CROP_PLAN_LIST,
      payload: {
        cropPlanList,
        isInitialized
      }
    });
  }, []);

  const setGrowingPlanList = useCallback((growingPlanList, isInitialized) => {
    dispatch({
      type: SEARCH_GROWING_PLAN_LIST,
      payload: {
        growingPlanList,
        isInitialized
      }
    });
  }, []);

  const setSeedList = useCallback((seedList, isInitialized) => {
    dispatch({
      type: SEARCH_SEED_LIST,
      payload: {
        seedList,
        isInitialized
      }
    });
  }, []);

  const setCuttingList = useCallback((cuttingList, isInitialized) => {
    dispatch({
      type: SEARCH_CUTTING_LIST,
      payload: {
        cuttingList,
        isInitialized
      }
    });
  }, []);

  const setMushroomList = useCallback((mushroomList, isInitialized) => {
    dispatch({
      type: SEARCH_MUSHROOM_LIST,
      payload: {
        mushroomList,
        isInitialized
      }
    });
  }, []);

  const setMicrogreenList = useCallback((microgreenList, isInitialized) => {
    dispatch({
      type: SEARCH_MICROGREEN_LIST,
      payload: {
        microgreenList,
        isInitialized
      }
    });
  }, []);

  const setVendorList = useCallback((vendorList, isInitialized) => {
    dispatch({
      type: SEARCH_VENDOR_LIST,
      payload: {
        vendorList,
        isInitialized
      }
    });
  }, []);

  const getCropPlanList = useCallback(async () => {
    try {
      const res = await axios.get(`/crop_plan/main/all`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getGrowingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/main/search');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getSeedList = useCallback(async () => {
    try {
      const res = await axios.get('/list/seed/search');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getCuttingList = useCallback(async () => {
    try {
      const res = await axios.get('/list/cutting/search');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getMushroomList = useCallback(async () => {
    try {
      const res = await axios.get('/list/mushroom/search');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getMicrogreenList = useCallback(async () => {
    try {
      const res = await axios.get('/list/microgreen/search');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getVendorList = useCallback(async () => {
    try {
      const res = await axios.get('/list/vendor');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  useEffect(() => {
    let cropFlag = false;
    let planFlag = false;
    let seedFlag = false;
    let cuttingFlag = false;
    let mushroomFlag = false;
    let microgrenFlag = false;
    let vendorFlag = false;
    getCropPlanList().then((res) => {
      cropFlag = true;
      setCropPlanList(res, cropFlag && planFlag && seedFlag && cuttingFlag && mushroomFlag && microgrenFlag && vendorFlag);
    });
    getGrowingPlanList().then((res) => {
      planFlag = true;
      setGrowingPlanList(res, cropFlag && planFlag && seedFlag && cuttingFlag && mushroomFlag && microgrenFlag && vendorFlag);
    });
    getSeedList().then((res) => {
      seedFlag = true;
      setSeedList(res, cropFlag && planFlag && seedFlag && cuttingFlag && mushroomFlag && microgrenFlag && vendorFlag);
    });
    getCuttingList().then((res) => {
      cuttingFlag = true;
      setCuttingList(res, cropFlag && planFlag && seedFlag && cuttingFlag && mushroomFlag && microgrenFlag && vendorFlag);
    });
    getMushroomList().then((res) => {
      mushroomFlag = true;
      setMushroomList(res, cropFlag && planFlag && seedFlag && cuttingFlag && mushroomFlag && microgrenFlag && vendorFlag);
    });
    getMicrogreenList().then((res) => {
      microgrenFlag = true;
      setMicrogreenList(res, cropFlag && planFlag && seedFlag && cuttingFlag && mushroomFlag && microgrenFlag && vendorFlag);
    });
    getVendorList().then((res) => {
      vendorFlag = true;
      setVendorList(res, cropFlag && planFlag && seedFlag && cuttingFlag && mushroomFlag && microgrenFlag && vendorFlag);
    });
  }, [
    setCropPlanList,
    setGrowingPlanList,
    setSeedList,
    setCuttingList,
    setMushroomList,
    setMicrogreenList,
    setVendorList,
    getCropPlanList,
    getGrowingPlanList,
    getSeedList,
    getCuttingList,
    getMushroomList,
    getMicrogreenList,
    getVendorList
  ]);

  return (
    <SearchContext.Provider
      value={{
        ...state,
        setCategory,
        setGrowingPlanList,
        setSeedList,
        setCuttingList,
        setMushroomList,
        getGrowingPlanList,
        getSeedList,
        getCuttingList,
        getMushroomList,
        getMicrogreenList
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node
};

export default SearchContext;
