// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...theme.typography.p3,
          color: theme.palette.common.black,
          border: 'none'
        },
        sizeSmall: {
          padding: 8
        },
        head: {
          ...theme.typography.p3m,
          border: 'none'
        },
        footer: {
          ...theme.typography.p3
        }
      }
    }
  };
}
