import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen, openItem } = menu;

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = { component: forwardRef((props, ref) => <Link {...props} to={item.url} target={itemTarget} ref={ref} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemIcon = item.icon ? <img src={item.icon} alt={item.icon} style={{ width: '18px', height: '18px' }} /> : false;
  const selectedMenuIcon = item.icon ? (
    <img src={item.icon} alt={item.icon} style={{ filter: 'invert(100%)', WebkitFilter: 'invert(100%)', width: '18px', height: '18px' }} />
  ) : (
    false
  );
  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    if (pathname.indexOf(item.url) === 0) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
  }, [pathname, dispatch, item]);

  const textColor = theme.palette.grey[900];
  const iconSelectedColor = theme.palette.common.white;

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pr: drawerOpen ? '14px' : '12px',
        pl: drawerOpen ? `${(level - 1) * 30 + 14}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.5 : '14px',
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: 2.5,
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: theme.palette.grey[100]
          },
          '&.Mui-selected': {
            bgcolor: theme.palette.darkgreen[300],
            color: `${iconSelectedColor}!important`,
            '&:hover': {
              color: iconSelectedColor,
              bgcolor: theme.palette.darkgreen[300]
            }
          }
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: theme.palette.grey[100]
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
          }
        })
      }}
    >
      {itemIcon && drawerOpen && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? `${iconSelectedColor}!important` : textColor,
            ...(!drawerOpen && {
              borderRadius: 2.5,
              width: 36,
              height: 36,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: theme.palette.grey[100]
              }
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: theme.palette.darkgreen[300],
                '&:hover': {
                  bgcolor: theme.palette.darkgreen[300]
                }
              })
          }}
        >
          {isSelected ? selectedMenuIcon : itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography sx={{ color: isSelected ? (drawerOpen ? iconSelectedColor : theme.palette.darkgreen[300]) : textColor }}>
              {item.title}
            </Typography>
          }
          sx={{ my: 0 }}
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
