import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect } from 'react';
import observationReducer, { initialState } from 'store/reducers/_observation';
import {
  OBSERVATION_IS_INITIALIZED,
  GROWING_PLAN_OBSERVATION_LIST_ALL,
  GROWING_PLAN_OBSERVATION_TO_EDIT,
  GROWING_PLAN_LIST_FOR_OBSERVATION,
  GROWING_PLAN_OBSERVATION_LOG_SUMMARY,
  SELECTED_GROWING_PLAN_OBSERVATION_TYPE,
  CROP_PLAN_LIST_FOR_OBSERVATION,
  CROP_PLAN_OBSERVATION_LIST_ALL,
  CROP_PLAN_OBSERVATION_TO_EDIT,
  SELECTED_CROP_PLAN_OBSERVATION_TYPE,
  CROP_PLAN_OBSERVATION_LOG_SUMMARY
} from 'store/reducers/actions';

import { CropPlanType } from 'utils/constants/crop_plan';
import { ShelfStatusString } from 'utils/constants/crop_plan';
import axios from 'utils/axios';
import useSetup from 'hooks/useSetup';

const ObservationContext = createContext(null);

export const ObservationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(observationReducer, initialState);
  const { getShelvesForLocation } = useSetup();

  const setInitialized = useCallback((isInitialized) => {
    dispatch({
      type: OBSERVATION_IS_INITIALIZED,
      payload: {
        isInitialized
      }
    });
  }, []);

  const setGrowingPlanObservationList = useCallback((growingPlanObservationList) => {
    dispatch({
      type: GROWING_PLAN_OBSERVATION_LIST_ALL,
      payload: {
        growingPlanObservationList
      }
    });
  }, []);

  const setGrowingPlanObservationToEdit = useCallback((growingPlanObservationToEdit, selectedGrowingPlan) => {
    dispatch({
      type: GROWING_PLAN_OBSERVATION_TO_EDIT,
      payload: {
        growingPlanObservationToEdit,
        selectedGrowingPlan
      }
    });
  }, []);

  const setGrowingPlanSelectedType = useCallback((growingPlanSelectedType) => {
    dispatch({
      type: SELECTED_GROWING_PLAN_OBSERVATION_TYPE,
      payload: {
        growingPlanSelectedType
      }
    });
  }, []);

  const setGrowingPlanObservationLogSummary = useCallback((growingPlanLogSummary) => {
    dispatch({
      type: GROWING_PLAN_OBSERVATION_LOG_SUMMARY,
      payload: {
        growingPlanLogSummary
      }
    });
  }, []);

  const getGrowingPlanObservationList = useCallback(async () => {
    try {
      const res = await axios.get('/observation');
      setGrowingPlanObservationList(res?.data);
    } catch (err) {
      setGrowingPlanObservationList([]);
    }
  }, [setGrowingPlanObservationList]);

  const createGrowingPlanObservation = useCallback(
    async (values) => {
      try {
        const res = await axios.put('/observation', values);
        setGrowingPlanObservationList(res?.data?.list);
        return { status: true, data: res?.data?.observation };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanObservationList]
  );

  const updateGrowingPlanObservation = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/observation/${ID}`, values);
        setGrowingPlanObservationList(res?.data?.list);
        return { status: true, data: res?.data?.observation };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanObservationList]
  );

  const deleteGrowingPlanObservation = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/observation/${ID}`);
        setGrowingPlanObservationList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanObservationList]
  );

  const initializeGrowingPlanObservationLogSummary = useCallback(() => {
    setGrowingPlanObservationLogSummary({
      germinations_count: 0,
      growths_count: 0,
      plant_placements_count: 0,
      transplant_steps_count: 0,
      growing_steps_count: 0,
      irrigations_count: 0,
      environments_count: 0,
      lightings_count: 0,
      harvests_count: 0,
      packagings_count: 0,
      storages_count: 0,
      miscellaneouses_count: 0
    });
  }, [setGrowingPlanObservationLogSummary]);

  const getGrowingPlanObservationLogSummary = useCallback(
    async (ID) => {
      try {
        const res = await axios.get('/observation/' + ID);
        setGrowingPlanObservationLogSummary({
          germinations_count: res.data?.germinations_count,
          growths_count: res.data?.growths_count,
          plant_placements_count: res.data?.plant_placements_count,
          transplant_steps_count: res.data?.transplant_steps_count,
          growing_steps_count: res.data?.growing_steps_count,
          irrigations_count: res.data?.irrigations_count,
          environments_count: res.data?.environments_count,
          lightings_count: res.data?.lightings_count,
          harvests_count: res.data?.harvests_count,
          packagings_count: res.data?.packagings_count,
          storages_count: res.data?.storages_count,
          miscellaneouses_count: res.data?.miscellaneouses_count
        });
        setGrowingPlanObservationToEdit(res?.data, res?.data?.growing_plan);
      } catch (err) {
        initializeGrowingPlanObservationLogSummary();
      }
    },
    [setGrowingPlanObservationLogSummary, setGrowingPlanObservationToEdit, initializeGrowingPlanObservationLogSummary]
  );

  const getGrowingPlanObservationLogList = useCallback(async (observationID, type) => {
    try {
      const res = await axios.get('/observation/note/' + observationID + '/' + type);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createGrowingPlanObservationLog = useCallback(
    async (observationID, type, values) => {
      try {
        const res = await axios.put('/observation/note/' + observationID + '/' + type, values);
        await getGrowingPlanObservationLogSummary(observationID);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getGrowingPlanObservationLogSummary]
  );

  const updateGrowingPlanObservationLog = useCallback(
    async (observationID, type, logID, values) => {
      try {
        const res = await axios.post('/observation/note/' + observationID + '/' + type + '/' + logID, values);
        await getGrowingPlanObservationLogSummary(observationID);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getGrowingPlanObservationLogSummary]
  );

  const deleteGrowingPlanObservationLog = useCallback(
    async (observationID, type, logID) => {
      try {
        const res = await axios.delete('/observation/note/' + observationID + '/' + type + '/' + logID);
        await getGrowingPlanObservationLogSummary(observationID);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getGrowingPlanObservationLogSummary]
  );

  const setCropPlanObservationList = useCallback((cropPlanObservationList) => {
    dispatch({
      type: CROP_PLAN_OBSERVATION_LIST_ALL,
      payload: {
        cropPlanObservationList
      }
    });
  }, []);

  const setCropPlanObservationToEdit = useCallback((cropPlanObservationToEdit, selectedCropPlan) => {
    dispatch({
      type: CROP_PLAN_OBSERVATION_TO_EDIT,
      payload: {
        cropPlanObservationToEdit,
        selectedCropPlan
      }
    });
  }, []);

  const setCropPlanSelectedType = useCallback((cropPlanSelectedType) => {
    dispatch({
      type: SELECTED_CROP_PLAN_OBSERVATION_TYPE,
      payload: {
        cropPlanSelectedType
      }
    });
  }, []);

  const setCropPlanObservationLogSummary = useCallback((cropPlanLogSummary) => {
    dispatch({
      type: CROP_PLAN_OBSERVATION_LOG_SUMMARY,
      payload: {
        cropPlanLogSummary
      }
    });
  }, []);

  const getCropPlanObservationList = useCallback(async () => {
    try {
      const res = await axios.get('/crop_observation');
      setCropPlanObservationList(res?.data);
    } catch (err) {
      setCropPlanObservationList([]);
    }
  }, [setCropPlanObservationList]);

  const createCropPlanObservation = useCallback(
    async (values) => {
      try {
        const res = await axios.put('/crop_observation', values);
        setCropPlanObservationList(res?.data?.list);

        return { status: true, data: res?.data?.observation };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setCropPlanObservationList]
  );

  const updateCropPlanObservation = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/crop_observation/${ID}`, values);
        setCropPlanObservationList(res?.data?.list);
        return { status: true, data: res?.data?.observation };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setCropPlanObservationList]
  );

  const deleteCropPlanObservation = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/crop_observation/${ID}`);
        setCropPlanObservationList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setCropPlanObservationList]
  );

  const initializeCropPlanObservationLogSummary = useCallback(() => {
    setCropPlanObservationLogSummary({
      germinations_count: 0,
      miscellaneouses_count: 0
    });
  }, [setCropPlanObservationLogSummary]);

  const getCropPlanObservationLogSummary = useCallback(
    async (ID) => {
      try {
        const res = await axios.get('/crop_observation/' + ID);
        setCropPlanObservationLogSummary({
          germinations_count: res.data?.germinations_count,
          miscellaneouses_count: res.data?.miscellaneouses_count
        });
        setCropPlanObservationToEdit(res.data, res.data?.crop_model);
      } catch (err) {
        initializeCropPlanObservationLogSummary();
      }
    },
    [setCropPlanObservationLogSummary, setCropPlanObservationToEdit, initializeCropPlanObservationLogSummary]
  );

  const getCropPlanObservationLogList = useCallback(async (observationID, type) => {
    try {
      const res = await axios.get('/crop_observation/note/' + observationID + '/' + type);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const createCropPlanObservationLog = useCallback(
    async (observationID, type, values) => {
      try {
        const res = await axios.put('/crop_observation/note/' + observationID + '/' + type, values);
        await getCropPlanObservationLogSummary(observationID);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getCropPlanObservationLogSummary]
  );

  const updateCropPlanObservationLog = useCallback(
    async (observationID, type, logID, values) => {
      try {
        const res = await axios.post('/crop_observation/note/' + observationID + '/' + type + '/' + logID, values);
        await getCropPlanObservationLogSummary(observationID);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getCropPlanObservationLogSummary]
  );

  const deleteCropPlanObservationLog = useCallback(
    async (observationID, type, logID) => {
      try {
        const res = await axios.delete('/crop_observation/note/' + observationID + '/' + type + '/' + logID);
        await getCropPlanObservationLogSummary(observationID);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getCropPlanObservationLogSummary]
  );

  const setGrowingPlanList = useCallback((growingPlanList) => {
    const privateGrowingPlanList = growingPlanList?.filter((plan) => !!plan?.user_id);
    const publicGrowingPlanList = growingPlanList?.filter((plan) => !plan?.user_id);
    dispatch({
      type: GROWING_PLAN_LIST_FOR_OBSERVATION,
      payload: {
        privateGrowingPlanList,
        publicGrowingPlanList
      }
    });
  }, []);

  const setCropPlanList = useCallback((cropPlanList) => {
    const autoCropPlanList = cropPlanList?.filter((plan) => plan?.type === CropPlanType.Auto);
    const advancedCropPlanList = cropPlanList?.filter((plan) => plan?.type === CropPlanType.Advanced);
    const manualCropPlanList = cropPlanList?.filter((plan) => plan?.type === CropPlanType.Manual);
    dispatch({
      type: CROP_PLAN_LIST_FOR_OBSERVATION,
      payload: {
        autoCropPlanList,
        advancedCropPlanList,
        manualCropPlanList
      }
    });
  }, []);

  const getGrowingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/main');
      setGrowingPlanList(res?.data);
    } catch (err) {
      setGrowingPlanList([]);
    }
  }, [setGrowingPlanList]);

  const getCropPlanList = useCallback(async () => {
    try {
      const res = await axios.get(`/crop_plan/main/all`);
      setCropPlanList(res?.data);
    } catch (err) {
      setCropPlanList([]);
    }
  }, [setCropPlanList]);

  const getShelvesForPlantPlacement = useCallback(
    async (locationID) => {
      const res = await getShelvesForLocation(locationID);
      return [
        {
          shelf_id: 'Outside Seeding',
          shelf_name: 'Outside Seeding',
          shelf_status: ShelfStatusString['Outside Seeding'],
          id: -1
        },
        ...res
      ];
    },
    [getShelvesForLocation]
  );

  const getPlantPlacementData = useCallback(async (locationID) => {
    try {
      const res = await axios.get(`/plant_placement/${locationID}`);
      return res?.data?.planting;
    } catch (err) {
      return [];
    }
  }, []);

  const getKhoraList = useCallback(async (locationID) => {
    try {
      const res = await axios.get(`/location/khora_detail/${locationID}`);
      let tmp = {};
      res?.data?.khoras?.forEach((khora) => {
        tmp[khora?.nickname] = khora?.shelves;
      });
      return tmp;
    } catch (err) {
      return {};
    }
  }, []);

  useEffect(() => {
    let growingPlanObservationFlag = false;
    let cropPlanObservationFlag = false;
    let planFlag = false;
    let cropFlag = false;
    getGrowingPlanObservationList().then(() => {
      growingPlanObservationFlag = true;
      setInitialized(growingPlanObservationFlag && cropPlanObservationFlag && planFlag && cropFlag);
    });
    getCropPlanObservationList().then(() => {
      cropPlanObservationFlag = true;
      setInitialized(growingPlanObservationFlag && cropPlanObservationFlag && planFlag && cropFlag);
    });
    getGrowingPlanList().then(() => {
      planFlag = true;
      setInitialized(growingPlanObservationFlag && cropPlanObservationFlag && planFlag && cropFlag);
    });
    getCropPlanList().then(() => {
      cropFlag = true;
      setInitialized(growingPlanObservationFlag && cropPlanObservationFlag && planFlag && cropFlag);
    });
  }, [getGrowingPlanObservationList, getCropPlanObservationList, getGrowingPlanList, getCropPlanList, setInitialized]);

  return (
    <ObservationContext.Provider
      value={{
        ...state,
        getGrowingPlanObservationList,
        setGrowingPlanObservationToEdit,
        setGrowingPlanSelectedType,
        setGrowingPlanObservationLogSummary,
        createGrowingPlanObservation,
        updateGrowingPlanObservation,
        deleteGrowingPlanObservation,
        initializeGrowingPlanObservationLogSummary,
        getGrowingPlanObservationLogSummary,
        createGrowingPlanObservationLog,
        getGrowingPlanObservationLogList,
        updateGrowingPlanObservationLog,
        deleteGrowingPlanObservationLog,
        getCropPlanObservationList,
        setCropPlanObservationToEdit,
        setCropPlanSelectedType,
        setCropPlanObservationLogSummary,
        createCropPlanObservation,
        updateCropPlanObservation,
        deleteCropPlanObservation,
        initializeCropPlanObservationLogSummary,
        getCropPlanObservationLogSummary,
        createCropPlanObservationLog,
        getCropPlanObservationLogList,
        updateCropPlanObservationLog,
        deleteCropPlanObservationLog,
        getShelvesForPlantPlacement,
        getPlantPlacementData,
        getKhoraList
      }}
    >
      {children}
    </ObservationContext.Provider>
  );
};

ObservationProvider.propTypes = {
  children: PropTypes.node
};

export default ObservationContext;
