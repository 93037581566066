import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect } from 'react';
import scheduleReducer, { initialState } from 'store/reducers/_schedule';
import {
  SCHEDULE_NETWORK_LIST_ALL,
  SCHEDULE_NETWORK_ID,
  SCHEDULE_LIGHTING_PLAN_FOR_LOG,
  SCHEDULE_CONSOLE_LOG,
  SCHEDULE_RUNNING_CROP_PLAN_ID,
  SCHEDULE_CALENDAR_TYPE,
  SCHEDULE_CROP_PLAN_COLOR
} from 'store/reducers/actions';

import axios from 'utils/axios';
import { ScheduleColors } from 'utils/constants/schedule';
const ScheduleContext = createContext(null);

export const ScheduleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(scheduleReducer, initialState);

  const setRunningCropPlanColors = useCallback((runningCropPlanColors) => {
    dispatch({
      type: SCHEDULE_CROP_PLAN_COLOR,
      payload: {
        runningCropPlanColors
      }
    });
  }, []);

  const setNetworkShelfStatus = useCallback(
    (networkList) => {
      const runningCropPlanIDs = networkList
        ?.map((network) => network?.locations?.map((location) => location?.crop_models?.filter((plan) => plan?.is_running)).flat())
        .flat()
        ?.map((plan) => plan?.id)
        .flat();
      const colors = {};
      runningCropPlanIDs?.map((id, idx) => {
        colors[id] = ScheduleColors[idx % 10];
      });
      setRunningCropPlanColors(colors);
      dispatch({
        type: SCHEDULE_NETWORK_LIST_ALL,
        payload: {
          networkList
        }
      });
    },
    [setRunningCropPlanColors]
  );

  const setNetworkID = useCallback((networkID) => {
    dispatch({
      type: SCHEDULE_NETWORK_ID,
      payload: {
        networkID
      }
    });
  }, []);

  const setRunningCropPlanID = useCallback((runningCropPlanID) => {
    dispatch({
      type: SCHEDULE_RUNNING_CROP_PLAN_ID,
      payload: {
        runningCropPlanID
      }
    });
  }, []);

  const getNetworkShelfStatus = useCallback(async () => {
    try {
      const res = await axios.get('/schedule/all_running_info');
      setNetworkShelfStatus(res?.data);
    } catch (err) {
      setNetworkShelfStatus([]);
    }
  }, [setNetworkShelfStatus]);

  const deleteDataChangedCropPlan = useCallback(
    async (id) => {
      try {
        const res = await axios.delete(`/schedule/data_changed/${id}`);
        setNetworkShelfStatus(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNetworkShelfStatus]
  );

  useEffect(() => {
    getNetworkShelfStatus();
  }, [getNetworkShelfStatus]);

  const startSchedule = useCallback(
    async (id, values) => {
      // setPusherData([]);
      try {
        await axios.post(`/schedule/start/${id}`, values);
        await getNetworkShelfStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getNetworkShelfStatus]
  );

  const stopSchedule = useCallback(
    async (id) => {
      try {
        await axios.post(`/schedule/stop/${id}`);
        await getNetworkShelfStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getNetworkShelfStatus]
  );

  const getCropPlanForSchedule = useCallback(async (ID) => {
    try {
      const res = await axios.get('/crop_plan/main/one/' + ID);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setGrowingPlanForLog = useCallback((lightingData) => {
    dispatch({
      type: SCHEDULE_LIGHTING_PLAN_FOR_LOG,
      payload: {
        lightingData
      }
    });
  }, []);

  const getLightingPlanLogForSchedule = useCallback(
    async (ID) => {
      setGrowingPlanForLog('');
      try {
        const res = await axios.get('/schedule/growing_plan_info/' + ID);
        setGrowingPlanForLog(res.data);
      } catch (err) {
        setGrowingPlanForLog('');
      }
    },
    [setGrowingPlanForLog]
  );

  const setConsoleLog = useCallback((consoleData) => {
    dispatch({
      type: SCHEDULE_CONSOLE_LOG,
      payload: {
        consoleData
      }
    });
  }, []);

  const getConsoleLog = useCallback(
    async (ID) => {
      try {
        const res = await axios.get('/schedule/real_time_info/' + ID);
        setConsoleLog(res.data);
      } catch (err) {
        setConsoleLog('');
      }
    },
    [setConsoleLog]
  );

  const setCalendarType = useCallback((calendarType) => {
    dispatch({
      type: SCHEDULE_CALENDAR_TYPE,
      payload: {
        calendarType
      }
    });
  }, []);

  const updateTaskReminder = useCallback(
    async (id, values) => {
      try {
        const res = await axios.post(`/schedule/update_reminder/${id}`, values);
        setNetworkShelfStatus(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNetworkShelfStatus]
  );

  const sendMessageReportingCommand = useCallback(async (locationId) => {
    try {
      await axios.post(`/schedule/messagereport/${locationId}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateLink4Environment = useCallback(
    async (cropModelID, values) => {
      try {
        const res = await axios.post(`crop_plan/link4/environment/${cropModelID}`, values);
        setNetworkShelfStatus(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNetworkShelfStatus]
  );

  const updateLink4Irrigation = useCallback(
    async (cropModelID, values) => {
      try {
        const res = await axios.post(`crop_plan/link4/irrigation/${cropModelID}`, values);
        setNetworkShelfStatus(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNetworkShelfStatus]
  );

  const updateLink4NewLighting = useCallback(
    async (cropModelID, values) => {
      try {
        const res = await axios.post(`crop_plan/link4/new_lighting/${cropModelID}`, values);
        setNetworkShelfStatus(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNetworkShelfStatus]
  );

  return (
    <ScheduleContext.Provider
      value={{
        ...state,
        // setPusherData,
        setNetworkID,
        setRunningCropPlanID,
        startSchedule,
        stopSchedule,
        setGrowingPlanForLog,
        getCropPlanForSchedule,
        getLightingPlanLogForSchedule,
        setConsoleLog,
        getConsoleLog,
        setCalendarType,
        updateTaskReminder,
        sendMessageReportingCommand,
        deleteDataChangedCropPlan,
        updateLink4Environment,
        updateLink4Irrigation,
        updateLink4NewLighting
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

ScheduleProvider.propTypes = {
  children: PropTypes.node
};

export default ScheduleContext;
