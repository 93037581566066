// import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  IconButton,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
// icon
import MembershipIcon from '@mui/icons-material/VerifiedOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import SettingIcon from '@mui/icons-material/SettingsOutlined';
import NotificationIcon from '@mui/icons-material/NotificationsNoneOutlined';
import TodoListIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import { getMembershipString } from 'utils/common';

import useAuth from 'hooks/useAuth';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { logout, user } = useAuth();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconStyle = { height: '20px', width: '20px', color: theme.palette.grey[800] };
  const membership = getMembershipString(user?.membership)?.title;

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton ref={anchorRef} onClick={handleToggle} sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ px: 1, py: 0.5 }}>
          <Avatar alt={user?.name} src={user?.avatar} />
        </Stack>
      </IconButton>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  border: `solid 1px ${theme.palette.grey[300]}`,
                  boxShadow: theme.customShadows.z1,
                  width: 300,
                  minWidth: 300,
                  maxWidth: 300,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <Grid container sx={{ my: 1 }}>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ px: 2, py: 0.5 }}>
                          <Avatar alt={user?.name} src={user?.avatar} sx={{ width: '36px', height: '36px' }} />
                          <Stack sx={{ wordBreak: 'break-all' }}>
                            <Typography sx={theme.typography.p3m}>{user?.name}</Typography>
                            <Typography color="textSecondary" sx={theme.typography.p3}>
                              {user?.email}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ mt: 0.5, my: 1 }} />
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemButton
                          onClick={() => {
                            setOpen(false);
                            navigate('/dashboard/todo_list');
                          }}
                          sx={{ pl: 2.75 }}
                        >
                          <ListItemIcon>
                            <TodoListIcon style={iconStyle} />
                          </ListItemIcon>
                          <ListItemText primary={'To-Do List'} style={{ marginLeft: '10px' }} />
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemButton
                          onClick={() => {
                            setOpen(false);
                            navigate('/dashboard/notification');
                          }}
                          sx={{ pl: 2.75 }}
                        >
                          <ListItemIcon>
                            <NotificationIcon style={iconStyle} />
                          </ListItemIcon>
                          <ListItemText primary={'Notification Center'} style={{ marginLeft: '10px' }} />
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ mt: 0.5, my: 1 }} />
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemButton
                          onClick={() => {
                            setOpen(false);
                            navigate('/setting/account/membership');
                          }}
                          sx={{ pl: 2.75 }}
                        >
                          <ListItemIcon>
                            <MembershipIcon style={iconStyle} />
                          </ListItemIcon>
                          <ListItemText primary={`Membership - ${membership}`} style={{ marginLeft: '10px' }} />
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemButton
                          onClick={() => {
                            setOpen(false);
                            navigate('/setting/account/login_security');
                          }}
                          sx={{ pl: 2.75 }}
                        >
                          <ListItemIcon>
                            <SettingIcon style={iconStyle} />
                          </ListItemIcon>
                          <ListItemText primary="Settings" style={{ marginLeft: '10px' }} />
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemButton onClick={logout} sx={{ pl: 2.75 }}>
                          <ListItemIcon>
                            <LogoutIcon style={iconStyle} />
                          </ListItemIcon>
                          <ListItemText primary="Logout" style={{ marginLeft: '10px' }} />
                        </ListItemButton>
                      </Grid>
                    </Grid>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
