import getColors from 'utils/getColors';
// ==============================|| OVERRIDES - BUTTON ||============================== //

function getColorStyle({ variant, color, theme }) {
  const colors = getColors(theme, color);
  const { light, main, dark, contrastText } = colors;
  switch (variant) {
    case 'contained':
      return {
        backgroundColor: main,
        color: contrastText,
        border: `solid 1px ${main}`,
        '&:hover': {
          backgroundColor: light,
          border: `solid 1px ${light}`,
          color: contrastText
        },
        '&:active': {
          backgroundColor: dark,
          border: `solid 1px ${dark}`,
          color: contrastText
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey.lighter,
          border: `solid 1px ${theme.palette.grey.lighter}`,
          color: contrastText
        }
      };
    case 'outlined':
      return {
        color: main,
        border: `solid 1px ${main}`,
        '&:hover': {
          color: light,
          border: `solid 1px ${light}`
        },
        '&:active': {
          border: `solid 1px ${dark}`,
          color: dark
        },
        '&.Mui-disabled': {
          border: `solid 1px ${theme.palette.grey.light}`,
          color: theme.palette.grey.light
        }
      };
    case 'text':
    default:
      return {
        color: main,
        '&:hover': {
          color: light,
          backgroundColor: 'transparent'
        },
        '&:active': {
          color: dark,
          backgroundColor: 'transparent'
        },
        '&.Mui-disabled': {
          color: theme.palette.grey.light
        }
      };
  }
}

export default function Button(theme) {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        tabIndex: -1
      },
      styleOverrides: {
        root: {
          borderRadius: '100px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        contained: getColorStyle({ variant: 'contained', color: 'darkgreen', theme }),
        outlined: getColorStyle({ variant: 'outlined', color: 'darkgreen', theme }),
        text: getColorStyle({ variant: 'text', color: 'darkgreen', theme }),
        containedPrimary: getColorStyle({ variant: 'contained', color: 'darkgreen', theme }),
        containedSecondary: getColorStyle({ variant: 'contained', color: 'secondary', theme }),
        containedError: getColorStyle({ variant: 'contained', color: 'error', theme }),
        containedSuccess: getColorStyle({ variant: 'contained', color: 'success', theme }),
        containedInfo: getColorStyle({ variant: 'contained', color: 'info', theme }),
        containedWarning: getColorStyle({ variant: 'contained', color: 'warning', theme }),
        outlinedPrimary: getColorStyle({ variant: 'outlined', color: 'darkgreen', theme }),
        outlinedSecondary: getColorStyle({ variant: 'outlined', color: 'secondary', theme }),
        outlinedError: getColorStyle({ variant: 'outlined', color: 'error', theme }),
        outlinedSuccess: getColorStyle({ variant: 'outlined', color: 'success', theme }),
        outlinedInfo: getColorStyle({ variant: 'outlined', color: 'info', theme }),
        outlinedWarning: getColorStyle({ variant: 'outlined', color: 'warning', theme }),
        textPrimary: getColorStyle({ variant: 'text', color: 'darkgreen', theme }),
        textSecondary: getColorStyle({ variant: 'text', color: 'secondary', theme }),
        textError: getColorStyle({ variant: 'text', color: 'error', theme }),
        textSuccess: getColorStyle({ variant: 'text', color: 'success', theme }),
        textInfo: getColorStyle({ variant: 'text', color: 'info', theme }),
        textWarning: getColorStyle({ variant: 'text', color: 'warning', theme }),
        endIcon: {
          marginLeft: '14px',
          marginRight: '0px'
        },
        startIcon: {
          marginLeft: '0px',
          marginRight: '14px'
        },
        iconSizeSmall: {
          '& svg': {
            fontSize: '12px'
          }
        },
        iconSizeMedium: {
          '& svg': {
            fontSize: '14px'
          }
        },
        iconSizeLarge: {
          '& svg': {
            fontSize: '16px'
          }
        },
        sizeLarge: {
          ...theme.typography.p2m,
          lineHeight: '20px',
          padding: '16px 32px'
        },
        sizeMedium: {
          ...theme.typography.p3m,
          lineHeight: '18px',
          padding: '12px 24px'
        },
        sizeSmall: {
          ...theme.typography.p4m,
          lineHeight: '16px',
          padding: '10px 16px'
        }
      }
    }
  };
}
