import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Box, Grid, Button, useMediaQuery, CircularProgress } from '@mui/material';
import Loader from 'components/Loader';
import NewsStyle1 from 'sections/news/NewsStyle1';
import NewsStyle2 from 'sections/news/NewsStyle2';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import useAuth from 'hooks/useAuth';

const News = () => {
  const navigate = useNavigate();
  const { getNewsList } = useAuth();

  const upSM = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const downMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getNewsList().then((res) => {
      setNewsList(res);
      setLoading(false);
    });
  }, [getNewsList]);

  const countForStyle1 = useMemo(() => {
    if (upSM && downMD) return 2;
    if (upSM && !downMD) return 3;
    if (!upSM && downMD) return 2;
    if (!upSM && !downMD) return 2;
    return 3;
  }, [upSM, downMD]);

  const countForStyle2 = useMemo(() => {
    if (upSM && downMD) return 6;
    if (upSM && !downMD) return 9;
    if (!upSM && downMD) return 6;
    if (!upSM && !downMD) return 4;
    return 9;
  }, [upSM, downMD]);

  return (
    <>
      {loading && <Loader />}
      <Stack spacing={4} py={4} sx={{ maxWidth: 1140, margin: 'auto' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Typography variant="h3">Top News</Typography>
          <Button
            size="small"
            variant="outlined"
            endIcon={<KeyboardArrowRightOutlinedIcon sx={{ width: '20px', height: 'auto' }} />}
            onClick={() => navigate('/news/all')}
          >
            View All News
          </Button>
        </Stack>
        <Box>
          <Grid container spacing={3.75}>
            {newsList?.length > 0 &&
              newsList?.slice(0, Math.min(countForStyle1 ?? 3, newsList?.length))?.map((news, idx) => {
                return (
                  <Grid
                    key={`news_style_1_${idx}`}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
                  >
                    <NewsStyle1 news={news} />
                  </Grid>
                );
              })}
            {newsList?.length > (countForStyle1 ?? 3) &&
              newsList?.slice(countForStyle1 ?? 3, Math.min(countForStyle2, newsList?.length))?.map((news, idx) => {
                return (
                  <Grid
                    key={`news_style_1_${idx}`}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
                  >
                    <NewsStyle2 news={news} />
                  </Grid>
                );
              })}
          </Grid>
          {newsList?.length === 0 && !loading && (
            <Box sx={{ px: 2, py: 3, mt: 3.75, backgroundColor: 'common.white', border: `solid 1px #eee`, borderRadius: '6px' }}>
              <Typography textAlign="center">No news were found.</Typography>
            </Box>
          )}
          {loading && (
            <Stack alignItems="center" justifyContent="center" sx={{ height: 360 }}>
              <CircularProgress color="primary" />
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  );
};
export default News;
