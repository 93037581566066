import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Divider, useTheme, Button } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const FAQKhoraHomeSection12 = () => {
  const theme = useTheme();
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">Khôra Home Commercial Technical Specifications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = '/assets/technical_specifications.pdf'; // Path to your pdf file
                  link.setAttribute('download', 'Khôra Home Commercial Technical Specifications.pdf'); // Any file name
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                Download
              </Button>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Electrical Requirements
              </Typography>
              <Divider sx={{ borderColor: theme.palette.primary[300] }} />
              <Stack spacing={1.25}>
                <Typography variant="p2">- Single 20A circuit for environmental controller and camera</Typography>
                <Typography variant="p2">- Power is converted to low voltage for:</Typography>
                <Stack spacing={1.25} pl={3}>
                  <Typography variant="p2">• Air circulation and exchange</Typography>
                  <Typography variant="p2">• Lighting</Typography>
                  <Typography variant="p2">• Irrigation</Typography>
                </Stack>
                <Typography variant="p2" color="secondary">
                  <i>Optional: Dedicated 15A circuit required for seeding pad for dedicate seeding shelf</i>
                </Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Irrigation System
              </Typography>
              <Divider sx={{ borderColor: theme.palette.primary[300] }} />
              <Stack spacing={1.25}>
                <Typography variant="p2">- External tank for water and organic nutrients</Typography>
                <Typography variant="p2">- Connected to a water source or filled using a hose</Typography>
                <Typography variant="p2" color="secondary">
                  <i>Note: Use non-softened water to avoid pH alteration</i>
                </Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Internet Connectivity
              </Typography>
              <Divider sx={{ borderColor: theme.palette.primary[300] }} />
              <Stack spacing={1.25}>
                <Typography variant="p2">
                  - Hardwired Ethernet and Wi-Fl connections to minimize disruptions with inconsistent internet
                </Typography>
                <Typography variant="p2">
                  - Growing data stored in the cloud with synchronization occuring if internet connection drops
                </Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                  Nutrients and Growing Accessories -
                </Typography>
                <Typography variant="p2m" color="secondary">
                  <i>available for purchase on Khôra Marketplace</i>
                </Typography>
              </Stack>
              <Divider sx={{ borderColor: theme.palette.primary[300] }} />
              <Stack spacing={1.25}>
                <Typography variant="p2">- Organic nutrients for soil and in irrigation tank</Typography>
                <Typography variant="p2">- Wicking Mats</Typography>
                <Typography variant="p2">- Growing Pots based on plant</Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Operating Expenses
              </Typography>
              <Divider sx={{ borderColor: theme.palette.primary[300] }} />
              <Stack spacing={1.25}>
                <Typography variant="p2">- Electricity usage is based on plants types grown</Typography>
                <Stack spacing={1.25} pl={3}>
                  <Typography variant="p2">• Estimated cost: ~$25/month</Typography>
                  <Stack spacing={1.25} pl={3}>
                    <Typography variant="p2">&#10003; Air circulation fans operate 24/7</Typography>
                    <Typography variant="p2">&#10003; Air exchange as required</Typography>
                    <Typography variant="p2">&#10003; Lights on for 12-16 hours/day</Typography>
                    <Typography variant="p2">&#10003; Water pumping approximately 10 minutes/day</Typography>
                  </Stack>
                </Stack>
                <Typography variant="p2">- Water usage is approximately 15 gallons every 19-21 days</Typography>
                <Stack spacing={1.25} pl={3}>
                  <Typography variant="p2">• Estimated cost: $4 per month</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                  Maintenance Requirements -
                </Typography>
                <Typography variant="p2m" color="secondary">
                  <i>available for purchase on Khôra Marketplace</i>
                </Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Wicking Mats
              </Typography>
              <Divider sx={{ borderColor: theme.palette.primary[300] }} />
              <Typography variant="p2">- Reusable with scrubbing; replacement necessary for large plants after extended use</Typography>
              <Typography variant="p2">- Recommended replacement 3-6 months</Typography>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Lighting
              </Typography>
              <Divider sx={{ borderColor: theme.palette.primary[300] }} />
              <Typography variant="p2">- Lifespan: 30,000 hours (approximately 5 years with 16 hours/day use)</Typography>
              <Typography variant="p2">- Replacement required over time as intensity diminishes</Typography>
              <Typography variant="p2">- Cost: ~$200 per light; 2 lights per shelf</Typography>
            </Stack>
            <Stack
              spacing={1.25}
              sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
            >
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Cleaning
              </Typography>
              <Divider sx={{ borderColor: theme.palette.primary[300] }} />
              <Typography variant="p2">- Recommended between major harvests or cit the end of crop cycles</Typography>
              <Typography variant="p2">- Overwatering may require cleaning of mats and trays to prevent loss of plants</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1.25}
              sx={{
                p: 2.5,
                borderRadius: '6px',
                border: `solid 1px ${theme.palette.primary[300]}`,
                backgroundColor: 'common.white',
                height: '100%'
              }}
            >
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                3504 Scenic Overlook Trail, Austin, TX 78734 USA
              </Typography>
              <Divider orientation="vertical" sx={{ height: 'auto' }} />
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                +1 (312) 399-0680
              </Typography>
              <Divider orientation="vertical" sx={{ height: 'auto' }} />
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Contact@KhoraHome.com
              </Typography>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FAQKhoraHomeSection12;
