import { PlacementType } from 'utils/constants/plant';
import {
  PLANT_PLACEMENT_DATA,
  PLANT_PLACEMENT_TYPE,
  PLANT_PLACEMENT_SELECTED_LIST,
  PLANT_PLACEMENT_ALL_SHELVES,
  PLANT_PLACEMENT_INITIALIZE
} from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  placementType: PlacementType.Planting,
  isRunning: 1,
  plantingList: [],
  harvestList: [],
  info: {
    harvested_amount: 0,
    growing_amount: 0
  },
  nextPlanting: null,
  nextHarvest: null,
  curCropPlan: null,
  selectedList: [],
  allShelves: []
};

// ==============================|| AUTH REDUCER ||============================== //

const plantPlacement = (state = initialState, action) => {
  switch (action.type) {
    case PLANT_PLACEMENT_INITIALIZE:
      return {
        ...initialState,
        isInitialized: true
      };
    case PLANT_PLACEMENT_TYPE: {
      const { placementType } = action.payload;
      return {
        ...state,
        placementType
      };
    }
    case PLANT_PLACEMENT_DATA: {
      const { isRunning, plantingList, harvestList, info, nextPlanting, nextHarvest, curCropPlan } = action.payload;
      return {
        ...state,
        isRunning,
        plantingList,
        harvestList,
        info,
        nextPlanting,
        nextHarvest,
        curCropPlan,
        isInitialized: true
      };
    }
    case PLANT_PLACEMENT_SELECTED_LIST: {
      const { selectedList } = action.payload;
      return {
        ...state,
        selectedList
      };
    }
    case PLANT_PLACEMENT_ALL_SHELVES: {
      const { allShelves } = action.payload;
      return {
        ...state,
        allShelves
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default plantPlacement;
