import { Stack, Typography } from '@mui/material';
import ComingSoon from 'pages/maintenance/ComingSoon';

const LocalParticipating = () => {
  return (
    <Stack spacing={10} py={4} sx={{ maxWidth: 1140, margin: 'auto' }}>
      <Typography variant="h3">Local Participating Farmers Markets</Typography>
      <ComingSoon />
    </Stack>
  );
};
export default LocalParticipating;
