import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
// material-ui
import { Box, Stack, Typography } from '@mui/material';

const comingSoon = '/assets/images/maintenance/Coming_Soon.png';

const ComingSoon = ({ size = 'large' }) => {
  const theme = useTheme();
  return (
    <Stack alignItems="center" justifyContent="center" spacing={size === 'large' ? 2.5 : size === 'medium' ? 2 : 1}>
      <Typography variant={size === 'large' ? 'h2' : size === 'medium' ? 'h4' : 'h5'} sx={{ color: theme.palette.darkgreen[300] }}>
        Coming Soon
      </Typography>
      <Typography sx={theme.typography.p2}>We are currently working hard on building this feature.</Typography>
      <Box sx={{ width: { xs: '100%', sm: size === 'large' ? '630px' : size === 'medium' ? '480px' : '300px' } }}>
        <img src={comingSoon} alt="comingSoon" style={{ width: '100%', height: 'auto' }} />
      </Box>
    </Stack>
  );
};
ComingSoon.propTypes = {
  size: PropTypes.string
};
export default ComingSoon;
