import { useNavigate } from 'react-router-dom';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQMarketplaceSection8 = () => {
  const navigate = useNavigate();
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">How do you report inappropriate reviews or comments?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
            <Typography variant="p2">
              Khôra Marketplace enforces a zero-tolerance policy toward inappropriate or abusive comments in reviews. Our goal is to foster
              a respectful and constructive environment where feedback is communicated courteously.
            </Typography>
            <Typography variant="p2">
              If you encounter an inappropriate review or comment, please report it by emailing{' '}
              <span role="button" tabIndex={0} onClick={() => navigate('/contact')} style={{ cursor: 'pointer' }}>
                <b>
                  <u>Contact@KhoraHome.com</u>
                </b>
              </span>
              . Include your username and the invoice number of the transaction. A member of our team will address the issue promptly.
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FAQMarketplaceSection8;
