import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material';

import { sleep } from 'utils/common';

const FAQMarketplaceSection9 = ({ expand }) => {
  const theme = useTheme();
  useEffect(() => {
    if (expand) {
      sleep(500).then(() => {
        window.scrollTo({
          top: 350,
          left: 0,
          behavior: 'smooth'
        });
      });
    }
  }, [expand]);

  return (
    <>
      <Accordion defaultExpanded={expand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">What are the Seller Fees when selling on Khôra Marketplace?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3}>
            <Stack spacing={1.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Application Fee
              </Typography>
              <Typography variant="p2">
                Covers processing your application, potentially reviewing your products in person, and providing a white-glove onboarding
                service. This fee is non-refundable and does not guarantee acceptance.
              </Typography>
              <Typography variant="p2" sx={{ color: theme.palette.info[300] }}>
                <i>
                  <b>Exciting News!</b> The $50 <u>Application Fee</u> is currently waived!
                </i>
              </Typography>
            </Stack>
            <Stack spacing={1.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Listing Fees
              </Typography>
              <Typography variant="p2">
                List 40 items each month for free; a $0.20 fee applies for each additional item. Listings last four months before needing
                renewal.
              </Typography>
              <Typography variant="p2" sx={{ color: theme.palette.info[300] }}>
                <i>
                  <b>Exciting News!</b> The $0.20 <u>Listing Fees</u> are currently waived!
                </i>
              </Typography>
            </Stack>
            <Stack spacing={1.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Selling Fees
              </Typography>
              <Typography variant="p2">Khôra Marketplace takes a percentage of each sale.</Typography>
              <Typography variant="p2" sx={{ color: theme.palette.info[300] }}>
                <b>Current Rate:</b> Discounted to 10%.
              </Typography>
            </Stack>
            <Stack spacing={1.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Credit Card Transaction Fees
              </Typography>
              <Typography variant="p2">
                We use Stripe and pass their transaction fees directly to you without a markup. As we secure volume discounts, we{"'"}ll
                pass the savings along to our sellers.
              </Typography>
              <Typography variant="p2" sx={{ color: theme.palette.info[300] }}>
                <b>Current Fees:</b> 2.9% + 30¢ per transaction
              </Typography>
            </Stack>
            <Stack spacing={1.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                Annual Sellers Membership
              </Typography>
              <Typography variant="p2">
                Supports site advertising and feature-rich application development. Ensures our technology evolves with our needs.
              </Typography>
              <Typography variant="p2" sx={{ color: theme.palette.info[300] }}>
                <i>
                  <b>Exciting News!</b> No annual seller{"'"}s membership fee currently.
                </i>
              </Typography>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
FAQMarketplaceSection9.propTypes = {
  expand: PropTypes.bool
};
export default FAQMarketplaceSection9;
