import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Divider, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

const FAQKhoraHomeSection1 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Khôra Home Residential',
      description: [
        'Designed for seamless integration into existing home or office kitchens. With two shelves, it is ideal for spaces with lower ceiling heights, ensuring efficient use of available space.'
      ]
    },
    {
      title: 'Khôra Home Commercial',
      description: [
        'The premier solution for maximizing indoor growing space in rooms with ceiling heights over 9 feet. Our units are meticulously crafted to provide multiple configurations tailored to your growing needs, with the standard setup perfectly accommodating three full growing shelves.',
        "Commercial units are ingeniously designed to daisy chain together, enabling shared services and offering unparalleled flexibility and scalability. Whether you're a seasoned indoor gardener, a commercial grower, or an aspiring home enthusiast eager to become a Certified Master Grower for Khôra Marketplace, Khôra Home Commercial empowers you to reach your growing goals."
      ]
    },
    {
      title: 'Khôra Home Retail Racks',
      description: [
        'Khôra Home Retail Racks, the ultimate solution for grocery stores or retail establishments aiming to maintain optimal growing environments directly on their premises. Our racks are meticulously crafted to guarantee that plants stay healthy and vibrant throughout the entire sale process, thanks to integrated lighting and irrigation systems.',
        'Our innovative design prioritizes plant health, ensuring that customers always receive the freshest produce possible. With Khôra Home Retail Racks, you can create an inviting and green shopping experience, distinguishing your establishment while promoting sustainability and freshness.'
      ]
    }
  ];
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">What are the different units coming to market?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack alignItems="flex-start" spacing={2.5}>
          <Typography variant="p2">
            Khôra Home is more than just a gardening appliance; it{"'"}s a sophisticated, elegantly designed technology that serves as the
            foundation of a community-based farming network. It enables individuals to collaborate and share growing knowledge, cultivate
            exceptional produce, and sell their surplus directly to their community on Khôra Marketplace, a platform dedicated to supporting
            farmers markets, local vendors, and businesses. Together, Khôra Home and Khôra Marketplace create a robust, end-to-end local
            food system that fosters sustainability, empowers communities to thrive independently, and offers consumers the benefits of
            hyper-local, fresh produce.
          </Typography>
          <Typography variant="p2">The following Khora Home units will be coming to market:</Typography>
          {contentData?.map((item, idx) => {
            return (
              <Stack
                key={`khorahome_content_${idx}`}
                spacing={1.25}
                sx={{ p: 2.5, borderRadius: '6px', border: `solid 1px ${theme.palette.primary[300]}`, backgroundColor: 'common.white' }}
              >
                <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                  {item?.title}
                </Typography>
                <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                <Stack spacing={1.25}>
                  {item?.description?.map((text, idx1) => {
                    return (
                      <Typography key={`content_${idx}_${idx1}`} variant="p2">
                        {text}
                      </Typography>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
          <Stack direction="row" alignItems="center" spacing={2.5}>
            <Button
              variant="outlined"
              endIcon={<ArrowRightAltOutlinedIcon />}
              onClick={() => window.open(process.env['REACT_APP_MARKETPLACE_URL'], '_blank')}
            >
              Khôra Marketplace
            </Button>
            <Button
              variant="contained"
              endIcon={<ArrowRightAltOutlinedIcon />}
              onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}/product/khora_home`, '_blank')}
            >
              Buy Khôra Home
            </Button>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQKhoraHomeSection1;
