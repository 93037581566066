import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  Grid,
  Button,
  useTheme,
  useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

const LandingFAQCostsSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const upMD = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Stack spacing={1} sx={{ maxWidth: 1140, margin: 'auto', pb: { xs: 5, md: 10 }, px: 2.5 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">What are the costs of Khôra Home, Sprout, and Khôra Marketplace?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3.75}>
            <Typography variant="p2">
              If you{"'"}re curious about Khôra Home produce before committing, you can purchase from a Certified Khôra Master Grower in
              your area through Khôra Marketplace. Best of all, there{"'"}s no membership fee for using Khôra Marketplace!
            </Typography>
            <Box sx={{ p: { xs: 2.5, md: 3.75 } }}>
              <Grid container spacing={{ xs: 3.75, md: 5 }}>
                {upMD && <Grid item xs={0} md={1} />}
                <Grid item xs={12} md={5}>
                  <Stack alignItems="center" spacing={1}>
                    <img src={'/assets/images/faq/cost_1.png'} alt={'cost_1'} style={{ width: '100%', height: 'auto', maxWidth: 280 }} />
                    <Button
                      endIcon={<ArrowRightAltOutlinedIcon />}
                      onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}/product/plant`, '_blank')}
                    >
                      Checkout Khôra Home Produce
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Stack alignItems="center" spacing={1}>
                    <img src={'/assets/images/faq/cost_2.png'} alt={'cost_2'} style={{ width: '100%', height: 'auto', maxWidth: 280 }} />
                    <Button
                      endIcon={<ArrowRightAltOutlinedIcon />}
                      onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}/product/speciality_food`, '_blank')}
                    >
                      Checkout Khôra Home Specialty Foods
                    </Button>
                  </Stack>
                </Grid>
                {upMD && <Grid item xs={0} md={1} />}
              </Grid>
            </Box>
            <Typography variant="p2">
              If you{"'"}re thinking about purchasing a Khôra Home and joining our community, check out these helpful links to explore the
              available systems and understand the associated costs.
            </Typography>
            <Box sx={{ p: { xs: 2.5, md: 3.75 } }}>
              <Grid container spacing={{ xs: 3.75, md: 5 }}>
                <Grid item xs={12} md={4}>
                  <Stack alignItems="center" spacing={1}>
                    <img src={'/assets/images/faq/cost_3.png'} alt={'cost_3'} style={{ width: '100%', height: 'auto', maxWidth: 280 }} />
                    <Button
                      endIcon={<ArrowRightAltOutlinedIcon />}
                      onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}/product/khora_home`, '_blank')}
                    >
                      Buy Khôra Home Appliances
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack alignItems="center" spacing={1}>
                    <img src={'/assets/images/faq/cost_4.png'} alt={'cost_4'} style={{ width: '100%', height: 'auto', maxWidth: 280 }} />
                    <Button
                      endIcon={<ArrowRightAltOutlinedIcon />}
                      onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}/product/accessory`, '_blank')}
                    >
                      Buy Khôra Home Accessories
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack alignItems="center" spacing={1}>
                    <img src={'/assets/images/faq/cost_5.png'} alt={'cost_5'} style={{ width: '100%', height: 'auto', maxWidth: 280 }} />
                    <Button endIcon={<ArrowRightAltOutlinedIcon />} onClick={() => navigate('/membership')}>
                      Sprout{"'"} Memberships
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Typography variant="p2">
              For sellers offering produce or specialty foods made from Khôra Home produce on Khôra Marketplace, seller fees apply, which
              are determined based on the type of item sold and its volume.
            </Typography>
            <Typography variant="p2">
              For resellers like grocery stores, Khôra Marketplace serves as a valuable tool for the Khôra Home Community to locate local
              stores that currently stock the plants on their shopping list. With real-time inventory tracking, Khôra Marketplace ensures
              that users can easily find the plants they need before heading to the store to make a purchase.
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
export default LandingFAQCostsSection;
