import { useEffect, useRef, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Button,
  ListItemButton,
  ListItemText,
  Collapse,
  List,
  Typography,
  Stack
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ChevronRight';
import ArrowDown from '@mui/icons-material/ExpandMore';
import Check from '@mui/icons-material/Check';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import Loader from 'components/Loader';
import SearchInput from 'components/SearchInput';
// icon
import { normalizeString } from 'utils/common';

import useSetup from 'hooks/useSetup';
// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Network = () => {
  const theme = useTheme();

  const { changeCurrentLocation, networkList, network, location } = useSetup();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNetworkID, setOpenNetworkID] = useState(network?.id);
  const [pattern, setPattern] = useState('');
  const [networkListToShow, setNetworkListToShow] = useState([]);

  const handleToggle = () => {
    setPattern('');
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangeNetwork = async (e, locationID) => {
    if (locationID === location?.id) {
      handleClose(e);
      return;
    }
    setLoading(true);
    await changeCurrentLocation(locationID);
    setLoading(false);
    handleClose(e);
  };

  useEffect(() => {
    if (!pattern) {
      setNetworkListToShow(networkList);
      return;
    } else {
      let tmpNetworkList = [];
      networkList?.forEach((networkData) => {
        const networkName = normalizeString(networkData?.name);
        const searchPattern = normalizeString(pattern);
        if (networkName?.includes(searchPattern)) {
          tmpNetworkList.push(networkData);
        } else {
          let tmpLocations = [];
          networkData?.locations?.forEach((locationData) => {
            const locationName = normalizeString(locationData?.name);
            if (locationName?.includes(searchPattern)) tmpLocations.push(locationData);
          });
          if (tmpLocations?.length > 0) tmpNetworkList.push({ ...networkData, locations: tmpLocations });
        }
      });
      setNetworkListToShow(tmpNetworkList);
    }
  }, [networkList, pattern]);

  return (
    <>
      {loading ? <Loader /> : null}
      <Box sx={{ flexShrink: 0 }}>
        <Button
          // variant="contained"
          size="small"
          ref={anchorRef}
          onClick={handleToggle}
          endIcon={<ArrowDown style={{ color: theme.palette.grey[900] }} />}
          sx={{
            mt: '-4px',
            py: 1.25,
            px: 2,
            borderRadius: '8px',
            backgroundColor: theme.palette.grey[100],
            '&:active': {
              backgroundColor: theme.palette.grey[100]
            },
            '&:hover': {
              backgroundColor: theme.palette.grey[100]
            }
          }}
        >
          <Stack direction="row" spacing={1.25}>
            <Typography sx={{ ...theme.typography.p3, color: theme.palette.common.black }}>{network?.name}</Typography>
            <Typography sx={{ ...theme.typography.p3, color: theme.palette.grey[300] }}>│</Typography>
            <Typography sx={{ ...theme.typography.p3m, color: theme.palette.common.black }}>{location?.name}</Typography>
          </Stack>
        </Button>
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 9]
                }
              }
            ]
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={open} {...TransitionProps}>
              {open && (
                <Paper
                  sx={{
                    border: `solid 1px ${theme.palette.grey[300]}`,
                    boxShadow: theme.customShadows.z1,
                    width: 300,
                    minWidth: 300,
                    maxWidth: 300,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 300
                    }
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <SearchInput
                        fullWidth
                        placeholder="Search your network"
                        value={pattern}
                        onChange={(e) => setPattern(e.target.value)}
                        sx={{ backgroundColor: 'inherit', border: 'none' }}
                      />
                      {networkListToShow?.map((net, idx) => {
                        return (
                          <>
                            <ListItemButton
                              key={'network_' + idx}
                              onClick={() => setOpenNetworkID(net?.id === openNetworkID ? 0 : net?.id)}
                              sx={{ px: 2.5, py: 1.5 }}
                            >
                              <ListItemText
                                primary={net.name}
                                sx={{
                                  '& .MuiListItemText-primary': {
                                    ...theme.typography.p3
                                  }
                                }}
                              />
                              {openNetworkID === net?.id ? (
                                <ExpandLess style={{ fontSize: '24px', color: theme.palette.grey[700] }} />
                              ) : (
                                <ExpandMore style={{ fontSize: '24px', color: theme.palette.grey[700] }} />
                              )}
                            </ListItemButton>
                            <Collapse in={openNetworkID === net?.id} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                {net?.locations?.map((loc, idx1) => {
                                  return (
                                    <ListItemButton
                                      key={'location_' + idx + '_' + idx1}
                                      sx={{
                                        ...theme.typography.p3m,
                                        backgroundColor: location?.id === loc?.id ? theme.palette.grey[100] : 'inherit',
                                        pl: 5,
                                        pr: 2.5,
                                        py: 1.25
                                      }}
                                      onClick={(e) => handleChangeNetwork(e, loc?.id)}
                                    >
                                      <ListItemText
                                        primary={loc?.name}
                                        sx={{
                                          '& .MuiListItemText-primary': {
                                            ...theme.typography.p3
                                          }
                                        }}
                                      />
                                      {location?.id === loc?.id ? (
                                        <Check style={{ fontSize: '20px', color: theme.palette.darkgreen[300] }} />
                                      ) : null}
                                    </ListItemButton>
                                  );
                                })}
                              </List>
                            </Collapse>
                          </>
                        );
                      })}
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default Network;
