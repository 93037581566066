import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { formatOnlyDate } from 'utils/common';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';

const ChipColors = ['#CAFFD7', '#DCDAB0', '#FFC3C3', '#C0E6B6', '#FFEF8E', '#E1CEFF', '#DDDDDD', '#FF8CD9', '#E3F2FF', '#FFF3E5'];

const NewsStyle2 = ({ news }) => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      onClick={() => navigate(`/news/view/${news?.id}`)}
      sx={{
        width: '100%',
        maxWidth: 360,
        aspectRatio: '16 / 7.5',
        borderRadius: '10px',
        borderLeft: `solid 6px #BDE1D8`,
        borderTop: `solid 1px #eee`,
        borderRight: `solid 1px #eee`,
        borderBottom: `solid 1px #eee`,
        backgroundColor: 'common.white',
        p: 1,
        cursor: 'pointer',
        transition: 'transform 250ms ease, box-shadow 250ms ease, color 250ms ease',
        '&:hover': {
          WebkitTransform: 'translateY(-0.25rem)',
          MsTransform: 'translateY(-0.25rem)',
          transform: 'translateY(-0.25rem)'
        }
      }}
    >
      <Box
        sx={{
          maxWidth: 360,
          height: '100%',
          aspectRatio: '9 / 14',
          backgroundImage: `url('${news?.image ?? '/assets/images/kmg_image.png'}')`,
          backgroundPosition: 'center, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: '6px'
        }}
      />
      <Stack alignItems="flex-start" spacing={1.5}>
        <Chip
          size="small"
          variant="outlined"
          label={news?.category?.name}
          sx={{ borderRadius: '100px', backgroundColor: ChipColors[news?.category?.id % 10], border: 'none', px: 1 }}
        />
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            WebkitLineClamp: 3
          }}
        >
          <b>{news?.title}</b>
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2" sx={{ color: '#667085' }}>
            {formatOnlyDate(news?.publish_date, false)}
          </Typography>
          <Typography sx={{ color: '#667085' }}>
            <b>・</b>
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TimeIcon sx={{ fontSize: '1.15rem', color: '#667085' }} />
            <Typography variant="body2" sx={{ color: '#667085' }}>
              {news?.time_to_read === 1 ? '1 min' : `${news?.time_to_read} mins`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
NewsStyle2.propTypes = {
  news: PropTypes.any
};
export default NewsStyle2;
