import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect } from 'react';
import paymentReducer, { initialState } from 'store/reducers/_payment';
import { STRIPE_OPTIONS, STRIPE_PROMISE, PAYMENT_METHOD, PAYMENT_METHOD_EDIT } from 'store/reducers/actions';

import { loadStripe } from '@stripe/stripe-js';
import axios from 'utils/axios';
import { PaymentMethodFlag, PaymentMethodType } from 'utils/constants/payment';

const PaymentContext = createContext(null);

export const PaymentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(paymentReducer, initialState);

  const setStripePromise = useCallback((stripePromise) => {
    dispatch({
      type: STRIPE_PROMISE,
      payload: {
        stripePromise
      }
    });
  }, []);

  const setStripeOptions = useCallback((stripeOptions) => {
    dispatch({
      type: STRIPE_OPTIONS,
      payload: {
        stripeOptions
      }
    });
  }, []);

  const setPaymentMethodEdit = useCallback(
    (paymentMethodEdit, paymentMethodType = PaymentMethodType.Default, paymentMethodFlag = PaymentMethodFlag.Create) => {
      dispatch({
        type: PAYMENT_METHOD_EDIT,
        payload: {
          paymentMethodEdit,
          paymentMethodType,
          paymentMethodFlag
        }
      });
    },
    []
  );

  const setPaymentMethod = useCallback((paymentMethod) => {
    dispatch({
      type: PAYMENT_METHOD,
      payload: {
        paymentMethod
      }
    });
  }, []);

  const createSetupIntent = useCallback(async () => {
    try {
      const res = await axios.put('/stripe/create_si');
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const createPaymentMethod = useCallback(async (pm_type, pm_id) => {
    try {
      const res = await axios.put(`/stripe/add_pm/${pm_type}`, { pm_id });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updatePaymentMethod = useCallback(async (pm_type, pm_id) => {
    try {
      const res = await axios.post(`/stripe/update_pm/${pm_type}`, { pm_id });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deletePaymentMethod = useCallback(async (pm_type) => {
    try {
      const res = await axios.delete(`/stripe/delete_pm/${pm_type}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getPaymentMethod = useCallback(async () => {
    try {
      const res = await axios.get('/stripe/pm_status');
      setPaymentMethod(res?.data);
    } catch (err) {
      setPaymentMethod({
        default: null,
        backup: null
      });
    }
  }, [setPaymentMethod]);

  const getPaymentHistory = useCallback(async () => {
    try {
      const res = await axios.get('/stripe/pm_history');
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: true, data: err?.message };
    }
  }, []);

  useEffect(() => {
    loadStripe(process.env.REACT_APP_STRIPE_KEY).then((res) => {
      setStripePromise(res);
      getPaymentMethod();
    });
  }, [setStripePromise, getPaymentMethod]);

  return (
    <PaymentContext.Provider
      value={{
        ...state,
        setPaymentMethod,
        setPaymentMethodEdit,
        setStripeOptions,
        createSetupIntent,
        createPaymentMethod,
        updatePaymentMethod,
        deletePaymentMethod,
        getPaymentHistory
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.propTypes = {
  children: PropTypes.node
};
export default PaymentContext;
