import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';

const LandingMascotSection = () => {
  const theme = useTheme();
  const titleStyle = {
    fontSize: { xs: '32px', md: '42px' },
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: { xs: '44px', md: '60px' }
  };
  return (
    <Stack>
      <Stack sx={{ maxWidth: 1140, margin: 'auto', pb: { xs: 5, md: 10 }, px: 2.5 }}>
        <Typography sx={{ ...titleStyle, pt: { xs: '40px', md: '60px' }, pb: 5 }}>Our Khôra Mascots</Typography>
        <Stack spacing={5}>
          <Typography variant="p1m" sx={{ maxWidth: 1024 }}>
            Once upon a time, on the south side of Boston, there lived a kind-hearted fisherman named Puppy. Every morning, Puppy set out on
            his boat to check his fishing nets and lobster traps, hoping for a bountiful catch. One sunny morning, as Puppy lifted a trap,
            he noticed something unusual—a tiny spotted octopus peeking inside with big curious eyes.
          </Typography>
          <Box>
            <Grid container spacing={3.75}>
              <Grid item xs={12} md={6}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    minHeight: { xs: 360, md: 420 },
                    height: '100%',
                    width: 'auto',
                    borderRadius: '6px',
                    backgroundImage: 'url("/assets/images/faq/mascots_1.png")',
                    backgroundPosition: 'center, center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain'
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack justifyContent="center" spacing={3.75} sx={{ height: '100%' }}>
                  <Typography variant="p2">
                    Surprised but delighted by this unexpected sight, Puppy gently lifted the baby octopus out of the trap and offered it a
                    tasty fish to eat. The octopus eagerly nibbled on the treat and Puppy couldn{"'"}t help but wonder how it had ended up
                    so far from home.
                  </Typography>
                  <Typography variant="p2">
                    To Puppy{"'"}s amazement, the baby octopus shared its dream of living on land, feeling the warmth of the sun on its face
                    and playing in the dirt with all 8 arms. Puppy, though uncertain at first, couldn{"'"}t ignore the sparkle of excitement
                    in the baby{"'"}s eyes. Puppy smiled and declared, {'"'}What a wonderful adventure that would be! From now on, you shall
                    be called Terra Firma—a name as strong and solid as the land of which you dream.{'"'} With newfound determination, Puppy
                    set sail for land, embarking on an extraordinary journey to help Terra Firma.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Stack>
      <Stack sx={{ backgroundColor: theme.palette.primary[50], py: { xs: 5, md: 10 } }}>
        <Stack sx={{ maxWidth: 1180, margin: 'auto', px: 2.5 }}>
          <Grid container columnSpacing={5}>
            <Grid item xs={12} md={5.5}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  minHeight: { xs: 360, md: 420 },
                  height: '100%',
                  width: 'auto',
                  backgroundImage: 'url("/assets/images/faq/mascots_6.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                }}
              />
            </Grid>
            <Grid item xs={12} md={6.5}>
              <Stack justifyContent="center" spacing={3.75} sx={{ height: '100%' }}>
                <Typography variant="p2">
                  Back in Boston, the years passed as Puppy raised Terra Firma to be a strong, independent, and curious octopus. When the
                  time came, Puppy helped Terra Firma apply to prestigious universities to learn the intricacies of agriculture. Soon, the
                  day came to move Terra Firma into the university{"'"}s dormitory. Puppy was sad but also so proud of Terra Firma. The
                  journey from that day on the boat was long and arduous, yet Terra Firma had an equally long and challenging journey ahead.
                </Typography>
                <Typography variant="p2">
                  Terra Firma had three dormitory roommates, Spinach, Romaine, and Celery, who were enrolled in the Colleges of Engineering,
                  Architecture, and Computer Science. Each roommate delved into a unique and highly specialized field, fully immersing
                  themselves in learning and absorbing all they could. The roommates began to grow food built from technologies they learned
                  in their classes. Their friends loved the produce and soon were requesting their favorite foods to be grown from their
                  homes. Their home looked more like a greenhouse science laboratory than a dormitory room.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  minHeight: { xs: 360, md: 420 },
                  height: '100%',
                  width: 'auto',
                  backgroundImage: 'url("/assets/images/faq/mascots_2.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                }}
              />
            </Grid>
            <Grid item xs={12} md={6.5}>
              <Stack justifyContent="center" spacing={3.75} sx={{ height: '100%' }}>
                <Typography variant="p2">
                  Their collaboration blossomed into a remarkable fusion of agriculture and technology. Spinach, with their engineering
                  prowess, designed innovative irrigation systems that optimized water usage. Romaine, the architect, crafted eco-friendly
                  structures to house their burgeoning crops, blending sustainability with aesthetic appeal. Meanwhile, Celery, the computer
                  scientist, developed cutting-edge software to monitor plant growth and analyze data for continuous improvement.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5.5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  minHeight: { xs: 360, md: 420 },
                  height: '100%',
                  width: 'auto',
                  backgroundImage: 'url("/assets/images/faq/mascots_2.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack sx={{ py: { xs: 5, md: 10 } }}>
        <Stack sx={{ maxWidth: 1180, margin: 'auto', px: 2.5 }}>
          <Grid container spacing={3.75}>
            <Grid item xs={12} md={6}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  minHeight: { xs: 360, md: 420 },
                  height: '100%',
                  width: 'auto',
                  borderRadius: '6px',
                  backgroundImage: 'url("/assets/images/faq/mascots_3.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack justifyContent="center" sx={{ height: '100%' }}>
                <Typography variant="p2">
                  On graduation day, Terra Firma and Plant Friends tossed their caps into the air with jubilation. Puppy, brimming with
                  pride, placed his fisherman{"'"}s cap on Terra Firma{"'"}s head. {'"'}Your dream of living on land has come true, defying
                  all odds, but never forget your roots are in the sea.{'"'} he said with a smile. With newfound confidence and excitement,
                  Terra Firma and Plant Friends embarked on their journey to build Khôra Home prioritizing innovation, sustainability, and
                  fostering an active community of like-minded growers.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack sx={{ pb: { xs: 5, md: 10 } }}>
        <Stack sx={{ maxWidth: 1180, margin: 'auto', px: 2.5 }}>
          <Grid container spacing={3.75}>
            <Grid item xs={12} md={6} sx={{ display: { xs: 'block', md: 'none' } }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  minHeight: { xs: 360, md: 420 },
                  height: '100%',
                  width: 'auto',
                  backgroundImage: 'url("/assets/images/faq/mascots_4.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={3.75} justifyContent="center" sx={{ height: '100%' }}>
                <Typography variant="p2">
                  Terra Firma proudly embodies Khôra Home{"'"}s adventurous spirit, symbolizing the pursuit of ambitious dreams. With its
                  multiple arms, Terra Firma represents the ability to efficiently handle numerous tasks simultaneously, essential for
                  successful gardening. This mirrors the collaborative community of Khôra Home, where members come together each putting
                  their hands in soil to share knowledge and continuously enhance the Khôra Home experience for all.
                </Typography>
                <Typography variant="p2">
                  Spinach, Romaine, and Celery, are delightful mascots that inspire people of all ages to explore the joys of home gardening
                  through technology innovation. They play essential roles in bringing Khôra Home and Marketplace to life, showcasing the
                  benefits of growing for self-reliance and contributing to the Khôra Home Community.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  minHeight: { xs: 360, md: 420 },
                  height: '100%',
                  width: 'auto',
                  backgroundImage: 'url("/assets/images/faq/mascots_4.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default LandingMascotSection;
