// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function FormControlLabel(theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 1,
          '&:has(> .Mui-focusVisible)': {
            outline: `1px solid ${theme.palette.darkgreen.main}`
          },
          '&:has(> .MuiSwitch-root > .Mui-focusVisible)': {
            outline: `1px solid ${theme.palette.darkgreen.main}`
          },
          paddingRight: '9px'
        },
        label: {
          ...theme.typography.p3
        }
      }
    }
  };
}
