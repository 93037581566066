// third-party
import { FormattedMessage } from 'react-intl';
// icons
// import CropplanIcon from '@mui/icons-material/AutoAwesomeOutlined';
// const icons = { CropplanIcon };

// icons
import CropPlanIcon from 'assets/icons/crop_plan_management/Crop_Plan.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const cropplan = {
  id: 'group-cropplan',
  type: 'group',
  children: [
    {
      id: 'cropplan',
      title: <FormattedMessage id="Crop Plan Management" />,
      type: 'item',
      url: '/crop_plan',
      icon: CropPlanIcon
    }
  ]
};
const cropplan_ = {
  id: 'group-cropplan',
  type: 'group',
  children: [
    {
      id: 'cropplan',
      title: <FormattedMessage id="Crop Plan Management" />,
      type: 'collapse',
      icon: CropPlanIcon,
      children: [
        {
          id: 'cropplan_',
          title: <FormattedMessage id="Crop Plan Management" />,
          type: 'item',
          url: '/crop_plan'
        }
      ]
    }
  ]
};

export { cropplan, cropplan_ };
