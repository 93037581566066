import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect } from 'react';
import {
  LIST_MANAGEMENT_CATEGORY,
  LIST_MANAGEMENT_ISINITIALIZED,
  LIST_MANAGEMENT_SEED_LIST,
  LIST_MANAGEMENT_SEED_TO_EDIT,
  LIST_MANAGEMENT_MICROGREEN_LIST,
  LIST_MANAGEMENT_MICROGREEN_TO_EDIT,
  LIST_MANAGEMENT_MUSHROOM_LIST,
  LIST_MANAGEMENT_MUSHROOM_TO_EDIT,
  LIST_MANAGEMENT_CUTTING_LIST,
  LIST_MANAGEMENT_CUTTING_TO_EDIT,
  LIST_MANAGEMENT_LIGHTING_PLAN_LIST,
  LIST_MANAGEMENT_LIGHTING_PLAN_TO_EDIT,
  LIST_MANAGEMENT_NEW_LIGHTING_PLAN_LIST,
  LIST_MANAGEMENT_NEW_LIGHTING_PLAN_TO_EDIT,
  LIST_MANAGEMENT_ENVIRONMENT_PLAN_LIST,
  LIST_MANAGEMENT_ENVIRONMENT_PLAN_TO_EDIT,
  LIST_MANAGEMENT_IRRIGATION_PLAN_LIST,
  LIST_MANAGEMENT_IRRIGATION_PLAN_TO_EDIT,
  LIST_MANAGEMENT_SEED_SEEDING_GROUP_LIST,
  LIST_MANAGEMENT_SEED_PLANT_GROUP_LIST,
  LIST_MANAGEMENT_SEED_PLANT_VARIETY,
  LIST_MANAGEMENT_SEED_PLANT_TYPE,
  LIST_MANAGEMENT_SEED_PHPREFS,
  LIST_MANAGEMENT_SEED_ZONE_COUNTRY,
  LIST_MANAGEMENT_SEED_ZONES,
  LIST_MANAGEMENT_SEED_VENDORS,
  LIST_MANAGEMENT_MUSHROOM_VARIETY,
  LIST_MANAGEMENT_CUTTING_GRAFTING,
  LIST_MANAGEMENT_LIGHTING_PLAN_STEPS,
  LIST_MANAGEMENT_LIGHTING_PLAN_STEP_TO_EDIT,
  List_MANAGEMENT_LIGHTING_PLAN_VERSION
} from 'store/reducers/actions';

import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';

import listReducer, { initialState } from 'store/reducers/_list';

import { LightingPlanVersion } from 'utils/constants/list';
import axios from 'utils/axios';

import useAuth from 'hooks/useAuth';

const ListContext = createContext(null);

export const ListProvider = ({ children }) => {
  const { user } = useAuth();
  const dispatchForSnackbar = useDispatch();
  const [state, dispatch] = useReducer(listReducer, initialState);

  const setIsInitialized = useCallback((isInitialized) => {
    dispatch({
      type: LIST_MANAGEMENT_ISINITIALIZED,
      payload: {
        isInitialized
      }
    });
  }, []);

  const setCategory = useCallback((category) => {
    dispatch({
      type: LIST_MANAGEMENT_CATEGORY,
      payload: {
        category
      }
    });
  }, []);

  const setSeedList = useCallback((privateSeedList, publicSeedList) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_LIST,
      payload: {
        privateSeedList,
        publicSeedList
      }
    });
  }, []);

  const setSeedToEdit = useCallback((seedToEdit) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_TO_EDIT,
      payload: {
        seedToEdit
      }
    });
  }, []);

  const getSeedList = useCallback(async () => {
    setIsInitialized(false);
    try {
      const res = await axios.get('/list/seed');
      const privateSeedList = res?.data?.filter((item) => !!item?.user_id);
      const publicSeedList = res?.data?.filter((item) => !item?.user_id);
      setSeedList(privateSeedList, publicSeedList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setSeedList, setIsInitialized, dispatchForSnackbar]);

  const cloneSeed = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/seed/clone/${ID}`, values);
        const privateSeedList = res?.data?.filter((item) => !!item?.user_id);
        const publicSeedList = res?.data?.filter((item) => !item?.user_id);
        setSeedList(privateSeedList, publicSeedList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setSeedList]
  );

  const createSeed = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/list/seed`, values);
        const privateSeedList = res?.data?.filter((item) => !!item?.user_id);
        const publicSeedList = res?.data?.filter((item) => !item?.user_id);
        setSeedList(privateSeedList, publicSeedList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setSeedList]
  );

  const updateSeed = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/seed/${ID}`, values);
        const privateSeedList = res?.data?.filter((item) => !!item?.user_id);
        const publicSeedList = res?.data?.filter((item) => !item?.user_id);
        setSeedList(privateSeedList, publicSeedList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setSeedList]
  );

  const deleteSeed = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/seed/${ID}`);
        const privateSeedList = res?.data?.filter((item) => !!item?.user_id);
        const publicSeedList = res?.data?.filter((item) => !item?.user_id);
        setSeedList(privateSeedList, publicSeedList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setSeedList]
  );

  const setMicrogreenList = useCallback((privateMicrogreenList, publicMicrogreenList) => {
    dispatch({
      type: LIST_MANAGEMENT_MICROGREEN_LIST,
      payload: {
        privateMicrogreenList,
        publicMicrogreenList
      }
    });
  }, []);

  const setMicrogreenToEdit = useCallback((microgreenToEdit) => {
    dispatch({
      type: LIST_MANAGEMENT_MICROGREEN_TO_EDIT,
      payload: {
        microgreenToEdit
      }
    });
  }, []);

  const getMicrogreenList = useCallback(async () => {
    setIsInitialized(false);
    try {
      const res = await axios.get('/list/microgreen');
      const privateMicrogreenList = res?.data?.filter((item) => !!item?.user_id);
      const publicMicrogreenList = res?.data?.filter((item) => !item?.user_id);
      setMicrogreenList(privateMicrogreenList, publicMicrogreenList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setMicrogreenList, setIsInitialized, dispatchForSnackbar]);

  const cloneMicrogreen = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/microgreen/clone/${ID}`, values);
        const privateMicrogreenList = res?.data?.filter((item) => !!item?.user_id);
        const publicMicrogreenList = res?.data?.filter((item) => !item?.user_id);
        setMicrogreenList(privateMicrogreenList, publicMicrogreenList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setMicrogreenList]
  );

  const createMicrogreen = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/list/microgreen`, values);
        const privateMicrogreenList = res?.data?.filter((item) => !!item?.user_id);
        const publicMicrogreenList = res?.data?.filter((item) => !item?.user_id);
        setMicrogreenList(privateMicrogreenList, publicMicrogreenList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setMicrogreenList]
  );

  const updateMicrogreen = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/microgreen/${ID}`, values);
        const privateMicrogreenList = res?.data?.filter((item) => !!item?.user_id);
        const publicMicrogreenList = res?.data?.filter((item) => !item?.user_id);
        setMicrogreenList(privateMicrogreenList, publicMicrogreenList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setMicrogreenList]
  );

  const deleteMicrogreen = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/microgreen/${ID}`);
        const privateMicrogreenList = res?.data?.filter((item) => !!item?.user_id);
        const publicMicrogreenList = res?.data?.filter((item) => !item?.user_id);
        setMicrogreenList(privateMicrogreenList, publicMicrogreenList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setMicrogreenList]
  );

  const setCuttingList = useCallback((privateCuttingList, publicCuttingList) => {
    dispatch({
      type: LIST_MANAGEMENT_CUTTING_LIST,
      payload: {
        privateCuttingList,
        publicCuttingList
      }
    });
  }, []);

  const setCuttingToEdit = useCallback((cuttingToEdit) => {
    dispatch({
      type: LIST_MANAGEMENT_CUTTING_TO_EDIT,
      payload: {
        cuttingToEdit
      }
    });
  }, []);

  const getCuttingList = useCallback(async () => {
    setIsInitialized(false);
    try {
      const res = await axios.get('/list/cutting');
      const privateCuttingList = res?.data?.filter((item) => !!item?.user_id);
      const publicCuttingList = res?.data?.filter((item) => !item?.user_id);
      setCuttingList(privateCuttingList, publicCuttingList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setCuttingList, setIsInitialized, dispatchForSnackbar]);

  const cloneCutting = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/cutting/clone/${ID}`, values);
        const privateCuttingList = res?.data?.filter((item) => !!item?.user_id);
        const publicCuttingList = res?.data?.filter((item) => !item?.user_id);
        setCuttingList(privateCuttingList, publicCuttingList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setCuttingList]
  );

  const updateCutting = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/cutting/${ID}`, values);
        const privateCuttingList = res?.data?.filter((item) => !!item?.user_id);
        const publicCuttingList = res?.data?.filter((item) => !item?.user_id);
        setCuttingList(privateCuttingList, publicCuttingList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setCuttingList]
  );

  const createCutting = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/list/cutting/`, values);
        const privateCuttingList = res?.data?.filter((item) => !!item?.user_id);
        const publicCuttingList = res?.data?.filter((item) => !item?.user_id);
        setCuttingList(privateCuttingList, publicCuttingList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setCuttingList]
  );

  const deleteCutting = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/cutting/${ID}`);
        const privateCuttingList = res?.data?.filter((item) => !!item?.user_id);
        const publicCuttingList = res?.data?.filter((item) => !item?.user_id);
        setCuttingList(privateCuttingList, publicCuttingList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setCuttingList]
  );

  const setMushroomList = useCallback((privateMushroomList, publicMushroomList) => {
    dispatch({
      type: LIST_MANAGEMENT_MUSHROOM_LIST,
      payload: {
        privateMushroomList,
        publicMushroomList
      }
    });
  }, []);

  const setMushroomToEdit = useCallback((mushroomToEdit) => {
    dispatch({
      type: LIST_MANAGEMENT_MUSHROOM_TO_EDIT,
      payload: {
        mushroomToEdit
      }
    });
  }, []);

  const getMushroomList = useCallback(async () => {
    setIsInitialized(false);
    try {
      const res = await axios.get('/list/mushroom');
      const privateMushroomList = res?.data?.filter((item) => !!item?.user_id);
      const publicMushroomList = res?.data?.filter((item) => !item?.user_id);
      setMushroomList(privateMushroomList, publicMushroomList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setMushroomList, setIsInitialized, dispatchForSnackbar]);

  const cloneMushroom = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/mushroom/clone/${ID}`, values);
        const privateMushroomList = res?.data?.filter((item) => !!item?.user_id);
        const publicMushroomList = res?.data?.filter((item) => !item?.user_id);
        setMushroomList(privateMushroomList, publicMushroomList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setMushroomList]
  );

  const createMushroom = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/list/mushroom/`, values);
        const privateMushroomList = res?.data?.filter((item) => !!item?.user_id);
        const publicMushroomList = res?.data?.filter((item) => !item?.user_id);
        setMushroomList(privateMushroomList, publicMushroomList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setMushroomList]
  );

  const updateMushroom = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/mushroom/${ID}`, values);
        const privateMushroomList = res?.data?.filter((item) => !!item?.user_id);
        const publicMushroomList = res?.data?.filter((item) => !item?.user_id);
        setMushroomList(privateMushroomList, publicMushroomList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setMushroomList]
  );

  const deleteMushroom = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/mushroom/${ID}`);
        const privateMushroomList = res?.data?.filter((item) => !!item?.user_id);
        const publicMushroomList = res?.data?.filter((item) => !item?.user_id);
        setMushroomList(privateMushroomList, publicMushroomList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setMushroomList]
  );

  const setLightingPlanList = useCallback((privateLightingPlanList, publicLightingPlanList) => {
    dispatch({
      type: LIST_MANAGEMENT_LIGHTING_PLAN_LIST,
      payload: {
        privateLightingPlanList,
        publicLightingPlanList
      }
    });
  }, []);

  const setNewLightingPlanList = useCallback((privateNewLightingPlanList, publicNewLightingPlanList) => {
    dispatch({
      type: LIST_MANAGEMENT_NEW_LIGHTING_PLAN_LIST,
      payload: {
        privateNewLightingPlanList,
        publicNewLightingPlanList
      }
    });
  }, []);

  const setLightingPlanToEdit = useCallback((lightingPlanToEdit, lightingSteps = [], lightingStepToEdit = null) => {
    dispatch({
      type: LIST_MANAGEMENT_LIGHTING_PLAN_TO_EDIT,
      payload: {
        lightingPlanToEdit,
        lightingSteps,
        lightingStepToEdit
      }
    });
  }, []);

  const setNewLightingPlanToEdit = useCallback((newLightingPlanToEdit) => {
    dispatch({
      type: LIST_MANAGEMENT_NEW_LIGHTING_PLAN_TO_EDIT,
      payload: {
        newLightingPlanToEdit
      }
    });
  }, []);

  const setLightingSteps = useCallback((lightingSteps, lightingStepToEdit = null) => {
    dispatch({
      type: LIST_MANAGEMENT_LIGHTING_PLAN_STEPS,
      payload: {
        lightingSteps,
        lightingStepToEdit
      }
    });
  }, []);

  const setLightingStepToEdit = useCallback((lightingStepToEdit) => {
    dispatch({
      type: LIST_MANAGEMENT_LIGHTING_PLAN_STEP_TO_EDIT,
      payload: {
        lightingStepToEdit
      }
    });
  }, []);

  const setLightingPlanVersion = useCallback((lightingPlanVersion) => {
    dispatch({
      type: List_MANAGEMENT_LIGHTING_PLAN_VERSION,
      payload: {
        lightingPlanVersion
      }
    });
  }, []);

  const getLightingPlanList = useCallback(() => {
    setIsInitialized(false);
    try {
      axios.get('/list/lighting').then((res) => {
        const privateLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setLightingPlanList(privateLightingPlanList, publicLightingPlanList);
      });
      axios.get('/list/new_lighting').then((res) => {
        const privateNewLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicNewLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setNewLightingPlanList(privateNewLightingPlanList, publicNewLightingPlanList);
      });
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setLightingPlanList, setNewLightingPlanList, setIsInitialized, dispatchForSnackbar]);

  const cloneLightingPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/lighting/clone/${ID}`, values);
        const privateLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setLightingPlanList(privateLightingPlanList, publicLightingPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setLightingPlanList]
  );

  const createLightingPlan = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/list/lighting`, values);
        const privateLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setLightingPlanList(privateLightingPlanList, publicLightingPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setLightingPlanList]
  );

  const updateLightingPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/lighting/${ID}`, values);
        const privateLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setLightingPlanList(privateLightingPlanList, publicLightingPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setLightingPlanList]
  );

  const deleteLightingPlan = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/lighting/${ID}`);
        const privateLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setLightingPlanList(privateLightingPlanList, publicLightingPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setLightingPlanList]
  );

  const cloneNewLightingPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/new_lighting/clone/${ID}`, values);
        const privateNewLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicNewLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setNewLightingPlanList(privateNewLightingPlanList, publicNewLightingPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNewLightingPlanList]
  );

  const createNewLightingPlan = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/list/new_lighting`, values);
        const privateNewLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicNewLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setNewLightingPlanList(privateNewLightingPlanList, publicNewLightingPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNewLightingPlanList]
  );

  const updateNewLightingPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/new_lighting/${ID}`, values);
        const privateNewLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicNewLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setNewLightingPlanList(privateNewLightingPlanList, publicNewLightingPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNewLightingPlanList]
  );

  const deleteNewLightingPlan = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/new_lighting/${ID}`);
        const privateNewLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicNewLightingPlanList = res?.data?.filter((item) => !item?.user_id);
        setNewLightingPlanList(privateNewLightingPlanList, publicNewLightingPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNewLightingPlanList]
  );

  const setEnvironmentPlanList = useCallback((privateEnvironmentPlanList, publicEnvironmentPlanList) => {
    dispatch({
      type: LIST_MANAGEMENT_ENVIRONMENT_PLAN_LIST,
      payload: {
        privateEnvironmentPlanList,
        publicEnvironmentPlanList
      }
    });
  }, []);

  const setEnvironmentPlanToEdit = useCallback((environmentPlanToEdit) => {
    dispatch({
      type: LIST_MANAGEMENT_ENVIRONMENT_PLAN_TO_EDIT,
      payload: {
        environmentPlanToEdit
      }
    });
  }, []);

  const getEnvironmentPlanList = useCallback(async () => {
    setIsInitialized(false);
    try {
      const res = await axios.get('/list/environment');
      const privateEnvironmentPlanList = res?.data?.filter((item) => !!item?.user_id);
      const publicEnvironmentPlanList = res?.data?.filter((item) => !item?.user_id);
      setEnvironmentPlanList(privateEnvironmentPlanList, publicEnvironmentPlanList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setEnvironmentPlanList, setIsInitialized, dispatchForSnackbar]);

  const cloneEnvironmentPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/environment/clone/${ID}`, values);
        const privateEnvironmentPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicEnvironmentPlanList = res?.data?.filter((item) => !item?.user_id);
        setEnvironmentPlanList(privateEnvironmentPlanList, publicEnvironmentPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setEnvironmentPlanList]
  );

  const createEnvironmentPlan = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/list/environment`, values);
        const privateEnvironmentPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicEnvironmentPlanList = res?.data?.filter((item) => !item?.user_id);
        setEnvironmentPlanList(privateEnvironmentPlanList, publicEnvironmentPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setEnvironmentPlanList]
  );

  const updateEnvironmentPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/environment/${ID}`, values);
        const privateEnvironmentPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicEnvironmentPlanList = res?.data?.filter((item) => !item?.user_id);
        setEnvironmentPlanList(privateEnvironmentPlanList, publicEnvironmentPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setEnvironmentPlanList]
  );

  const deleteEnvironmentPlan = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/environment/${ID}`);
        const privateEnvironmentPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicEnvironmentPlanList = res?.data?.filter((item) => !item?.user_id);
        setEnvironmentPlanList(privateEnvironmentPlanList, publicEnvironmentPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setEnvironmentPlanList]
  );

  const setIrrigationPlanList = useCallback((privateIrrigationPlanList, publicIrrigationPlanList) => {
    dispatch({
      type: LIST_MANAGEMENT_IRRIGATION_PLAN_LIST,
      payload: {
        privateIrrigationPlanList,
        publicIrrigationPlanList
      }
    });
  }, []);

  const setIrrigationPlanToEdit = useCallback((irrigationPlanToEdit) => {
    dispatch({
      type: LIST_MANAGEMENT_IRRIGATION_PLAN_TO_EDIT,
      payload: {
        irrigationPlanToEdit
      }
    });
  }, []);

  const getIrrigationPlanList = useCallback(async () => {
    setIsInitialized(false);
    try {
      const res = await axios.get('/list/irrigation');
      const privateIrrigationPlanList = res?.data?.filter((item) => !!item?.user_id);
      const publicIrrigationPlanList = res?.data?.filter((item) => !item?.user_id);
      setIrrigationPlanList(privateIrrigationPlanList, publicIrrigationPlanList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setIrrigationPlanList, setIsInitialized, dispatchForSnackbar]);

  const cloneIrrigationPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/irrigation/clone/${ID}`, values);
        const privateIrrigationPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicIrrigationPlanList = res?.data?.filter((item) => !item?.user_id);
        setIrrigationPlanList(privateIrrigationPlanList, publicIrrigationPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setIrrigationPlanList]
  );

  const updateIrrigationPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/irrigation/${ID}`, values);
        const privateIrrigationPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicIrrigationPlanList = res?.data?.filter((item) => !item?.user_id);
        setIrrigationPlanList(privateIrrigationPlanList, publicIrrigationPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setIrrigationPlanList]
  );

  const createIrrigationPlan = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/list/irrigation`, values);
        const privateIrrigationPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicIrrigationPlanList = res?.data?.filter((item) => !item?.user_id);
        setIrrigationPlanList(privateIrrigationPlanList, publicIrrigationPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setIrrigationPlanList]
  );

  const deleteIrrigationPlan = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/irrigation/${ID}`);
        const privateIrrigationPlanList = res?.data?.filter((item) => !!item?.user_id);
        const publicIrrigationPlanList = res?.data?.filter((item) => !item?.user_id);
        setIrrigationPlanList(privateIrrigationPlanList, publicIrrigationPlanList);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setIrrigationPlanList]
  );

  const setSeedingGroupList = useCallback((seedingGroupList) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_SEEDING_GROUP_LIST,
      payload: {
        seedingGroupList
      }
    });
  }, []);

  const getSeedingGroupList = useCallback(async () => {
    try {
      const res = await axios.get('/type/seeding_group');
      setSeedingGroupList(res?.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setSeedingGroupList, dispatchForSnackbar]);

  const setPlantGroupList = useCallback((plantGroupList) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_PLANT_GROUP_LIST,
      payload: {
        plantGroupList
      }
    });
  }, []);

  const getPlantGroupList = useCallback(async () => {
    try {
      const res = await axios.get('/type/plant_group');
      setPlantGroupList(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setPlantGroupList, dispatchForSnackbar]);

  const setPlantVariety = useCallback((plantVarieties) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_PLANT_VARIETY,
      payload: {
        plantVarieties
      }
    });
  }, []);

  const getPlantVariety = useCallback(async () => {
    try {
      const res = await axios.get('/type/plant_variety');
      setPlantVariety(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setPlantVariety, dispatchForSnackbar]);

  const setPlantType = useCallback((plantTypes) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_PLANT_TYPE,
      payload: {
        plantTypes
      }
    });
  }, []);

  const getPlantType = useCallback(async () => {
    try {
      const res = await axios.get('/type/plant_type');
      setPlantType(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setPlantType, dispatchForSnackbar]);

  const setPhPreference = useCallback((PhPrefs) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_PHPREFS,
      payload: {
        PhPrefs
      }
    });
  }, []);

  const getPhPreference = useCallback(async () => {
    try {
      const res = await axios.get('/growing_item/ph_level');
      setPhPreference(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setPhPreference, dispatchForSnackbar]);

  const setZoneCountry = useCallback((zoneCountries) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_ZONE_COUNTRY,
      payload: {
        zoneCountries
      }
    });
  }, []);

  const getZoneCountry = useCallback(async () => {
    try {
      const res = await axios.get('/type/zone_country');
      setZoneCountry(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setZoneCountry, dispatchForSnackbar]);

  const setZones = useCallback((zones) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_ZONES,
      payload: {
        zones
      }
    });
  }, []);

  const getZones = useCallback(async () => {
    try {
      const res = await axios.get('/growing_item/zone');
      setZones(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setZones, dispatchForSnackbar]);

  const setSupplierNames = useCallback((vendors) => {
    dispatch({
      type: LIST_MANAGEMENT_SEED_VENDORS,
      payload: {
        vendors
      }
    });
  }, []);

  const getSupplierNames = useCallback(async () => {
    try {
      const res = await axios.get('/list/seed/vendor');
      setSupplierNames(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setSupplierNames, dispatchForSnackbar]);

  const setMushroomVariety = useCallback((mushroomVarieties) => {
    dispatch({
      type: LIST_MANAGEMENT_MUSHROOM_VARIETY,
      payload: {
        mushroomVarieties
      }
    });
  }, []);

  const getMushroomVariety = useCallback(async () => {
    try {
      const res = await axios.get('/type/mushroom_variety');
      setMushroomVariety(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setMushroomVariety, dispatchForSnackbar]);

  const setGraftingList = useCallback((graftingList) => {
    dispatch({
      type: LIST_MANAGEMENT_CUTTING_GRAFTING,
      payload: {
        graftingList
      }
    });
  }, []);

  const getGraftingList = useCallback(async () => {
    try {
      const res = await axios.get('/type/grafting');
      setGraftingList(res.data);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setGraftingList, dispatchForSnackbar]);

  useEffect(() => {
    getSeedingGroupList();
  }, [getSeedingGroupList]);

  useEffect(() => {
    getPlantGroupList();
  }, [getPlantGroupList]);

  useEffect(() => {
    getPlantVariety();
  }, [getPlantVariety]);

  useEffect(() => {
    getPlantType();
  }, [getPlantType]);

  useEffect(() => {
    getPhPreference();
  }, [getPhPreference]);

  useEffect(() => {
    getZoneCountry();
  }, [getZoneCountry]);

  useEffect(() => {
    getZones();
  }, [getZones]);

  useEffect(() => {
    getSupplierNames();
  }, [getSupplierNames]);

  useEffect(() => {
    getMushroomVariety();
  }, [getMushroomVariety]);

  useEffect(() => {
    getGraftingList();
  }, [getGraftingList]);

  useEffect(() => {
    setLightingPlanVersion(user?.user_setting?.lighting_flag ? LightingPlanVersion.New : LightingPlanVersion.Old);
  }, [user?.user_setting?.lighting_flag, setLightingPlanVersion]);

  return (
    <ListContext.Provider
      value={{
        ...state,
        setIsInitialized,
        setCategory,
        setSeedList,
        setSeedToEdit,
        getSeedList,
        createSeed,
        updateSeed,
        cloneSeed,
        deleteSeed,
        setMicrogreenList,
        setMicrogreenToEdit,
        getMicrogreenList,
        createMicrogreen,
        updateMicrogreen,
        cloneMicrogreen,
        deleteMicrogreen,
        setCuttingList,
        setCuttingToEdit,
        getCuttingList,
        createCutting,
        updateCutting,
        cloneCutting,
        deleteCutting,
        setMushroomList,
        setMushroomToEdit,
        getMushroomList,
        createMushroom,
        updateMushroom,
        cloneMushroom,
        deleteMushroom,
        setLightingPlanList,
        setLightingPlanToEdit,
        setNewLightingPlanList,
        setNewLightingPlanToEdit,
        setLightingPlanVersion,
        setLightingSteps,
        setLightingStepToEdit,
        getLightingPlanList,
        createLightingPlan,
        updateLightingPlan,
        cloneLightingPlan,
        deleteLightingPlan,
        createNewLightingPlan,
        updateNewLightingPlan,
        cloneNewLightingPlan,
        deleteNewLightingPlan,
        setEnvironmentPlanList,
        setEnvironmentPlanToEdit,
        getEnvironmentPlanList,
        createEnvironmentPlan,
        updateEnvironmentPlan,
        cloneEnvironmentPlan,
        deleteEnvironmentPlan,
        setIrrigationPlanList,
        setIrrigationPlanToEdit,
        getIrrigationPlanList,
        createIrrigationPlan,
        updateIrrigationPlan,
        cloneIrrigationPlan,
        deleteIrrigationPlan
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

ListProvider.propTypes = {
  children: PropTypes.node
};

export default ListContext;
