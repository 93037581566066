import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid, Divider } from '@mui/material';
import SpotItem from 'components/SpotItem';

import { sleep } from 'utils/common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQMarketplaceSection5 = ({ expand }) => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Show off and tell your story!',
      description:
        "Share what you sell and give us the scoop on your business. It's time to toot your own horn and let us know just how amazing your products are!"
    },
    {
      title: 'Submit a Sellers Review Request:',
      description: [
        'Obtain a promotional code or the invoice number from your application fee.',
        'Share descriptions, images, and details on your favorite products you sell.'
      ]
    },
    {
      title: 'Meet Your Product Ambassador:',
      description:
        'Once you start, a dedicated Product Ambassador will guide you through the entire process, answer your questions, and ensure things are completed efficiently.'
    },
    {
      title: 'Product Evaluation:',
      description: [
        <>
          To ensure your products meet the high standards and values of Khôra Marketplace, we carefully review each submission. This may
          involve requesting samples or inviting you to a{' '}
          <b>
            <i>Product Showcase Event</i>
          </b>
          , where you can present your products in person and take them home with you. Experienced vendors might have the option to bypass
          this step!
        </>
      ]
    },
    {
      title: 'Product Acceptance:',
      description:
        "If your products are accepted, it's time to celebrate! 🎉 Before you pop the confetti, get ready for some paperwork. You'll need to submit essential documents like a government ID, signed W-9, and provide bank information. Your Product Ambassador will help you submit everything you need."
    },
    {
      title: 'Start Listing:',
      description:
        'Once your documentation has been finalized, you can list your products in any category. The review process is designed to sample what you sell, not to approve all items.'
    }
  ];

  useEffect(() => {
    if (expand) {
      sleep(500).then(() => {
        window.scrollTo({
          top: 350,
          left: 0,
          behavior: 'smooth'
        });
      });
    }
  }, [expand]);

  return (
    <>
      <Accordion defaultExpanded={expand}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">How to become a Khôra Marketplace Seller! 🎉</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2.5}>
            <Stack spacing={3.75} direction={{ xs: 'column', sm: 'row' }} alignItems="center" sx={{ pr: 5 }}>
              <Typography variant="p2">
                Whether you{"'"}re a Farmers Market, a seasoned vendor, an artisan business, or a budding new venture, joining Khôra
                Marketplace connects you with passionate Farmers Market enthusiasts who value quality, freshness, and local flavor. Below
                are the key steps to becoming a seller. For all the details, download the full guide{' '}
                <u
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = '/assets/nitty_gritty_details.pdf'; // Path to your pdf file
                    link.setAttribute('download', 'ONBOARDING PROCESS FOR TODAY - NITTY GRITTY DETAILS.pdf'); // Any file name
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <b>here</b>
                </u>
                .
              </Typography>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: '10px',
                  cursor: 'pointer',
                  backgroundImage: 'url("/assets/images/faq/marketplace_4.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  aspectRatio: '1126 / 544',
                  height: 160
                }}
              />
            </Stack>
            <Box>
              <Stack
                sx={{
                  p: 2.5,
                  borderRadius: '6px',
                  border: `solid 1px ${theme.palette.primary[300]}`,
                  backgroundColor: 'common.white',
                  height: '100%'
                }}
              >
                <Grid container spacing={2}>
                  {contentData?.map((item, idx) => {
                    return [
                      <Grid key={`content_info_${idx}`} item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1.25}>
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ width: 50, height: 78, backgroundColor: theme.palette.primary[50] }}
                          >
                            <Typography variant="h4" sx={{ color: theme.palette.darkgreen[300] }}>
                              {idx + 1}
                            </Typography>
                          </Stack>
                          <Stack spacing={1.25} sx={{ width: `calc(100% - 50px)` }}>
                            <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                              {item?.title}
                            </Typography>
                            {typeof item?.description === 'string' ? (
                              <Typography variant="p2">{item?.description}</Typography>
                            ) : (
                              item?.description?.map((text, idx) => {
                                return typeof text === 'string' ? (
                                  <SpotItem key={`text_${idx}`} variant="p2" text={text} />
                                ) : (
                                  <Typography variant="p2">{item?.description}</Typography>
                                );
                              })
                            )}
                          </Stack>
                        </Stack>
                      </Grid>,
                      idx < contentData?.length - 1 && (
                        <Grid key={`content_info_divider_${idx}`} item xs={12}>
                          <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        </Grid>
                      )
                    ];
                  })}
                </Grid>
              </Stack>
            </Box>
            <Typography variant="p2">
              Get ready to share your incredible products with the world. Let{"'"}s make magic happen at Khôra Marketplace! 🌟
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
FAQMarketplaceSection5.propTypes = {
  expand: PropTypes.bool
};
export default FAQMarketplaceSection5;
