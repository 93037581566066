// material-ui
import { Box } from '@mui/material';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';

import { MAIN_HEADER_HEIGHT } from 'config';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  return (
    <SimpleBar
      sx={{
        px: 1.5,
        maxHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT - 1}px)`,
        overflowX: 'hidden',
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box sx={{ pt: 7, pb: 2.5 }}>
        <Navigation />
      </Box>
    </SimpleBar>
  );
};

export default DrawerContent;
