import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

import { ObservationProvider } from 'contexts/ObservationContext';
import { GrowingPlanProvider } from 'contexts/GrowingPlanContext';

// render - Observation Management
const ObservationManagement = Loadable(lazy(() => import('pages/observation')));
// ==============================|| MAIN ROUTING ||============================== //

const ObservationRoutes = {
  path: 'observation',
  element: (
    <GrowingPlanProvider>
      <ObservationProvider>
        <MainLayout networkShow={false}>
          <Outlet />
        </MainLayout>
      </ObservationProvider>
    </GrowingPlanProvider>
  ),
  children: [
    {
      path: '',
      element: <ObservationManagement />
    }
  ]
};

export default ObservationRoutes;
