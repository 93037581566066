import { useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Modal,
  IconButton,
  useTheme,
  Box,
  Grid,
  Divider
} from '@mui/material';
import VideoCard from 'components/VideoCard';
import ReactPlayer from 'react-player';
import { CloseOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection9 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Plug in the unit',
      description: 'Simply plug the Khôra Home unit into a dedicated power source.'
    },
    {
      title: 'Connect to a water source',
      description: 'Connect Khôra Home to a water source like connecting a refrigerator to a water line or choose an external tank.'
    },
    {
      title: 'Complete the self-guided online setup process',
      description:
        'Follow the step-by-step instructions provided in the self-guided online setup process to complete the initial setup and configuration of the unit.'
    },
    {
      title: 'Pick plants to grow',
      description:
        'Create a Crop Plan by selecting what you would like to grow from the public library, quantities, and harvest frequencies.'
    },
    {
      title: 'Place Khôra Cubes inside',
      description: 'Insert Khôra Cubes into the unit.'
    },
    {
      title: 'Start the Crop Plan',
      description: 'Schedule the Crop Plan to begin.'
    },
    {
      title: 'Let Khôra Home perform its magic (or should we say science?)',
      description:
        'Sit back and let Khôra Home take care of the rest. The unit will provide optimal growing conditions for your plants, ensuring they thrive and flourish.'
    },
    {
      title: 'Harvest when ready',
      description: 'Receive notifications when your plants are ready for harvest.'
    }
  ];

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const handleVideoModalOpen = () => setOpenVideoModal(true);
  const handleVideoModalClose = () => setOpenVideoModal(false);

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">How easy is it to set up a Khôra Home?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2.5}>
            <Stack spacing={3.75} direction={{ xs: 'column', sm: 'row' }} alignItems="center" sx={{ maxWidth: 914 }}>
              <Stack justifyContent="space-around" spacing={1.25}>
                <Typography variant="p2">
                  Setting up Khôra Home is designed to be straightforward and user-friendly, requiring just a few simple steps:
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3.75}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  onClick={handleVideoModalOpen}
                  sx={{
                    borderRadius: '10px',
                    cursor: 'pointer',
                    backgroundImage: 'url("/assets/images/khora_setup/watch.png")',
                    backgroundPosition: 'center, center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: 220,
                    height: 160
                  }}
                >
                  <img src="/assets/images/landing/demobtn.svg" alt="demo button" />
                </Stack>
              </Stack>
            </Stack>
            <Box>
              <Stack
                sx={{
                  p: 2.5,
                  borderRadius: '6px',
                  border: `solid 1px ${theme.palette.primary[300]}`,
                  backgroundColor: 'common.white',
                  height: '100%'
                }}
              >
                <Grid container spacing={2}>
                  {contentData?.map((item, idx) => {
                    return [
                      <Grid key={`content_info_${idx}`} item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1.25}>
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ width: 50, height: 78, backgroundColor: theme.palette.primary[50] }}
                          >
                            <Typography variant="h4" sx={{ color: theme.palette.darkgreen[300] }}>
                              {idx + 1}
                            </Typography>
                          </Stack>
                          <Stack spacing={1.25} sx={{ width: `calc(100% - 50px)` }}>
                            <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                              {item?.title}
                            </Typography>
                            <Typography variant="p2">{item?.description}</Typography>
                          </Stack>
                        </Stack>
                      </Grid>,
                      idx < contentData?.length - 1 && (
                        <Grid key={`content_info_divider_${idx}`} item xs={12}>
                          <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        </Grid>
                      )
                    ];
                  })}
                </Grid>
              </Stack>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Modal open={openVideoModal} onClose={handleVideoModalClose}>
        <VideoCard
          className="video-size-landing"
          title="Naming a Network, Locations, and Khôras"
          secondary={
            <IconButton onClick={handleVideoModalClose} sx={{ borderRadius: '100px' }}>
              <CloseOutlined />
            </IconButton>
          }
        >
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  controls: true,
                  autoPlay: true,
                  muted: false
                }
              }
            }}
            url={'/assets/video/network_naming.mp4'}
            width={'100%'}
            height={'100%'}
          />
        </VideoCard>
      </Modal>
    </>
  );
};
export default FAQKhoraHomeSection9;
