import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { Stack, Typography, Box, Grid, Button, CircularProgress } from '@mui/material';
import Loader from 'components/Loader';

import HTMLReactParser from 'html-react-parser';

import { formatOnlyDate } from 'utils/common';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import ArrowRightIcon from '@mui/icons-material/WestOutlined';

import useAuth from 'hooks/useAuth';

const ViewNews = () => {
  const { newsID } = useParams();
  const navigate = useNavigate();
  const { getOneNews } = useAuth();

  const [curNews, setCurNews] = useState(null);
  const [nextNews, setNextNews] = useState(null);
  const [prevNews, setPrevNews] = useState(null);
  const [sameCategoryNewsList, setSameCategoryNewsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!newsID || isNaN(parseInt(newsID))) {
      setLoading(false);
      return;
    }
    getOneNews(parseInt(newsID)).then((res) => {
      setCurNews(res?.current);
      setNextNews(res?.next);
      setPrevNews(res?.previous);
      setSameCategoryNewsList(res?.same_category);
      setLoading(false);
    });
  }, [getOneNews, newsID, setCurNews, setNextNews, setPrevNews]);

  return (
    <>
      {loading && <Loader />}
      <Stack spacing={4} py={4} sx={{ maxWidth: 1140, margin: 'auto' }}>
        {!!curNews && (
          <Box>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={2}>
                  <Stack spacing={3}>
                    <Typography variant="h4">{curNews?.title}</Typography>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Stack direction="row" alignItems="center" spacing={0.75}>
                        <Typography variant="p2m" sx={{ color: '#667085' }}>
                          <b>{curNews?.category?.name}</b>
                        </Typography>
                      </Stack>
                      <Typography sx={{ color: '#667085' }}>
                        <b>・</b>
                      </Typography>
                      <Typography sx={{ color: '#667085' }}>{formatOnlyDate(curNews?.publish_date, false)}</Typography>
                      <Typography sx={{ color: '#667085' }}>
                        <b>・</b>
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <TimeIcon sx={{ color: '#667085', fontSize: '1.25rem' }} />
                        <Typography sx={{ color: '#667085' }}>
                          {curNews?.time_to_read === 1 ? '1 min' : `${curNews?.time_to_read} mins`}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Button startIcon={<ArrowRightIcon />} onClick={() => window.history.back()} sx={{ mt: 2.5 }}>
                    Back
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} md={8.5}>
                <Stack spacing={3}>
                  <Box sx={{ backgroundColor: 'common.white', borderRadius: '10px', border: `solid 1px #eee` }}>
                    <Box
                      sx={{
                        height: 360,
                        width: '100%',
                        backgroundImage: `url('${curNews?.image ?? '/assets/images/kmg_image.png'}')`,
                        backgroundPosition: 'center, center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px'
                      }}
                    />
                    <Box className="ql-snow" sx={{ p: 3 }}>
                      <Box className="ql-editor">{HTMLReactParser(curNews?.content ?? '')}</Box>
                    </Box>
                    <Box sx={{ m: 1, p: 3, width: `calc(100% - 16px)`, backgroundColor: '#344054', borderRadius: '6px' }}>
                      <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                          <Stack spacing={2} alignItems="flex-start">
                            <Typography sx={{ color: '#667085' }}>
                              <b>Previous News</b>
                            </Typography>
                            <Typography
                              component={Link}
                              variant="h6"
                              to={`/news/view/${prevNews?.id}`}
                              color="common.white"
                              sx={{ textDecoration: 'none' }}
                            >
                              {prevNews?.title}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack spacing={2} alignItems="flex-end">
                            <Typography sx={{ color: '#667085' }}>
                              <b>Next News</b>
                            </Typography>
                            <Typography
                              component={Link}
                              variant="h6"
                              to={`/news/view/${nextNews?.id}`}
                              color="common.white"
                              sx={{ textDecoration: 'none', textAlign: 'right' }}
                            >
                              {nextNews?.title}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={0} md={3.5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <Stack spacing={3}>
                  <Typography variant="h5" sx={{ textAlign: 'center', color: '#667085' }}>
                    Related News
                  </Typography>
                  <Stack spacing={2} sx={{ p: 3, backgroundColor: '#344054', borderRadius: '10px' }}>
                    {sameCategoryNewsList?.map((item) => (
                      <Stack
                        key={`same_category_item_${item?.id}`}
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        onClick={() => navigate(`/news/view/${item?.id}`)}
                        sx={{
                          width: '100%',
                          maxWidth: 360,
                          aspectRatio: '16 / 7.5',
                          borderRadius: '10px',
                          borderLeft: `solid 6px #BDE1D8`,
                          borderTop: `solid 1px #eee`,
                          borderRight: `solid 1px #eee`,
                          borderBottom: `solid 1px #eee`,
                          backgroundColor: 'common.white',
                          p: 2,
                          cursor: 'pointer',
                          transition: 'transform 250ms ease, box-shadow 250ms ease, color 250ms ease',
                          '&:hover': {
                            WebkitTransform: 'translateY(-0.25rem)',
                            MsTransform: 'translateY(-0.25rem)',
                            transform: 'translateY(-0.25rem)'
                          }
                        }}
                      >
                        <Stack alignItems="flex-start" spacing={1.5}>
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              wordBreak: 'break-word',
                              WebkitLineClamp: 3
                            }}
                          >
                            <b>{item?.title}</b>
                          </Typography>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="body2" sx={{ color: '#667085' }}>
                              {formatOnlyDate(item?.publish_date, false)}
                            </Typography>
                            <Typography sx={{ color: '#667085' }}>
                              <b>・</b>
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <TimeIcon sx={{ fontSize: '1.15rem', color: '#667085' }} />
                              <Typography variant="body2" sx={{ color: '#667085' }}>
                                {item?.time_to_read === 1 ? '1 min' : `${item?.time_to_read} mins`}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}
                    {sameCategoryNewsList?.length === 0 && (
                      <Box sx={{ backgroundColor: 'common.white', p: 2, borderRadius: '6px' }}>
                        <Typography>Nothing to Show</Typography>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
        {loading && (
          <Stack alignItems="center" justifyContent="center" sx={{ height: 260, width: '100%' }}>
            <CircularProgress />
          </Stack>
        )}
        {!loading && !curNews && (
          <Box>
            <Typography variant="h4">View News</Typography>
            <Box sx={{ px: 2, py: 3, mt: 3.75, backgroundColor: 'common.white', border: `solid 1px #eee`, borderRadius: '6px' }}>
              <Typography textAlign="center">Nothing to Show</Typography>
            </Box>
          </Box>
        )}
      </Stack>
    </>
  );
};
export default ViewNews;
