// third-party
import { FormattedMessage } from 'react-intl';
// icons
import ListManagementIcon from 'assets/icons/list_management/KH-1247_List_Management.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const list_management = {
  id: 'group-list',
  type: 'group',
  children: [
    {
      id: 'list',
      title: <FormattedMessage id="List Management" />,
      type: 'item',
      icon: ListManagementIcon,
      url: '/list'
    }
  ]
};
const list_management_ = {
  id: 'group-list',
  type: 'group',
  children: [
    {
      id: 'list',
      title: <FormattedMessage id="List Management" />,
      type: 'collapse',
      icon: ListManagementIcon,
      children: [
        {
          id: 'list_',
          title: <FormattedMessage id="List Management" />,
          type: 'item',
          url: '/list'
        }
      ]
    }
  ]
};
export { list_management, list_management_ };
