import {
  OBSERVATION_IS_INITIALIZED,
  GROWING_PLAN_LIST_FOR_OBSERVATION,
  GROWING_PLAN_OBSERVATION_LIST_ALL,
  GROWING_PLAN_OBSERVATION_TO_EDIT,
  SELECTED_GROWING_PLAN_OBSERVATION_TYPE,
  GROWING_PLAN_OBSERVATION_LOG_SUMMARY,
  CROP_PLAN_LIST_FOR_OBSERVATION,
  CROP_PLAN_OBSERVATION_LIST_ALL,
  CROP_PLAN_OBSERVATION_TO_EDIT,
  SELECTED_CROP_PLAN_OBSERVATION_TYPE,
  CROP_PLAN_OBSERVATION_LOG_SUMMARY
} from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  privateGrowingPlanList: [],
  publicGrowingPlanList: [],
  autoCropPlanList: [],
  advancedCropPlanList: [],
  manualCropPlanList: [],
  growingPlanObservationList: [],
  growingPlanObservationToEdit: null,
  selectedGrowingPlan: null,
  growingPlanSelectedType: 0,
  growingPlanLogSummary: {
    germinations_count: 0,
    growths_count: 0,
    plant_placements_count: 0,
    transplant_steps_count: 0,
    growing_steps_count: 0,
    irrigations_count: 0,
    environments_count: 0,
    lightings_count: 0,
    harvests_count: 0,
    packagings_count: 0,
    storages_count: 0,
    miscellaneouses_count: 0
  },
  cropPlanObservationList: [],
  cropPlanObservationToEdit: null,
  selectedCropPlan: null,
  cropPlanSelectedType: 0,
  cropPlanLogSummary: {
    germinations_count: 0,
    miscellaneouses_count: 0
  }
};

// ==============================|| AUTH REDUCER ||============================== //

const observation = (state = initialState, action) => {
  switch (action.type) {
    case OBSERVATION_IS_INITIALIZED: {
      const { isInitialized } = action.payload;
      return {
        ...state,
        isInitialized
      };
    }
    case GROWING_PLAN_LIST_FOR_OBSERVATION: {
      const { privateGrowingPlanList, publicGrowingPlanList } = action.payload;
      return {
        ...state,
        privateGrowingPlanList,
        publicGrowingPlanList
      };
    }
    case CROP_PLAN_LIST_FOR_OBSERVATION: {
      const { autoCropPlanList, advancedCropPlanList, manualCropPlanList } = action.payload;
      return {
        ...state,
        autoCropPlanList,
        advancedCropPlanList,
        manualCropPlanList
      };
    }
    case GROWING_PLAN_OBSERVATION_LIST_ALL: {
      const { growingPlanObservationList } = action.payload;
      return {
        ...state,
        growingPlanObservationList
      };
    }
    case CROP_PLAN_OBSERVATION_LIST_ALL: {
      const { cropPlanObservationList } = action.payload;
      return {
        ...state,
        cropPlanObservationList
      };
    }
    case GROWING_PLAN_OBSERVATION_TO_EDIT: {
      const { growingPlanObservationToEdit, selectedGrowingPlan } = action.payload;
      return {
        ...state,
        growingPlanObservationToEdit,
        selectedGrowingPlan
      };
    }
    case CROP_PLAN_OBSERVATION_TO_EDIT: {
      const { cropPlanObservationToEdit, selectedCropPlan } = action.payload;
      return {
        ...state,
        cropPlanObservationToEdit,
        selectedCropPlan
      };
    }
    case GROWING_PLAN_OBSERVATION_LOG_SUMMARY: {
      const { growingPlanLogSummary } = action.payload;
      return {
        ...state,
        growingPlanLogSummary
      };
    }
    case CROP_PLAN_OBSERVATION_LOG_SUMMARY: {
      const { cropPlanLogSummary } = action.payload;
      return {
        ...state,
        cropPlanLogSummary
      };
    }
    case SELECTED_GROWING_PLAN_OBSERVATION_TYPE: {
      const { growingPlanSelectedType } = action.payload;
      return {
        ...state,
        growingPlanSelectedType
      };
    }
    case SELECTED_CROP_PLAN_OBSERVATION_TYPE: {
      const { cropPlanSelectedType } = action.payload;
      return {
        ...state,
        cropPlanSelectedType
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default observation;
