import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection4 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Public Growing Plans',
      description:
        'Sprout offers a repository of pre-defined Growing Plans, meticulously developed by in-house experts. These plans encapsulate proven strategies for successful cultivation and are readily accessible to Khôra Home Community members. Each plan outlines precise steps and parameters tailored to the specific needs of various plant species.'
    },
    {
      title: 'Private Growing Plan Library',
      description:
        'While Public Growing Plans serve as a foundation, users have the flexibility to customize the Growing Plans to accommodate their unique preferences, seeds, and growing processes. This allows growers to tailor cultivation strategies and create their own Private Growing Plan Library.'
    },
    {
      title: 'Submitting Private Growing Plans for acceptance in the Public Library',
      description:
        'Anyone in the Khôra Community may submit their personalized Growing Plans for consideration to be included in the Public Library, which is one of the steps in becoming a Certified Khôra Master Grower.'
    },
    {
      title: 'Notification System',
      description:
        'Sprout employs a notification system to keep users informed about essential tasks and milestones throughout the cultivation process. Users receive timely notifications regarding activities such as planting, watering, fertilization, and harvesting, ensuring that they stay on track with their cultivation schedule.'
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">How does Sprout know how to grow each plant?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Stack spacing={2.5} direction="row" alignItems="center" sx={{ maxWidth: 914 }}>
            <Stack justifyContent="space-around" spacing={1.25} sx={{ height: { xs: 'inherit', md: 160 } }}>
              <Typography variant="p2">
                Sprout leverages Growing Plans to effectively guide the cultivation process for each plant. These plans are meticulously
                crafted to provide comprehensive instructions on essential steps such as planting, transplanting, lighting, irrigation, and
                environmental controls.
              </Typography>
              <Typography variant="p2">Here{"'"}s how Sprout ensures successful cultivation:</Typography>
            </Stack>
            <Box sx={{ height: 180, display: { xs: 'none', md: 'block' } }}>
              <img src={'/assets/images/faq/sprout_2.png'} alt={'sprout_2'} style={{ width: 'auto', height: '180px' }} />
            </Box>
          </Stack>
          <Box>
            <Grid container spacing={2.5}>
              {contentData?.map((item, idx) => {
                return (
                  <Grid key={`content_info_${idx}`} item xs={12} md={6}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      spacing={1.25}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                          {item?.title}
                        </Typography>
                        <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        <Typography variant="p2">{item?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQSproutSection4;
