import { useContext } from 'react';

// setup provider
import SetupContext from 'contexts/SetupContext';

// ==============================|| AUTH HOOKS ||============================== //

const useSetup = () => {
  const context = useContext(SetupContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useSetup;
