import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

import { ListProvider } from 'contexts/ListContext';

// render - List Management
const ListManagement = Loadable(lazy(() => import('pages/list_management')));

// ==============================|| MAIN ROUTING ||============================== //

const ListRoutes = {
  path: 'list',
  element: (
    <ListProvider>
      <MainLayout networkShow={false}>
        <Outlet />
      </MainLayout>
    </ListProvider>
  ),
  children: [
    {
      path: '',
      element: <ListManagement />
    }
  ]
};

export default ListRoutes;
