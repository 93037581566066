import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import SetupLayout from 'layout/SetupLayout';

import { SetupURL, SetupRedirectURL, SetupStep } from 'utils/constants/setup';

// render - khora setup pages
const AddNetwork = Loadable(lazy(() => import('pages/setup/khora/AddNetwork')));
const AddLocation = Loadable(lazy(() => import('pages/setup/khora/AddLocation')));
const NameKhora = Loadable(lazy(() => import('pages/setup/khora/NameKhora')));
const Accessory = Loadable(lazy(() => import('pages/setup/khora/Accessory')));
const Stacking = Loadable(lazy(() => import('pages/setup/khora/Stacking')));
const SetShelves = Loadable(lazy(() => import('pages/setup/khora/SetShelves')));
const Another = Loadable(lazy(() => import('pages/setup/khora/Another')));
const KhoraSetupComplete = Loadable(lazy(() => import('pages/setup/khora/KhoraSetupComplete')));
const ShelfManagement = Loadable(lazy(() => import('pages/setup/khora/ShelfManagement')));
// const AnotherNetwork = Loadable(lazy(() => import('pages/setup/khora/AnotherNetwork')));
const AdditionalAddress = Loadable(lazy(() => import('pages/setup/khora/AdditionalAddress')));
// render - user setup pages
const GrowAuto = Loadable(lazy(() => import('pages/setup/user/GrowAuto')));
const GrowInterest = Loadable(lazy(() => import('pages/setup/user/GrowInterest')));
const GrowPrefer = Loadable(lazy(() => import('pages/setup/user/GrowPrefer')));
const GrowWhat = Loadable(lazy(() => import('pages/setup/user/GrowWhat')));
const MyKhora = Loadable(lazy(() => import('pages/setup/khora/MyKhora')));
const UserSetupComplete = Loadable(lazy(() => import('pages/setup/user/UserSetupComplete')));

// ==============================|| MAIN ROUTING ||============================== //

const SetupRoutes = {
  path: '',
  element: (
    <SetupLayout>
      <Outlet />
    </SetupLayout>
  ),
  children: [
    {
      path: SetupURL,
      children: [
        {
          path: SetupRedirectURL[SetupStep.Network],
          element: <AddNetwork />
        },
        {
          path: SetupRedirectURL[SetupStep.Location],
          element: <AddLocation />
        },
        {
          path: SetupRedirectURL[SetupStep.Accessory],
          element: <Accessory />
        },
        {
          path: SetupRedirectURL[SetupStep.NameKhora],
          element: <NameKhora />
        },
        {
          path: SetupRedirectURL[SetupStep.Stacking],
          element: <Stacking />
        },
        {
          path: SetupRedirectURL[SetupStep.SetShelves],
          element: <SetShelves />
        },
        {
          path: SetupRedirectURL[SetupStep.Another],
          element: <Another />
        },
        {
          path: SetupRedirectURL[SetupStep.KhoraSetupComplete],
          element: <KhoraSetupComplete />
        },
        {
          path: SetupRedirectURL[SetupStep.ShelfManagement],
          element: <ShelfManagement />
        },
        // {
        //   path: SetupRedirectURL[SetupStep.AnotherNetwork],
        //   element: <AnotherNetwork />
        // },
        {
          path: SetupRedirectURL[SetupStep.AdditionalAddress],
          element: <AdditionalAddress />
        },
        {
          path: SetupRedirectURL[SetupStep.GrowAuto],
          element: <GrowAuto />
        },
        {
          path: SetupRedirectURL[SetupStep.GrowInterest],
          element: <GrowInterest />
        },
        {
          path: SetupRedirectURL[SetupStep.GrowPrefer],
          element: <GrowPrefer />
        },
        {
          path: SetupRedirectURL[SetupStep.GrowWhat],
          element: <GrowWhat />
        },
        {
          path: SetupRedirectURL[SetupStep.MyKhora],
          element: <MyKhora />
        },
        {
          path: SetupRedirectURL[SetupStep.UserSetupComplete],
          element: <UserSetupComplete />
        }
      ]
    }
  ]
};

export default SetupRoutes;
