import { Box, Chip, Typography, Stack, Tooltip } from '@mui/material';
import JsPDF from 'jspdf';
import { saveAs } from 'file-saver';

import { ShelfStatus, ShelfStatusString, GroupType, GrowingMethod } from './constants/crop_plan';
import { ReminderType, Months } from './constants/schedule';
import { TodoStatus, TodoTaskType, TodoType } from './constants/todo';
import { GrowInterestMethod } from './constants/setup';
import { ScheduleStepType } from './constants/schedule';
import { Membership } from './constants/auth';
import { MembershipDetail } from './constants/membership';
import { Tips } from './constants/tooltip';
import { CardLogo, CardLogoLabel } from './constants/payment';
import PresoakIcon from 'assets/icons/growing_plan_management/presoak.svg';
import { getCountry } from 'iso-3166-1-alpha-2';
import { GrowingPeriod } from './constants/list';

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const handleScrollUp = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

export const apostrophe = (str) => {
  if (!str) return '';
  if (str.substr(-1) === 's') {
    return `${str}'`;
  } else {
    return `${str}'s`;
  }
};

export const getContainerIdOfGrowingPlan = (plan) => {
  if (!plan) return null;
  if (plan.is_seed) {
    return plan?.seed?.container_id;
  } else {
    return plan?.cutting?.container_id;
  }
};

export const getPlantGroupTypeOfGrowingPlan = (plan) => {
  if (!plan) return null;
  if (plan?.is_seed) {
    return plan?.seed?.plant_group?.type;
  } else {
    return plan?.cutting?.plant_group?.type;
  }
};

export const getPlantGroupOfGrowingPlan = (plan) => {
  if (!plan) return null;
  if (plan?.is_seed) {
    return plan?.seed?.plant_group;
  } else {
    return plan?.cutting?.plant_group;
  }
};

export const getImageOfGrowinPlan = (plant) => {
  if (!plant) return null;
  if (true === plant?.is_seed) {
    return plant?.seed?.image;
  }
  if (false === plant.is_seed) {
    return plant?.cutting?.image;
  }
  return null;
};

export const tableStyle = {
  borderCollapse: 'collapse',
  '& .MuiTableCell-root': {
    px: 0.5,
    py: 1.5
  }
};

export const getShelfStatus = (id) => {
  let tmpColor = 'success';
  switch (parseInt(id)) {
    case ShelfStatusString['Khôra Seeding']:
      tmpColor = 'error';
      break;
    case ShelfStatusString.OFF:
      tmpColor = 'secondary';
      break;
    case ShelfStatusString['Outside Seeding']:
      tmpColor = 'error';
      break;
    default:
      tmpColor = 'success';
      break;
  }
  return (
    <Box sx={{ mt: 1 }}>
      <Chip color={tmpColor} label={ShelfStatus[id]} size="small" variant="contained" />
    </Box>
  );
};

export const getTimelineMarkPoints = (duration) => {
  const _duration = parseInt(duration || 180);
  const limit_points = [
    {
      day: 30,
      step: 7,
      param: 4
    },
    {
      day: 60,
      step: 10,
      param: 6
    },
    {
      day: 90,
      step: 20,
      param: 10
    },
    {
      step: 30,
      param: 15
    }
  ];
  let index = 0;
  let tmpMarks = [];
  for (; index < 4; index++) {
    if (!limit_points[index]?.day) break;
    if (limit_points[index]?.day > _duration) break;
  }
  for (let i = 0; ; i += limit_points[index].step) {
    if (i > 0 && i + limit_points[index].param > _duration) {
      tmpMarks.push({ value: _duration, label: 'Day ' + _duration });
      break;
    } else {
      tmpMarks.push({ value: i, label: 'Day ' + i });
    }
  }
  return tmpMarks;
};

export const getPlantGroupChip = (plantGroup) => {
  switch (plantGroup) {
    case GroupType.Seed:
      return <Chip variant="contained" size="small" label={'Seed'} sx={{ backgroundColor: '#1998E0', color: '#FFFFFF' }} />;
    case GroupType.Microgreen:
      return <Chip variant="contained" size="small" label={'Microgreen'} sx={{ backgroundColor: '#719794', color: '#FFFFFF' }} />;
    case GroupType.Mushroom:
      return <Chip variant="contained" size="small" label={'Mushroom'} sx={{ backgroundColor: '#FF8E48', color: '#FFFFFF' }} />;
    case GroupType.Cutting:
      return <Chip variant="contained" size="small" label={'Cutting'} sx={{ backgroundColor: '#FFC933', color: '#FFFFFF' }} />;
    default:
      return <Chip variant="contained" size="small" label={'Seed'} />;
  }
};

export const getGrowingMethodChip = (growingMethod, variant = 'contained', size = 'medium') => {
  switch (growingMethod) {
    case GrowingMethod.Once:
      return <Chip variant={variant} size={size} color="info" label={'Grow Once'} />;
    case GrowingMethod.Repeat:
      return <Chip variant={variant} size={size} color="info" label={'Repeat Growing'} />;
    case GrowingMethod.Continuous:
      return <Chip variant={variant} size={size} color="info" label={'Continuous Harvest'} />;
    default:
      return <Chip variant={variant} size={size} color="info" label={'Mixed'} />;
  }
};

export const getGrowInterestChip = (growInterest, variant = 'outlined', size = 'meidum') => {
  switch (growInterest) {
    case GrowInterestMethod.DirtyHands:
      return <Chip variant={variant} size={size} label={'Dirty Hands'} sx={{ color: '#AD7133', borderColor: '#AD7133' }} />;
    case GrowInterestMethod.CleanHands:
      return <Chip variant={variant} size={size} label={'Clean Hands'} sx={{ color: '#33A4E4', borderColor: '#33A4E4' }} />;
    default:
      return <Chip variant={variant} size={size} label={'Unknown'} sx={{ color: '#FF0000', borderColor: '#FF0000' }} />;
  }
};

export const getNextGrowingStepChip = (label) => {
  let color = '';
  if (label === 'Place Plant') {
    color = '#FF8E48';
  } else if (label.includes('1st Transplant')) {
    color = '#912018';
  } else if (label.includes('2nd Transplant')) {
    color = '#33A4E4';
  } else if (label.includes('3rd Transplant')) {
    color = '#E56717';
  } else if (label === 'Harvest when ready') {
    color = '#7CB8BD';
  } else if (label === 'Harvest') {
    color = '#027A48';
  } else {
    color = '#808285';
  }

  return (
    <Box
      sx={{
        color,
        border: `solid 1px ${color}`,
        px: 1,
        py: 0.25,
        borderRadius: '20px',
        width: 'fit-content',
        fontSize: '12px',
        textWrap: 'nowrap'
      }}
    >
      {label}
    </Box>
  );
};

export const normalizeString = (str) => {
  return str
    ?.toString()
    ?.trim()
    ?.toLocaleLowerCase()
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/\n/g, ' ');
};

export const getChipColorForMissingCostData = (field) => {
  switch (parseInt(field?.ctype)) {
    case 0:
      return '#FF8031';
    case 1:
      return '#77A6C6';
    case 2:
      return '#C29466';
    case 3:
      return '#FFCF4C';
    case 4:
      return '#FFCF4C';
    case 5:
      return '#039855';
    case 6:
      return '#039855';
    case 10:
      return '#529DD9';
    case 11:
      return '#B35012';
    default:
      return '#82ADA9';
  }
};

export const inactiveLightingPhase = (id, active = false, duration_days = 0, dawn_increment_count = 0, dusk_decrement_count = 0) => {
  return {
    growing_phase: id,
    active: active,
    duration_days: duration_days,
    dawn_hours: 0,
    dawn_minutes: 0,
    dawn_increment_count: dawn_increment_count,
    dawn_uv_intensity: 0,
    dawn_blue_intensity: 0,
    dawn_white_intensity: 0,
    dawn_red_intensity: 0,
    dawn_far_red_intensity: 0,
    dawn_mixed_intensity: 0,
    dawn_other_intensity: 0,
    day_hours: 0,
    day_minutes: 0,
    day_uv_intensity: 0,
    day_blue_intensity: 0,
    day_white_intensity: 0,
    day_red_intensity: 0,
    day_far_red_intensity: 0,
    day_mixed_intensity: 0,
    day_other_intensity: 0,
    dusk_hours: 0,
    dusk_minutes: 0,
    dusk_decrement_count: dusk_decrement_count,
    dusk_uv_intensity: 0,
    dusk_blue_intensity: 0,
    dusk_white_intensity: 0,
    dusk_red_intensity: 0,
    dusk_far_red_intensity: 0,
    dusk_mixed_intensity: 0,
    dusk_other_intensity: 0,
    night_hours: 0,
    night_minutes: 0
  };
};

const checkLightingZero = (type, data) => {
  return (
    data[`${type}_blue_intensity`] +
    data[`${type}_far_red_intensity`] +
    data[`${type}_mixed_intensity`] +
    data[`${type}_other_intensity`] +
    data[`${type}_red_intensity`] +
    data[`${type}_uv_intensity`] +
    data[`${type}_white_intensity`]
  );
};

export const activeValidationCheck = (data) => {
  if (data.dawn_minutes + data.dawn_hours != 0 && checkLightingZero('dawn', data) == 0) return false;
  if (data.dawn_minutes + data.dawn_hours == 0 && checkLightingZero('dawn', data) != 0) return false;
  if (data.day_minutes + data.day_hours != 0 && checkLightingZero('day', data) == 0) return false;
  if (data.day_minutes + data.day_hours == 0 && checkLightingZero('day', data) != 0) return false;
  if (data.dusk_minutes + data.dusk_hours != 0 && checkLightingZero('dusk', data) == 0) return false;
  if (data.dusk_minutes + data.dusk_hours == 0 && checkLightingZero('dusk', data) != 0) return false;
  if (
    data.dawn_minutes + data.dawn_hours == 0 &&
    checkLightingZero('dawn', data) == 0 &&
    data.day_minutes + data.day_hours == 0 &&
    checkLightingZero('day', data) == 0 &&
    data.dusk_minutes + data.dusk_hours == 0 &&
    checkLightingZero('dusk', data) == 0
  )
    return false;
  return true;
};

export const initialValuesForLightingStep = (id, flag) => {
  return { values: inactiveLightingPhase(id, flag, flag ? '' : 0, flag ? 10 : 0, flag ? 10 : 0) };
};

export const lightingValues = (type, data) => {
  let ret = '';
  ret += data[`${type}_blue_intensity`] != '0' ? `Blue: ${data[`${type}_blue_intensity`]}%, ` : '';
  ret += data[`${type}_white_intensity`] != '0' ? `Green: ${data[`${type}_white_intensity`]}%, ` : '';
  ret += data[`${type}_red_intensity`] != '0' ? `Red: ${data[`${type}_red_intensity`]}%, ` : '';
  ret += data[`${type}_far_red_intensity`] != '0' ? `Far Red: ${data[`${type}_far_red_intensity`]}%, ` : '';
  ret += data[`${type}_uv_intensity`] != '0' ? `UV: ${data[`${type}_uv_intensity`]}%, ` : '';
  ret += data[`${type}_mixed_intensity`] != '0' ? `Mixed: ${data[`${type}_mixed_intensity`]}%, ` : '';
  ret += data[`${type}_other_intensity`] != '0' ? `Other: ${data[`${type}_other_intensity`]}%, ` : '';
  return ret === '' ? 'No Lighting' : ret.substr(0, ret?.length - 2);
};

export const getStartScheduleDateTime = (start_date, start_time) => {
  if (!start_date) return null;
  if (!start_time) return null;
  const date = new Date(start_date);
  const retDate = new Date(start_time);
  retDate.setFullYear(date.getFullYear());
  retDate.setMonth(date.getMonth());
  retDate.setDate(date.getDate());
  return retDate;
};

export const getReminder = (values) => {
  switch (values.reminder) {
    case 'Custom':
      return {
        type: ReminderType.Custom,
        value: parseInt(values.custom_reminder),
        unit: values.custom_unit?.split('_')[0],
        pov: values.custom_unit?.split('_')[1]
      };
    default:
      return {
        type: ReminderType.Manual,
        value: parseInt(values.reminder?.split('_')[0]),
        unit: values.reminder?.split('_')[1],
        pov: values.reminder?.split('_')[2]
      };
  }
};

export const getReminderString = (reminder) => {
  const PointOfView = { B: 'Before', A: 'After' };
  const TimeUnits = { M: 'Minute', H: 'Hour', D: 'Day', W: 'Week' };
  if (reminder?.value == 0 && reminder?.unit == 'N' && reminder?.pov == 'N') {
    return 'None';
  } else {
    return `${reminder?.value} ${TimeUnits?.[reminder?.unit]}${reminder?.value === 1 ? '' : 's'} ${PointOfView?.[reminder?.pov]}`;
  }
};

export const getFirstDayOfWeek = (d) => {
  const dd = new Date(d);
  return new Date(dd.setDate(dd.getDate() - dd.getDay()));
};
export const getOffsetDayOfWeek = (d, offset) => {
  const dd = new Date(d);
  return new Date(dd.setDate(dd.getDate() + offset));
};

export const isToday = (tmpDate) => {
  const today = new Date();
  return today.getFullYear() === tmpDate.getFullYear() && today.getMonth() === tmpDate.getMonth() && today.getDate() === tmpDate.getDate();
};

export const getMonthLabel = (d) => {
  const dd = new Date(d);
  if (dd.getDate() > 3) {
    return `${Months[dd.getMonth()]}, ${dd.getFullYear()}`;
  } else {
    if (dd.getMonth() === 0) {
      return `${Months[11]}, ${dd.getFullYear() - 1}`;
    } else {
      return `${Months[dd.getMonth() - 1]}, ${dd.getFullYear()}`;
    }
  }
};

const addMonth = (year, month, flag) => {
  const weeks = [];
  let firstDate = new Date(year, month, 1);
  let lastDate = new Date(year, month + 1, 0);
  let numDays = lastDate.getDate();

  let dayOfWeekCounter = firstDate.getDay();
  for (let date = 1; date <= numDays; date++) {
    if (dayOfWeekCounter === 0 || weeks.length === 0) {
      weeks.push([]);
    }
    weeks[weeks.length - 1].push(date);
    dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
  }
  if (flag === 0) return weeks[0];
  if (flag === 1) return weeks[weeks.length - 1];
  return [];
};

export const getWeeksInMonth = (year, month) => {
  const weeks = [];

  let firstDate = new Date(year, month, 1);
  let lastDate = new Date(year, month + 1, 0);
  let numDays = lastDate.getDate();

  let dayOfWeekCounter = firstDate.getDay();

  for (let date = 1; date <= numDays; date++) {
    if (dayOfWeekCounter === 0 || weeks.length === 0) {
      weeks.push([]);
    }
    weeks[weeks.length - 1].push(date);
    dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
  }

  if (weeks[0]?.length < 7) {
    const beforeIndex1 = addMonth(year, month - 1, 1);
    const indexRefactor = [...beforeIndex1, ...weeks[0]];
    weeks[0] = indexRefactor;
  }

  if (weeks[weeks.length - 1].length < 7) {
    const afterIndex1 = addMonth(year, month + 1, 0);
    const indexRefactor = [...weeks[weeks.length - 1], ...afterIndex1];
    weeks[weeks.length - 1] = indexRefactor;
  }

  return weeks.filter((w) => !!w.length);
  // return weeks.filter((w) => !!w.length).map((w) => ({ start: w[0], end: w[w.length - 1], dates: w }));
};

export const calculateDate = (date, offset, flag = true) => {
  if (!date) return '';
  const offsetMillis = offset * 24 * 60 * 60 * 1000;
  const tmpDateTime = flag ? new Date(date?.replace(' ', 'T') + 'Z') : new Date(date);
  tmpDateTime.setTime(tmpDateTime.getTime() + offsetMillis);
  const year = tmpDateTime.getFullYear();
  const month = (tmpDateTime.getMonth() + 1).toString().padStart(2, '0');
  const day = tmpDateTime.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
};

export const formatStartTime = (startTime) => {
  let newDate = new Date('2022/09/23 ' + startTime);
  let ampm = 'AM';
  let hour = newDate.getHours();
  if (hour >= 12) {
    ampm = 'PM';
    if (hour !== 12) hour = hour - 12;
  } else {
    if (hour === 0) hour = 12;
  }
  hour = hour.toString().padStart(2, '0');
  let minute = newDate.getMinutes();
  minute = minute.toString().padStart(2, '0');
  let second = newDate.getSeconds();
  second = second.toString().padStart(2, '0');
  return hour + ':' + minute + ':' + second + ' ' + ampm;
};

export const formatEndTime = (startTime, minutes, seconds) => {
  let newDate = new Date('2022/09/23 ' + startTime);
  let resultDate = new Date();
  resultDate.setTime(newDate.getTime() + parseInt(minutes) * 60000 + parseInt(seconds) * 1000);

  let ampm = 'AM';
  let hour = resultDate.getHours();
  if (hour >= 12) {
    ampm = 'PM';
    if (hour !== 12) hour = hour - 12;
  } else {
    if (hour === 0) hour = 12;
  }
  hour = hour.toString().padStart(2, '0');
  let minute = resultDate.getMinutes();
  minute = minute.toString().padStart(2, '0');
  let second = resultDate.getSeconds();
  second = second.toString().padStart(2, '0');
  return hour + ':' + minute + ':' + second + ' ' + ampm;
};

export const formatDate = (date, flag = true) => {
  if (!date) return null;

  // Create new date object from given date string
  const dateTime = flag ? new Date(date?.replace(' ', 'T') + 'Z') : new Date(date);

  // Extract year, month, and day from date object as strings
  const year = dateTime.getFullYear();
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
  const day = dateTime.getDate().toString().padStart(2, '0');

  // Extract hour and minute from date object as strings
  let ampm = 'AM';
  let hour = dateTime.getHours();
  if (hour >= 12) {
    ampm = 'PM';
    if (hour !== 12) hour -= 12;
  } else {
    if (hour === 0) hour = 12;
  }
  hour = hour.toString().padStart(2, '0');
  const minute = dateTime.getMinutes().toString().padStart(2, '0');

  // Return formatted date string using template literals
  return `${month}/${day}/${year} ${hour}:${minute} ${ampm}`;
};

export const formatOnlyDate = (date, flag = true) => {
  const fullDate = flag ? new Date(date?.replace(' ', 'T') + 'Z') : new Date(date);
  const year = fullDate.getFullYear();
  let month = (fullDate.getMonth() + 1).toString().padStart(2, '0');
  let day = fullDate.getDate().toString().padStart(2, '0');
  return `${month}/${day}/${year}`;
};

export const formatDateForDatabase = (date) => {
  if (!date) return null;

  // Create new date object from given date string
  const dateTime = new Date(date).toISOString();
  const dateString = dateTime?.split('.')[0]?.replace('T', ' ');
  return dateString;
};
export const addDays = (date, days, flag = true) => {
  let result = flag ? new Date(date?.replace(' ', 'T') + 'Z') : new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
export const formatAddress = (address) => {
  const { country, state, city, address1, address2, postal_code } = address;
  const fullCountry = country ? getCountry(country) : '';
  return address1 + ', ' + (!address2 ? '' : address2 + ', ') + city + ', ' + state + ' ' + postal_code + ', ' + fullCountry;
};

export const formatAddressWithFullName = (address) => {
  const { name, country, state, city, address1, address2, postal_code } = address;
  const fullCountry = getCountry(country);
  return (
    (name ? name + ' - ' : '') +
    address1 +
    ', ' +
    (!address2 ? '' : address2 + ', ') +
    city +
    ', ' +
    state +
    ' ' +
    postal_code +
    ', ' +
    (fullCountry ?? country)
  );
};

// Harvest
export const handleHarvestData = (data) => {
  let harvestData = [];
  Object.keys(data)?.forEach((e) => {
    let tmp = [];
    data[e]?.forEach((item) => {
      tmp.push({
        plant_variety: item?.plant_variety?.title,
        nickname: item?.nickname,
        growing_plan_name: item?.growing_plan_name,
        num_shelves: item?.num_shelves,
        num_trays: item?.num_trays,
        num_cubes: item?.plant_group_type !== GroupType.Seed && item?.plant_group_type !== GroupType.Cutting ? '-' : item?.volume,
        harvest_container: item?.container,
        harvest_day: formatOnlyDate(item?.harvest_day),
        days_later: item?.days
      });
    });
    harvestData[e] = tmp?.sort((a, b) => {
      if (parseInt(a?.days_later) > parseInt(b?.days_later)) return 1;
      if (parseInt(a?.days_later) < parseInt(b?.days_later)) return -1;
      if (normalizeString(a?.plant_variety) > normalizeString(b?.plant_variety)) return 1;
      if (normalizeString(a?.plant_variety) < normalizeString(b?.plant_variety)) return -1;
      if (normalizeString(a?.nickname) > normalizeString(b?.nickname)) return 1;
      if (normalizeString(a?.nickname) < normalizeString(b?.nickname)) return -1;
      return 0;
    });
  });
  return harvestData;
};

// Get transplant step label
export const getTransplantStepLabel = (id, isSeed, groupType, presoak) => {
  switch (parseInt(id)) {
    case 1:
      if (isSeed && groupType != GroupType.Mushroom && presoak) {
        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography>Seeding</Typography>
            <Tooltip title={Tips.Presoak}>
              <img src={PresoakIcon} alt="presoak" style={{ width: '20px', height: '20px' }} />
            </Tooltip>
          </Stack>
        );
      }
      return isSeed ? (groupType == GroupType.Mushroom ? 'Inoculation' : 'Seeding') : 'Grafting or Leaf / Stem Cutting';
    case 2:
      return '1st Transplant';
    case 3:
      return '2nd Transplant';
    case 4:
      return '3rd Transplant';
    default:
      return '';
  }
};

// get enhancer label
export const getEnhancerLabel = (item) => {
  let tmp = JSON.parse(item);
  let ret = '';
  for (const x in tmp) {
    if (tmp[x] === true) ret += x + ', ';
  }
  return ret?.length === 0 ? '-' : ret.substring(0, ret?.length - 2);
};

export const getTimeDifferent = (date) => {
  const timeNow = new Date().getTime() / 1000;
  const timeSelected = new Date(date).getTime() / 1000;

  if (timeNow - timeSelected < 60) return 'Now';

  if (timeNow - timeSelected < 3600) {
    const diff = Math.ceil((timeNow - timeSelected) / 60);
    return diff === 60 ? '1 hour ago' : diff + (diff === 1 ? ' minute ago' : ' minutes ago');
  }
  if (timeNow - timeSelected < 86400) {
    const diff = Math.ceil((timeNow - timeSelected) / 3600);
    return diff === 24 ? '1 day ago' : diff + (diff === 1 ? ' hour ago' : ' hours ago');
  }
  const diff = Math.ceil((timeNow - timeSelected) / 86400);
  return diff + (diff === 1 ? 'day ago' : ' days ago');
};

export const getToDoListStatusString = (value) => {
  switch (value) {
    case TodoStatus.Current:
    default:
      return 'Current';
    case TodoStatus.Past:
      return 'Past';
    case TodoStatus.Future:
      return 'Future';
  }
};

export const getTodoItemTitle = (todo) => {
  if (todo?.is_manual) {
    const taskType = Object.keys(TodoTaskType)?.find((key) => TodoTaskType[key] === todo?.type);
    return todo?.name ? `${taskType}: ${todo?.name}` : taskType;
  } else {
    switch (todo?.type) {
      case TodoType.Planting.value:
        return todo?.plant_name ? `Planting: ${todo?.plant_name}` : 'Planting';
      case TodoType.Transplanting.value:
        return todo?.plant_name ? `Transplant: ${todo?.plant_name}` : 'Transplant';
      case TodoType.Growing.value:
        return todo?.growing_task_name ? `${todo?.growing_task_type_name}: ${todo?.growing_task_name}` : todo?.growing_task_type_name;
    }
  }
};

export const getTodoItemIcon = (todo) => {
  if (todo?.is_manual) {
    const taskType = Object.keys(TodoTaskType)?.find((key) => TodoTaskType[key] === todo?.type);
    return `/assets/icons/todo/${taskType?.toLocaleLowerCase()}.svg`;
  } else {
    const todoType = Object.keys(TodoType)?.find((key) => TodoType[key].value === todo?.type);
    return `/assets/icons/notification/${TodoType[todoType].image}`;
  }
};

export const formatNumber = (number) => {
  if (!number && number != 0) return '';
  const parts = number?.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const generateSeedingListPDF = (content, dataLength = 0, filename) => {
  const report = new JsPDF('p', 'mm', [216, 279.5]);

  const headerHeight = document.querySelector('#download #header').clientHeight + 70;
  const shppingTitleHeight = document.querySelector('#download #shopping_header').clientHeight;
  const networkTitleHeight = document.querySelector('#download #network_header').clientHeight + 10;
  const locationTitleHeight = document.querySelector('#download #location_header').clientHeight + 30;
  const startEndHeight = document.querySelector('#download #start_end_header').clientHeight + 16;
  const labelHeight = document.querySelector('#download #label_header').clientHeight + 22;

  const heightPerPage = (279.5 - 10) / (206 / (window.innerWidth * 0.8));
  const initialHeight = headerHeight + shppingTitleHeight + networkTitleHeight + locationTitleHeight + startEndHeight + labelHeight;
  let pdfHeight = initialHeight;
  let curPage = 1;

  for (let i = 0; i < dataLength; i++) {
    const height = document.querySelector('#download #shopping_week_' + i).clientHeight + (i === 0 ? 0 : 14);
    if (pdfHeight + height > heightPerPage * curPage) {
      document.querySelector('#download #shopping_week_' + i).style.marginTop = `${
        heightPerPage * curPage - pdfHeight + (i === 0 ? 0 : 14)
      }px`;
      pdfHeight = heightPerPage * curPage + height;
      curPage += 1;
    } else {
      pdfHeight += height;
    }
  }

  report
    .html(content, {
      html2canvas: {
        scale: 206 / (window.innerWidth * 0.8)
      },
      margin: 5,
      autoPaging: 'text'
    })
    .then(() => {
      report.save(filename);
      for (let i = 1; i < dataLength; i++) {
        document.querySelector('#download #shopping_week_' + i).style.marginTop = '14px';
      }
    });
};

export const generatePDF = (content, filename) => {
  const report = new JsPDF('l', 'mm', [279.5, 216]);
  report
    .html(content, {
      html2canvas: {
        scale: 269.5 / (window.innerWidth * 0.8)
      },
      margin: 5,
      autoPaging: 'text'
    })
    .then(() => {
      report.save(filename);
    });
};

export const generateImage = (content, filename) => {
  html2canvas(content).then((canvas) => {
    canvas.toBlob((blob) => {
      if (blob) {
        saveAs(blob, filename);
      }
    }, 'image/png');
  });
};

export const getCalendarStepColor = (data) => {
  if (data?.length === 0) return 'inherit';
  let type = ScheduleStepType.End;
  let color = '#98A2B3';
  data?.forEach((item) => {
    if (item?.type < type) {
      type = item?.type;
      color = item?.color;
    }
  });
  return color;
};

export const replaceHtmlContent = (content) => {
  return (
    content
      ?.replaceAll('<p>', '<p style="margin: 0px;">')
      ?.replaceAll('<ol>', '<ol style="margin: 0px;">')
      ?.replaceAll('<ul>', '<ul style="margin: 0px;">')
      ?.replaceAll('<h1>', '<h1 style="margin: 0px;">')
      ?.replaceAll('<h2>', '<h2 style="margin: 0px;">')
      ?.replaceAll('<h3>', '<h3 style="margin: 0px;">')
      ?.replace(
        /(<a href="https?:\/\/([a-zA-Z0-9-]+\.)*([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}[^>]*>)http[^<]*<\/a>/g,
        '$1<img src="/assets/icons/social/$3.png" alt="$3" style="width: 28px; height: auto; background-color: #59B39E; padding: 2px; border-radius: 6px;"></a>'
      ) ?? ''
  );
};

export const getPercentageString = (value, decimal = 2) => {
  return value === '-' ? '-' : formatNumber(value?.toFixed(decimal)) + ' %';
};

export const getDollarString = (value, decimal = 7) => {
  return value == '-' || value == 0 ? <Typography color="error">No Data</Typography> : '$' + formatNumber(value?.toFixed(decimal));
};

export const getImageBase64 = async (url) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

export const getMembershipString = (membership) => {
  const mKey = Object.keys(Membership).find((key) => Membership[key] === membership) ?? 'Free';
  return MembershipDetail[mKey];
};

export const getMembershipButtonString = (membership) => {
  const mKey = Object.keys(Membership).find((key) => Membership[key] === membership) ?? 'Free';
  return `Select ${mKey} Membership`;
};

export const getPaymentMethodBrand = (brand) => {
  const idx = CardLogo.findIndex((logo) => logo === brand);
  if (idx === -1) return null;
  return <img key={brand} src={`/assets/images/payment/cards/${brand}.png`} alt={brand} width="32px" height="20px" />;
};

export const getPaymentMethodLabel = (brand) => {
  const idx = CardLogo.findIndex((logo) => logo === brand);
  if (idx === -1) return 'Unknown';
  return CardLogoLabel[idx];
};
export const getMembership = (membership, isTrial = 0) => {
  let color = '#667085';
  let label = 'Trial';
  if (!isTrial)
    switch (parseInt(membership)) {
      case Membership.Free:
        color = '#667085';
        label = 'Free';
        break;
      case Membership.Seed:
        color = '#F04438';
        label = 'Seed';
        break;
      case Membership.Grow:
        color = '#994D00';
        label = 'Grow';
        break;
      case Membership.Bloom:
        color = '#FFBE0D';
        label = 'Bloom';
        break;
      case Membership.Harvest:
        color = '#1998E0';
        label = 'Harvest';
        break;
      case Membership.Master:
        color = '#50A18E';
        label = 'Master';
        break;
      default:
        return '-';
    }
  return (
    <Box
      sx={{
        backgroundColor: color,
        color: '#fff',
        px: 1.5,
        py: 0.25,
        borderRadius: '20px',
        width: 'fit-content',
        fontSize: '12px',
        textWrap: 'nowrap'
      }}
    >
      {label}
    </Box>
  );
};
export const envInfo = [
  {
    title: 'Air Temperature',
    values: [
      { key: 'air_optimal_day', label: 'Air Optimal Day', unit: 'metric' },
      { key: 'air_day_variance', label: 'Air Day Variance', unit: 'metric' },
      { key: 'air_optimal_night', label: 'Air Optimal Night', unit: 'metric' },
      { key: 'air_night_variance', label: 'Air Night Variance', unit: 'metric' },
      { key: 'air_critical_low', label: 'Air Critical Low', unit: 'metric' },
      { key: 'air_critical_high', label: 'Air Critical High', unit: 'metric' }
    ]
  },
  {
    title: 'Soil Temperature',
    values: [
      { key: 'soil_optimal', label: 'Soil Optimal', unit: 'metric' },
      { key: 'soil_variance', label: 'Soil Variance', unit: 'metric' },
      { key: 'soil_critical_low', label: 'Soil Critical Low', unit: 'metric' },
      { key: 'soil_critical_high', label: 'Soil Critical High', unit: 'metric' }
    ]
  },
  {
    title: 'Humidity Percentage',
    values: [
      { key: 'humidity_optimal', label: 'Humidity Optimal', unit: 'percentage' },
      { key: 'humidity_variance', label: 'Humidity Variance', unit: 'percentage' },
      { key: 'humidity_critical_low', label: 'Humidity Critical Low', unit: 'percentage' },
      { key: 'humidity_critical_high', label: 'Humidity Critical High', unit: 'percentage' }
    ]
  },
  {
    title: 'Carbon Dioxide',
    values: [
      { key: 'co2_optimal', label: 'CO2 Optimal', unit: 'measurement' },
      { key: 'co2_variance', label: 'CO2 Variance', unit: 'measurement' },
      { key: 'co2_critical_high', label: 'CO2 Critical High', unit: 'measurement' }
    ]
  }
];

export const showWateringTime = (timeInSeconds) => {
  if (!timeInSeconds) return '';
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
  const seconds = Math.round(timeInSeconds - hours * 3600 - minutes * 60);
  return `${hours}h ${minutes}m ${seconds}s`;
};

export const getEnvironmentUnit = (unit, metric, measurement) => {
  switch (unit) {
    case 'measurement':
      return measurement;
    case 'percentage':
      return '%';
    case 'metric':
      return metric ? '°C' : '°F';
    default:
      return '';
  }
};

export const getTimeStringFromNumber = (number) => {
  const date = new Date();
  const hours = Math.floor(number / 60);
  const minutes = number % 60;
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

export const getDurationTimeStringFromNumber = (number) => {
  const date = new Date();
  const hours = Math.floor(number / 3600);
  const minutes = Math.floor((number % 3600) / 60);
  const seconds = number - hours * 3600 - minutes * 60;
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};

export const getTimeStringFromNumberV2 = (number) => {
  if (number === 1440) return '12:00 AM';
  if (!number) return '12:00 AM';
  let hours = Math.floor(number / 60);
  const minutes = number % 60;
  const ampm = hours > 12 ? 'PM' : 'AM';
  hours = hours > 12 ? hours - 12 : hours;
  hours = hours === 0 ? 12 : hours;
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
};

export const getDurationTimeStringFromNumberV2 = (number) => {
  if (!number) return '12:00 AM';
  const hours = Math.floor(number / 3600);
  const minutes = Math.floor((number % 3600) / 60);
  const seconds = number - hours * 3600 - minutes * 60;
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

export const getTimeNumberFromString = (time) => {
  const date = new Date(time);
  return date.getHours() * 60 + date.getMinutes();
};

export const getDurationTimeNumberFromString = (time) => {
  const date = new Date(time);
  return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
};

export const getSeedingGroupLabel = (label, metrics) => {
  if (!metrics) return label;
  let matches = label.match(/\d+/g);
  let ret = label;
  matches?.forEach((degree, idx) => {
    if (idx === 0) return;
    ret = ret.replaceAll(degree + '°F', (Math.round(((parseInt(degree) - 32) * 50) / 9) / 10).toString() + '°C');
  });
  return ret;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getGrowingPeriodLabel = (label) => {
  switch (label) {
    case GrowingPeriod.Annual:
      return 'Annual';
    case GrowingPeriod.Perennial:
      return 'Perennial';
    default:
      return 'None';
  }
};
