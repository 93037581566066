import PropTypes from 'prop-types';
import FAQWrapper from 'sections/landing/faq/Wrapper';
import LandingFAQMarketplaceSection from 'sections/landing/faq/Marketplace';
import { Helmet } from 'react-helmet-async';

const LandingFAQMarketplace = ({ expand = '' }) => {
  return (
    <>
      <Helmet title="FAQ - Khôra Marketplace" />
      <FAQWrapper>
        <LandingFAQMarketplaceSection expand={expand} />
      </FAQWrapper>
    </>
  );
};
LandingFAQMarketplace.propTypes = {
  expand: PropTypes.string
};
export default LandingFAQMarketplace;
