const ListCategory = {
  Seeds: 1,
  Microgreens: 7,
  Mushrooms: 2,
  Cuttings: 3,
  'Lighting Plans': 4,
  'Irrigation Plans': 6,
  'Environment Plans': 5
};

const GrowingPhase = {
  UNDEFINED: 0,
  SEEDING: 1,
  GERMINATION: 2,
  JUVENILE: 3,
  ADULT: 4,
  FLOWERING: 5
};

const LightingPhaseLabels = {
  1: 'Phase 1 - Seeding (Radicle Growth/Rootlet)',
  2: 'Phase 2 - Germination and Initial Leaves',
  3: 'Phase 3 - Juvenile Plants with Adult Leaves',
  4: 'Phase 4 - Adult Plant',
  5: 'Phase 5 - Flowering Adult Plant'
};

const WarningType = {
  Edit: 1,
  Delete: 2
};

const WateringList = {
  'Week 1': ['a_sun', 'a_mon', 'a_tue', 'a_wed', 'a_thu', 'a_fri', 'a_sat'],
  'Week 2': ['b_sun', 'b_mon', 'b_tue', 'b_wed', 'b_thu', 'b_fri', 'b_sat']
};

const StartTimesForIrrigation = ['start_time_1', 'start_time_2', 'start_time_3', 'start_time_4', 'start_time_5', 'start_time_6'];

const LightingPlanVersion = {
  Old: 0,
  New: 1
};

const GrowingPeriod = {
  Annual: 1,
  Perennial: 2
};

export {
  ListCategory,
  GrowingPhase,
  LightingPhaseLabels,
  WarningType,
  WateringList,
  StartTimesForIrrigation,
  LightingPlanVersion,
  GrowingPeriod
};
