const PaymentType = {
  Card: 1,
  Paypal: 2,
  Google: 3,
  Apple: 4
};
const PaymentMethodType = {
  Default: 1,
  Backup: 2
};
const PaymentMethodFlag = {
  Create: 1,
  Update: 2
};
const PaymentMethods = {
  Card: {
    title: 'Debit or Credit Card',
    type: PaymentType.Card
  },
  Paypal: {
    title: 'Paypal',
    type: PaymentType.Paypal
  },
  Google: {
    title: 'Google Pay',
    type: PaymentType.Google
  },
  Apple: {
    title: 'Apple Pay',
    type: PaymentType.Apple
  }
};
const CardLogo = ['amex', 'cirrus', 'diners', 'dankort', 'discover', 'jcb', 'maestro', 'mastercard', 'visa', 'visaelectron'];
const CardLogoLabel = [
  'American Express',
  'Cirrus',
  'Diners Club',
  'DanKort',
  'DISCOVER',
  'JCB',
  'Maestro',
  'MasterCard',
  'VISA',
  'VISA Electron'
];
const InvoiceStatus = {
  draft: {
    title: 'Draft',
    color: '#6B95B2'
  },
  open: {
    title: 'Open',
    color: '#008DDD'
  },
  void: {
    title: 'Void',
    color: '#939598'
  },
  paid: {
    title: 'Paid',
    color: '#59B39E'
  },
  uncollectible: {
    title: 'Uncollectible',
    color: '#F04438'
  }
};
const PaymentStatus = {
  requires_payment_method: {
    title: 'Requires Payment Method',
    color: '#F04438'
  },
  requires_confirmation: {
    title: 'Requires Confirmation',
    color: '#F04438'
  },
  requires_action: {
    title: 'Requires Action',
    color: '#F04438'
  },
  processing: {
    title: 'Processing',
    color: '#008DDD'
  },
  requires_capture: {
    title: 'Requires Capture',
    color: '#F04438'
  },
  canceled: {
    title: 'Canceled',
    color: '#939598'
  },
  succeeded: {
    title: 'Completed',
    color: '#59B39E'
  }
};
export { PaymentMethods, PaymentType, PaymentMethodFlag, PaymentMethodType, CardLogo, CardLogoLabel, InvoiceStatus, PaymentStatus };
