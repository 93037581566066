import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'components/Loadable';

import { AuthURL } from 'utils/constants/auth';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/ForgotPassword')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/CodeVerification')));
const AuthEmailVerify = Loadable(lazy(() => import('pages/auth/EmailVerification')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '',
  element: (
    <GuestGuard>
      <Outlet />
    </GuestGuard>
  ),
  children: [
    {
      path: AuthURL[0],
      element: <AuthLogin />
    },
    {
      path: AuthURL[1],
      element: <AuthRegister />
    },
    {
      path: AuthURL[2],
      element: <AuthForgotPassword />
    },
    {
      path: `${AuthURL[3]}/:token`,
      element: <AuthResetPassword />
    },
    {
      path: AuthURL[4],
      element: <AuthCodeVerification />
    },
    {
      path: `${AuthURL[5]}/:token`,
      element: <AuthEmailVerify />
    }
  ]
};

export default LoginRoutes;
