import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect } from 'react';
import vendorReducer, { initialState } from 'store/reducers/_vendor';
import { VENDOR_LIST_ALL, VENDOR_TO_EDIT, VENDOR_TYPE_LIST } from 'store/reducers/actions';

import axios from 'utils/axios';

const VendorContext = createContext(null);

export const VendorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(vendorReducer, initialState);

  const setVendorList = useCallback((vendorList) => {
    dispatch({
      type: VENDOR_LIST_ALL,
      payload: {
        vendorList
      }
    });
  }, []);

  const setVendorToEdit = useCallback((vendorToEdit) => {
    dispatch({
      type: VENDOR_TO_EDIT,
      payload: {
        vendorToEdit
      }
    });
  }, []);

  const getVendorList = useCallback(async () => {
    try {
      const res = await axios.get('/list/vendor');
      setVendorList(res?.data);
    } catch (err) {
      setVendorList([]);
    }
  }, [setVendorList]);

  const createVendor = useCallback(
    async (values) => {
      try {
        const res = await axios.put('/list/vendor', values);
        setVendorList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setVendorList(state.vendorList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.vendorList, setVendorList]
  );

  const updateVendor = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/list/vendor/${ID}`, values);
        setVendorList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setVendorList(state.vendorList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.vendorList, setVendorList]
  );

  const deleteVendor = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/list/vendor/${ID}`);
        setVendorList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setVendorList(state.vendorList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.vendorList, setVendorList]
  );

  const setVendorTypeList = useCallback((vendorTypeList) => {
    dispatch({
      type: VENDOR_TYPE_LIST,
      payload: {
        vendorTypeList
      }
    });
  }, []);

  const getVendorTypeList = useCallback(async () => {
    try {
      const res = await axios.get('/type/vendor_type');
      setVendorTypeList(res?.data);
    } catch (err) {
      setVendorTypeList([]);
    }
  }, [setVendorTypeList]);

  useEffect(() => {
    getVendorList();
    getVendorTypeList();
  }, [getVendorList, getVendorTypeList]);

  return (
    <VendorContext.Provider
      value={{
        ...state,
        getVendorList,
        setVendorToEdit,
        createVendor,
        updateVendor,
        deleteVendor
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

VendorProvider.propTypes = {
  children: PropTypes.node
};

export default VendorContext;
