import { SetupProvider } from 'contexts/SetupContext';
// project import
import MainRoutes from './MainRoutes';
import DashboardRoutes from './DashboardRoutes';
import MyKhoraRoutes from './MyKhoraRoutes';
import CropPlanRoutes from './CropPlanRoutes';
import SetupRoutes from './SetupRoutes';
import SettingRoutes from './SettingRoutes';
import CostRoutes from './CostRoutes';
import ObservationRoutes from './ObservationRoutes';
import ListRoutes from './ListRoutes';
import ScheduleV2Routes from './ScheduleV2Routes';
import GrowingPlanRoutes from './GrowingPlanRoutes';
import ShoppingRoutes from './ShoppingRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AuthGroupLayout from 'layout/AuthGroupLayout';

const AuthRoutesGroup = {
  path: '',
  element: (
    <AuthGuard>
      <SetupProvider>
        <AuthGroupLayout />
      </SetupProvider>
    </AuthGuard>
  ),
  children: [
    SetupRoutes,
    MainRoutes,
    MyKhoraRoutes,
    DashboardRoutes,
    CropPlanRoutes,
    SettingRoutes,
    CostRoutes,
    ObservationRoutes,
    ListRoutes,
    ScheduleV2Routes,
    GrowingPlanRoutes,
    ShoppingRoutes
  ]
};
export default AuthRoutesGroup;
