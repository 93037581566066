const WholesaleRetailCategory = {
  Seeds: 1,
  Microgreens: 2,
  Mushrooms: 3,
  Cuttings: 4
};

const MissingCostCategory = {
  // set category by seed type
  1: {
    // Seed
    0: 'Seed Cost',
    1: 'Germination to 1st Transplant Cost',
    2: 'Daily Growing Cost',
    3: 'Harvest & Delivery Cost for Adult Plants',
    5: 'Harvest & Delivery Cost for Small Cube',
    10: 'Wholesale & Retail Prices for Adult Plants', // missing wholesale & retail price
    11: 'Wholesale & Retail Prices for Small Cube' // missing wholesale & retail price
  },
  2: {
    // Microgreen
    0: 'Seed Cost',
    1: 'Tray Assembly Cost',
    2: 'Daily Growing Cost',
    3: 'Harvest & Delivery - Tray Cost for Adult Plants',
    4: 'Harvest & Delivery - Clam Cost for Adult Plants',
    5: 'Harvest & Delivery - Tray Cost for Small Cube',
    6: 'Harvest & Delivery - Clam Cost for Small Cube',
    10: 'Wholesale & Retail Prices for Adult Plants', // missing wholesale & retail price
    11: 'Wholesale & Retail Prices for Small Cube' // missing wholesale & retail price
  },
  3: {
    // Mushroom
    0: 'Mushroom Cost',
    1: 'Inoculation Cost',
    2: 'Daily Growing Cost',
    3: 'Harvest & Delivery Cost',
    10: 'Wholesale & Retail Prices for Adult Plants', // missing wholesale & retail price
    11: 'Wholesale & Retail Prices for Small Cube' // missing wholesale & retail price
  },
  4: {
    // Cutting
    0: 'Cutting Cost',
    1: 'Graft Assembly Cost',
    2: 'Daily Growing Cost',
    3: 'Harvest & Delivery Cost',
    10: 'Wholesale & Retail Prices for Adult Plants', // missing wholesale & retail price
    11: 'Wholesale & Retail Prices for Small Cube' // missing wholesale & retail price
  }
};

const GrowingMode = {
  Full: 1,
  Small: 2
};

const GrowingModeLabel = { Full: 'Adult Plant Growing', Small: 'Small Cube Growing' };

const SmallKhoraCubeCategory = {
  Seeds: 1,
  'Germination to 1st Transplant': 2,
  'Daily Growing': 3,
  'Harvest & Delivery': 4,
  Total: 5
};

const MicrogreenTrayCategory = {
  Seeds: 1,
  'Tray Assembly': 2,
  'Daily Growing': 3,
  'Harvest & Delivery - Tray': 4,
  'Harvest & Delivery - Clam': 5,
  Total: 6
};

const MushroomCategory = {
  'Spore Inoculation': 1,
  Medium: 2,
  'Daily Growing': 3,
  'Harvest & Delivery': 4,
  Total: 5
};

const CuttingCategory = {
  Cuttings: 1,
  'Graft Assembly': 2,
  'Daily Growing': 3,
  'Harvest & Delivery': 4,
  Total: 5
};

const CostType = {
  GerminationOrInoculation: 1,
  DailyGrowing: 2,
  Harvest: 3,
  ClamHarvest: 4,
  DeliverMarket: 5,
  ClamDeliverMarket: 6,
  Total: 7
};

const SeedTypeForCost = {
  SmallKhoraCube: 1,
  MicrogreenTray: 2,
  Mushroom: 3,
  Cutting: 4
};

const TaxDeliveryType = {
  All: 1,
  Network: 2,
  Location: 3
};

const UniqueFactors = ['', 'Common', 'Extremely Unique', 'Slightly Unique', 'Unusual'];
const TimeToMarkets = ['', '1-14 days', '15-30 Days', '31-60 Days', '61-90 Days', '90+ Days'];

const PriceMode = {
  Wholesale: 1,
  Retail: 2
};

export {
  WholesaleRetailCategory,
  GrowingMode,
  GrowingModeLabel,
  SmallKhoraCubeCategory,
  MicrogreenTrayCategory,
  MushroomCategory,
  CuttingCategory,
  MissingCostCategory,
  CostType,
  SeedTypeForCost,
  TaxDeliveryType,
  UniqueFactors,
  TimeToMarkets,
  PriceMode
};
