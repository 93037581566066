import { useState } from 'react';
import { Box, Stack, Typography, Modal, IconButton, useTheme } from '@mui/material';
import { CloseOutlined, PlayArrow } from '@mui/icons-material';
import ReactPlayer from 'react-player';

import VideoCard from 'components/VideoCard';

const NewLandingSection2 = () => {
  const theme = useTheme();
  const previews = [
    {
      backgroundUrl: '/assets/images/new_landing/preview1.png',
      title: 'Khôra Home changes how to get farm-to-table food.',
      videoUrl: '/assets/video/khora.mp4'
    },
    {
      backgroundUrl: '/assets/images/new_landing/preview2.png',
      title: 'Growing for Khôra Marketplace and your community.',
      videoUrl: '/assets/video/marketplace.mp4'
    }
  ];

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const handleVideoModalOpen = () => setOpenVideoModal(true);
  const handleVideoModalClose = () => setOpenVideoModal(false);

  const [currentVideoTitle, setCurrentVideoTitle] = useState('');
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  return (
    <>
      <Stack alignItems="center" spacing={4}>
        <Stack alignItems="center" spacing={4} sx={{ maxWidth: 1140, width: '100%', p: 3 }}>
          <Typography textAlign="center" sx={{ ...theme.typography.p1m }}>
            Introducing Khôra Home – the futuristic innovation with unparalleled convenience and taste, revolutionizing the way we
            experience homegrown freshness.
          </Typography>
          <Typography textAlign="center" sx={{ ...theme.typography.p2 }}>
            With Khôra Home, growing pesticide-free produce is effortless. Just choose what to grow, how much, and when to harvest. Select a
            personalized crop plan, receive a shopping list, and find everything you need from local farmers on Khôra Marketplace. Khôra
            Home takes care of the rest and alerts you when it{"'"}s time to harvest.
          </Typography>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="center" spacing={4} sx={{ width: '100%', p: 2 }}>
          {previews?.map((item, idx) => {
            return (
              <Box
                key={`video_preview_${idx}`}
                sx={{
                  width: '100%',
                  '--div-width': '435px',
                  maxWidth: `var(--div-width)`,
                  height: `calc(var(--div-width) * 240 / 435)`,
                  backgroundImage: `url("${item?.backgroundUrl}")`,
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  borderRadius: '10px',
                  p: 2
                }}
              >
                <Stack direction="row" alignItems="flex-end" spacing={2} sx={{ height: '100%' }}>
                  <Typography sx={{ ...theme.typography.p1, color: 'common.white' }}>{item?.title}</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      minWidth: 40,
                      height: 40,
                      borderRadius: '100%',
                      backgroundColor: theme.palette.darkgreen[500],
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setCurrentVideoTitle(item?.title);
                      setCurrentVideoUrl(item?.videoUrl);
                      handleVideoModalOpen();
                    }}
                  >
                    <PlayArrow sx={{ color: 'common.white' }} />
                  </Stack>
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </Stack>
      <Modal open={openVideoModal} onClose={handleVideoModalClose}>
        <VideoCard
          className="video-size-landing"
          title={currentVideoTitle}
          secondary={
            <IconButton onClick={handleVideoModalClose} sx={{ borderRadius: '100px' }}>
              <CloseOutlined />
            </IconButton>
          }
        >
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  controls: true,
                  autoPlay: true,
                  muted: false
                }
              }
            }}
            url={currentVideoUrl}
            width={'100%'}
            height={'100%'}
          />
        </VideoCard>
      </Modal>
    </>
  );
};
export default NewLandingSection2;
