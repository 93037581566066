import FAQWrapper from 'sections/landing/faq/Wrapper';
import LandingFAQCostsSection from 'sections/landing/faq/Costs';
import { Helmet } from 'react-helmet-async';

const LandingFAQCosts = () => {
  return (
    <>
      <Helmet title="FAQ - Costs" />
      <FAQWrapper>
        <LandingFAQCostsSection />
      </FAQWrapper>
    </>
  );
};
export default LandingFAQCosts;
