import { Stack, Typography, useTheme } from '@mui/material';

const NewLandingHomeSection11 = () => {
  const theme = useTheme();

  return (
    <Stack alignItems="center" sx={{ pt: { xs: 7.5, md: 10 } }}>
      <Stack spacing={3.75} sx={{ maxWidth: 1172, px: 2 }}>
        <Stack spacing={3.75}>
          <Typography variant="h4">Come join our Khôra Home Community!</Typography>
          <Typography sx={{ ...theme.typography.p2 }}>
            The Khôra Home Community is a diverse network of individuals, from beginners to experts in agriculture, all driven by a common
            passion for fostering collaboration, sharing knowledge, and improving the well-being of community through platforms like Sprout
            and Khôra Marketplace.
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.primary.main,
            width: '100%',
            height: '100%',
            borderRadius: '10px'
          }}
        >
          <Stack alignItems="center" justifyContent="flex-end" sx={{ width: { xs: '80%', md: '60%' } }}>
            <img src="/assets/images/new_landing/image5_2.png" alt="image5_2" style={{ width: '100%', height: 'auto' }} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default NewLandingHomeSection11;
