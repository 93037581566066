import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection6 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Access to Fresh Produce',
      description: (
        <Typography variant="p2">
          Khôra Home allows families to grow their own fresh produce right in their home, ensuring a steady supply of nutritious fruits,
          vegetables, and herbs without relying on store-bought options.
        </Typography>
      )
    },
    {
      title: 'Sell Surplus on Khôra Marketplace',
      description: (
        <Typography variant="p2">
          Khôra Home offers the opportunity to sell surplus produce on a dedicated online platform, <b>Khôra Marketplace</b>, that connects
          growers with consumers seeking fresh, locally-grown foods, unique products. This marketplace promotes local farmers markets,
          vendors, and small businesses, allowing access to a diverse range of hyper-local agricultural products and artisanal goods.
        </Typography>
      )
    },
    {
      title: 'Convenience',
      description: (
        <Typography variant="p2">
          Having a Khôra Home means having fresh produce readily available at any time, eliminating the need for frequent trips to the
          grocery store. Families can simply harvest what they need when they need it, reducing food waste and saving time.
        </Typography>
      )
    },
    {
      title: 'Educational Opportunity',
      description: (
        <Typography variant="p2">
          Khôra Home provides a valuable educational opportunity for families, especially children, to learn about plant growth, nutrition,
          and sustainability. It offers hands-on experience in gardening and allows families to engage in meaningful discussions about food
          production and environmental responsibility.
        </Typography>
      )
    },
    {
      title: 'Control Over Growing Conditions',
      description: (
        <Typography variant="p2">
          With Khôra Home, families have control over the growing conditions of their plants, including temperature, humidity, and lighting.
          This control ensures optimal growth and allows experimentation with different plant varieties and growing techniques.
        </Typography>
      )
    },
    {
      title: 'Sustainability',
      description: (
        <Typography variant="p2">
          Growing food at home with Khôra Home promotes sustainability by reducing the carbon footprint associated with transportation and
          packaging of store-bought produce. Families can also compost organic waste from their homegrown plants, further minimizing
          environmental impact.
        </Typography>
      )
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">Why would a family want a Khôra Home?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Stack justifyContent="space-around" spacing={2.5}>
            <Typography variant="p2">
              Khôra Home provides families with the opportunity to create culinary masterpieces using the freshest ingredients possible. The
              flavors, aromas, and textures of homegrown fruits, vegetables, and herbs can elevate any recipe and leave a lasting impression
              on anyone who enjoys their meals.
            </Typography>
            <Typography variant="p2">
              This not only enhances the dining experience but Khôra Home provides a family with the following:
            </Typography>
          </Stack>
          <Box>
            <Grid container spacing={2.5}>
              {contentData?.map((item, idx) => {
                return (
                  <Grid key={`content_info_6_${idx}`} item xs={12}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      spacing={1.25}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                          {item?.title}
                        </Typography>
                        {item?.description}
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQKhoraHomeSection6;
