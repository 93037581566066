import { Membership } from 'utils/constants/auth';
// project import
import { dashboard, dashboard_ } from './dashboard';
import { mykhora, mykhora_ } from './mykhora';
import { cropplan, cropplan_ } from './crop_plan_management';
import { shopping, shopping_, shopping_seeding, shopping_seeding_ } from './shopping';

import { schedule_new, schedule_new_ } from './schedule_v2';
import { plant_placement, plant_placement_ } from './plant_placement';
import { vendor, vendor_, vendor_seed, vendor_seed_ } from './vendor';
import cost_management from './cost_management';
import { list_management, list_management_ } from './list_management';
import { search, search_ } from './search';

import { growing_plan, growing_plan_ } from './growing_plan';
import { observation, observation_ } from './observation';

import { settings, settings_ } from './settings';
import { contactus, contactus_ } from './contactus';
import { faqs, faqs_ } from './faqs';
import { customer_management, customer_management_ } from './customer_management';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (membership) => {
  const extras = [settings, contactus, faqs];
  const extras_ = [settings_, contactus_, faqs_];
  let items = [
    dashboard,
    mykhora,
    growing_plan,
    cropplan,
    schedule_new,
    shopping_seeding,
    plant_placement,
    observation,
    cost_management,
    list_management,
    vendor,
    customer_management,
    search
  ];
  let items_ = [
    dashboard_,
    mykhora_,
    growing_plan_,
    cropplan_,
    schedule_new_,
    shopping_seeding_,
    plant_placement_,
    observation_,
    cost_management,
    list_management_,
    vendor_,
    customer_management_,
    search_
  ];

  switch (membership) {
    case Membership.Seed:
      items = [dashboard, mykhora, cropplan, schedule_new, shopping_seeding, plant_placement, vendor_seed, search];
      items_ = [dashboard_, mykhora_, cropplan_, schedule_new_, shopping_seeding_, plant_placement_, vendor_seed_, search_];
      break;
    case Membership.Grow:
      items = [dashboard, mykhora, growing_plan, cropplan, schedule_new, plant_placement, observation, vendor, search];
      items_ = [dashboard_, mykhora_, growing_plan_, cropplan_, schedule_new_, plant_placement_, observation_, vendor_, search_];
      break;
    case Membership.Bloom:
      items = [
        dashboard,
        mykhora,
        growing_plan,
        cropplan,
        schedule_new,
        shopping_seeding,
        plant_placement,
        list_management,
        vendor,
        search
      ];
      items_ = [
        dashboard_,
        mykhora_,
        growing_plan_,
        cropplan_,
        schedule_new_,
        shopping_seeding_,
        plant_placement_,
        list_management_,
        vendor_,
        search_
      ];
      break;
    case Membership.Harvest:
      items = [
        dashboard,
        mykhora,
        growing_plan,
        cropplan,
        schedule_new,
        shopping_seeding,
        plant_placement,
        observation,
        cost_management,
        list_management,
        vendor,
        search
      ];
      items_ = [
        dashboard_,
        mykhora_,
        growing_plan_,
        cropplan_,
        schedule_new_,
        shopping_seeding_,
        plant_placement_,
        observation_,
        cost_management,
        list_management_,
        vendor_,
        search_
      ];
      break;
    case Membership.Master:
      break;
    case Membership.Free:
    default:
      items = [dashboard, mykhora, cropplan, schedule_new, shopping, plant_placement, search];
      items_ = [dashboard_, mykhora_, cropplan_, schedule_new_, shopping_, plant_placement_, search_];
      break;
  }
  return { items, items_, extras, extras_ };
};

export default menuItems;
