import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { OutlinedInput } from '@mui/material';

import SearchIcon from '@mui/icons-material/SearchOutlined';

const SearchInput = ({ sx = {}, ...others }) => {
  const theme = useTheme();
  return (
    <OutlinedInput
      placeholder="Search"
      startAdornment={<SearchIcon sx={{ color: theme.palette.grey[300] }} />}
      {...others}
      sx={{
        borderRadius: '100px',
        backgroundColor: 'transparent',
        border: 'solid thin',
        borderColor: theme.palette.primary[300],
        '& .MuiOutlinedInput-input': {
          pl: 0.5
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none!important'
        },
        ...sx
      }}
    />
  );
};
SearchInput.propTypes = {
  sx: PropTypes.object
};
export default SearchInput;
