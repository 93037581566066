import { Stack, Divider, Typography, useTheme } from '@mui/material';

const NewLandingSection7 = () => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="center"
      sx={{
        width: '100%',
        '--div-width': '1440px',
        maxWidth: `var(--div-width)`,
        height: { xs: 740, sm: 640, md: 460 },
        backgroundImage: `url("/assets/images/new_landing/image3.png")`,
        backgroundPosition: 'center, center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'center', md: 'flex-start' }}
        justifyContent="center"
        spacing={{ xs: 3.75, md: 10 }}
        sx={{ p: 3 }}
      >
        <Stack spacing={3} sx={{ maxWidth: 491 }}>
          <Typography variant="h4" sx={{ color: 'common.white' }}>
            Mission
          </Typography>
          <Typography sx={{ ...theme.typography.p1, color: 'common.white' }}>
            Our mission is to cultivate a vibrant community of passionate Khôra Home growers and food enthusiasts that fosters knowledge
            sharing and collaboration while delivering exceptional culinary experiences.
          </Typography>
        </Stack>
        <Divider orientation="vertical" sx={{ borderColor: 'common.white', display: { xs: 'none', md: 'block' } }} />
        <Stack spacing={3} sx={{ maxWidth: 491 }}>
          <Typography variant="h4" sx={{ color: 'common.white' }}>
            Vision
          </Typography>
          <Typography sx={{ ...theme.typography.p1, color: 'common.white' }}>
            Khôra Home and Marketplace envision a future where the communities share knowledge and harvests to ensure everyone has access to
            fresh, healthy food.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default NewLandingSection7;
