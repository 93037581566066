const Account = {
  title: 'Account Settings',
  url: 'account',
  children: [
    {
      title: 'Login & Security',
      url: 'login_security'
    },
    {
      title: 'Personal',
      url: 'personal'
    },
    {
      title: 'Avatar',
      url: 'avatar'
    },
    {
      title: 'Membership',
      url: 'membership'
    },
    {
      title: 'How I Grow',
      url: 'how_i_grow'
    },
    {
      title: 'Lighting',
      url: 'lighting'
    },
    {
      title: 'Link4',
      url: 'link4'
    },
    {
      title: 'Notifications',
      url: 'notification'
    },
    {
      title: 'Close Account',
      url: 'close_account'
    }
  ]
};

const Payment = {
  title: 'Payments',
  url: 'payment',
  children: [
    {
      title: 'Methods',
      url: 'method'
    },
    {
      title: 'History',
      url: 'history'
    }
  ]
};

const SettingConfig = [Account, Payment];

export default SettingConfig;
