import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect } from 'react';
import customerReducer, { initialState } from 'store/reducers/_customer';
import { CUSTOMER_LIST_ALL, CUSTOMER_TO_EDIT } from 'store/reducers/actions';

import axios from 'utils/axios';

const CustomerContext = createContext(null);

export const CustomerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(customerReducer, initialState);

  const setCustomerList = useCallback((customerList) => {
    dispatch({
      type: CUSTOMER_LIST_ALL,
      payload: {
        customerList
      }
    });
  }, []);

  const setCustomerToEdit = useCallback((customerToEdit) => {
    dispatch({
      type: CUSTOMER_TO_EDIT,
      payload: {
        customerToEdit
      }
    });
  }, []);

  const getCustomerList = useCallback(async () => {
    try {
      const res = await axios.get('/customer');
      setCustomerList(res?.data);
    } catch (err) {
      setCustomerList([]);
    }
  }, [setCustomerList]);

  const createCustomer = useCallback(
    async (values) => {
      try {
        const res = await axios.put('/customer', values);
        setCustomerList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCustomerList(state.customerList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.customerList, setCustomerList]
  );

  const updateCustomer = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/customer/${ID}`, values);
        setCustomerList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCustomerList(state.customerList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.customerList, setCustomerList]
  );

  const deleteCustomer = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/customer/${ID}`);
        setCustomerList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        setCustomerList(state.customerList, true);
        return { status: false, data: err?.message };
      }
    },
    [state.customerList, setCustomerList]
  );

  useEffect(() => {
    getCustomerList();
  }, [getCustomerList]);

  return (
    <CustomerContext.Provider
      value={{
        ...state,
        getCustomerList,
        setCustomerToEdit,
        createCustomer,
        updateCustomer,
        deleteCustomer
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

CustomerProvider.propTypes = {
  children: PropTypes.node
};

export default CustomerContext;
