import { Stack, Typography, useTheme } from '@mui/material';

const NewLandingHomeSection10 = () => {
  const theme = useTheme();
  return (
    <Stack alignItems="center" sx={{ pt: { xs: 7.5, md: 10 } }}>
      <Stack spacing={3.75} sx={{ maxWidth: 1172, px: 2 }}>
        <Stack spacing={3.75}>
          <Typography variant="h4">Khôra Home{"'"}s Sustainability</Typography>
          <Typography sx={{ ...theme.typography.p2 }}>
            Khôra Home is committed to sustainability in every aspect of its design and operation. From utilizing innovative technology to
            minimize water and energy consumption to sourcing materials that are recyclable and durable, Khôra Home strives to reduce its
            environmental footprint.
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.primary.main,
            width: '100%',
            height: '100%',
            borderRadius: '10px'
          }}
        >
          <Stack alignItems="center" justifyContent="flex-end" sx={{ width: { xs: '80%', md: '60%' } }}>
            <img src="/assets/images/new_landing/image5_1.png" alt="image5_1" style={{ width: '100%', height: 'auto' }} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default NewLandingHomeSection10;
