// third-party
import { FormattedMessage } from 'react-intl';
// icons
import GrowingPlanIcon from 'assets/icons/growing_plan_management/growing_plan.png';
// import GrowingPlanIcon from '@mui/icons-material/YardOutlined';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const growing_plan = {
  id: 'group-growing_plan',
  type: 'group',
  children: [
    {
      id: 'growing_plan',
      title: <FormattedMessage id="Growing Plan Management" />,
      type: 'item',
      url: '/growing_plan',
      icon: GrowingPlanIcon
    }
  ]
};
const growing_plan_ = {
  id: 'group-growing_plan',
  type: 'group',
  children: [
    {
      id: 'growing_plan',
      title: <FormattedMessage id="Growing Plan Management" />,
      type: 'collapse',
      icon: GrowingPlanIcon,
      children: [
        {
          id: 'growing_plan_',
          title: <FormattedMessage id="Growing Plan Management" />,
          type: 'item',
          url: '/growing_plan'
        }
      ]
    }
  ]
};

export { growing_plan, growing_plan_ };
