import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection7 = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">What happens when I go on vacation?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={8}>
            <Stack justifyContent="center" spacing={1.25} sx={{ height: '100%' }}>
              <Typography variant="p2">
                When you go on vacation, you have the option to create Crop Plans that accommodate your absence.
              </Typography>
              <Typography variant="p2">
                These plans can be tailored to either pause cultivation activities before your departure, allowing ample time to consume any
                harvests, or to continue growing for harvest upon your return.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                minHeight: 160,
                height: '100%',
                width: 'auto',
                backgroundImage: 'url("/assets/images/faq/sprout_9.png")',
                backgroundPosition: 'center, center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQSproutSection7;
