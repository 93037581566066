import LandingAboutSection from 'sections/landing/LandingAbout';
import { Helmet } from 'react-helmet-async';

const LandingAbout = () => {
  return (
    <>
      <Helmet title="About Us - Khôra Home" />
      <LandingAboutSection />
    </>
  );
};
export default LandingAbout;
