// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary(theme) {
  return {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary[50],
          border: `1px solid ${theme.palette.primary[50]}`,
          borderRadius: '10px',
          flexDirection: 'row',
          padding: '0px 20px 0px 0px',
          '&.Mui-disabled': {
            opacity: 1,
            backgroundColor: theme.palette.primary.lighter
          },
          '&.Mui-expanded': {
            borderRadius: '10px 10px 0px 0px',
            backgroundColor: theme.palette.primary[50],
            border: `1px solid ${theme.palette.primary[50]}`
          }
        },
        content: {
          padding: '20px',
          margin: 0
        }
      }
    }
  };
}
