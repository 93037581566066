import { useTheme } from '@mui/material/styles';

// material-ui
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

// assets
const error404 = '/assets/images/maintenance/Error404.png';

// ==============================|| ERROR 404 - MAIN ||============================== //

const Error404 = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={3.75}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh', overflow: 'hidden', backgroundColor: theme.palette.grey[50] }}
    >
      <Grid item xs={12}>
        <Stack direction="row">
          <Grid item>
            <Box sx={{ width: 400, height: 400 }}>
              <img src={error404} alt="404error" style={{ width: '100%', height: '100%' }} />
            </Box>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography variant="h1">Page Not Found</Typography>
          <Typography color="textSecondary" align="center" sx={{ width: { xs: '73%', sm: '61%' } }}>
            The page you are looking was moved, removed, renamed, or might never exist!
          </Typography>
          <Button variant="contained" size="large" onClick={() => window.history.back()} sx={{ mt: 2.5 }}>
            Back
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Error404;
