import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Box, Grid, Button, CircularProgress } from '@mui/material';
import Loader from 'components/Loader';
import NewsStyle3 from 'sections/news/NewsStyle3';

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import useAuth from 'hooks/useAuth';

const ViewAllNews = () => {
  const navigate = useNavigate();
  const { getNewsList } = useAuth();

  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showCount, setShowCount] = useState(6);

  const handleShowMore = () => {
    setShowCount((prev) => prev + 6);
  };

  useEffect(() => {
    getNewsList().then((res) => {
      setNewsList(res);
      setLoading(false);
    });
  }, [getNewsList]);

  return (
    <>
      {loading && <Loader />}
      <Stack spacing={4} py={4} sx={{ maxWidth: 1140, margin: 'auto' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Typography variant="h3">News</Typography>
          <Button
            size="small"
            variant="outlined"
            endIcon={<KeyboardArrowRightOutlinedIcon sx={{ width: '20px', height: 'auto' }} />}
            onClick={() => navigate('/news/top')}
          >
            View Top News
          </Button>
        </Stack>
        <Box>
          <Grid container spacing={3.75}>
            {newsList
              ?.filter((_, index) => index < showCount)
              ?.map((news, idx) => {
                return (
                  <Grid
                    key={`news_style_1_${idx}`}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
                  >
                    <NewsStyle3 news={news} />
                  </Grid>
                );
              })}
            {newsList?.length > showCount && (
              <Grid item xs={12}>
                <Stack alignItems="center" sx={{ width: '100%' }}>
                  <Button endIcon={<ExpandMoreOutlinedIcon sx={{ width: '20px', height: 'auto' }} />} onClick={handleShowMore}>
                    See More
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>
          {newsList?.length === 0 && !loading && (
            <Box sx={{ px: 2, py: 3, mt: 3.75, backgroundColor: 'common.white', border: `solid 1px #eee`, borderRadius: '6px' }}>
              <Typography textAlign="center">No news were found.</Typography>
            </Box>
          )}
          {loading && (
            <Stack alignItems="center" justifyContent="center" sx={{ height: 360 }}>
              <CircularProgress color="primary" />
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  );
};
export default ViewAllNews;
