import { useNavigate } from 'react-router-dom';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Grid, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

const FAQSproutSection9 = () => {
  const navigate = useNavigate();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">How do Sprout Memberships trials work?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3.75}>
          <Grid item xs={12} md={7}>
            <Stack justifyContent="center" spacing={2.5} sx={{ height: '100%' }}>
              <Typography variant="p2">
                Sprout offers trial memberships to provide users with an opportunity to explore its features and benefits before committing
                to a paid subscription. During the trial period, users have unlimited access to all membership levels allowing users to test
                out Sprout{"'"}s features and determine what membership meets their needs and preferences.
              </Typography>
              <Typography variant="p2">
                After the trial period ends, users may choose to subscribe to a paid membership to continue accessing Sprout{"'"}s features
                or become a Free member with limited access and features.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                minHeight: 320,
                height: 'auto',
                width: '100%',
                backgroundImage: 'url("/assets/images/faq/sprout_11.png")',
                backgroundPosition: 'center, center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" endIcon={<ArrowRightAltOutlinedIcon />} onClick={() => navigate('/membership')}>
              Learn more about Sprout{"'"}s Memberships
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQSproutSection9;
