import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';

const FAQWrapper = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const titleStyle = {
    fontSize: { xs: '32px', md: '42px' },
    lineHeight: { xs: '44px', md: '60px' },
    fontFamily: 'Poppins',
    fontWeight: 400
  };
  const FAQs = [
    {
      link: '/faqs/khora_home',
      title: 'Khôra Home'
    },
    {
      link: '/faqs/sprout',
      title: "Sprout - The Grower's Platform"
    },
    {
      link: '/faqs/marketplace',
      title: 'Khôra Marketplace'
    },
    {
      link: '/faqs/costs',
      title: 'Costs'
    }
  ];

  return (
    <Stack>
      <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', py: { xs: '40px', md: '60px' }, px: 2.5 }}>
        <Grid container spacing={{ xs: 5, md: 10 }}>
          <Grid item xs={12} md={7.5}>
            <Stack spacing={3}>
              <Typography sx={{ ...titleStyle }}>Frequently Asked Questions</Typography>
              <Typography sx={matchesMD ? theme.typography.p2m : theme.typography.p1m}>
                Got questions? We{"'"}ve got you covered! Explore Khôra Home for futuristic home gardening, Sprout for cloud-based growing
                management, and Khôra Marketplace where local farmers meet all your Khôra Home needs.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4.5}>
            <Stack alignItems="center" sx={{ width: '100%' }}>
              <img src={'/assets/images/faq/image1.png'} alt={'image1'} style={{ height: 264, width: 'auto' }} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', px: 2.5 }}>
        <Stack direction="row" alignItems="center" sx={{ borderBottom: 'solid 1px #eee' }}>
          {FAQs?.map((item, idx) => {
            const isActive = window.location.pathname.startsWith(item?.link);
            const borderBottomStyle = `solid 3px ${isActive ? theme.palette.darkgreen.main : theme.palette.common.white}`;
            return (
              <Box
                key={`faq_${idx}`}
                sx={{ p: 1.25, borderBottom: borderBottomStyle, cursor: 'pointer', mr: 2.5 }}
                onClick={() => navigate(`${item?.link}`)}
              >
                <Typography variant="p1" sx={{ textDecoration: 'none', fontWeight: isActive ? 500 : 400 }}>
                  {item?.title}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Box>
      <Box sx={{ mt: 5 }}>{children}</Box>
    </Stack>
  );
};
FAQWrapper.propTypes = {
  children: PropTypes.node
};
export default FAQWrapper;
