import { useMemo } from 'react';
// material UI
import { useTheme } from '@mui/material/styles';
import { AppBar, Box } from '@mui/material';
import CommonHeader from 'layout/CommonHeader';

// config
import { MAIN_HEADER_HEIGHT } from 'config';

const SetupHeader = () => {
  const theme = useTheme();
  const headerLayout = useMemo(() => <CommonHeader redirectURL="#" notificationShow={false} />, []);
  return (
    <AppBar
      sx={{
        elevation: 0,
        width: '100%',
        alignItems: 'center',
        boxShadow: 'none',
        backgroundColor: theme.palette.common.white,
        borderBottom: `2px solid ${theme.palette.grey[100]}`
      }}
    >
      <Box
        sx={{
          py: '16px',
          px: '30px',
          width: '100%',
          height: MAIN_HEADER_HEIGHT
        }}
      >
        {headerLayout}
      </Box>
    </AppBar>
  );
};

export default SetupHeader;
