import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection2 = () => {
  const theme = useTheme();

  const contentData = [
    {
      title: 'Khôra Home',
      description:
        'Focuses on the physical shell, hardware and devices installed within the appliance. It includes sensors, cameras, and other connected devices that create the optimal growing environment.',
      image: '/assets/images/faq/khorahome_8.png'
    },
    {
      title: 'Sprout',
      description:
        'Is the cloud-base software that enables communication, coordination, and management of these devices. It provides the interface through which users interact with their Khôra Home system, allowing them to customize settings, create automation routines, and monitor their growing remotely.',
      image: '/assets/images/faq/khorahome_9.png'
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">What is the difference between Khôra Home and Sprout?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack alignItems="flex-start" spacing={2.5}>
          <Typography variant="p2">
            The simplest explanation is Khôra Home is the appliance and Sprout is the software that runs it.
          </Typography>
          <Box>
            <Grid container spacing={2.5}>
              {contentData?.map((item, idx) => {
                return (
                  <Grid key={`content_info_${idx}`} item xs={12} md={6}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      spacing={1.25}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <img src={item?.image} alt={item?.title} style={{ width: '100px', height: 'auto' }} />
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                          {item?.title}
                        </Typography>
                        <Typography variant="p2">{item?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQKhoraHomeSection2;
