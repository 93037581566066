import { SearchCategory } from 'utils/constants/search';
import {
  SEARCH_CATEGORY,
  SEARCH_CROP_PLAN_LIST,
  SEARCH_GROWING_PLAN_LIST,
  SEARCH_SEED_LIST,
  SEARCH_CUTTING_LIST,
  SEARCH_MUSHROOM_LIST,
  SEARCH_MICROGREEN_LIST,
  SEARCH_VENDOR_LIST
} from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  category: SearchCategory['Crop Plans'],
  cropPlanList: [],
  growingPlanList: [],
  seedList: [],
  cuttingList: [],
  mushroomList: [],
  microgreenList: [],
  vendorList: []
};

// ==============================|| AUTH REDUCER ||============================== //

const search = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CATEGORY: {
      const { category } = action.payload;
      return {
        ...state,
        category
      };
    }
    case SEARCH_CROP_PLAN_LIST: {
      const { cropPlanList, isInitialized } = action.payload;
      return {
        ...state,
        cropPlanList,
        isInitialized
      };
    }
    case SEARCH_GROWING_PLAN_LIST: {
      const { growingPlanList, isInitialized } = action.payload;
      return {
        ...state,
        growingPlanList,
        isInitialized
      };
    }
    case SEARCH_SEED_LIST: {
      const { seedList, isInitialized } = action.payload;
      return {
        ...state,
        seedList,
        isInitialized
      };
    }
    case SEARCH_CUTTING_LIST: {
      const { cuttingList, isInitialized } = action.payload;
      return {
        ...state,
        cuttingList,
        isInitialized
      };
    }
    case SEARCH_MUSHROOM_LIST: {
      const { mushroomList, isInitialized } = action.payload;
      return {
        ...state,
        mushroomList,
        isInitialized
      };
    }
    case SEARCH_MICROGREEN_LIST: {
      const { microgreenList, isInitialized } = action.payload;
      return {
        ...state,
        microgreenList,
        isInitialized
      };
    }
    case SEARCH_VENDOR_LIST: {
      const { vendorList, isInitialized } = action.payload;
      return {
        ...state,
        vendorList,
        isInitialized
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default search;
