// import LandingHomeSection from 'sections/landing/home';
import NewLandingHomeSection from 'sections/landing/new_home';
import { Helmet } from 'react-helmet-async';

const LandingHome = () => {
  return (
    <>
      <Helmet title="Khôra Home - Smell, taste, and see the difference" />
      <NewLandingHomeSection />
    </>
  );
};
export default LandingHome;
