import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  progressStep: 0
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const setupProgress = createSlice({
  name: 'setupProgress',
  initialState,
  reducers: {
    setProgressStep(state, action) {
      const { progressStep } = action.payload;
      state.progressStep = progressStep;
    }
  }
});

export default setupProgress.reducer;

export const { setProgressStep } = setupProgress.actions;
