import { SCHEDULE_NETWORK_LIST_ALL, SCHEDULE_CROP_PLAN_COLOR } from './actions';

// initial state
export const initialState = {
  networkList: [],
  runningCropPlanColors: {}
};

// ==============================|| AUTH REDUCER ||============================== //

const schedule = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_NETWORK_LIST_ALL: {
      const { networkList } = action.payload;
      return {
        ...state,
        networkList
      };
    }
    case SCHEDULE_CROP_PLAN_COLOR: {
      const { runningCropPlanColors } = action.payload;
      return {
        ...state,
        runningCropPlanColors
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default schedule;
