// third-party
import { FormattedMessage } from 'react-intl';
// icons
// import { DashboardOutlined } from '@ant-design/icons';
// const icons = { DashboardOutlined };

// icons
import DashboardIcon from 'assets/icons/dashboard/Dashboard-v2_1.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: DashboardIcon,
      children: [
        {
          id: 'dashboard-notification',
          title: <FormattedMessage id="Notification" />,
          type: 'hidden',
          url: '/notification'
        }
      ]
    }
  ]
};
const dashboard_ = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="Dashboard" />,
      type: 'collapse',
      icon: DashboardIcon,
      children: [
        {
          id: 'dashboard_',
          title: <FormattedMessage id="Dashboard" />,
          type: 'item',
          url: '/dashboard',
          children: [
            {
              id: 'dashboard-notification',
              title: <FormattedMessage id="Notification" />,
              type: 'hidden',
              url: '/notification'
            }
          ]
        }
      ]
    }
  ]
};

export { dashboard, dashboard_ };
