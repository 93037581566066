import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid, Divider, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

const FAQMarketplaceSection1 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: "Chef's Choice",
      description: 'Each month, a Certified Khôra Master Grower is highlighted, featuring their farm and exceptional products.'
    },
    {
      title: 'Khôra Home Appliances, Accessories, and Parts',
      description: 'Various Khôra Home products, including appliances, accessories, and replacement parts, are available for purchase.'
    },
    {
      title: 'Starter and Ready-to-Harvest Khôra Home Plants',
      description: 'Customers can find plants by local farmers that support their Khôra Home growing activities.'
    },
    {
      title: 'Growing Supplies',
      description:
        "Essential supplies for cultivation, such as soil, nutrients, and gardening tools, are offered to support growers' needs."
    },
    {
      title: 'Specialty Foods',
      description: 'Unique and gourmet food items using Khôra Home plants are available for those seeking culinary delights.'
    },
    {
      title: 'Gifts',
      description: 'A selection of curated gifts, perfect for plant enthusiasts and home gardeners.'
    },
    {
      title: 'Classes',
      description:
        'Fun and educational classes covering various aspects of cultivation, gardening, and sustainable living are available for those looking to expand their knowledge.'
    },
    {
      title: 'Private Chef Events',
      description: 'Exclusive culinary events hosted by private chefs showcasing Khôra Home-grown ingredients and dishes.'
    },
    {
      title: 'Private Contract Farming',
      description:
        'Opportunities for local Certified Khôra Master Growers to create and grow crop plans tailored for businesses and individuals.'
    },
    {
      title: 'Leased Farming',
      description:
        'Leasing options for space in local Khôra farms, allowing individuals to utilize Khôra Home technology for their cultivation endeavors.'
    },
    {
      title: 'Khôra Home Consulting Services',
      description:
        'Professional consulting solutions provided by Khôra Home experts to offer guidance and assistance to growers and businesses.'
    },
    {
      title: 'Agricultural Education',
      description:
        'Structured coursework designed to prepare individuals for attaining and upholding the status of Certified Khôra Master Growers.'
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">What is Khôra Marketplace?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Stack spacing={3.75} direction="row" alignItems="center" sx={{ maxWidth: 960 }}>
            <Stack justifyContent="space-around" spacing={1.25} sx={{ height: { xs: 'inherit', md: 160 } }}>
              <Typography variant="p2">
                Khôra Marketplace is the ultimate online exchange for enthusiasts of fresh, locally-sourced foods and unique items. Our
                platform gathers diverse farmers market vendors in one place, offering artisanal treasures that are often unavailable in
                grocery stores. Highlighted in our platform are Certified Khôra Master Growers delivering unmatched quality and flavors from
                Khôra Home-grown produce.
              </Typography>
              <Typography variant="p2">Here are some key highlights of the Khôra Marketplace:</Typography>
            </Stack>
            <Box sx={{ height: 200, display: { xs: 'none', md: 'block' } }}>
              <img src={'/assets/images/faq/marketplace_1.png'} alt={'marketplace_1'} style={{ width: 'auto', height: '200px' }} />
            </Box>
          </Stack>
          <Box>
            <Grid container spacing={2.5}>
              {contentData?.map((item, idx) => {
                return (
                  <Grid key={`content_info_${idx}`} item xs={12} md={4}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      spacing={1.25}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                          {item?.title}
                        </Typography>
                        <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        <Typography variant="p2">{item?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Stack alignItems="flex-end">
            <Button
              variant="contained"
              endIcon={<ArrowRightAltOutlinedIcon />}
              onClick={() => window.open(process.env['REACT_APP_MARKETPLACE_URL'], '_blank')}
            >
              See More
            </Button>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQMarketplaceSection1;
