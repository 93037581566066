import { useState } from 'react';
// material-ui
import { Box, Typography, Stack, Tooltip } from '@mui/material';
// import Swiper core and required modules
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
// import 'swiper/css/pagination';
import 'swiper/css/navigation';

const NewLandingHomeSection9 = () => {
  const [swiperTooltip, setSwiperTooltip] = useState('Click to pause');

  const content = [
    {
      title: 'No chemicals here.',
      text: [
        'Khôra Home ensures pesticide-free harvests, utilizing 100% USDA organic soil and natural kitchen ingredients for fertilizer, providing safe-to-eat produce free from contaminants and pests.'
      ],
      image: 'image7_1.png '
    },
    {
      title: 'Get ready for mind-blowing experience!',
      text: [
        'Harvest veggies as you cook, locking in vital vitamins, minerals, and nutrients for your culinary masterpieces. Prepare to be blown away and understand our motto, "Smell, taste, and see the difference!"'
      ],
      image: 'image7_2.png '
    },
    {
      title: 'Revitalize Your Drinks with Khôra Home',
      text: [
        'Get ready to revolutionize your beverage game by incorporating freshly harvested Khôra Home produce, bursting with flavor and nutrients.'
      ],
      image: 'image7_3.png '
    },
    {
      title: 'Enjoy dishes anytime, regardless of the season.',
      text: ['Say goodbye to waiting for the right season—now every season is the right season for your favorite dishes with Khôra Home.'],
      image: 'image7_4.png '
    }
  ];

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        '--div-width': '1440px',
        maxWidth: `var(--div-width)`,
        height: { xs: 800, sm: 500, md: 600 },
        backgroundImage: `url("/assets/images/new_landing/background3.png")`,
        backgroundPosition: 'center, center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        p: 2
      }}
    >
      <Box sx={{ maxWidth: 1140, width: '100%' }}>
        <Swiper
          loop
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          effect={'fade'}
          modules={[Autoplay]}
          spaceBetween={40}
          slidesPerView={1}
          speed={1000}
          onClick={(s) => {
            if (s.autoplay.running) {
              setSwiperTooltip('Click to continue');
              s.autoplay.stop();
            } else {
              setSwiperTooltip('Click to pause');
              s.slideNext();
              s.autoplay.start();
            }
          }}
        >
          {content?.map((item, idx) => {
            return (
              <SwiperSlide key={'slide_' + idx}>
                <Tooltip title={swiperTooltip} followCursor placement="right">
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems="center"
                    spacing={{ xs: 3.75, sm: 7.5 }}
                    sx={{
                      height: { xs: `calc(100% - 60px)`, md: 'inherit' },
                      backgroundColor: 'common.white',
                      borderRadius: '20px',
                      p: { xs: 2.5, sm: 3.75, md: 7.5 },
                      cursor: 'pointer'
                    }}
                  >
                    <Box sx={{ width: 260 }}>
                      <img src={'/assets/images/new_landing/' + item?.image} alt={item?.image} style={{ width: '100%', height: 'auto' }} />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: { xs: 'flex-start', md: 'center' },
                        alignItems: 'flex-start',
                        width: { xs: '100%', sm: `calc(100% - 290px)`, md: `calc(100% - 320px)` },
                        pb: { xs: 2.5, sm: 0 }
                      }}
                    >
                      <Typography variant="h5" pb={3}>
                        {item?.title}
                      </Typography>
                      <Stack spacing={2.5}>
                        {item?.text?.map((text, idx1) => {
                          return (
                            <Typography key={'text_' + idx + idx1} variant="p2">
                              {text}
                            </Typography>
                          );
                        })}
                      </Stack>
                    </Box>
                  </Stack>
                </Tooltip>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Stack>
  );
};
export default NewLandingHomeSection9;
