import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

import { CropPlanProvider } from 'contexts/CropPlanContext';
import { ShoppingProvider } from 'contexts/ShoppingContext';

// render - Shopping
const Shopping = Loadable(lazy(() => import('pages/shopping')));
// ==============================|| MAIN ROUTING ||============================== //

const ShoppingRoutes = {
  path: 'shopping',
  element: (
    <CropPlanProvider>
      <ShoppingProvider>
        <MainLayout networkShow={false}>
          <Outlet />
        </MainLayout>
      </ShoppingProvider>
    </CropPlanProvider>
  ),
  children: [
    {
      path: '',
      element: <Shopping />
    }
  ]
};

export default ShoppingRoutes;
