import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback, useEffect } from 'react';
import growingPlanReducer, { initialState } from 'store/reducers/_growing_plan';
import {
  GROWING_PLAN_UPDATE_ID,
  GROWING_PLAN_LIST_ALL,
  GROWING_PLAN_TO_EDIT,
  GROWING_PLAN_ACTIVE_STEP,
  GROWING_PLAN_STATUS,
  GROWING_PLAN_LIGHTING_PLAN_LIST,
  GROWING_PLAN_LIGHTING_PLAN_TO_EDIT,
  GROWING_PLAN_NEW_LIGHTING_PLAN_LIST,
  GROWING_PLAN_NEW_LIGHTING_PLAN_TO_EDIT,
  GROWING_PLAN_LIGHTING_PLAN_VERSION,
  GROWING_PLAN_LIGHTING_PLAN_STEPS,
  GROWING_PLAN_IS_LIGHTING_PLAN_SAVED,
  GROWING_PLAN_LIGHTING_PLAN_STEP_TO_EDIT,
  GROWING_PLAN_ENVIRONMENT_PLAN_LIST,
  GROWING_PLAN_ENVIRONMENT_PLAN_TO_EDIT,
  GROWING_PLAN_IS_ENVIRONMENT_PLAN_SAVED,
  GROWING_PLAN_IRRIGATION_PLAN_LIST,
  GROWING_PLAN_IRRIGATION_PLAN_TO_EDIT,
  GROWING_PLAN_IS_IRRIGATION_PLAN_SAVED
} from 'store/reducers/actions';

import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';

import { GrowingPhase } from 'utils/constants/list';
import { inactiveLightingPhase } from 'utils/common';
import { LightingPlanVersion } from 'utils/constants/list';

import axios from 'utils/axios';

import useAuth from 'hooks/useAuth';

const GrowingPlanContext = createContext(null);

export const GrowingPlanProvider = ({ children }) => {
  const dispatchForSnackbar = useDispatch();
  const { user } = useAuth();
  const [state, dispatch] = useReducer(growingPlanReducer, initialState);

  const setGrowingPlanList = useCallback((growingPlanList) => {
    const privateGrowingPlanList = growingPlanList?.filter((plan) => !!plan?.user_id);
    const publicGrowingPlanList = growingPlanList?.filter((plan) => !plan?.user_id);
    dispatch({
      type: GROWING_PLAN_LIST_ALL,
      payload: {
        privateGrowingPlanList,
        publicGrowingPlanList
      }
    });
  }, []);

  const setGrowingPlanIdToUpdate = useCallback((growingPlanIdToUpdate) => {
    dispatch({
      type: GROWING_PLAN_UPDATE_ID,
      payload: {
        growingPlanIdToUpdate
      }
    });
  }, []);

  const setGrowingPlanToEdit = useCallback((growingPlanToEdit) => {
    dispatch({
      type: GROWING_PLAN_TO_EDIT,
      payload: {
        growingPlanToEdit
      }
    });
  }, []);

  const setActiveStep = useCallback((activeStep) => {
    dispatch({
      type: GROWING_PLAN_ACTIVE_STEP,
      payload: {
        activeStep
      }
    });
  }, []);

  const setGrowingPlanStatus = useCallback((status) => {
    dispatch({
      type: GROWING_PLAN_STATUS,
      payload: {
        isPlantSelected: !!status?.plant_variety,
        isTransplantStepSaved: !!status?.transplant,
        isGrowingStepSaved: !!status?.growing,
        isLightingPlanSaved: !!status?.lighting,
        isEnvironmentPlanSaved: !!status?.environment,
        isIrrigationPlanSaved: !!status?.irrigation
      }
    });
  }, []);

  /**
   * Create growing plan
   */

  // create temp plan for growing plan creation
  const createTempGrowingPlan = useCallback(async (plant_id, is_seed) => {
    try {
      await axios.put('/growing_plan/main', {
        seed_id: is_seed ? plant_id : null,
        cutting_id: is_seed ? null : plant_id,
        is_seed
      });
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err.message };
    }
  }, []);

  const getTempGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/temp/one/1');
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getTempGrowingPlanStatus = useCallback(
    async (type) => {
      try {
        const res = await axios.get(`/growing_plan/temp/status/${type}`);
        setGrowingPlanStatus(res?.data);
        return { status: true, data: res.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanStatus]
  );

  useEffect(() => {
    getTempGrowingPlanStatus(1);
  }, [getTempGrowingPlanStatus]);

  // Transplant Steps
  const getContainerData = useCallback(async () => {
    try {
      const res = await axios.get('/type/container/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getSoilMediumData = useCallback(async () => {
    try {
      const res = await axios.get('/type/soil/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getEnhancerData = useCallback(async () => {
    try {
      const res = await axios.get('/type/enhancer/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getTransPlantStep = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.get('/growing_plan/transplant/' + growingPlanId);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const saveTransPlantStep = useCallback(async (growingPlanId, values) => {
    try {
      const res = await axios.put('/growing_plan/transplant/' + growingPlanId, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateTransPlantStep = useCallback(async (growingPlanId, transplantId, values) => {
    try {
      const res = await axios.post(`/growing_plan/transplant/${growingPlanId}/${transplantId}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteTransPlantStep = useCallback(async (growingPlanId, transplantId) => {
    try {
      const res = await axios.delete(`/growing_plan/transplant/${growingPlanId}/${transplantId}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // Growing Steps
  const getTaskSteps = useCallback(async () => {
    try {
      const res = await axios.get('/type/growing_task_type');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getTaskData = useCallback(async () => {
    try {
      const res = await axios.get('/type/growing_task/all');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getAllMeasure = useCallback(async () => {
    try {
      const res = await axios.get('/type/measurement');
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getItemsByStep = useCallback(async (Step) => {
    try {
      const res = await axios.get('/growing_item/growing_task/' + Step);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const saveGrowingStep = useCallback(async (growingPlanId, values) => {
    try {
      const res = await axios.put(`/growing_plan/growing/${growingPlanId}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingStep = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.get(`/growing_plan/growing/${growingPlanId}`);
      return res.data;
    } catch (err) {
      return [];
    }
  }, []);

  const updateGrowingStep = useCallback(async (growingPlanId, stepId, values) => {
    try {
      const res = await axios.post(`/growing_plan/growing/${growingPlanId}/${stepId}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteGrowingStep = useCallback(async (growingPlanId, stepId) => {
    try {
      const res = await axios.delete(`/growing_plan/growing/${growingPlanId}/${stepId}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // Lighting plan
  const setLightingPlanList = useCallback((privateLightingPlanList, publicLightingPlanList) => {
    dispatch({
      type: GROWING_PLAN_LIGHTING_PLAN_LIST,
      payload: {
        privateLightingPlanList,
        publicLightingPlanList
      }
    });
  }, []);

  const setNewLightingPlanList = useCallback((privateNewLightingPlanList, publicNewLightingPlanList) => {
    dispatch({
      type: GROWING_PLAN_NEW_LIGHTING_PLAN_LIST,
      payload: {
        privateNewLightingPlanList,
        publicNewLightingPlanList
      }
    });
  }, []);

  const setLightingPlanVersion = useCallback((lightingPlanVersion) => {
    dispatch({
      type: GROWING_PLAN_LIGHTING_PLAN_VERSION,
      payload: {
        lightingPlanVersion
      }
    });
  }, []);

  useEffect(() => {
    setLightingPlanVersion(
      state.growingPlanToEdit?.lighting_flag ?? user?.user_setting?.lighting_flag ? LightingPlanVersion.New : LightingPlanVersion.Old
    );
  }, [state.growingPlanToEdit, user?.user_setting?.lighting_flag, setLightingPlanVersion]);

  const setIsLightingPlanSaved = useCallback((isLightingPlanSaved) => {
    dispatch({
      type: GROWING_PLAN_IS_LIGHTING_PLAN_SAVED,
      payload: {
        isLightingPlanSaved
      }
    });
  }, []);

  const getLightingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/list/lighting');
      const privateLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
      const publicLightingPlanList = res?.data?.filter((item) => !item?.user_id);
      setLightingPlanList(privateLightingPlanList, publicLightingPlanList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setLightingPlanList, dispatchForSnackbar]);

  const getNewLightingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/list/new_lighting');
      const privateNewLightingPlanList = res?.data?.filter((item) => !!item?.user_id);
      const publicNewLightingPlanList = res?.data?.filter((item) => !item?.user_id);
      setNewLightingPlanList(privateNewLightingPlanList, publicNewLightingPlanList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setNewLightingPlanList, dispatchForSnackbar]);

  const setLightingPlanToEdit = useCallback((lightingPlanToEdit, lightingStepToEdit = null) => {
    let lightingSteps = [];
    if (lightingPlanToEdit) {
      lightingSteps = [...lightingPlanToEdit.lighting_steps];
      const phases = lightingPlanToEdit.lighting_steps?.map((item) => item?.growing_phase) ?? [];
      for (let i = GrowingPhase.SEEDING; i <= GrowingPhase.FLOWERING; i++) {
        if (!phases.includes(i)) {
          lightingSteps.push(inactiveLightingPhase(i));
        }
      }
    }
    dispatch({
      type: GROWING_PLAN_LIGHTING_PLAN_TO_EDIT,
      payload: {
        lightingPlanToEdit,
        lightingSteps,
        lightingStepToEdit
      }
    });
  }, []);

  const setNewLightingPlanToEdit = useCallback((newLightingPlanToEdit) => {
    dispatch({
      type: GROWING_PLAN_NEW_LIGHTING_PLAN_TO_EDIT,
      payload: {
        newLightingPlanToEdit
      }
    });
  }, []);

  const setLightingSteps = useCallback((lightingSteps, lightingStepToEdit = null) => {
    dispatch({
      type: GROWING_PLAN_LIGHTING_PLAN_STEPS,
      payload: {
        lightingSteps,
        lightingStepToEdit
      }
    });
  }, []);

  const setLightingStepToEdit = useCallback((lightingStepToEdit) => {
    dispatch({
      type: GROWING_PLAN_LIGHTING_PLAN_STEP_TO_EDIT,
      payload: {
        lightingStepToEdit
      }
    });
  }, []);

  const getCurLightingPlan = useCallback(
    async (growingPlanId) => {
      if (!growingPlanId) {
        setLightingPlanToEdit(null);
        setIsLightingPlanSaved(false);
        return;
      }
      try {
        const res = await axios.get(`/growing_plan/lighting/${growingPlanId}`);
        setLightingPlanToEdit(res.data);
        setIsLightingPlanSaved(true);
      } catch (err) {
        setLightingPlanToEdit(null);
        setIsLightingPlanSaved(false);
      }
    },
    [setLightingPlanToEdit, setIsLightingPlanSaved]
  );

  const getCurNewLightingPlan = useCallback(
    async (growingPlanId) => {
      if (!growingPlanId) {
        setNewLightingPlanToEdit(null);
        setIsLightingPlanSaved(false);
        return;
      }
      try {
        const res = await axios.get(`/growing_plan/new_lighting/${growingPlanId}`);
        setNewLightingPlanToEdit(res.data);
        setIsLightingPlanSaved(true);
      } catch (err) {
        setNewLightingPlanToEdit(null);
        setIsLightingPlanSaved(false);
      }
    },
    [setNewLightingPlanToEdit, setIsLightingPlanSaved]
  );

  const getLightingSteps = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.get(`/growing_plan/lighting/${growingPlanId}`);
      return res.data?.lighting_steps;
    } catch (err) {
      return [];
    }
  }, []);

  const updateLightingPlan = useCallback(async (growingPlanId, values) => {
    try {
      const res = await axios.post(`/growing_plan/lighting/${growingPlanId}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteLightingPlan = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.delete(`/growing_plan/lighting/${growingPlanId}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateNewLightingPlan = useCallback(async (growingPlanId, values) => {
    try {
      const res = await axios.post(`/growing_plan/new_lighting/${growingPlanId}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteNewLightingPlan = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.delete(`/growing_plan/new_lighting/${growingPlanId}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // Environment Plan

  const setEnvironmentPlanList = useCallback((privateEnvironmentPlanList, publicEnvironmentPlanList) => {
    dispatch({
      type: GROWING_PLAN_ENVIRONMENT_PLAN_LIST,
      payload: {
        privateEnvironmentPlanList,
        publicEnvironmentPlanList
      }
    });
  }, []);

  const getEnvironmentPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/list/environment');
      const privateEnvironmentPlanList = res?.data?.filter((item) => !!item?.user_id);
      const publicEnvironmentPlanList = res?.data?.filter((item) => !item?.user_id);
      setEnvironmentPlanList(privateEnvironmentPlanList, publicEnvironmentPlanList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setEnvironmentPlanList, dispatchForSnackbar]);

  const getPlantVariety = useCallback(async () => {
    try {
      const res = await axios.get('/type/plant_variety');
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const setIsEnvironmentPlanSaved = useCallback((isEnvironmentPlanSaved) => {
    dispatch({
      type: GROWING_PLAN_IS_ENVIRONMENT_PLAN_SAVED,
      payload: {
        isEnvironmentPlanSaved
      }
    });
  }, []);

  const setEnvironmentPlanToEdit = useCallback((environmentPlanToEdit) => {
    dispatch({
      type: GROWING_PLAN_ENVIRONMENT_PLAN_TO_EDIT,
      payload: {
        environmentPlanToEdit
      }
    });
  }, []);

  const getCurEnvironmentPlan = useCallback(
    async (growingPlanId) => {
      if (!growingPlanId) {
        setEnvironmentPlanToEdit(null);
        setIsEnvironmentPlanSaved(false);
        return;
      }
      try {
        const res = await axios.get(`/growing_plan/environment/${growingPlanId}`);
        setEnvironmentPlanToEdit(res.data);
        setIsEnvironmentPlanSaved(true);
      } catch (err) {
        setEnvironmentPlanToEdit(null);
        setIsEnvironmentPlanSaved(false);
      }
    },
    [setEnvironmentPlanToEdit, setIsEnvironmentPlanSaved]
  );

  const getEnvironmentPlan = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.get(`/growing_plan/environment/${growingPlanId}`);
      return res.data;
    } catch (err) {
      return {};
    }
  }, []);

  const updateEnvironmentPlan = useCallback(async (growingPlanId, values) => {
    try {
      const res = await axios.post(`/growing_plan/environment/${growingPlanId}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteEnvironmentPlan = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.delete(`/growing_plan/environment/${growingPlanId}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // Irrigation Plan
  const setIrrigationPlanList = useCallback((privateIrrigationPlanList, publicIrrigationPlanList) => {
    dispatch({
      type: GROWING_PLAN_IRRIGATION_PLAN_LIST,
      payload: {
        privateIrrigationPlanList,
        publicIrrigationPlanList
      }
    });
  }, []);

  const setIsIrrigationPlanSaved = useCallback((isIrrigationPlanSaved) => {
    dispatch({
      type: GROWING_PLAN_IS_IRRIGATION_PLAN_SAVED,
      payload: {
        isIrrigationPlanSaved
      }
    });
  }, []);

  const getIrrigationPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/list/irrigation');
      const privateIrrigationPlanList = res?.data?.filter((item) => !!item?.user_id);
      const publicIrrigationPlanList = res?.data?.filter((item) => !item?.user_id);
      setIrrigationPlanList(privateIrrigationPlanList, publicIrrigationPlanList);
    } catch (err) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: err?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    }
  }, [setIrrigationPlanList, dispatchForSnackbar]);

  const setIrrigationPlanToEdit = useCallback((irrigationPlanToEdit) => {
    dispatch({
      type: GROWING_PLAN_IRRIGATION_PLAN_TO_EDIT,
      payload: {
        irrigationPlanToEdit
      }
    });
  }, []);

  const getCurIrrigationPlan = useCallback(
    async (growingPlanId) => {
      if (!growingPlanId) {
        setIrrigationPlanToEdit(null);
        setIsIrrigationPlanSaved(false);
        return;
      }
      try {
        const res = await axios.get(`/growing_plan/irrigation/${growingPlanId}`);
        setIrrigationPlanToEdit(res.data);
        setIsIrrigationPlanSaved(true);
      } catch (err) {
        setIrrigationPlanToEdit(null);
        setIsIrrigationPlanSaved(false);
      }
    },
    [setIrrigationPlanToEdit, setIsIrrigationPlanSaved]
  );

  const getIrrigationSteps = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.get(`/growing_plan/irrigation/${growingPlanId}`);
      return res.data?.irrigation_steps;
    } catch (err) {
      return [];
    }
  }, []);

  const updateIrrigationPlan = useCallback(async (growingPlanId, values) => {
    try {
      const res = await axios.post(`/growing_plan/irrigation/${growingPlanId}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteIrrigationPlan = useCallback(async (growingPlanId) => {
    try {
      const res = await axios.delete(`/growing_plan/irrigation/${growingPlanId}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/main');
      setGrowingPlanList(res?.data);
    } catch (err) {
      setGrowingPlanList([]);
    }
  }, [setGrowingPlanList]);

  const getGrowingPlanById = useCallback(async (id) => {
    try {
      const res = await axios.get(`/growing_plan/main/one/${id}`);
      return res?.data;
    } catch (err) {
      return null;
    }
  }, []);

  const createGrowingPlan = useCallback(
    async (values) => {
      try {
        const res = await axios.put('/growing_plan/main', values);
        setGrowingPlanList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanList]
  );

  const updateGrowingPlan = useCallback(async (ID, values) => {
    try {
      const res = await axios.post(`/growing_plan/main/${ID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  /**
   * Update progress
   */
  const loadGrowingPlanToTemp = useCallback(
    async (id) => {
      try {
        setGrowingPlanIdToUpdate(id);
        const res = await axios.post(`/growing_plan/load/${id}`);
        return res.data;
      } catch (err) {
        return [];
      }
    },
    [setGrowingPlanIdToUpdate]
  );

  const deleteGrowingPlan = useCallback(
    async (ID) => {
      try {
        const res = await axios.delete(`/growing_plan/main/${ID}`);
        setGrowingPlanList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanList]
  );

  const cloneGrowingPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/growing_plan/clone/${ID}`, values);
        setGrowingPlanList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanList]
  );

  const getPublishGrowingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/publishstatus');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const publishGrowingPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/growing_plan/publish/${ID}`, values);
        setGrowingPlanList(res?.data, true);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanList]
  );

  const finalizeGrowingPlan = useCallback(
    async (values) => {
      try {
        const res = await axios.post('/growing_plan/save', values);
        setGrowingPlanList(res?.data, true);
        return { status: true, data: res?.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanList]
  );

  const updateAllGrowingPlan = useCallback(
    async (ID, values) => {
      try {
        const res = await axios.post(`/growing_plan/update/${ID}`, values);
        setGrowingPlanList(res?.data, true);
        return { status: true, data: res?.data };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setGrowingPlanList]
  );

  useEffect(() => {
    getGrowingPlanList();
  }, [getGrowingPlanList]);

  return (
    <GrowingPlanContext.Provider
      value={{
        ...state,
        setGrowingPlanIdToUpdate,
        setGrowingPlanToEdit,
        setActiveStep,
        // creation
        createTempGrowingPlan,
        getTempGrowingPlan,
        getTempGrowingPlanStatus,
        // Transplant Steps
        getContainerData,
        getSoilMediumData,
        getEnhancerData,
        getTransPlantStep,
        saveTransPlantStep,
        updateTransPlantStep,
        deleteTransPlantStep,
        // Growing Steps
        getTaskSteps,
        getTaskData,
        getAllMeasure,
        getItemsByStep,
        saveGrowingStep,
        getGrowingStep,
        updateGrowingStep,
        deleteGrowingStep,
        // Lighting Plan
        getLightingPlanList,
        getNewLightingPlanList,
        setLightingPlanToEdit,
        setNewLightingPlanToEdit,
        setLightingPlanVersion,
        setLightingSteps,
        setLightingStepToEdit,
        getCurLightingPlan,
        getCurNewLightingPlan,
        getLightingSteps,
        updateLightingPlan,
        deleteLightingPlan,
        updateNewLightingPlan,
        deleteNewLightingPlan,
        setIsLightingPlanSaved,
        // Environment Plan
        getEnvironmentPlanList,
        getPlantVariety,
        setEnvironmentPlanToEdit,
        getCurEnvironmentPlan,
        getEnvironmentPlan,
        updateEnvironmentPlan,
        deleteEnvironmentPlan,
        setIsEnvironmentPlanSaved,
        // Irrigation Plan
        getIrrigationPlanList,
        setIrrigationPlanToEdit,
        getCurIrrigationPlan,
        getIrrigationSteps,
        updateIrrigationPlan,
        deleteIrrigationPlan,
        setIsIrrigationPlanSaved,
        // plan management
        getGrowingPlanList,
        getGrowingPlanById,
        createGrowingPlan,
        updateGrowingPlan,
        loadGrowingPlanToTemp,
        deleteGrowingPlan,
        cloneGrowingPlan,
        getPublishGrowingPlanList,
        publishGrowingPlan,
        finalizeGrowingPlan,
        updateAllGrowingPlan
      }}
    >
      {children}
    </GrowingPlanContext.Provider>
  );
};

GrowingPlanProvider.propTypes = {
  children: PropTypes.node
};

export default GrowingPlanContext;
