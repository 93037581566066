import Routes from 'routes';
import ThemeCustomization from 'themes';

import ScrollTop from 'components/ScrollTop';
import Locales from 'components/Locales';
import Notistack from 'components/third-party/Notistack';
import Snackbar from 'components/@extended/Snackbar';

import { AuthProvider } from 'contexts/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function App() {
  return (
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </>
            </GoogleOAuthProvider>
          </AuthProvider>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  );
}
