import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, Box, CardHeader } from '@mui/material';

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const VideoCard = forwardRef(({ children, title = '', secondary, className, sx = {}, ...others }, ref) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      ref={ref}
      {...others}
      sx={{
        border: 'none',
        borderRadius: '10px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.common.white,
        ...sx
      }}
    >
      <CardHeader
        sx={{ p: 2, backgroundColor: theme.palette.common.white }}
        titleTypographyProps={{ variant: 'p1m', color: theme.palette.common.black }}
        title={title}
        action={secondary}
      />
      <Box className={className} sx={{ px: 2, pt: 0, pb: 2 }}>
        {children}
      </Box>
    </Card>
  );
});

VideoCard.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  className: PropTypes.string,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default VideoCard;
