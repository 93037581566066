// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function Menu() {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF'
        }
      }
    }
  };
}
