// action - state management
import { STATUS_INITIALIZE, NETWORK, USER_SETUP } from './actions';
import { GuideCheck, SetupStep, LocationType } from 'utils/constants/setup';

// initial state
export const initialState = {
  status: GuideCheck.NeverChecked,
  step: SetupStep.Initial,
  network: null,
  location: null,
  locationType: LocationType.Manual,
  khoraID: null,
  khoraNames: null,
  khoraCount: null,
  addresses: null,
  accessory: null,
  shelf: null,
  networkList: [],
  summary: { network_count: '-', location_count: '-', address_count: '-', khora_count: '-' },
  userSetup: {
    grow_what: 0,
    grow_prefer: 0,
    get_cube_how: 0,
    grow_auto: 0,
    grow_interest: 0,
    people_count: 0,
    buffer_percentage: 0
  },
  isInitialized: false
};

// ==============================|| AUTH REDUCER ||============================== //

const setup = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_INITIALIZE: {
      const { status, step, network, location, locationType, khoraID, khoraNames, khoraCount, addresses, accessory, shelf } =
        action.payload;
      return {
        ...state,
        status,
        step,
        network,
        location,
        locationType,
        khoraID,
        khoraNames,
        khoraCount,
        addresses,
        accessory,
        shelf,
        isInitialized: true
      };
    }
    case NETWORK: {
      const { networkList, summary } = action.payload;
      return {
        ...state,
        networkList,
        summary
      };
    }
    case USER_SETUP: {
      const { userSetup } = action.payload;
      return {
        ...state,
        userSetup
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default setup;
