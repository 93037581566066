import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback } from 'react';
import dashboardReducer, { initialState } from 'store/reducers/_dashboard';
import axios from 'utils/axios';
import { ScheduleColors } from 'utils/constants/schedule';
import { SCHEDULE_NETWORK_LIST_ALL, SCHEDULE_CROP_PLAN_COLOR } from 'store/reducers/actions';
import useSetup from 'hooks/useSetup';

const DashboardContext = createContext(null);

export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dashboardReducer, initialState);
  const { location, khoraCount, shelf, accessory } = useSetup();

  const getShelfStatus = useCallback(async () => {
    try {
      const res = await axios.get(`/khora_setup/shelf/status/${location?.id}`);
      return {
        Khôras: khoraCount || 0,
        'Total Shelves': parseInt((khoraCount || 0) * (shelf?.shelf_count || 0) + (accessory?.shelf_count || 0)),
        'Seeding Shelves': res?.seeding?.length || 0,
        Off: res?.off?.length || 0
      };
    } catch (err) {
      return {
        Khôras: khoraCount || 0,
        'Total Shelves': parseInt((khoraCount || 0) * (shelf?.shelf_count || 0) + (accessory?.shelf_count || 0)),
        'Seeding Shelves': 0,
        Off: 0
      };
    }
  }, [location?.id, khoraCount, shelf?.shelf_count, accessory?.shelf_count]);

  const getTodoList = useCallback(async () => {
    try {
      const res = await axios.get(`/info/to_do/${location?.id}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, [location?.id]);

  const getNotificationList = useCallback(async () => {
    try {
      const res = await axios.get(`/noti/all`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const setRunningCropPlanColors = useCallback((runningCropPlanColors) => {
    dispatch({
      type: SCHEDULE_CROP_PLAN_COLOR,
      payload: {
        runningCropPlanColors
      }
    });
  }, []);

  const setNetworkShelfStatus = useCallback(
    (networkList) => {
      const runningCropPlanIDs = networkList
        ?.map((network) => network?.locations?.map((location) => location?.crop_models?.filter((plan) => plan?.is_running)).flat())
        .flat()
        ?.map((plan) => plan?.id)
        .flat();
      const colors = {};
      runningCropPlanIDs?.map((id, idx) => {
        colors[id] = ScheduleColors[idx % 10];
      });
      setRunningCropPlanColors(colors);
      dispatch({
        type: SCHEDULE_NETWORK_LIST_ALL,
        payload: {
          networkList
        }
      });
    },
    [setRunningCropPlanColors]
  );

  const getNetworkShelfStatus = useCallback(async () => {
    try {
      const res = await axios.get('/schedule/all_running_info');
      setNetworkShelfStatus(res?.data);
    } catch (err) {
      setNetworkShelfStatus([]);
    }
  }, [setNetworkShelfStatus]);

  const updateTaskReminder = useCallback(
    async (id, values) => {
      try {
        const res = await axios.post(`/schedule/update_reminder/${id}`, values);
        setNetworkShelfStatus(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setNetworkShelfStatus]
  );

  const stopSchedule = useCallback(
    async (id) => {
      try {
        await axios.post(`/schedule/stop/${id}`);
        await getNetworkShelfStatus();
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [getNetworkShelfStatus]
  );

  const getHarvestHistory = useCallback(async () => {
    try {
      const res = await axios.get(`/dashboard/total_harvested_plants/${location?.id}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, [location?.id]);

  const getUpcomingHarvest = useCallback(async () => {
    try {
      const res = await axios.get(`/dashboard/upcoming_harvests/${location?.id}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, [location?.id]);

  const getGrowingPlanSubmission = useCallback(async () => {
    try {
      const res = await axios.get(`/dashboard/submission_summary`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getTopGrowingPlans = useCallback(async () => {
    try {
      const res = await axios.get(`/dashboard/top_growing_plans`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getTopVotedGrowingPlans = useCallback(async () => {
    try {
      const res = await axios.get(`/dashboard/top_voted`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getCurrentRunningCropPlanSummary = useCallback(async () => {
    try {
      const res = await axios.get(`/dashboard/cost_summary/${location?.id}`);
      return res?.data;
    } catch (err) {
      return { crop_plan_name: 'No scheduled crop plan exist' };
    }
  }, [location?.id]);

  const getTopCropsByVariety = useCallback(async () => {
    try {
      const res = await axios.get(`/dashboard/top_crops/${location?.id}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, [location?.id]);

  const getCurrentGrowing = useCallback(async () => {
    try {
      const res = await axios.get(`/dashboard/current_growing/${location?.id}`);
      return res?.data;
    } catch (err) {
      return [0, 0, 0, 0];
    }
  }, [location?.id]);

  const CaptureImage = useCallback(async () => {
    try {
      await axios.get(`/dashboard/screenshot/${location?.id}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, [location?.id]);

  const StartVideoStream = useCallback(async () => {
    try {
      await axios.get(`/dashboard/videostream/${location?.id}`);
      return { status: true, data: '' };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, [location?.id]);

  return (
    <DashboardContext.Provider
      value={{
        ...state,
        getShelfStatus,
        getTodoList,
        getNotificationList,
        getNetworkShelfStatus,
        updateTaskReminder,
        stopSchedule,
        getHarvestHistory,
        getUpcomingHarvest,
        getGrowingPlanSubmission,
        getTopGrowingPlans,
        getTopVotedGrowingPlans,
        getCurrentRunningCropPlanSummary,
        getTopCropsByVariety,
        getCurrentGrowing,
        CaptureImage,
        StartVideoStream
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node
};

export default DashboardContext;
