import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
const SpotItem = ({ variant = 'p3', text, ...others }) => {
  return (
    <Stack direction="row" spacing={1.25} {...others}>
      <span style={{ marginTop: variant === 'p2' ? '3px' : '0px' }}>&#8226;</span>
      <Typography variant={variant}>{text}</Typography>
    </Stack>
  );
};
SpotItem.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};
export default SpotItem;
