import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { formatOnlyDate } from 'utils/common';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';

const NewsStyle1 = ({ news }) => {
  const navigate = useNavigate();
  return (
    <Stack
      onClick={() => navigate(`/news/view/${news?.id}`)}
      justifyContent="flex-end"
      spacing={2}
      sx={{
        background: `linear-gradient( 0deg, #1b1b1b 0%, transparent 100% ), url('${news?.image ?? '/assets/images/kmg_image.png'}')`,
        width: '100%',
        maxWidth: 360,
        aspectRatio: '3 / 4',
        borderRadius: '10px',
        p: 3,
        backgroundPosition: 'center, center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        transition: 'transform 250ms ease, box-shadow 250ms ease, color 250ms ease',
        '&:hover': {
          WebkitTransform: 'translateY(-0.25rem)',
          MsTransform: 'translateY(-0.25rem)',
          transform: 'translateY(-0.25rem)'
        }
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: 'common.white',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          wordBreak: 'break-word',
          WebkitLineClamp: 3
        }}
      >
        {news?.title}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={0.75}>
        <Typography variant="p2m" sx={{ color: 'common.white' }}>
          <b>{news?.category?.name}</b>
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
        <Typography sx={{ color: '#98A2B3' }}>{formatOnlyDate(news?.publish_date, false)}</Typography>
        <Typography sx={{ color: '#98A2B3' }}>
          <b>・</b>
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <TimeIcon sx={{ color: '#98A2B3', fontSize: '1.25rem' }} />
          <Typography sx={{ color: '#98A2B3' }}>{news?.time_to_read === 1 ? '1 min' : `${news?.time_to_read} mins`}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
NewsStyle1.propTypes = {
  news: PropTypes.any
};
export default NewsStyle1;
