import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQMarketplaceSection6 = () => {
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">How do you leave a review?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2.5}>
            <Stack spacing={2.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2">
                Product reviews build trust by reassuring potential customers of quality and reliability. They provide insights from
                previous buyers, aiding informed decisions and highlighting any issues. Reviews offer valuable feedback for sellers, helping
                them improve products and enhance customer satisfaction. Additionally, they allow buyers to express their appreciation to
                sellers.
              </Typography>
              <Typography variant="p2">
                In Khôra Marketplace, reviews can be left only after the item is purchased, delivered, and the seller has marked the
                transaction as <b>&quot;Completed.&quot;</b> Additionally, the product must still be on sale to be able to leave a review.
              </Typography>
              <Typography variant="p2">
                Buyers may go to <b>&quot;My Purchases&quot;</b> and look for the prompt to add a reviews for items in the order.
              </Typography>
            </Stack>
            <Stack alignItems={'center'}>
              <img src={'/assets/images/faq/marketplace_5.png'} alt="marketplace_5" style={{ width: '80%' }} />
            </Stack>
            <Stack spacing={2.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2">
                When leaving a review, rank your experience, provide a title, and write a note for the seller and the community. Your
                ranking is averaged with all reviews created on the item.
              </Typography>
              <Typography variant="p2">
                We are a kind community. Please ensure your comments are accurate, honest, and appropriate. All comments are monitored.
              </Typography>
            </Stack>
            <Stack alignItems={'center'}>
              <img src={'/assets/images/faq/marketplace_6.png'} alt="marketplace_6" style={{ width: '60%' }} />
            </Stack>
            <Stack spacing={2.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2">You can edit your review up to two times.</Typography>
            </Stack>
            <Stack alignItems={'center'}>
              <img src={'/assets/images/faq/marketplace_7.png'} alt="marketplace_7" style={{ width: '80%' }} />
            </Stack>
            <Stack spacing={2.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2">
                To see your review, return to the item{"'"}s listing and click the <b>&quot;Reviews&quot;</b> link. There, you can read your
                review and see the seller{"'"}s response.
              </Typography>
            </Stack>
            <Stack alignItems={'center'}>
              <img src={'/assets/images/faq/marketplace_8.png'} alt="marketplace_8" style={{ width: '60%' }} />
            </Stack>
            <Stack spacing={2.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2">
                We encourage thumbs-up responses to show appreciation for someone{"'"}s comments or reviews.
              </Typography>
            </Stack>
            <Stack alignItems={'center'}>
              <img src={'/assets/images/faq/marketplace_9.png'} alt="marketplace_9" style={{ width: '60%' }} />
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FAQMarketplaceSection6;
