const GuideCheck = { NeverChecked: 0, Checked: 1, OneNetworkComplete: 2, NerverShowLater: 3 };

const SetupStep = {
  Initial: 0,
  GrowAuto: 1,
  GrowInterest: 2,
  GrowWhat: 3,
  GrowPrefer: 4,
  UserSetupComplete: 5,
  Network: 6,
  Location: 7,
  Accessory: 8,
  NameKhora: 9,
  Stacking: 10,
  SetShelves: 11,
  Another: 12,
  ShelfManagement: 13,
  MyKhora: 14,
  KhoraSetupComplete: 15, // This value is also used in the backend
  AdditionalAddress: 16
};

const SetupURL = 'khora_setup';

const SetupRedirectURL = [
  'grow_auto',
  'grow_auto',
  'grow_interest',
  'grow_what',
  'grow_prefer',
  'user_complete',
  'add_network',
  'add_location',
  'accessory',
  'name_khora',
  'stacking',
  'set_shelves',
  'another',
  'shelf_management',
  'my_khora',
  'khora_complete',
  'additional_address'
];

const LocationType = {
  Manual: 1,
  Auto: 2
};

const SetupProgress = ['Network', 'Location', 'Accessories', 'Shelves'];

const AddressType = {
  Unknown: 0,
  Corporate: 1,
  Plant: 2,
  Mailing: 3
};

const DefaultKhoraSizeInch = {
  Width: '48.00',
  Depth: '24.00',
  Height: '72.00'
};

const KhoraNameSuggestions = ['Green Garden', 'Baby Blue', 'Kitchen Garden'];

const GrowInterestMethod = {
  Unknown: 0,
  CleanHands: 1,
  DirtyHands: 2
};

const GapManageMethod = {
  GapExist: 0,
  NoGapExist: 1
};

const GrowInterestString = ['No answer', 'I will keep my hands clean!', 'I love to get dirty and will do it all!'];
const PlacePlantString = [
  'No answer',
  'Fill all 4 shelf trays with the same plant.',
  'Fill trays with different plants that grow well together.'
];

const GrowAutoMethod = {
  Unknown: 0,
  Automated: 1,
  Advanced: 2,
  Manual: 3,
  All: 4
};

const GrowAutoString = [
  'No answer',
  'Yes, do all of the growing for me!',
  'No, I want advanced features.',
  'No, I want manual control.',
  'I want access to do everything!'
];

const GrowPreferMethod = {
  Unknown: 0,
  LoveNewFood: 1,
  TryNewPlant: 2,
  NoTryNewFood: 3
};

const GrowPreferString = [
  'No answer',
  'I love new foods especially unique and funky ones!',
  'I would try new types of the plants I typically order.',
  'No, thank you. I like what I like.'
];

const GrowWhatString = ['No answer', 'Produce including herbs', 'Microgreens', 'Mushrooms', 'Cannabis'];

const AccessoryNames = [
  'shelf_count',
  'light_count',
  'seeding_pad_count',
  'medium_container_count',
  'large_container_count',
  'microgreen_tray_count',
  'shelf_tray_count',
  'soil_vapor_sensor_count'
];

const SensorLabels = {
  at0: { title: 'Analog Temp 1', unit: '°F' },
  at1: { title: 'Analog Temp 2', unit: '°F' },
  inT: { title: 'Inside Temp', unit: '°F' },
  inH: { title: 'Inside Humidity', unit: '% RH' },
  inL: { title: 'Inside Light', unit: 'μMol/m²/s' },
  inC: { title: 'Inside CO2', unit: 'ppm' },
  ouT: { title: 'Outside Temp', unit: '°F' },
  ouH: { title: 'Outside Humidity', unit: '% RH' },
  ouL: { title: 'Outside Light', unit: 'μMol/m²/s' },
  sm0: { title: 'Soil Moisture 1', unit: '%' },
  sm1: { title: 'Soil Moisture 2', unit: '%' }
};

const SensorColors = [
  { header: '#FFF9EA', body: '#FFFBF1' },
  { header: '#E3F4FF', body: '#F0F9FF' },
  { header: '#FFEAEA', body: '#FFF6F6' },
  { header: '#F2F0FF', body: '#F8F7FF' }
];

export {
  GuideCheck,
  SetupStep,
  SetupURL,
  SetupRedirectURL,
  LocationType,
  SetupProgress,
  AddressType,
  KhoraNameSuggestions,
  DefaultKhoraSizeInch,
  GrowInterestMethod,
  GapManageMethod,
  GrowAutoMethod,
  GrowPreferMethod,
  GrowInterestString,
  GrowAutoString,
  GrowPreferString,
  GrowWhatString,
  AccessoryNames,
  PlacePlantString,
  SensorLabels,
  SensorColors
};
