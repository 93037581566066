// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: { style: { maxHeight: 300, maxWidth: 300 } },
          MenuListProps: {
            disablePadding: true
          }
        }
      },
      styleOverrides: {
        // root: {
        //   backgroundColor: '#FFFFFF'
        // },
        '&.Mui-focused': {
          outline: `2px solid ${theme.palette.primary.dark}`
        },
        notchedOutline: {
          borderColor: theme.palette.primary[300]
        }
      }
    }
  };
}
