import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

import { CostProvider } from 'contexts/CostContext';

// render - Cost Management
const TaxAndDelivery = Loadable(lazy(() => import('pages/cost_management/tax_delivery')));
const SalesPricesAndProfits = Loadable(lazy(() => import('pages/cost_management/price_profit')));
const SmallKhoraCubeCost = Loadable(lazy(() => import('pages/cost_management/seed')));
const MicrogreenTrayCost = Loadable(lazy(() => import('pages/cost_management/microgreen')));
const MushroomCost = Loadable(lazy(() => import('pages/cost_management/mushroom')));
const CuttingCost = Loadable(lazy(() => import('pages/cost_management/cutting')));
const MissingCostData = Loadable(lazy(() => import('pages/cost_management/missing_cost')));
const ImportExportCost = Loadable(lazy(() => import('pages/cost_management/importexport')));

// ==============================|| MAIN ROUTING ||============================== //

const CostRoutes = {
  path: 'cost',
  element: (
    <CostProvider>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </CostProvider>
  ),
  children: [
    {
      path: 'tax_delivery',
      element: <TaxAndDelivery />
    },
    {
      path: 'seed',
      element: <SmallKhoraCubeCost />
    },
    {
      path: 'microgreen',
      element: <MicrogreenTrayCost />
    },
    {
      path: 'mushroom',
      element: <MushroomCost />
    },
    {
      path: 'cutting',
      element: <CuttingCost />
    },
    {
      path: 'missing_cost',
      element: <MissingCostData />
    },
    {
      path: 'price_profit',
      element: <SalesPricesAndProfits />
    },
    {
      path: 'import_export',
      element: <ImportExportCost />
    }
  ]
};

export default CostRoutes;
