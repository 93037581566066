import { PaymentMethodFlag, PaymentMethodType } from 'utils/constants/payment';
import { STRIPE_OPTIONS, STRIPE_PROMISE, PAYMENT_METHOD, PAYMENT_METHOD_EDIT } from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  stripePromise: null,
  stripeOptions: null,
  paymentMethod: {
    default: null,
    backup: null
  },
  paymentMethodFlag: PaymentMethodFlag.Create,
  paymentMethodType: PaymentMethodType.Default,
  paymentMethodEdit: false
};

// ==============================|| AUTH REDUCER ||============================== //

const payment = (state = initialState, action) => {
  switch (action.type) {
    case STRIPE_PROMISE: {
      const { stripePromise } = action.payload;
      return {
        ...state,
        stripePromise
      };
    }
    case STRIPE_OPTIONS: {
      const { stripeOptions } = action.payload;
      return {
        ...state,
        stripeOptions
      };
    }
    case PAYMENT_METHOD_EDIT: {
      const { paymentMethodEdit, paymentMethodType, paymentMethodFlag } = action.payload;
      return {
        ...state,
        paymentMethodEdit,
        paymentMethodType,
        paymentMethodFlag
      };
    }
    case PAYMENT_METHOD: {
      const { paymentMethod } = action.payload;
      return {
        ...state,
        isInitialized: true,
        paymentMethod
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default payment;
