import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQMarketplaceSection7 = () => {
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">How do you respond to a Buyer{"'"}s review?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2.5}>
            <Stack spacing={2.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2">
                As a seller, you can view and respond to reviews on an individual active listing by clicking the review link within the
                listing details.
              </Typography>
            </Stack>
            <Stack alignItems={'center'}>
              <img src={'/assets/images/faq/marketplace_10.png'} alt="marketplace_10" style={{ width: '50%' }} />
            </Stack>
            <Stack spacing={2.5} sx={{ backgroundColor: 'common.white', p: 2.5, borderRadius: '10px' }}>
              <Typography variant="p2">
                Additionally, you can see all of your reviews by going to your profile and clicking the Reviews tab. Both you and the buyer
                can leave up to two responses. We encourage thumbs-up responses to show appreciation for comments or reviews. Please ensure
                all comments are accurate, honest, and appropriate. All comments are monitored.
              </Typography>
            </Stack>
            <Stack alignItems={'center'}>
              <img src={'/assets/images/faq/marketplace_11.png'} alt="marketplace_11" style={{ width: '50%' }} />
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FAQMarketplaceSection7;
