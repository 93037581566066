import { LightingPlanVersion } from 'utils/constants/list';
import {
  GROWING_PLAN_UPDATE_ID,
  GROWING_PLAN_TO_EDIT,
  GROWING_PLAN_LIST_ALL,
  GROWING_PLAN_ACTIVE_STEP,
  GROWING_PLAN_STATUS,
  GROWING_PLAN_LIGHTING_PLAN_LIST,
  GROWING_PLAN_LIGHTING_PLAN_TO_EDIT,
  GROWING_PLAN_NEW_LIGHTING_PLAN_LIST,
  GROWING_PLAN_NEW_LIGHTING_PLAN_TO_EDIT,
  GROWING_PLAN_LIGHTING_PLAN_VERSION,
  GROWING_PLAN_LIGHTING_PLAN_STEPS,
  GROWING_PLAN_IS_LIGHTING_PLAN_SAVED,
  GROWING_PLAN_LIGHTING_PLAN_STEP_TO_EDIT,
  GROWING_PLAN_ENVIRONMENT_PLAN_LIST,
  GROWING_PLAN_ENVIRONMENT_PLAN_TO_EDIT,
  GROWING_PLAN_IS_ENVIRONMENT_PLAN_SAVED,
  GROWING_PLAN_IRRIGATION_PLAN_LIST,
  GROWING_PLAN_IRRIGATION_PLAN_TO_EDIT,
  GROWING_PLAN_IS_IRRIGATION_PLAN_SAVED
} from './actions';
import { GrowingStep } from 'utils/constants/growing_plan';

// initial state
export const initialState = {
  isInitialized: false,
  growingPlanIdToUpdate: 0,
  growingPlanToEdit: null,
  privateGrowingPlanList: [],
  publicGrowingPlanList: [],
  activeStep: GrowingStep['Select Plant'],
  isPlantSelected: false,
  isTransplantStepSaved: false,
  isGrowingStepSaved: false,
  // lighting plan
  privateLightingPlanList: [],
  publicLightingPlanList: [],
  lightingPlanToEdit: null,
  privateNewLightingPlanList: [],
  publicNewLightingPlanList: [],
  newLightingPlanToEdit: null,
  lightingSteps: [],
  lightingStepToEdit: null,
  isLightingPlanSaved: false,
  lightingPlanVersion: LightingPlanVersion.Old,
  // environment plan
  privateEnvironmentPlanList: [],
  publicEnvironmentPlanList: [],
  environmentPlanToEdit: null,
  isEnvironmentPlanSaved: false,
  // irrigation plan
  privateIrrigationPlanList: [],
  publicIrrigationPlanList: [],
  irrigationPlanToEdit: null,
  isIrrigationPlanSaved: false
};

// ==============================|| AUTH REDUCER ||============================== //

const growingPlan = (state = initialState, action) => {
  switch (action.type) {
    case GROWING_PLAN_LIST_ALL: {
      const { privateGrowingPlanList, publicGrowingPlanList } = action.payload;
      return {
        ...state,
        privateGrowingPlanList,
        publicGrowingPlanList,
        isInitialized: true
      };
    }
    case GROWING_PLAN_UPDATE_ID: {
      const { growingPlanIdToUpdate } = action.payload;
      return {
        ...state,
        growingPlanIdToUpdate
      };
    }
    case GROWING_PLAN_TO_EDIT: {
      const { growingPlanToEdit } = action.payload;
      return {
        ...state,
        growingPlanToEdit
      };
    }
    case GROWING_PLAN_ACTIVE_STEP: {
      const { activeStep } = action.payload;
      return {
        ...state,
        activeStep
      };
    }
    case GROWING_PLAN_STATUS: {
      const {
        isPlantSelected,
        isTransplantStepSaved,
        isGrowingStepSaved,
        isLightingPlanSaved,
        isEnvironmentPlanSaved,
        isIrrigationPlanSaved
      } = action.payload;
      return {
        ...state,
        isPlantSelected,
        isTransplantStepSaved,
        isGrowingStepSaved,
        isLightingPlanSaved,
        isEnvironmentPlanSaved,
        isIrrigationPlanSaved
      };
    }
    case GROWING_PLAN_LIGHTING_PLAN_LIST: {
      const { privateLightingPlanList, publicLightingPlanList } = action.payload;
      return {
        ...state,
        privateLightingPlanList,
        publicLightingPlanList,
        isInitialized: true
      };
    }
    case GROWING_PLAN_NEW_LIGHTING_PLAN_LIST: {
      const { privateNewLightingPlanList, publicNewLightingPlanList } = action.payload;
      return {
        ...state,
        privateNewLightingPlanList,
        publicNewLightingPlanList,
        isInitialized: true
      };
    }
    case GROWING_PLAN_LIGHTING_PLAN_TO_EDIT: {
      const { lightingPlanToEdit, lightingSteps, lightingStepToEdit } = action.payload;
      return {
        ...state,
        lightingPlanToEdit,
        lightingSteps,
        lightingStepToEdit
      };
    }
    case GROWING_PLAN_NEW_LIGHTING_PLAN_TO_EDIT: {
      const { newLightingPlanToEdit } = action.payload;
      return {
        ...state,
        newLightingPlanToEdit
      };
    }
    case GROWING_PLAN_LIGHTING_PLAN_VERSION: {
      const { lightingPlanVersion } = action.payload;
      return {
        ...state,
        lightingPlanVersion
      };
    }
    case GROWING_PLAN_LIGHTING_PLAN_STEPS: {
      const { lightingSteps, lightingStepToEdit } = action.payload;
      return {
        ...state,
        lightingSteps,
        lightingStepToEdit
      };
    }
    case GROWING_PLAN_LIGHTING_PLAN_STEP_TO_EDIT: {
      const { lightingStepToEdit } = action.payload;
      return {
        ...state,
        lightingStepToEdit
      };
    }
    case GROWING_PLAN_IS_LIGHTING_PLAN_SAVED: {
      const { isLightingPlanSaved } = action.payload;
      return {
        ...state,
        isLightingPlanSaved
      };
    }
    case GROWING_PLAN_ENVIRONMENT_PLAN_LIST: {
      const { privateEnvironmentPlanList, publicEnvironmentPlanList } = action.payload;
      return {
        ...state,
        privateEnvironmentPlanList,
        publicEnvironmentPlanList,
        isInitialized: true
      };
    }
    case GROWING_PLAN_ENVIRONMENT_PLAN_TO_EDIT: {
      const { environmentPlanToEdit } = action.payload;
      return {
        ...state,
        environmentPlanToEdit
      };
    }
    case GROWING_PLAN_IS_ENVIRONMENT_PLAN_SAVED: {
      const { isEnvironmentPlanSaved } = action.payload;
      return {
        ...state,
        isEnvironmentPlanSaved
      };
    }
    case GROWING_PLAN_IRRIGATION_PLAN_LIST: {
      const { privateIrrigationPlanList, publicIrrigationPlanList } = action.payload;
      return {
        ...state,
        privateIrrigationPlanList,
        publicIrrigationPlanList,
        isInitialized: true
      };
    }
    case GROWING_PLAN_IRRIGATION_PLAN_TO_EDIT: {
      const { irrigationPlanToEdit } = action.payload;
      return {
        ...state,
        irrigationPlanToEdit
      };
    }
    case GROWING_PLAN_IS_IRRIGATION_PLAN_SAVED: {
      const { isIrrigationPlanSaved } = action.payload;
      return {
        ...state,
        isIrrigationPlanSaved
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default growingPlan;
