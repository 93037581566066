import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

import { CropPlanProvider } from 'contexts/CropPlanContext';
import { CustomerProvider } from 'contexts/CustomerContext';

// render - Crop Plan Management
const CreateAutoCropPlan = Loadable(lazy(() => import('pages/crop_plan_management/auto/create')));
const UpdateAutoCropPlan = Loadable(lazy(() => import('pages/crop_plan_management/auto/update')));
const CreateManualCropPlan = Loadable(lazy(() => import('pages/crop_plan_management/manual/create')));
const UpdateManualCropPlan = Loadable(lazy(() => import('pages/crop_plan_management/manual/update')));
const CreateAdvancedCropPlan = Loadable(lazy(() => import('pages/crop_plan_management/advanced/create')));
const UpdateAdvancedCropPlan = Loadable(lazy(() => import('pages/crop_plan_management/advanced/update')));

// render - Crop Plan Management
const CropPlanManagement = Loadable(lazy(() => import('pages/crop_plan_management')));

// ==============================|| MAIN ROUTING ||============================== //

const CropPlanRoutes = {
  path: 'crop_plan',
  element: (
    <CustomerProvider>
      <CropPlanProvider>
        <MainLayout>
          <Outlet />
        </MainLayout>
      </CropPlanProvider>
    </CustomerProvider>
  ),
  children: [
    {
      path: '',
      element: <CropPlanManagement />
    },
    {
      path: 'auto',
      children: [
        {
          path: 'create',
          element: <CreateAutoCropPlan />
        },
        {
          path: 'update/:planID',
          element: <UpdateAutoCropPlan />
        }
      ]
    },
    {
      path: 'manual',
      children: [
        {
          path: 'create',
          element: <CreateManualCropPlan />
        },
        {
          path: 'update/:planID',
          element: <UpdateManualCropPlan />
        }
      ]
    },
    {
      path: 'advanced',
      children: [
        {
          path: 'create',
          element: <CreateAdvancedCropPlan />
        },
        {
          path: 'update/:planID',
          element: <UpdateAdvancedCropPlan />
        }
      ]
    }
  ]
};

export default CropPlanRoutes;
