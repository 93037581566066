// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function PickersDay(theme) {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          '&.MuiPickersDay-today': {
            borderColor: theme.palette.darkgreen.main
          },
          '&.Mui-selected': {
            backgroundColor: `${theme.palette.darkgreen.main}!important`,
            color: theme.palette.darkgreen.contrastText
          }
        }
      }
    }
  };
}
