import { Box, Typography, Stack, useTheme } from '@mui/material';
import CommunitySection1 from './Section1';
import CommunitySection2 from './Section2';

const LandingMoreInfoSection = () => {
  const theme = useTheme();

  const titleStyle = {
    fontSize: { xs: '32px', md: '42px' },
    lineHeight: { xs: '44px', md: '60px' },
    fontFamily: 'Poppins',
    fontWeight: 400
  };

  return (
    <>
      <Box sx={{ width: '100%', background: theme.palette.common.white }}>
        <Box
          sx={{
            backgroundImage: 'url("/assets/images/landing/background.jpg")',
            backgroundPosition: 'center, center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            py: { xs: '40px', md: '60px' }
          }}
        >
          <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', px: 2.5 }}>
            <Stack spacing={3.75} justifyContent="center" sx={{ height: '100%' }}>
              <Typography sx={{ ...titleStyle, color: theme.palette.common.white }}>Our Khôra Community</Typography>
              <Stack spacing={2.5}>
                <Typography sx={{ ...theme.typography.p1, color: theme.palette.common.white }}>
                  Khôra Home growers are creative, ingenious, and persistent individuals dedicated to cultivating the healthiest, tastiest,
                  and most beautiful plants year-round. With pride and the power of Khôra, we deliver bountiful beauties worthy of blue
                  ribbons at any State Fair!
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', py: { xs: 5, md: 10 }, px: 2.5 }}>
        <CommunitySection1 />
      </Box>
      <CommunitySection2 />
    </>
  );
};
export default LandingMoreInfoSection;
