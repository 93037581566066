import { useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Modal, IconButton, Stack } from '@mui/material';
import VideoCard from 'components/VideoCard';
import ReactPlayer from 'react-player';
import { CloseOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection3 = () => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const handleVideoModalOpen = () => setOpenVideoModal(true);
  const handleVideoModalClose = () => setOpenVideoModal(false);
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">Can Sprout be configured for my complex personal or business needs?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3.75} direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'center', sm: 'flex-start' }}>
            <Stack justifyContent="space-around" spacing={1.25}>
              <Typography variant="p2">
                Yes, Sprout is designed to be highly customizable to match your personal or business requirements.
              </Typography>
              <Typography variant="p2">
                During the setup process, whether it{"'"}s for a single unit or multiple Khôra Home units, you have the flexibility to
                configure the networks, locations, and Khôra Home units to align with your specific needs and preferences.
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3.75}>
              <Stack
                alignItems="center"
                justifyContent="center"
                onClick={handleVideoModalOpen}
                sx={{
                  borderRadius: '10px',
                  cursor: 'pointer',
                  backgroundImage: 'url("/assets/images/khora_setup/watch.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: 220,
                  height: 160
                }}
              >
                <img src="/assets/images/landing/demobtn.svg" alt="demo button" />
              </Stack>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Modal open={openVideoModal} onClose={handleVideoModalClose}>
        <VideoCard
          className="video-size-landing"
          title="Naming a Network, Locations, and Khôras"
          secondary={
            <IconButton onClick={handleVideoModalClose} sx={{ borderRadius: '100px' }}>
              <CloseOutlined />
            </IconButton>
          }
        >
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  controls: true,
                  autoPlay: true,
                  muted: false
                }
              }
            }}
            url={'/assets/video/network_naming.mp4'}
            width={'100%'}
            height={'100%'}
          />
        </VideoCard>
      </Modal>
    </>
  );
};
export default FAQSproutSection3;
