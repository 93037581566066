import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import GuideLayout from 'layout/GuideLayout';

// render - guide
const Guide = Loadable(lazy(() => import('pages/guide')));

// ==============================|| MAIN ROUTING ||============================== //

const GuideRoutes = {
  path: 'guide',
  element: (
    <AuthGuard>
      <GuideLayout>
        <Outlet />
      </GuideLayout>
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <Guide />
    }
  ]
};

export default GuideRoutes;
