import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CardContent, Stack, Button, Modal, Typography, useTheme } from '@mui/material';
import AlertCard from 'components/AlertCard';

import { createContext, useReducer, useCallback, useEffect, useState } from 'react';
import axios from 'utils/axios';

import { TODO_LIST, TODO_TO_EDIT, TODO_STATUS } from 'store/reducers/actions';

import todoReducer, { initialState } from 'store/reducers/_todo';

import useSetup from 'hooks/useSetup';

const TodoContext = createContext(null);

export const TodoProvider = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(todoReducer, initialState);

  const { network, location } = useSetup();

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const handleErrorAlertOpen = () => setOpenErrorAlert(true);
  const [errTitle, setErrTitle] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (!network?.id) {
      setErrTitle('No network found!');
      setErrMsg('You have no network. Please add your first network');
      handleErrorAlertOpen();
      return;
    }
    if (!location?.id) {
      setErrTitle('No location found!');
      setErrMsg('You have no location. Please add your first location');
      handleErrorAlertOpen();
      return;
    }
  }, [network, location]);

  const setTodoList = useCallback((todoList) => {
    dispatch({
      type: TODO_LIST,
      payload: {
        todoList
      }
    });
  }, []);

  const getTodoList = useCallback(
    async (locationID) => {
      try {
        const res = await axios.get(`/info/to_do/${locationID}`);
        setTodoList(res?.data);
      } catch (err) {
        setTodoList([]);
      }
    },
    [setTodoList]
  );

  const setTodoToEdit = useCallback((todoToEdit) => {
    dispatch({
      type: TODO_TO_EDIT,
      payload: {
        todoToEdit
      }
    });
  }, []);

  const setTodoStatus = useCallback((todoStatus) => {
    dispatch({
      type: TODO_STATUS,
      payload: {
        todoStatus
      }
    });
  }, []);

  useEffect(() => {
    getTodoList(location?.id);
  }, [location?.id, getTodoList]);

  const createTodoTask = useCallback(
    async (values) => {
      try {
        const res = await axios.put(`/info/additional_task/${location?.id}`, values);
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList, location?.id]
  );

  const updateTodoTask = useCallback(
    async (taskID, values) => {
      try {
        const res = await axios.post(`/info/additional_task/${location?.id}/${taskID}`, values);
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList, location?.id]
  );

  const deleteManualTodoTask = useCallback(
    async (taskID) => {
      try {
        const res = await axios.delete(`/info/additional_task/${location?.id}/${taskID}`);
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList, location?.id]
  );

  const deleteSystemTodoTask = useCallback(
    async (taskID) => {
      try {
        const res = await axios.delete(`/info/to_do/${location?.id}/${taskID}`);
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList, location?.id]
  );

  const moveManualTodoTask = useCallback(
    async (taskID, status) => {
      try {
        const res = await axios.post(`/info/additional_task/change_status/${taskID}`, { status });
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList]
  );

  const moveSystemTodoTask = useCallback(
    async (taskID, status) => {
      try {
        const res = await axios.post(`/info/to_do/change_status/${taskID}`, { status });
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList]
  );

  const deleteMultiTodoTask = useCallback(
    async (ids) => {
      try {
        const res = await axios.post(`/info/additional_task/multi_delete/${location?.id}`, { ids });
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList, location?.id]
  );

  const deleteMultiTodoTaskForPast = useCallback(
    async (ids) => {
      try {
        const res = await axios.post(`/info/to_do/multi_delete/${location?.id}`, { ids });
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList, location?.id]
  );

  const moveMultiTodoTask = useCallback(
    async (ids, status) => {
      try {
        const res = await axios.post(`/info/to_do/multi_status/${location?.id}`, { ids, status });
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList, location?.id]
  );

  const updateMultiReminder = useCallback(
    async (reminder, ids) => {
      try {
        const res = await axios.post(`/info/additional_task/multi_reminder/${location?.id}`, { reminder, ids });
        setTodoList(res?.data);
        return { status: true, data: '' };
      } catch (err) {
        return { status: false, data: err?.message };
      }
    },
    [setTodoList, location?.id]
  );

  return (
    <TodoContext.Provider
      value={{
        ...state,
        setTodoList,
        setTodoToEdit,
        setTodoStatus,
        createTodoTask,
        updateTodoTask,
        deleteManualTodoTask,
        deleteSystemTodoTask,
        moveManualTodoTask,
        moveSystemTodoTask,
        deleteMultiTodoTask,
        deleteMultiTodoTaskForPast,
        moveMultiTodoTask,
        updateMultiReminder
      }}
    >
      {children}
      <Modal open={openErrorAlert}>
        <AlertCard title={errTitle} sx={{ width: { xs: '90%', md: '640px' } }}>
          <CardContent>
            <Typography gutterBottom sx={theme.typography.p2}>
              {errMsg}
            </Typography>
          </CardContent>
          <Stack direction="row" spacing={3} justifyContent="center" sx={{ p: 2.5 }}>
            <Button variant="contained" size="large" onClick={() => navigate('/my_khora')}>
              Yes
            </Button>
          </Stack>
        </AlertCard>
      </Modal>
    </TodoContext.Provider>
  );
};

TodoProvider.propTypes = {
  children: PropTypes.node
};

export default TodoContext;
