// third-party
import { FormattedMessage } from 'react-intl';
// icons
import FAQIcon from 'assets/icons/faqs/FAQ.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const faqs = {
  id: 'group-faqs',
  type: 'group',
  children: [
    {
      id: 'faqs',
      title: <FormattedMessage id="FAQ" />,
      type: 'item',
      url: '/faqs/sprout',
      icon: FAQIcon
    }
  ]
};
const faqs_ = {
  id: 'group-faqs',
  type: 'group',
  children: [
    {
      id: 'faqs',
      title: <FormattedMessage id="FAQ" />,
      type: 'collapse',
      icon: FAQIcon,
      children: [
        {
          id: 'faqs_',
          title: <FormattedMessage id="FAQ" />,
          type: 'item',
          url: '/faqs/sprout'
        }
      ]
    }
  ]
};

export { faqs, faqs_ };
