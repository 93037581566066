import LandingJourneySection from 'sections/landing/LandingJourney';
import { Helmet } from 'react-helmet-async';

const LandingJourney = () => {
  return (
    <>
      <Helmet title="Our Journey - Khôra Home" />
      <LandingJourneySection />
    </>
  );
};
export default LandingJourney;
