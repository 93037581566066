import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection4 = () => {
  const theme = useTheme();
  const plantData = [
    {
      title: 'Leafy Vegetables',
      description:
        'Enjoy the convenience of harvesting your own crisp lettuce, spinach, kale, and other leafy greens for salads, sandwiches, and cooking.',
      image: '/assets/images/faq/khorahome_2.png'
    },
    {
      title: 'Herbs',
      description:
        "Elevate your culinary creations with an array of aromatic herbs such as basil, mint, cilantro, parsley, and more, all freshly grown within arm's reach.",
      image: '/assets/images/faq/khorahome_3.png'
    },
    {
      title: 'Microgreens',
      description:
        'Delight in the vibrant colors and flavors of nutrient-packed microgreens, perfect for garnishing dishes or adding a burst of freshness to salads and smoothies.',
      image: '/assets/images/faq/khorahome_4.png'
    },
    {
      title: 'Mushrooms',
      description:
        'Explore the world of gourmet mushrooms by cultivating varieties like oyster, shiitake, and button mushrooms, adding a unique touch to your culinary repertoire.',
      image: '/assets/images/faq/khorahome_5.png'
    },
    {
      title: 'Cuttings',
      description:
        'Beyond traditional plants, Khôra Home supports the growth of various cuttings, enabling you to experiment with different species and expand your indoor garden collection.',
      image: '/assets/images/faq/khorahome_6.png'
    }
  ];
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">What can be grown in a Khôra Home?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Typography variant="p2">
            Khôra Home provides a versatile platform for cultivating a wide range of fresh produce right in the comfort of your home or
            business. Here are some examples of what you can grow:
          </Typography>
          <Box>
            <Grid container spacing={2.5}>
              {plantData?.map((item, idx) => {
                return (
                  <Grid key={`plant_info_${idx}`} item xs={12} md={6}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      spacing={1.25}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <img src={item?.image} alt={item?.title} style={{ width: '100px', height: 'auto' }} />
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                          {item?.title}
                        </Typography>
                        <Typography variant="p2">{item?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQKhoraHomeSection4;
