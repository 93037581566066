import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, Button, Fade, Grow, Slide, IconButton, AlertTitle } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// project import
import { closeSnackbar } from 'store/reducers/snackbar';

// assets
import { CloseOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';

// animation function
function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

// animation options
const animation = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const snackbar = useSelector((state) => state.snackbar);
  const { actionButton, anchorOrigin, alert, message, open, transition, variant } = snackbar;

  const isSystemError = message.startsWith('---');
  const msg = isSystemError ? message.slice(3) : message;
  const color = isSystemError ? 'error' : alert.color === 'error' ? 'darkblue' : alert.color;
  const label = isSystemError ? 'Error' : alert.color === 'error' ? 'Oops' : color.charAt(0).toUpperCase() + color.slice(1);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <>
      {/* default snackbar */}
      {variant === 'default' && (
        <MuiSnackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={msg}
          TransitionComponent={animation[transition]}
          action={
            <>
              <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                <CloseOutlined />
              </IconButton>
            </>
          }
        />
      )}

      {/* alert snackbar */}
      {variant === 'alert' && (
        <MuiSnackbar
          TransitionComponent={animation[transition]}
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            variant={alert.variant}
            color={color}
            severity={color === 'darkblue' ? 'warning' : color}
            iconMapping={{
              success: (
                <CheckCircleOutlineIcon
                  style={{
                    color: theme.palette.grey[25],
                    fontSize: '1.25rem'
                  }}
                />
              ),
              error: (
                <CancelIcon
                  style={{
                    color: theme.palette.grey[25],
                    fontSize: '1.25rem'
                  }}
                />
              ),
              warning: (
                <WarningIcon
                  style={{
                    color: theme.palette.grey[25],
                    fontSize: '1.25rem'
                  }}
                />
              )
            }}
            action={
              <>
                {actionButton !== false && (
                  <Button color={color} size="small" onClick={handleClose}>
                    UNDO
                  </Button>
                )}
                <IconButton aria-label="close" variant="contained" onClick={handleClose} sx={{ p: 0 }}>
                  <CloseOutlined
                    style={{
                      color: theme.palette.grey[50],
                      fontSize: '1.25rem'
                    }}
                  />
                </IconButton>
              </>
            }
            sx={{
              ...(alert.variant === 'outlined' && {
                bgcolor: 'grey.0'
              }),
              ...(color === 'darkblue' && {
                bgcolor: (theme) => theme.palette.darkblue[200]
              })
            }}
          >
            <AlertTitle sx={{ fontSize: '1rem' }}>
              <b>{label}</b>
            </AlertTitle>
            {msg}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  );
};

export default Snackbar;
