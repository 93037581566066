import PropTypes from 'prop-types';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// material UI
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, IconButton, useMediaQuery, Popper, Paper, ClickAwayListener, AppBar, Typography } from '@mui/material';
// Icon
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { AppsOutlined } from '@mui/icons-material';
// third party
import Profile from './Profile';
import Notification from './Notification';
import Network from './Network';
// third part
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';
import { APP_DEFAULT_PATH } from 'config';

const LogoImage = '/assets/images/khora_logo_final.svg';
const SproutImage = '/assets/images/sprout_logo.svg';
const MarketImage = '/assets/images/logo_market.svg';

const CommonHeader = ({ networkShow = false, redirectURL = APP_DEFAULT_PATH, notificationShow = true }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchWidth = useMediaQuery('(min-width: 1024px)');
  const iconButtonStyle = { '&:hover': { backgroundColor: 'transparent' } };
  const iconStyle = { color: theme.palette.grey[900] };

  const profile = useMemo(() => <Profile />, []);
  const notification = useMemo(() => <Notification />, []);
  const network = useMemo(() => <Network />, []);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (matchWidth) setOpen(false);
  }, [matchWidth]);

  const anchorRef = useRef(null);
  const [logoOpen, setLogoOpen] = useState(false);
  const toggle = () => {
    setLogoOpen((prevOpen) => !prevOpen);
  };

  const close = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setLogoOpen(false);
  };

  return (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={1.25}>
            <IconButton ref={anchorRef} onClick={toggle}>
              <AppsOutlined sx={{ color: theme.palette.darkgreen[500], fontSize: '2.5rem' }} />
            </IconButton>
            {redirectURL === '#' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <img src={SproutImage} alt="logo" />
              </Box>
            ) : (
              <Box onClick={() => navigate(redirectURL)} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <img src={SproutImage} alt="logo" />
              </Box>
            )}
          </Stack>
        </Grid>
        <Grid item xs></Grid>
        <Grid item>
          <Stack spacing={!matchWidth ? 1.5 : 2.5} direction="row" justifyContent="center">
            {!matchWidth && networkShow ? (
              <>
                {notificationShow && notification}
                <IconButton onClick={() => setOpen(!open)} sx={iconButtonStyle}>
                  {open ? <CloseOutlinedIcon sx={iconStyle} /> : <MenuOutlinedIcon sx={iconStyle} />}
                </IconButton>
              </>
            ) : (
              <>
                {networkShow ? network : null}
                {notificationShow && notification}
                {profile}
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Popper placement="bottom-end" open={open} anchorEl={null} transition disablePortal>
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <AppBar
                  sx={{
                    marginTop: '71px',
                    boxShadow: theme.customShadows.z1,
                    backgroundColor: theme.palette.common.white
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ p: '16px 30px' }}>
                    {networkShow ? network : null}
                    {profile}
                  </Box>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <Popper
        placement="bottom-start"
        open={logoOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={logoOpen} {...TransitionProps}>
            {logoOpen && (
              <Paper
                sx={{
                  border: `solid 1px ${theme.palette.grey[300]}`,
                  boxShadow: theme.customShadows.z1
                }}
              >
                <ClickAwayListener onClickAway={close}>
                  <MainCard elevation={0} border={false}>
                    <Stack spacing={2} alignItems="flex-start">
                      <Typography color="secondary">Switch to</Typography>
                      <Link to={process.env['REACT_APP_SPROUT_URL']} onClick={close}>
                        <img src={LogoImage} alt="home logo" style={{ height: '20px', width: 'auto', cursor: 'pointer' }} />
                      </Link>
                      <Link to={process.env['REACT_APP_MARKETPLACE_URL']}>
                        <img src={MarketImage} alt="market logo" style={{ height: '19.5px', width: 'auto', cursor: 'pointer' }} />
                      </Link>
                    </Stack>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </>
  );
};

CommonHeader.propTypes = {
  networkShow: PropTypes.bool,
  notificationShow: PropTypes.bool,
  redirectURL: PropTypes.string
};

export default CommonHeader;
