import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Grid,
  Button,
  IconButton,
  AppBar,
  ClickAwayListener,
  Paper,
  Popper,
  Toolbar,
  Stack,
  useMediaQuery,
  Divider,
  Typography
} from '@mui/material';
// icon
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';
// third part
import { useDispatch, useSelector } from 'react-redux';
import { handleClose, handleToggle } from 'store/reducers/landingMenu';

import { handleScrollUp } from 'utils/common';
import { AppsOutlined } from '@mui/icons-material';

const LogoImage = '/assets/images/khora_logo_final.svg';
const SproutImage = '/assets/images/sprout_logo.svg';
const MarketImage = '/assets/images/logo_market.svg';
// ==============================|| LANDING - HEADER ||============================== //

const LandingHeader = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const { menuOpen } = useSelector((state) => state.landingMenu);

  const linkStyle = {
    ...theme.typography.p2m,
    textDecoration: 'none',
    color: theme.palette.common.black
  };

  useEffect(() => {
    if (!matchesMD) dispatch(handleClose());
  }, [matchesMD, dispatch]);

  const anchorRef = useRef(null);
  const [logoOpen, setLogoOpen] = useState(false);
  const toggle = () => {
    setLogoOpen((prevOpen) => !prevOpen);
  };

  const close = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setLogoOpen(false);
  };

  return (
    <Box
      sx={{
        maxWidth: 1440,
        py: '21px',
        px: '20px',
        width: '100%'
      }}
    >
      <Grid container sx={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={1.25}>
            <IconButton ref={anchorRef} onClick={toggle}>
              <AppsOutlined sx={{ color: theme.palette.darkgreen[500], fontSize: '2.5rem' }} />
            </IconButton>
            <Link to="/" style={linkStyle} onClick={handleScrollUp}>
              <img src={LogoImage} alt="logo" style={{ height: '24px', width: 'auto' }} />
            </Link>
          </Stack>
        </Grid>
        {!matchesMD ? (
          <>
            <Grid item xs>
              <Stack direction="row" spacing={3} justifyContent="center" sx={{ width: '100%' }}>
                <Link to="/faqs/khora_home" style={linkStyle} onClick={handleScrollUp}>
                  FAQ
                </Link>
                <Link to="/about" style={linkStyle} onClick={handleScrollUp}>
                  About Us
                </Link>
                <Link to="/community" style={linkStyle} onClick={handleScrollUp}>
                  Our Khôra Community
                </Link>
                <Link to="/mascot" style={linkStyle} onClick={handleScrollUp}>
                  Our Khôra Mascots
                </Link>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="center" spacing={2.5}>
                <Link
                  to="/login"
                  style={{
                    ...theme.typography.p3m,
                    textDecoration: 'none',
                    backgroundColor: theme.palette.darkgreen[300],
                    color: theme.palette.common.white,
                    padding: '11px 24px',
                    border: `solid 1px ${theme.palette.darkgreen[300]}`,
                    borderRadius: '100px'
                  }}
                >
                  Sprout - The Grower{"'"}s Platform
                </Link>
                <Button variant="outlined" onClick={() => window.open(process.env['REACT_APP_MARKETPLACE_URL'], '_blank')}>
                  Khôra Marketplace
                </Button>
              </Stack>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs></Grid>
            <Grid item>
              <IconButton onClick={() => dispatch(handleToggle())} sx={{ height: 50, width: 50 }}>
                {menuOpen ? <CloseOutlined style={{ fontSize: '20px' }} /> : <MenuOutlined style={{ fontSize: '20px' }} />}
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
      <Popper placement="bottom-end" open={menuOpen} anchorEl={null} transition disablePortal>
        {({ TransitionProps }) => (
          <Transitions type="fade" in={menuOpen} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => dispatch(handleClose())}>
                <AppBar
                  color="inherit"
                  sx={{
                    marginTop: '86px',
                    height: `calc(100vh - 86px)`,
                    minHeight: 280,
                    overflow: 'auto',
                    backgroundColor: theme.palette.common.white,
                    boxShadow: 'none'
                  }}
                >
                  <Toolbar sx={{ p: 2, height: '100%' }}>
                    <Stack
                      direction="column"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: '100%', height: '100%' }}
                    >
                      <Stack direction="column" alignItems="center" spacing={5} sx={{ width: '100%' }}>
                        <Link
                          to="/faqs/khora_home"
                          style={linkStyle}
                          onClick={() => {
                            handleScrollUp();
                            dispatch(handleClose());
                          }}
                        >
                          FAQ
                        </Link>
                        <Link
                          to="/about"
                          style={linkStyle}
                          onClick={() => {
                            handleScrollUp();
                            dispatch(handleClose());
                          }}
                        >
                          About Us
                        </Link>
                        <Link
                          to="/community"
                          style={linkStyle}
                          onClick={() => {
                            handleScrollUp();
                            dispatch(handleClose());
                          }}
                        >
                          Our Khôra Community
                        </Link>
                        <Link
                          to="/mascot"
                          style={linkStyle}
                          onClick={() => {
                            handleScrollUp();
                            dispatch(handleClose());
                          }}
                        >
                          Our Khôra Mascots
                        </Link>
                      </Stack>
                      <Stack direction="column" spacing={2} alignItems="center" sx={{ width: { xs: '100%', sm: '50%' }, pb: 3 }}>
                        <Divider sx={{ borderColor: theme.palette.primary[300], mb: 3, width: '100%' }} />
                        <Link
                          to="/login"
                          style={{
                            ...theme.typography.p3m,
                            textDecoration: 'none',
                            backgroundColor: theme.palette.darkgreen[300],
                            color: theme.palette.common.white,
                            padding: '11px 24px',
                            border: `solid 1px ${theme.palette.darkgreen[300]}`,
                            borderRadius: '100px',
                            width: '100%',
                            textAlign: 'center'
                          }}
                        >
                          Sprout - The Grower{"'"}s Platform
                        </Link>
                        <Button variant="outlined" onClick={() => window.open(process.env['REACT_APP_MARKETPLACE_URL'], '_blank')}>
                          Khôra Marketplace
                        </Button>
                      </Stack>
                    </Stack>
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <Popper
        placement="bottom-start"
        open={logoOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={logoOpen} {...TransitionProps}>
            {logoOpen && (
              <Paper
                sx={{
                  border: `solid 1px ${theme.palette.grey[300]}`,
                  boxShadow: theme.customShadows.z1
                }}
              >
                <ClickAwayListener onClickAway={close}>
                  <MainCard elevation={0} border={false}>
                    <Stack spacing={2} alignItems="flex-start">
                      <Typography color="secondary">Switch to</Typography>
                      <Link to={'/dashboard'}>
                        <img src={SproutImage} alt="home logo" style={{ height: '34px', width: 'auto', cursor: 'pointer' }} />
                      </Link>
                      <Link to={process.env['REACT_APP_MARKETPLACE_URL']}>
                        <img src={MarketImage} alt="market logo" style={{ height: '19.5px', width: 'auto', cursor: 'pointer' }} />
                      </Link>
                    </Stack>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};
export default LandingHeader;
