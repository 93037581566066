// react
import { useState, useEffect } from 'react';
import { Box, Stack, Typography, Grid, useTheme, IconButton, Collapse } from '@mui/material';
import NoIcon from '@mui/icons-material/CloseOutlined';
import LimitedIcon from '@mui/icons-material/RemoveOutlined';
import FullIcon from '@mui/icons-material/CheckOutlined';
import ExpandIcon from '@mui/icons-material/ExpandMoreOutlined';
import CollapseIcon from '@mui/icons-material/ExpandLessOutlined';

import { MembershipData, MembershipDetail, MembershipLevel } from 'utils/constants/membership';

const LandingMemebership = () => {
  const theme = useTheme();

  const [expandStatus, setExpandStatus] = useState(null);
  const [hoverMembership, setHoverMembership] = useState(null);

  const lastIndex = Object.keys(MembershipData)?.length - 1;

  const titleStyle = {
    fontSize: { xs: '32px', md: '42px' },
    lineHeight: { xs: '44px', md: '60px' },
    fontFamily: 'Poppins',
    fontWeight: 400
  };

  const getMembershipLevelIcon = (level) => {
    switch (level) {
      case MembershipLevel.Full:
        return <FullIcon style={{ color: theme.palette.darkgreen[400] }} />;
      case MembershipLevel.Limited:
        return <LimitedIcon style={{ color: theme.palette.grey[500] }} />;
      case MembershipLevel.No:
      default:
        return <NoIcon style={{ color: theme.palette.error[600] }} />;
    }
  };

  useEffect(() => {
    let result = {};
    const getExpandStatus = (data) => {
      data
        ?.filter((item) => item?.children?.length > 0)
        ?.map((item) => {
          result = { ...result, [item?.title]: false };
          if (item?.children?.length > 0) {
            getExpandStatus(item?.children);
          }
        });
    };
    getExpandStatus(MembershipData, 1);
    setExpandStatus(result);
  }, []);

  const getBorder = (title, key) => {
    return {
      borderTop: `solid 1px ${theme.palette.grey[300]}`,
      borderBottom:
        title === MembershipData?.[lastIndex]?.title
          ? key === hoverMembership
            ? `solid 1px ${theme.palette.info[300]}`
            : `solid 1px ${theme.palette.grey[300]}`
          : 'none',
      borderLeft: key === hoverMembership ? `solid 1px ${theme.palette.info[300]}` : `solid 1px ${theme.palette.grey[300]}`,
      borderRight: key === hoverMembership ? `solid 1px ${theme.palette.info[300]}` : `solid 1px ${theme.palette.grey[300]}`
    };
  };

  const getBackground = (idx, deep, key) => {
    switch (deep) {
      case 1:
        return {
          backgroundColor: hoverMembership === key ? '#F4FAFF' : idx % 2 === 1 ? theme.palette.warning[25] : theme.palette.warning[50]
        };
      case 2:
        return {
          backgroundColor: hoverMembership === key ? '#F4FAFF' : idx % 2 === 1 ? theme.palette.error[25] : theme.palette.error[50]
        };
      default:
      case 0:
        return {
          backgroundColor: hoverMembership === key ? '#F4FAFF' : idx % 2 === 1 ? theme.palette.common.white : theme.palette.grey[50]
        };
    }
  };

  const getRightContent = (data, idx, deep) => {
    const values = data?.value;
    return (
      <>
        {Object.keys(MembershipDetail)?.map((key) => {
          return (
            <Grid
              key={`membership_data_${idx}_${key}`}
              item
              xs={1.5}
              onMouseEnter={() => setHoverMembership(key)}
              onMouseLeave={() => setHoverMembership(null)}
              sx={{ cursor: 'pointer' }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '100%',
                  py: 0.75,
                  ...getBackground(idx, deep, key),
                  ...getBorder(data?.title, key)
                }}
              >
                {getMembershipLevelIcon(values?.[key])}
              </Stack>
            </Grid>
          );
        })}
      </>
    );
  };

  const getLeftContent = (data, idx, deep) => {
    return (
      <Grid item xs={3}>
        <Stack
          justifyContent="center"
          sx={{
            height: '100%',
            ...getBackground(idx, deep),
            ...getBorder(data?.title)
          }}
        >
          <Stack justifyContent="center" sx={{ py: 0.75 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ ml: 2.5 * deep }}>
              <Typography variant="p3m" sx={{ ml: 0.75 }}>
                {data?.title}
              </Typography>
              {data?.children?.length > 0 && (
                <IconButton
                  onClick={() => {
                    setExpandStatus((prev) => {
                      return { ...prev, [data?.title]: !prev?.[data?.title] };
                    });
                  }}
                  sx={{ width: 24, height: 24, borderRadius: '50%', mr: 0.75 }}
                >
                  {expandStatus?.[data?.title] ? <CollapseIcon /> : <ExpandIcon />}
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    );
  };

  const getContent = (content, deep) => {
    return content?.map((data, idx) => {
      return (
        <>
          {getLeftContent(data, idx, deep)}
          {getRightContent(data, idx, deep)}
          <Grid item xs={12}>
            <Collapse in={expandStatus?.[data?.title]}>
              <Box>
                <Grid container columnSpacing={0.5}>
                  {getContent(data?.children, deep + 1)}
                </Grid>
              </Box>
            </Collapse>
          </Grid>
        </>
      );
    });
  };

  return (
    <>
      <Box sx={{ width: '100%', background: theme.palette.common.white }}>
        <Box
          sx={{
            backgroundImage: 'url("/assets/images/landing/background.jpg")',
            backgroundPosition: 'center, center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            py: { xs: '40px', md: '60px' }
          }}
        >
          <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', px: '20px', height: '100%' }}>
            <Stack spacing={3.75} justifyContent="center" sx={{ height: '100%' }}>
              <Typography sx={{ ...titleStyle, color: theme.palette.common.white }}>Sprout{"'"}s Memberships</Typography>
              <Typography sx={{ ...theme.typography.p2, color: theme.palette.common.white }}>
                Sprout{"'"}s Memberships have different levels based on their features. When joining the Khôra Home Community, the highest
                level of membership, Harvest, is provided for free during the trial period. Community members can change membership levels
                as many times as desired to find the right level.
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: { xs: '40px', md: '60px' } }}>
        <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', px: '20px' }}>
          <Grid container columnSpacing={0.5}>
            <Grid item xs={3}></Grid>
            {Object.keys(MembershipDetail)?.map((key) => {
              return (
                <Grid
                  key={`membership_header_price_${key}`}
                  item
                  xs={1.5}
                  onMouseEnter={() => setHoverMembership(key)}
                  onMouseLeave={() => setHoverMembership(null)}
                  sx={{ cursor: 'pointer', position: 'relative' }}
                >
                  <Box sx={{ height: '100%', position: 'relative', overflow: 'hidden' }}>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: '28px',
                        overflow: 'hidden',
                        borderTopRightRadius: '6px',
                        borderTopLeftRadius: '6px',
                        backgroundColor: MembershipDetail[key].tagColor
                      }}
                    >
                      <Typography variant="p3m" textAlign="center" sx={{ color: theme.palette.common.white, p: 0.5 }}>
                        {MembershipDetail[key].tag}
                      </Typography>
                    </Stack>
                    <Stack
                      alignItems="center"
                      sx={{
                        height: `calc(100% - 28px)`,
                        p: 0.5,
                        backgroundColor: hoverMembership === key ? '#F4FAFF' : theme.palette.grey[50],
                        border: key === hoverMembership ? `solid 1px ${theme.palette.info[300]}` : `solid 1px ${theme.palette.grey[300]}`,
                        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
                        borderTop: 'none'
                      }}
                    >
                      <Typography variant="h6" textAlign="center" sx={{ color: MembershipDetail[key].tagColor }}>
                        <b>{MembershipDetail[key]?.title}</b>
                      </Typography>
                      <Typography variant="l2" textAlign="center">
                        {MembershipDetail[key]?.description}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
              );
            })}
            <Grid item xs={3}></Grid>
            {Object.keys(MembershipDetail)?.map((key) => {
              return (
                <Grid
                  key={`membership_header_price_other_${key}`}
                  item
                  xs={1.5}
                  onMouseEnter={() => setHoverMembership(key)}
                  onMouseLeave={() => setHoverMembership(null)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Stack
                    alignItems="center"
                    sx={{
                      px: 0.5,
                      py: 1,
                      backgroundColor: hoverMembership === key ? '#F4FAFF' : theme.palette.common.white,
                      border: key === hoverMembership ? `solid 1px ${theme.palette.info[300]}` : `solid 1px ${theme.palette.grey[300]}`,
                      borderTop: 'none',
                      borderBottom: 'none'
                    }}
                  >
                    <Typography variant="p2m" textAlign="center">
                      <b>{`$${MembershipDetail[key].price}/Month`}</b>
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
          <Grid container columnSpacing={0.5}>
            {getContent(MembershipData, 0)}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default LandingMemebership;
