// third-party
import { FormattedMessage } from 'react-intl';
// icons
import ScheduleIcon from 'assets/icons/schedule/Schedule-v2.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const schedule_new = {
  id: 'group-schedule_new',
  type: 'group',
  children: [
    {
      id: 'schedule_new',
      title: <FormattedMessage id="Schedule" />,
      type: 'item',
      url: '/schedule',
      icon: ScheduleIcon
    }
  ]
};
const schedule_new_ = {
  id: 'group-schedule_new',
  type: 'group',
  children: [
    {
      id: 'schedule_new',
      title: <FormattedMessage id="Schedule" />,
      type: 'collapse',
      icon: ScheduleIcon,
      children: [
        {
          id: 'schedule_new_',
          title: <FormattedMessage id="Schedule" />,
          type: 'item',
          url: '/schedule'
        }
      ]
    }
  ]
};

export { schedule_new, schedule_new_ };
