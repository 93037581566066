import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

const CommunitySection1 = () => {
  const theme = useTheme();
  const liStyle = { ...theme.typography.p2, color: theme.palette.common.white };
  const checkStyle = { color: theme.palette.common.white, marginTop: '1px' };
  return (
    <Stack spacing={3.75}>
      <Box sx={{ pt: { xs: 0, md: 1.25 } }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.warning[50],
                width: '100%',
                height: '100%',
                minHeight: { xs: '400px', sm: '540px' },
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: { xs: '0px', md: '20px' },
                borderTopRightRadius: { xs: '20px', md: '0px' }
              }}
            >
              <img src="/assets/images/landing/community.png" alt="community" style={{ width: '90%', height: 'auto' }} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: theme.palette.info[400],
                p: { xs: 2.5, md: 5 },
                borderBottomRightRadius: '20px',
                borderTopRightRadius: { xs: '0px', md: '20px' },
                borderBottomLeftRadius: { xs: '20px', md: '0px' }
              }}
            >
              <Stack spacing={2}>
                <Stack direction="row" alignItems="flex-start" spacing={1.25}>
                  <CheckCircleOutline style={checkStyle} />
                  <Typography sx={liStyle}>We care about our planet.</Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-start" spacing={1.25}>
                  <CheckCircleOutline style={checkStyle} />
                  <Typography sx={liStyle}>We ensure a promising future for the next generations.</Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-start" spacing={1.25}>
                  <CheckCircleOutline style={checkStyle} />
                  <Typography sx={liStyle}>
                    We are an active part of a sustainable movement focused on growing exceptional food for improved health and wellbeing.
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-start" spacing={1.25}>
                  <CheckCircleOutline style={checkStyle} />
                  <Typography sx={liStyle}>
                    We prioritize having safe, nutritious, and available food independent from global food supply chains.
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-start" spacing={1.25}>
                  <CheckCircleOutline style={checkStyle} />
                  <Typography sx={liStyle}>
                    We grow to recapture the rich, memorable flavors and textures of our past and enjoy delicious and soul-nourishing meals.
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-start" spacing={1.25}>
                  <CheckCircleOutline style={checkStyle} />
                  <Typography sx={liStyle}>We are creative, and ingenious, and grow plants unbound by origin or season.</Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-start" spacing={1.25}>
                  <CheckCircleOutline style={checkStyle} />
                  <Typography sx={liStyle}>
                    We are a committed network of consumers and growers who want exceptional food safety and quality.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
export default CommunitySection1;
