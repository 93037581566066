import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQKhoraHomeSection3 = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">Is Khôra Home based on hydroponic technology?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack alignItems="flex-start" spacing={2.5}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={8}>
              <Stack justifyContent="center" spacing={1.25} sx={{ height: '100%' }}>
                <Typography variant="p2">
                  No, Khôra Home does not rely on hydroponic technology. Instead, it utilizes soil-based technology, harnessing the
                  beneficial microbial life within the soil.
                </Typography>
                <Typography variant="p2">
                  These microorganisms maintain a symbiotic relationship with the plant, offering soluble nutrients that enrich the flavor,
                  texture, and aroma of the plants grown in Khôra Home.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  minHeight: 180,
                  height: '100%',
                  width: 'auto',
                  backgroundImage: 'url("/assets/images/faq/khorahome_1.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQKhoraHomeSection3;
