// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';
import SearchIcon from 'assets/icons/search/search.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const search = {
  id: 'group-search',
  type: 'group',
  children: [
    {
      id: 'search',
      title: <FormattedMessage id="Searches" />,
      type: 'item',
      icon: SearchIcon,
      url: '/search'
    }
  ]
};

const search_ = {
  id: 'group-search',
  type: 'group',
  children: [
    {
      id: 'search',
      title: <FormattedMessage id="Searches" />,
      type: 'collapse',
      icon: SearchIcon,
      children: [
        {
          id: 'search_',
          title: <FormattedMessage id="Searches" />,
          type: 'item',
          url: '/search'
        }
      ]
    }
  ]
};
export { search, search_ };
