// third-party
import { FormattedMessage } from 'react-intl';
// icons
import CustomerIcon from 'assets/icons/customer_management/customer.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const customer_management = {
  id: 'group-customer_management',
  type: 'group',
  children: [
    {
      id: 'customer_management',
      title: <FormattedMessage id="Customer Management" />,
      type: 'item',
      url: '/customer',
      icon: CustomerIcon
    }
  ]
};
const customer_management_ = {
  id: 'group-customer_management',
  type: 'group',
  children: [
    {
      id: 'customer_management',
      title: <FormattedMessage id="Customer Management" />,
      type: 'collapse',
      icon: CustomerIcon,
      children: [
        {
          id: 'customer_management_',
          title: <FormattedMessage id="Customer Management" />,
          type: 'item',
          url: '/customer'
        }
      ]
    }
  ]
};

export { customer_management, customer_management_ };
