import LandingMascotSection from 'sections/landing/LandingMascot';
import { Helmet } from 'react-helmet-async';

const LandingMascot = () => {
  return (
    <>
      <Helmet title="Mascots - Khôra Home" />
      <LandingMascotSection />
    </>
  );
};
export default LandingMascot;
