import { Outlet } from 'react-router-dom';
import LandingLayout from 'layout/LandingLayout';

import LandingHome from 'pages/landing/home';
import LandingAbout from 'pages/landing/about';
import LandingContact from 'pages/landing/contact';
import LandingFAQKhoraHome from 'pages/landing/faq/khorahome';
import LandingFAQSprout from 'pages/landing/faq/sprout';
import LandingFAQMarketplace from 'pages/landing/faq/marketplace';
import LandingFAQCosts from 'pages/landing/faq/costs';
import LandingMascot from 'pages/landing/mascot';
import LandingSellers from 'pages/landing/sellers';
import LandingMoreInfo from 'pages/landing/community';
import LandingMemebership from 'pages/landing/membership';
import LandingJourney from 'pages/landing/journey';
import TopNewsPage from 'pages/news/top';
import AllNewsPage from 'pages/news/all';
import ViewNewsPage from 'pages/news/view';
import LocalParticipatingPage from 'pages/local_participating';

// ==============================|| MAIN ROUTING ||============================== //

const LandingRoutes = {
  path: '',
  element: (
    <LandingLayout>
      <Outlet />
    </LandingLayout>
  ),
  children: [
    {
      path: '',
      element: <LandingHome />
    },
    {
      path: 'about',
      element: <LandingAbout />
    },
    {
      path: 'contact',
      element: <LandingContact />
    },
    {
      path: 'faqs/khora_home',
      element: <LandingFAQKhoraHome />
    },
    {
      path: 'faqs/sprout',
      element: <LandingFAQSprout />
    },
    {
      path: 'faqs/marketplace',
      element: <LandingFAQMarketplace />
    },
    {
      path: 'faqs/marketplace/become_seller',
      element: <LandingFAQMarketplace expand={'become_seller'} />
    },
    {
      path: 'faqs/marketplace/fee_structure',
      element: <LandingFAQMarketplace expand={'fee_structure'} />
    },
    {
      path: 'faqs/costs',
      element: <LandingFAQCosts />
    },
    {
      path: 'mascot',
      element: <LandingMascot />
    },
    {
      path: 'sellers',
      element: <LandingSellers />
    },
    {
      path: 'community',
      element: <LandingMoreInfo />
    },
    {
      path: 'membership',
      element: <LandingMemebership />
    },
    {
      path: 'journey',
      element: <LandingJourney />
    },
    {
      path: 'news/top',
      element: <TopNewsPage />
    },
    {
      path: 'news/all',
      element: <AllNewsPage />
    },
    {
      path: 'news/view/:newsID',
      element: <ViewNewsPage />
    },
    {
      path: 'local_participation',
      element: <LocalParticipatingPage />
    }
  ]
};

export default LandingRoutes;
