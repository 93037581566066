import { useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Modal, IconButton, Stack } from '@mui/material';
import VideoCard from 'components/VideoCard';
import SpotItem from 'components/SpotItem';
import ReactPlayer from 'react-player';
import { CloseOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection10 = () => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const handleVideoModalOpen = () => setOpenVideoModal(true);
  const handleVideoModalClose = () => setOpenVideoModal(false);
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">Do you have a video to demonstrate Plant Placement with repeat and continuous growing?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3.75} direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'center', sm: 'flex-start' }}>
            <Stack justifyContent="space-around" spacing={1.25}>
              <Typography variant="p2">
                We sure do! Once you{"'"}ve finalized a personalized Crop Plan, Sprout provides a Plant Shopping List to purchase from Khôra
                Marketplace or local retailers. When scheduling your Crop Plan, follow Sprout{"'"}s Plant Placement instructions and confirm
                all of the plants have been placed inside Khôra Home. Watch the video that highlights the following:
              </Typography>
              <Stack sx={{ pl: 2 }}>
                <SpotItem variant="p2" text={'Plant placement in trays'} />
                <SpotItem variant="p2" text={'Harvest largest plants first'} />
                <SpotItem variant="p2" text={'Harvest in checkerboard pattern'} />
                <SpotItem variant="p2" text={'Make more space to allow plants to grow bigger'} />
                <SpotItem variant="p2" text={'Use buffered space if harvesting slower than scheduled times or going on vacation'} />
                <SpotItem variant="p2" text={'Restocking'} />
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3.75}>
              <Stack
                alignItems="center"
                justifyContent="center"
                onClick={handleVideoModalOpen}
                sx={{
                  borderRadius: '10px',
                  cursor: 'pointer',
                  backgroundImage: 'url("/assets/video/plant_placement.png")',
                  backgroundPosition: 'center, center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: 220,
                  height: 160
                }}
              >
                <img src="/assets/images/landing/demobtn.svg" alt="demo button" />
              </Stack>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Modal open={openVideoModal} onClose={handleVideoModalClose}>
        <VideoCard
          className="video-size-landing"
          title="How to Place Plants"
          secondary={
            <IconButton onClick={handleVideoModalClose} sx={{ borderRadius: '100px' }}>
              <CloseOutlined />
            </IconButton>
          }
        >
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  controls: true,
                  autoPlay: true,
                  muted: false
                }
              }
            }}
            url={'/assets/video/plant_placement.mp4'}
            width={'100%'}
            height={'100%'}
          />
        </VideoCard>
      </Modal>
    </>
  );
};
export default FAQSproutSection10;
