import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import { openDrawer } from 'store/reducers/menu';
import { DRAWER_WIDTH, MAIN_HEADER_HEIGHT } from 'config';
// assets
import { IconButton } from '@mui/material';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ layout = true, networkShow = true }) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG, dispatch]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen, open]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header networkShow={networkShow} />
      {layout && <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />}
      {layout ? (
        <Box
          component="main"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            marginTop: `${MAIN_HEADER_HEIGHT}px`,
            flexGrow: 1,
            backgroundColor: theme.palette.grey[50]
          }}
        >
          <Box
            sx={{
              minHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT}px)`,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButton
              size="small"
              onClick={handleDrawerToggle}
              sx={{
                position: 'sticky',
                marginLeft: { xs: '14px', md: '-18px' },
                marginTop: '-16px',
                top: MAIN_HEADER_HEIGHT + 23,
                p: '4px',
                fontSize: '16px',
                borderRadius: '100px',
                zIndex: theme.zIndex.drawer,
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                border: `solid 1px ${theme.palette.grey[100]}`,
                backgroundColor: theme.palette.common.white,
                '&:hover': {
                  backgroundColor: theme.palette.grey[100]
                }
              }}
            >
              {open ? <LeftOutlined /> : <RightOutlined />}
            </IconButton>
            <Box sx={{ mt: '-14px' }}>
              <Box sx={{ maxWidth: '1170px', margin: 'auto', px: 3.75 }}>
                <Outlet />
              </Box>
            </Box>
            <Footer />
          </Box>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            width: '100%',
            minHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT}px)`,
            marginTop: `${MAIN_HEADER_HEIGHT}px`,
            flexGrow: 1,
            backgroundColor: theme.palette.grey[50]
          }}
        >
          <Box sx={{ maxWidth: '1170px', margin: 'auto', px: 3.75 }}>
            <Outlet />
          </Box>
        </Box>
      )}
    </Box>
  );
};
MainLayout.propTypes = {
  layout: PropTypes.bool,
  networkShow: PropTypes.bool
};
export default MainLayout;
