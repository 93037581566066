import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, OutlinedInput, Typography, FormHelperText, Select, MenuItem } from '@mui/material';
import RightIcon from '@mui/icons-material/East';
// reducer
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';

import * as Yup from 'yup';
import { Formik } from 'formik';
import FocusError from 'components/FocusError';

import axios from 'utils/axios';
import { normalizeString } from 'utils/common';
import { Regions } from 'utils/constants/auth';

const LandingContactSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const textStyle = {
    '&.MuiOutlinedInput-root': { backgroundColor: theme.palette.common.white, borderRadius: '5px' }
  };
  const typographyStyle = { ...theme.typography.p3, color: theme.palette.common.black, mb: 0.75 };

  return (
    <Box sx={{ width: '100%', py: { xs: '40px', md: '60px' }, backgroundColor: theme.palette.grey[50] }}>
      <Box sx={{ maxWidth: 1140, width: '100%', margin: 'auto', px: '20px', py: '20px' }}>
        <Grid container spacing={3.75}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4">Want to learn more about Khôra Home and Khôra Marketplace?</Typography>
            <Box sx={{ width: '100%', maxWidth: '500px', height: 'auto', margin: 'auto' }}>
              <img src={'/assets/images/landing/contact.png'} alt="contact" style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: '20px',
                backgroundColor: theme.palette.grey[100],
                p: { xs: 2.5, md: 3.75 }
              }}
            >
              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                  region: 'North America',
                  content: '',
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  first_name: Yup.string().max(255).required('First name is required'),
                  last_name: Yup.string().max(255).required('Last name is required'),
                  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  region: Yup.string().required('Region is required'),
                  content: Yup.string().max(2047).required('Message is required')
                })}
                onSubmit={async (values, { resetForm, setStatus, setSubmitting }) => {
                  setSubmitting(true);
                  try {
                    await axios.put('/info/contact', values);
                    setStatus({ success: true });
                    setSubmitting(false);
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: 'Success to send email.',
                        variant: 'alert',
                        alert: {
                          color: 'success'
                        }
                      })
                    );
                    resetForm();
                  } catch (err) {
                    setStatus({ success: false });
                    setSubmitting(false);
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: err?.message,
                        variant: 'alert',
                        alert: {
                          color: 'error'
                        }
                      })
                    );
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={typographyStyle}>First Name</Typography>
                        <OutlinedInput
                          id="first_name_contactus"
                          type="text"
                          value={values.first_name}
                          name="first_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          error={Boolean(touched.first_name && errors.first_name)}
                          sx={textStyle}
                        />
                        {touched.first_name && errors.first_name && (
                          <FormHelperText error id="standard-weight-helper-text-first_name_contactus">
                            {errors.first_name}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={typographyStyle}>Last Name</Typography>
                        <OutlinedInput
                          id="last_name_contactus"
                          type="text"
                          value={values.last_name}
                          name="last_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          error={Boolean(touched.last_name && errors.last_name)}
                          sx={textStyle}
                        />
                        {touched.last_name && errors.last_name && (
                          <FormHelperText error id="standard-weight-helper-text-last_name_contactus">
                            {errors.last_name}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={typographyStyle}>Email</Typography>
                        <OutlinedInput
                          id="email_contactus"
                          type="email"
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          error={Boolean(touched.email && errors.email)}
                          sx={textStyle}
                        />
                        {touched.email && errors.email && (
                          <FormHelperText error id="standard-weight-helper-text-email_contactus">
                            {errors.email}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={typographyStyle}>Region</Typography>
                        <Select
                          fullWidth
                          error={Boolean(touched.region && errors.region)}
                          id="region_contact"
                          value={values.region}
                          name="region"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 240, maxWidth: 300 } }
                          }}
                          sx={{ backgroundColor: theme.palette.common.white, borderRadius: '5px' }}
                        >
                          {Regions.sort((a, b) => {
                            if (normalizeString(a) > normalizeString(b)) return 1;
                            if (normalizeString(a) < normalizeString(b)) return -1;
                            return 0;
                          })?.map((item, idx) => (
                            <MenuItem key={'region_' + idx} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.region && errors.region && (
                          <FormHelperText error id="helper-text-region_register">
                            {errors.region}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={typographyStyle}>Message</Typography>
                        <OutlinedInput
                          multiline
                          rows={4}
                          id="content_contactus"
                          type="text"
                          value={values.content}
                          name="content"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          error={Boolean(touched.content && errors.content)}
                          sx={textStyle}
                        />
                        {touched.content && errors.content && (
                          <FormHelperText error id="standard-weight-helper-text-content_contactus">
                            {errors.content}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} textAlign="right">
                        <Button
                          variant="contained"
                          size="large"
                          type="submit"
                          endIcon={<RightIcon />}
                          disabled={isSubmitting}
                          sx={{ width: { xs: '100%', sm: 'inherit' } }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                    <FocusError />
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default LandingContactSection;
