// react
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// material UI
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// reducer
import { useDispatch } from 'react-redux';
import { setProgressStep } from 'store/reducers/setupProgress';
// sub pages
import SetupHeader from './SetupHeader';
import SetupProgressBar from './SetupProgressBar';
import SetupConfig from './SetupConfig';

import { SetupURL, SetupRedirectURL, SetupStep } from 'utils/constants/setup';

// config

const SetupLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const progressStep = SetupConfig[location.pathname].progressStep;
  const showProgress = SetupConfig[location.pathname].showProgress;

  const setupHeader = useMemo(() => <SetupHeader />, []);

  useEffect(() => {
    dispatch(setProgressStep({ progressStep }));
  }, [dispatch, progressStep]);

  return (
    <Box sx={{ width: '100%', backgroundColor: theme.palette.grey[50], pt: 12.5, pb: 3.75, minHeight: '100vh' }}>
      {setupHeader}
      <Box sx={{ width: '100%', margin: 'auto' }}>
        {showProgress ? (
          <Box sx={{ maxWidth: '1170px', margin: 'auto', px: 3.75 }}>
            <SetupProgressBar />
          </Box>
        ) : (
          <Box sx={{ maxWidth: '1170px', margin: 'auto', px: 3.75 }}></Box>
        )}
        <Box
          sx={{
            maxWidth: location.pathname === `/${SetupURL}/${SetupRedirectURL[SetupStep.UserSetupComplete]}` ? '100%' : '1170px',
            margin: 'auto',
            px: location.pathname === `/${SetupURL}/${SetupRedirectURL[SetupStep.UserSetupComplete]}` ? 0 : 3.75
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SetupLayout;
