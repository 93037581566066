import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Box, Chip } from '@mui/material';
import { formatOnlyDate } from 'utils/common';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';

const ChipColors = ['#CAFFD7', '#DCDAB0', '#FFC3C3', '#C0E6B6', '#FFEF8E', '#E1CEFF', '#DDDDDD', '#FF8CD9', '#E3F2FF', '#FFF3E5'];

const NewsStyle3 = ({ news }) => {
  const navigate = useNavigate();
  return (
    <Stack
      spacing={1}
      onClick={() => navigate(`/news/view/${news?.id}`)}
      sx={{
        p: 1,
        width: '100%',
        maxWidth: 360,
        borderRadius: '10px',
        cursor: 'pointer',
        backgroundColor: 'common.white',
        border: `solid 1px #eee`,
        transition: 'transform 250ms ease, box-shadow 250ms ease, color 250ms ease',
        '&:hover': {
          WebkitTransform: 'translateY(-0.25rem)',
          MsTransform: 'translateY(-0.25rem)',
          transform: 'translateY(-0.25rem)'
        }
      }}
    >
      <Box
        sx={{
          width: '100%',
          aspectRatio: '4 / 3',
          backgroundImage: `url('${news?.image ?? '/assets/images/kmg_image.png'}')`,
          backgroundPosition: 'center, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: '6px'
        }}
      />
      <Stack alignItems="flex-start" spacing={2} sx={{ p: 2 }}>
        <Chip
          variant="outlined"
          label={news?.category?.name}
          sx={{ borderRadius: '100px', backgroundColor: ChipColors[news?.category?.id % 10], border: 'none' }}
        />
        <Typography
          variant="h5"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            WebkitLineClamp: 4,
            height: 100
          }}
        >
          {news?.title}
        </Typography>
        <Typography
          sx={{
            color: '#667085',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            WebkitLineClamp: 4,
            height: 82
          }}
        >
          {news?.text}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} sx={{ width: '100%' }}>
          <Typography sx={{ color: '#98A2B3' }}>{formatOnlyDate(news?.publish_date, false)}</Typography>
          <Typography sx={{ color: '#98A2B3' }}>
            <b>・</b>
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TimeIcon sx={{ color: '#98A2B3' }} />
            <Typography sx={{ color: '#98A2B3' }}>{news?.time_to_read === 1 ? '1 min' : `${news?.time_to_read} mins`}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
NewsStyle3.propTypes = {
  news: PropTypes.any
};
export default NewsStyle3;
