import axios from 'axios';

const axiosServices = axios.create({
  baseURL: '/api/user/v1',
  headers: {
    'Content-Type': 'application/json'
  }
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = '/login';
      return Promise.reject((error?.response && error?.response?.data) || 'Wrong Services');
    }

    if (error?.response?.status >= 500) {
      const message = '---' + (error?.response && error?.response?.data?.message) || 'System error';
      return Promise.reject({ message });
    }

    return Promise.reject((error?.response && error?.response?.data) || 'Wrong Services');
  }
);

export default axiosServices;
