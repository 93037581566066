import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection1 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Centralized Management',
      description:
        'Sprout acts as a central hub for all growing activities. Users can access the platform through various devices such as computers, tablets, or smartphones, making it convenient to manage their network of Khôra Homes from anywhere.',
      image: '/assets/images/faq/sprout_3.png'
    },
    {
      title: 'Growing Plans',
      description:
        'The platform employs sophisticated algorithms that are based on established Growing Plans. These plans outline the optimal conditions and steps required for successful cultivation of various plants, considering factors like the seed, soil composition, lighting, humidity, and temperature.',
      image: '/assets/images/faq/sprout_4.png'
    },
    {
      title: 'Automated Monitoring',
      description:
        'Sprout continuously monitors the growing environment and the status of each plant using sensors and other monitoring devices. It collects data on factors such as moisture levels, nutrient levels, and overall plant health.',
      image: '/assets/images/faq/sprout_5.png'
    },
    {
      title: 'Alerts and Notifications',
      description:
        "Based on the data collected, Sprout provides real-time alerts and notifications to users regarding important growing steps. For example, it may remind users when it's time to transplant seedlings into larger containers, adjust lighting settings, or apply fertilizers.",
      image: '/assets/images/faq/sprout_6.png'
    },
    {
      title: 'Customization',
      description:
        'While Sprout offers predefined Growing Plans for various plants, users also have the flexibility to customize their growing parameters based on their specific needs and preferences. They can adjust settings such as watering schedules, lighting intensity, and nutrient levels to optimize their cultivation process.',
      image: '/assets/images/faq/sprout_7.png'
    },
    {
      title: 'Analytics and Insights',
      description:
        'Sprout generates detailed analytics and insights based on the data collected over time. Users can analyze trends, identify patterns, and make informed decisions to improve their growing techniques and maximize yields.',
      image: '/assets/images/faq/sprout_8.png'
    }
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">What is Sprout - The Grower{"'"}s Platform?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2.5}>
          <Typography variant="p2">
            Sprout - The Grower{"'"}s Platform is a comprehensive system developed by Khôra Home technologists to facilitate the cultivation
            process for a wide range of growers, from beginners to large-scale farmers. Its primary function is to provide a centralized
            platform where users can manage and monitor their growing operations with ease and collaborate with others on the platform. Here
            {"'"}s how it works:
          </Typography>
          <Box>
            <Grid container spacing={2.5}>
              {contentData?.map((item, idx) => {
                return (
                  <Grid key={`content_info_7_${idx}`} item xs={12} md={4}>
                    <Stack
                      alignItems="flex-start"
                      spacing={2}
                      sx={{
                        p: 2.5,
                        borderRadius: '6px',
                        border: `solid 1px ${theme.palette.primary[300]}`,
                        backgroundColor: 'common.white',
                        height: '100%'
                      }}
                    >
                      <img src={item?.image} alt={item?.image} style={{ width: 120, height: 'auto' }} />
                      <Stack spacing={1.25}>
                        <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                          {item?.title}
                        </Typography>
                        <Typography variant="p2">{item?.description}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQSproutSection1;
