// third-party
import { FormattedMessage } from 'react-intl';
// icons
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// const icons = { SettingsOutlinedIcon };

// icons
import SettingIcon from 'assets/icons/settings/Setting.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const settings = {
  id: 'group-settings',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: <FormattedMessage id="Settings" />,
      type: 'item',
      url: '/setting/account/login_security',
      icon: SettingIcon
    }
  ]
};
const settings_ = {
  id: 'group-settings',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: <FormattedMessage id="Settings" />,
      type: 'collapse',
      icon: SettingIcon,
      children: [
        {
          id: 'settings_',
          title: <FormattedMessage id="Settings" />,
          type: 'item',
          url: '/setting/account/login_security'
        }
      ]
    }
  ]
};

export { settings, settings_ };
