// action - state management
import {
  REGISTER,
  LOGIN,
  LOGOUT,
  PUSHER,
  NOTIFICATION_LIST,
  NOTIFICATION_COUNT,
  NOTIFICATION_CATEGORY,
  CRITICAL,
  HIDE_CRITICAL
} from './actions';

// initial state
export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  privatePusher: null,
  publicPusher: null,
  notifications: [],
  notificationCount: 0,
  notificationCategory: 'All',
  critical: null,
  openCritical: false
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case LOGOUT: {
      return {
        ...state,
        privatePusher: null,
        publicPusher: null,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    case PUSHER: {
      const { privatePusher, publicPusher } = action.payload;
      return {
        ...state,
        privatePusher,
        publicPusher
      };
    }
    case NOTIFICATION_LIST: {
      const { notifications } = action.payload;
      return {
        ...state,
        notifications
      };
    }
    case NOTIFICATION_COUNT: {
      const { notificationCount } = action.payload;
      return {
        ...state,
        notificationCount
      };
    }
    case NOTIFICATION_CATEGORY: {
      const { notificationCategory } = action.payload;
      return {
        ...state,
        notificationCategory
      };
    }
    case CRITICAL: {
      const { critical } = action.payload;
      return {
        ...state,
        critical,
        openCritical: true
      };
    }
    case HIDE_CRITICAL: {
      return {
        ...state,
        openCritical: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
