import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, useTheme, Box, Grid, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection2 = () => {
  const theme = useTheme();
  const contentData = [
    {
      title: 'Sprout Dashboard',
      description: 'Provides a centralized location to conveniently track various data related to cultivation activities.'
    },
    {
      title: 'My Khôra',
      description:
        'Allows users to create and manage networks, locations, and Khôra units. Features include shelf management for tasks like growing, seeding, and maintenance, as well as accessory use and assignment.'
    },
    {
      title: 'Growing Plan Management',
      description:
        'Enables users to access public and private Growing Plan libraries, create new Growing Plans, and submit Growing Plans for acceptance in the public Khôra Home Library.'
    },
    {
      title: 'Scheduling',
      description: 'Enables users to start and stop Growing Plans by location, helping to manage cultivation activities efficiently.'
    },
    {
      title: 'Cost Management',
      description: 'Provides tools for tracking costs associated with cultivation and analyzing profitability.'
    },
    {
      title: 'Shopping List',
      description:
        'Provides users with list of plants to buy or growing supplies to obtain through sellers, resellers, and Certified Master Growers on Khôra Marketplace'
    },
    {
      title: 'List Management',
      description:
        'Users can access public lists of Growing Plan details, create or clone existing Growing Plan details for future use, and manage their own lists.'
    },
    {
      title: 'Vendor Management',
      description: 'Facilitates interaction with vendors for sourcing supplies and equipment.'
    },
    {
      title: 'Searches',
      description: 'Provides quick and easy search functionality for finding Growing Plans, plants, and vendors.'
    }
  ];

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p2m">What are Sprout{"'"}s top features?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2.5}>
            <Stack spacing={3.75} direction={{ xs: 'column', sm: 'row' }} alignItems="center" sx={{ maxWidth: 914 }}>
              <Stack justifyContent="space-around" spacing={1.25} sx={{ height: { xs: 'inherit', md: 160 } }}>
                <Typography variant="p2">
                  Sprout offers a wide range of features designed to streamline the cultivation process and provide users with the tools
                  they need to succeed.
                </Typography>
                <Typography variant="p2">Here are the key features of Sprout:</Typography>
              </Stack>
              <Box sx={{ height: 180, display: { xs: 'none', md: 'block' } }}>
                <img src={'/assets/images/faq/sprout_1.png'} alt={'sprout_1'} style={{ width: 'auto', height: '180px' }} />
              </Box>
            </Stack>
            <Box>
              <Stack
                sx={{
                  p: 2.5,
                  borderRadius: '6px',
                  border: `solid 1px ${theme.palette.primary[300]}`,
                  backgroundColor: 'common.white',
                  height: '100%'
                }}
              >
                <Grid container spacing={2}>
                  {contentData?.map((item, idx) => {
                    return [
                      <Grid key={`content_info_${idx}`} item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1.25}>
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ width: 50, height: 78, backgroundColor: theme.palette.primary[50] }}
                          >
                            <Typography variant="h4" sx={{ color: theme.palette.darkgreen[300] }}>
                              {idx + 1}
                            </Typography>
                          </Stack>
                          <Stack spacing={1.25} sx={{ width: `calc(100% - 50px)` }}>
                            <Typography variant="p2m" sx={{ color: theme.palette.darkgreen[300] }}>
                              {item?.title}
                            </Typography>
                            <Typography variant="p2">{item?.description}</Typography>
                          </Stack>
                        </Stack>
                      </Grid>,
                      idx < contentData?.length - 1 && (
                        <Grid key={`content_info_divider_${idx}`} item xs={12}>
                          <Divider sx={{ borderColor: theme.palette.primary[300] }} />
                        </Grid>
                      )
                    ];
                  })}
                </Grid>
              </Stack>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default FAQSproutSection2;
