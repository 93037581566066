import { Stack } from '@mui/material';

import FAQSproutSection1 from './sprout/Section1';
import FAQSproutSection2 from './sprout/Section2';
import FAQSproutSection3 from './sprout/Section3';
import FAQSproutSection4 from './sprout/Section4';
import FAQSproutSection5 from './sprout/Section5';
import FAQSproutSection6 from './sprout/Section6';
import FAQSproutSection7 from './sprout/Section7';
import FAQSproutSection8 from './sprout/Section8';
import FAQSproutSection9 from './sprout/Section9';
import FAQSproutSection10 from './sprout/Section10';

const LandingFAQSproutSection = () => {
  return (
    <Stack spacing={1.25} sx={{ maxWidth: 1140, margin: 'auto', pb: { xs: 5, md: 10 }, px: 2.5 }}>
      <FAQSproutSection1 />
      <FAQSproutSection2 />
      <FAQSproutSection3 />
      <FAQSproutSection4 />
      <FAQSproutSection5 />
      <FAQSproutSection10 />
      <FAQSproutSection6 />
      <FAQSproutSection7 />
      <FAQSproutSection8 />
      <FAQSproutSection9 />
    </Stack>
  );
};
export default LandingFAQSproutSection;
