// ==============================|| OVERRIDES - DIALOG ||============================== //

export default function DialogActions(theme) {
  return {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '10px',
          // paddingBottom: '20px',
          // paddingRight: '20px',
          '& > :first-child': {
            border: `solid 1px ${theme.palette.darkgreen.main}`
          },
          '& > :last-child': {
            backgroundColor: theme.palette.darkgreen.main,
            color: theme.palette.darkgreen.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.darkgreen.light,
              color: theme.palette.darkgreen.contrastText
            },
            '&:active': {
              backgroundColor: theme.palette.darkgreen.dark,
              color: theme.palette.darkgreen.contrastText
            }
          }
        }
      }
    }
  };
}
