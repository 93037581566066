export const Access = {
  Private: 'private',
  Public: 'public'
};
export const AccessStatus = {
  1: 'Private',
  2: 'Public'
};
export const NotificationCategories = {
  GreetingsMilestone: {
    value: 1,
    title: 'Greetings & Milestones',
    description: 'Celebrate your growing successes by reaching special milestones like harvesting and other achievements.',
    image: 'greetings_milestones.svg',
    mandatory: true,
    private: false,
    public: false,
    standard: true,
    color: '#EBF7F4'
  },
  GrowingPlanSubmissionStatus: {
    value: 2,
    title: 'Growing Plan Submission Status',
    description:
      'Submitting a Growing Plan is an exciting opportunity for the Khôra Home Community to try your State Fair Blue-Ribbon worthy beauties. Receive updates on changes in your Growing Plan submission.',
    image: 'growing_plan_submission_status.svg',
    mandatory: true,
    private: true,
    public: false,
    standard: true,
    color: '#FFF1CC'
  },
  GrowingCompetition: {
    value: 3,
    title: 'Growing Competitions',
    description:
      'Khôra growers are creative, ingenious, and persistent individuals striving to grow the healthiest, tastiest, and most gorgeous plants unbounded by origin or season. Want to throw your hat in the ring? Sign up for Competition notifications and updates.',
    image: 'growing_competitions.svg',
    mandatory: true,
    private: false,
    public: true,
    standard: true,
    color: '#FEE4E2'
  },
  NewsAndEvent: {
    value: 4,
    title: 'News and Events',
    description:
      'Khôra Home Community is active with many exciting updates to report. Join the Khôra Home Community to have a greater awareness of activities occurring near you. News and Events messages will appear in notifications and be emailed.',
    image: 'news_events.svg',
    mandatory: true,
    private: false,
    public: true,
    standard: true,
    color: '#E5F4FC'
  },
  MarketingCampaign: {
    value: 5,
    title: 'Marketing Campaigns',
    description: 'Stay in the know for upcoming products, features, and new opportunities for the Khôra Home Community to enjoy.',
    image: 'marketing_campaigns.svg',
    mandatory: true,
    private: false,
    public: true,
    standard: false,
    color: '#FFF1E8'
  },
  SocialMedia: {
    value: 6,
    title: 'Social Media',
    description:
      'We know how popular you and your Blue-Ribbon Prize beauties are. Receive notifications on your ranking, followers, and your growing blog activities.',
    image: 'social_media.svg',
    mandatory: true,
    private: false,
    public: true,
    standard: false,
    color: '#E5F4FC'
  },
  UrgentSystemNotification: {
    value: 7,
    title: 'Urgent System Notifications',
    description: '',
    image: 'urgent_system_notifications.svg',
    mandatory: false,
    private: false,
    public: false,
    standard: true,
    color: '#F5EDE5'
  },
  TransplantStep: {
    value: 8,
    title: 'Transplant Steps',
    description: '',
    image: 'transplant_steps.svg',
    mandatory: false,
    private: false,
    public: false,
    standard: true,
    color: '#F1F6F9'
  },
  GrowingSteps: {
    value: 9,
    title: 'Growing Steps',
    description: '',
    image: 'growing_steps.svg',
    mandatory: false,
    private: false,
    public: false,
    standard: true,
    color: '#FEF3F2'
  },
  ShoppingSeedingReminder: {
    value: 10,
    title: 'Shopping & Seeding Reminders',
    description:
      'We can all be forgetful at times or have senior moments all of the time. Ensure your continuous and repeat harvests occur on time by having replacement plants ready to restock when you harvest. Monday mornings, a weekly shopping notification will be sent for your clean hands or a weekly seedling notification for your dirty hands.',
    image: 'shopping_seeding_reminders.svg',
    mandatory: true,
    private: false,
    public: false,
    standard: true,
    color: '#DAEFED'
  },
  PlantPlacement: {
    value: 11,
    title: 'Plant Placement',
    description: '',
    image: 'plant_placement.svg',
    mandatory: false,
    private: false,
    public: false,
    standard: true,
    color: '#D7EEE9'
  },
  ScheduleReminder: {
    value: 12,
    title: 'Schedule Reminders',
    description: '',
    image: 'schedule_reminders.svg',
    mandatory: false,
    private: false,
    public: false,
    standard: true,
    color: '#FFF8E5'
  },
  Warning: {
    value: 13,
    title: 'Warnings',
    description: '',
    image: 'warnings.svg',
    mandatory: false,
    private: true,
    public: false,
    standard: false,
    color: '#FEF3F2'
  },
  Payment: {
    value: 14,
    title: 'Payment',
    description: '',
    image: 'payment.svg',
    mandatory: false,
    private: false,
    public: false,
    standard: true,
    color: '#FEF3F2'
  },
  Security: {
    value: 15,
    title: 'Security',
    description: '',
    image: 'security.svg',
    mandatory: false,
    private: false,
    public: false,
    standard: true,
    color: '#FEF3F2'
  },
  Membership: {
    value: 16,
    title: 'Membership',
    description: '',
    image: 'membership.svg',
    mandatory: false,
    private: false,
    public: false,
    standard: true,
    color: '#FEF3F2'
  }
};
