import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import FAQMarketplaceSection1 from './marketplace/Section1';
import FAQMarketplaceSection2 from './marketplace/Section2';
import FAQMarketplaceSection3 from './marketplace/Section3';
import FAQMarketplaceSection4 from './marketplace/Section4';
import FAQMarketplaceSection5 from './marketplace/Section5';
import FAQMarketplaceSection6 from './marketplace/Section6';
import FAQMarketplaceSection7 from './marketplace/Section7';
import FAQMarketplaceSection8 from './marketplace/Section8';
import FAQMarketplaceSection9 from './marketplace/Section9';

const LandingFAQMarketplaceSection = ({ expand = '' }) => {
  return (
    <Stack spacing={1.25} sx={{ maxWidth: 1140, margin: 'auto', pb: { xs: 5, md: 10 }, px: 2.5 }}>
      <FAQMarketplaceSection1 />
      <FAQMarketplaceSection5 expand={expand === 'become_seller'} />
      <FAQMarketplaceSection9 expand={expand === 'fee_structure'} />
      <FAQMarketplaceSection4 />
      <FAQMarketplaceSection6 />
      <FAQMarketplaceSection7 />
      <FAQMarketplaceSection8 />
      <FAQMarketplaceSection2 />
      <FAQMarketplaceSection3 />
    </Stack>
  );
};
LandingFAQMarketplaceSection.propTypes = {
  expand: PropTypes.string
};
export default LandingFAQMarketplaceSection;
