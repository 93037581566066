// action - state management
import { GapManageMethod, GrowInterestMethod } from 'utils/constants/setup';
import { GrowingMode } from 'utils/constants/cost';
import { PlacePlant } from 'utils/constants/crop_plan';

import {
  SEED_INITIALIZE,
  CUTTING_INITIALIZE,
  GROWINGPLAN_INITIALIZE,
  SELECTED_PLANTS,
  SELECTED_GROWING_PLANS,
  GROW_INTEREST,
  GAP_MANAGE,
  GROW_MODE,
  DURATION,
  CROP_MODELING_STEP,
  ESTIMATE_PARAMS,
  ASSIGNED_SHELVES,
  ASSIGNED_TRAYS,
  ASSIGNED_SEEDINGS,
  PLACE_PLANT,
  BUFFER_PERCENTAGE,
  KEG_VALUE,
  START_DATE,
  TOTAL_ACTIVE_KHORAS,
  TOTAL_ACTIVE_REMAINING_SEEDING_TRAY,
  CURRENT_SHELVES_FOR_MANUAL,
  ADDED_GROWING_PLAN_ID,
  ADDED_GROWING_PLANS,
  GROWING_GROUP_PER_SHELF
} from './actions';
// initial state
export const initialState = {
  activeShelfCount: 0,
  activeSeedingTrayCount: 0,
  offCount: 0,
  seedingCount: 0,
  growInterest: GrowInterestMethod.Unknown,
  gapManage: GapManageMethod.GapExist,
  growingMode: GrowingMode.Full,
  placePlant: PlacePlant.Same,
  duration: 180,
  bufferPercentage: 10,
  KEG: 0,
  startDate: new Date(),
  step: 1,
  assignedShelves: 0,
  assignedTrays: 0,
  assignedSeedings: 0,
  estimateParams: [],
  seedList: [],
  cuttingList: [],
  growingPlanList: [],
  selectedPlantList: [],
  selectedGrowingPlanList: [],
  currentShelvesForManual: [],
  addedGrowingPlanID: 0,
  addedGrowingPlans: [],
  growingGroupPerShelf: {},
  isInitialized: false
};

// ==============================|| AUTH REDUCER ||============================== //

const cropPlan = (state = initialState, action) => {
  switch (action.type) {
    case SEED_INITIALIZE: {
      const { seedList, isInitialized } = action.payload;
      return {
        ...state,
        seedList,
        isInitialized
      };
    }
    case CUTTING_INITIALIZE: {
      const { cuttingList, isInitialized } = action.payload;
      return {
        ...state,
        cuttingList,
        isInitialized
      };
    }
    case GROWINGPLAN_INITIALIZE: {
      const { growingPlanList, isInitialized } = action.payload;
      return {
        ...state,
        growingPlanList,
        isInitialized
      };
    }
    case SELECTED_PLANTS: {
      const { selectedPlantList } = action.payload;
      return {
        ...state,
        selectedPlantList
      };
    }
    case SELECTED_GROWING_PLANS: {
      const { selectedGrowingPlanList } = action.payload;
      return {
        ...state,
        selectedGrowingPlanList
      };
    }
    case GROW_INTEREST: {
      const { growInterest } = action.payload;
      return {
        ...state,
        growInterest
      };
    }
    case GAP_MANAGE: {
      const { gapManage } = action.payload;
      return {
        ...state,
        gapManage
      };
    }
    case GROW_MODE: {
      const { growingMode } = action.payload;
      return {
        ...state,
        growingMode
      };
    }
    case DURATION: {
      const { duration } = action.payload;
      return {
        ...state,
        duration
      };
    }
    case CROP_MODELING_STEP: {
      const { step } = action.payload;
      return {
        ...state,
        step
      };
    }
    case ESTIMATE_PARAMS: {
      const { estimateParams } = action.payload;
      return {
        ...state,
        estimateParams
      };
    }
    case ASSIGNED_SHELVES: {
      const { assignedShelves } = action.payload;
      return {
        ...state,
        assignedShelves
      };
    }
    case ASSIGNED_TRAYS: {
      const { assignedTrays } = action.payload;
      return {
        ...state,
        assignedTrays
      };
    }
    case ASSIGNED_SEEDINGS: {
      const { assignedSeedings } = action.payload;
      return {
        ...state,
        assignedSeedings
      };
    }
    case CURRENT_SHELVES_FOR_MANUAL: {
      const { currentShelvesForManual } = action.payload;
      return {
        ...state,
        currentShelvesForManual
      };
    }
    case PLACE_PLANT: {
      const { placePlant } = action.payload;
      return {
        ...state,
        placePlant
      };
    }
    case BUFFER_PERCENTAGE: {
      const { bufferPercentage } = action.payload;
      return {
        ...state,
        bufferPercentage
      };
    }
    case KEG_VALUE: {
      const { KEG } = action.payload;
      return {
        ...state,
        KEG
      };
    }
    case START_DATE: {
      const { startDate } = action.payload;
      return {
        ...state,
        startDate
      };
    }
    case TOTAL_ACTIVE_KHORAS: {
      const { activeShelfCount, offCount, seedingCount } = action.payload;
      return {
        ...state,
        activeShelfCount,
        offCount,
        seedingCount
      };
    }
    case TOTAL_ACTIVE_REMAINING_SEEDING_TRAY: {
      const { activeSeedingTrayCount } = action.payload;
      return {
        ...state,
        activeSeedingTrayCount
      };
    }
    case ADDED_GROWING_PLAN_ID: {
      const { addedGrowingPlanID } = action.payload;
      return {
        ...state,
        addedGrowingPlanID
      };
    }
    case ADDED_GROWING_PLANS: {
      const { addedGrowingPlans } = action.payload;
      return {
        ...state,
        addedGrowingPlans
      };
    }
    case GROWING_GROUP_PER_SHELF: {
      const { growingGroupPerShelf } = action.payload;
      return {
        ...state,
        growingGroupPerShelf
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default cropPlan;
