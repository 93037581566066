// react
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
// material UI
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// sub pages
import GuideHeader from './GuideHeader';
// config

const GuideLayout = () => {
  const theme = useTheme();
  const guideHeader = useMemo(() => <GuideHeader />, []);

  return (
    <Box sx={{ width: '100%', backgroundColor: theme.palette.grey[50], pt: 12.5, pb: 3.75, minHeight: '100vh' }}>
      {guideHeader}
      <Box sx={{ width: '100%', margin: 'auto' }}>
        <Box sx={{ maxWidth: '1170px', margin: 'auto', px: 3.75 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default GuideLayout;
