import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AppBar, Box } from '@mui/material';

import LandingHeader from './LandingHeader';
import LandingFooter from './LandingFooter';
import LandingWrapper from './LandingWrapper';

import { useDispatch } from 'react-redux';
import { handleClose } from 'store/reducers/landingMenu';
// ==============================|| LANDING ||============================== //

const LandingLayout = () => {
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    dispatch(handleClose());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <LandingWrapper showButton={showButton} setShowButton={setShowButton}>
      <AppBar
        sx={{
          boxShadow: showButton ? '0px 2px 5px 0px rgba(12, 61, 49, 0.25)' : 'none',
          backgroundColor: '#FFFFFF',
          backdropFilter: 'blur(8px)',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <LandingHeader />
      </AppBar>
      <Box sx={{ width: '100%', mt: '86px' }}>
        <Outlet />
      </Box>
      <LandingFooter />
    </LandingWrapper>
  );
};
export default LandingLayout;
