import { Typography, Accordion, AccordionSummary, AccordionDetails, Stack, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSproutSection8 = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="p2m">Can Sprout support special events?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={8}>
            <Stack justifyContent="center" spacing={1.25} sx={{ height: '100%' }}>
              <Typography variant="p2">
                Yes, Sprout can support special events by adjusting the crop modeling and harvest scheduling. During crop modeling, the
                platform displays the weekly harvest schedule, allowing growers to scale up cultivation ensuring large volumes of produce
                are ready in time for the special occasion.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                minHeight: 160,
                height: '100%',
                width: 'auto',
                backgroundImage: 'url("/assets/images/faq/sprout_10.png")',
                backgroundPosition: 'center, center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default FAQSproutSection8;
