import { CUSTOMER_TO_EDIT, CUSTOMER_LIST_ALL } from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  customerToEdit: null,
  customerList: []
};

// ==============================|| AUTH REDUCER ||============================== //

const customer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_ALL: {
      const { customerList } = action.payload;
      return {
        ...state,
        customerList,
        isInitialized: true
      };
    }
    case CUSTOMER_TO_EDIT: {
      const { customerToEdit } = action.payload;
      return {
        ...state,
        customerToEdit
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default customer;
