// third-party
import { FormattedMessage } from 'react-intl';
// icons
// import { ClusterOutlined } from '@ant-design/icons';
// const icons = { ClusterOutlined };

// icons
import MyKhoraIcon from 'assets/icons/my_khora/My_Khora.png';
// ==============================|| MENU ITEMS - MY KHORA ||============================== //

const mykhora = {
  id: 'group-mykhora',
  type: 'group',
  children: [
    {
      id: 'mykhora',
      title: <FormattedMessage id="My Khôra" />,
      type: 'item',
      url: '/my_khora',
      icon: MyKhoraIcon
    }
  ]
};
const mykhora_ = {
  id: 'group-mykhora',
  type: 'group',
  children: [
    {
      id: 'mykhora',
      title: <FormattedMessage id="My Khôra" />,
      type: 'collapse',
      icon: MyKhoraIcon,
      children: [
        {
          id: 'mykhora_',
          title: <FormattedMessage id="My Khôra" />,
          type: 'item',
          url: '/my_khora'
        }
      ]
    }
  ]
};

export { mykhora, mykhora_ };
