// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';
// import ContactUsIcon from '@mui/icons-material/AddIcCallOutlined';
// import ObservationIcon from '@mui/icons-material/TravelExploreOutlined';
import ObservationIcon from 'assets/icons/observation/observation.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const observation = {
  id: 'group-observation',
  type: 'group',
  children: [
    {
      id: 'observation',
      title: <FormattedMessage id="Observations" />,
      type: 'item',
      url: '/observation',
      icon: ObservationIcon
    }
  ]
};
const observation_ = {
  id: 'group-observation',
  type: 'group',
  children: [
    {
      id: 'observation',
      title: <FormattedMessage id="Observations" />,
      type: 'collapse',
      icon: ObservationIcon,
      children: [
        {
          id: 'observation_',
          title: <FormattedMessage id="Observations" />,
          type: 'item',
          url: '/observation'
        }
      ]
    }
  ]
};

export { observation, observation_ };
