// react
import { memo } from 'react';
import PropTypes from 'prop-types';
// material UI
import { useTheme } from '@mui/material/styles';
import { Box, Grid, LinearProgress, Typography, useMediaQuery } from '@mui/material';
// store
import { useSelector } from 'store';
// constant
import { SetupProgress } from 'utils/constants/setup';

const SetupProgressBar = ({ children }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { progressStep } = useSelector((state) => state.setupProgress);
  return (
    <Box sx={{ mb: 7.5 }}>
      <Grid container sx={{ py: 1 }}>
        {matchDownSM ? (
          <Grid item xs={12}>
            <Typography sx={theme.typography.p2}>{SetupProgress[progressStep]}</Typography>
          </Grid>
        ) : (
          SetupProgress?.map((item) => {
            return (
              <Grid item xs={12 / SetupProgress?.length} key={'step_progress_bar_' + item}>
                <Typography sx={theme.typography.p2}>{item}</Typography>
              </Grid>
            );
          })
        )}
      </Grid>
      <LinearProgress variant="determinate" value={Math.round((100 * (progressStep + 1)) / SetupProgress?.length)} />
      {children}
    </Box>
  );
};
SetupProgressBar.propTypes = {
  children: PropTypes.node
};

export default memo(SetupProgressBar);
