import { Outlet, useLocation } from 'react-router-dom';
import Error404 from 'pages/maintenance/404';
import { Membership } from 'utils/constants/auth';
import useAuth from 'hooks/useAuth';

const AuthGroupLayout = () => {
  const location = useLocation();
  const { user } = useAuth();
  const path = location.pathname;

  switch (user?.membership) {
    case undefined:
      return;
    case Membership.Seed:
      if (path.startsWith('/growing_plan') || path.startsWith('/list') || path.startsWith('/cost') || path.startsWith('/observation')) {
        return <Error404 />;
      }
      break;
    case Membership.Grow:
      if (path.startsWith('/list') || path.startsWith('/cost') || path.startsWith('/shopping')) {
        return <Error404 />;
      }
      break;
    case Membership.Bloom:
      if (path.startsWith('/observation') || path.startsWith('/cost')) {
        return <Error404 />;
      }
      break;
    case Membership.Harvest:
      break;
    case Membership.Master:
      break;
    case Membership.Free:
    default:
      if (
        path.startsWith('/growing_plan') ||
        path.startsWith('/list') ||
        path.startsWith('/cost') ||
        path.startsWith('/observation') ||
        path.startsWith('/vendor')
      ) {
        return <Error404 />;
      }
      break;
  }
  return <Outlet />;
};
export default AuthGroupLayout;
