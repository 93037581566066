import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

import { GrowingPlanProvider } from 'contexts/GrowingPlanContext';
import { SearchProvider } from 'contexts/SearchContext';

// render - GrowingPlan
const GrowingPlan = Loadable(lazy(() => import('pages/growing_plan_management')));
// ==============================|| MAIN ROUTING ||============================== //

const GrowingPlanRoutes = {
  path: 'growing_plan',
  element: (
    <SearchProvider>
      <GrowingPlanProvider>
        <MainLayout networkShow={false}>
          <Outlet />
        </MainLayout>
      </GrowingPlanProvider>
    </SearchProvider>
  ),
  children: [
    {
      path: '',
      element: <GrowingPlan />
    }
  ]
};

export default GrowingPlanRoutes;
