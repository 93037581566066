const ScheduleColors = ['#E3F2FF', '#FFF3E5', '#E1CEFF', '#CAFFD7', '#DCDAB0', '#FFC3C3', '#C0E6B6', '#FFEF8E', '#FF8CD9', '#DDDDDD'];
const ReminderType = { Manual: 0, Custom: 1 };
const CalendarType = { Week: 1, Month: 2, Year: 3 };
const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const InitialMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const Weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const InitialWeeks = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const TransplantStepsForMushroom = ['', 'Inoculation', '1st Transplant', '2nd Transplant', '3rd Transplant'];
const TransplantStepsForSeed = ['', 'Seeding', '1st Transplant', '2nd Transplant', '3rd Transplant'];
const TransplantStepsForCutting = ['', 'Grafting or Leaf / Stem Cutting', '1st Transplant', '2nd Transplant', '3rd Transplant'];

const ScheduleStepType = { Harvest: 1, Planting: 2, Transplant: 3, Growing: 4, Start: 5, End: 6 };

const TaskReminders = {
  '0_N_N': 'None',
  '1_W_B': '1 Week Before',
  '2_D_B': '2 Days Before',
  '2_D_A': '2 Days After',
  '1_W_A': '1 Week After',
  Custom: 'Custom'
};

const TimeCategory = {
  M_B: 'Minute Before',
  M_A: 'Minute After',
  H_B: 'Hour Before',
  H_A: 'Hour After',
  D_B: 'Day Before',
  D_A: 'Day After',
  W_B: 'Week Before',
  W_A: 'Week After'
};

export {
  ScheduleColors,
  ReminderType,
  CalendarType,
  Months,
  InitialMonths,
  Weeks,
  InitialWeeks,
  TransplantStepsForSeed,
  TransplantStepsForMushroom,
  TransplantStepsForCutting,
  ScheduleStepType,
  TaskReminders,
  TimeCategory
};
