// third-party
import { FormattedMessage } from 'react-intl';
// icons
import VendorIcon from 'assets/icons/vendor/vendor.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const vendor = {
  id: 'group-vendor',
  type: 'group',
  children: [
    {
      id: 'vendor',
      title: <FormattedMessage id="Vendor Management" />,
      type: 'item',
      url: '/vendor',
      icon: VendorIcon
    }
  ]
};
const vendor_ = {
  id: 'group-vendor',
  type: 'group',
  children: [
    {
      id: 'vendor',
      title: <FormattedMessage id="Vendor Management" />,
      type: 'collapse',
      icon: VendorIcon,
      children: [
        {
          id: 'vendor_',
          title: <FormattedMessage id="Vendor Management" />,
          type: 'item',
          url: '/vendor'
        }
      ]
    }
  ]
};

const vendor_seed = {
  id: 'group-vendor',
  type: 'group',
  children: [
    {
      id: 'vendor',
      title: <FormattedMessage id="Vendors" />,
      type: 'item',
      url: '/vendor',
      icon: VendorIcon
    }
  ]
};
const vendor_seed_ = {
  id: 'group-vendor',
  type: 'group',
  children: [
    {
      id: 'vendor',
      title: <FormattedMessage id="Vendors" />,
      type: 'collapse',
      icon: VendorIcon,
      children: [
        {
          id: 'vendor_',
          title: <FormattedMessage id="Vendor Management" />,
          type: 'item',
          url: '/vendor'
        }
      ]
    }
  ]
};

export { vendor, vendor_, vendor_seed, vendor_seed_ };
