import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { createContext, useEffect, useReducer, useCallback, useState } from 'react';
import cropPlanReducer, { initialState } from 'store/reducers/_crop_plan';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';

import { CardContent, Stack, Button, Modal, Typography, useTheme } from '@mui/material';
import AlertCard from 'components/AlertCard';

import {
  SEED_INITIALIZE,
  CUTTING_INITIALIZE,
  GROWINGPLAN_INITIALIZE,
  SELECTED_PLANTS,
  SELECTED_GROWING_PLANS,
  GROW_INTEREST,
  GAP_MANAGE,
  GROW_MODE,
  DURATION,
  CROP_MODELING_STEP,
  ESTIMATE_PARAMS,
  ASSIGNED_SHELVES,
  ASSIGNED_TRAYS,
  ASSIGNED_SEEDINGS,
  PLACE_PLANT,
  BUFFER_PERCENTAGE,
  KEG_VALUE,
  START_DATE,
  TOTAL_ACTIVE_KHORAS,
  TOTAL_ACTIVE_REMAINING_SEEDING_TRAY,
  CURRENT_SHELVES_FOR_MANUAL,
  ADDED_GROWING_PLAN_ID,
  ADDED_GROWING_PLANS,
  GROWING_GROUP_PER_SHELF
} from 'store/reducers/actions';

import { GrowInterestMethod } from 'utils/constants/setup';
import { GrowingMode } from 'utils/constants/cost';
import { AssignmentMethod, GroupType, GrowingMethod, PlacePlant, PlantTypeByContainer } from 'utils/constants/crop_plan';
import { getPlantGroupTypeOfGrowingPlan } from 'utils/common';
import axios from 'utils/axios';
import useSetup from 'hooks/useSetup';

const CropPlanContext = createContext(null);

export const CropPlanProvider = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatchForSnackbar = useDispatch();
  const [state, dispatch] = useReducer(cropPlanReducer, initialState);

  const { userSetup, network, location, khoraCount, shelf, accessory, getShelfStatus } = useSetup();

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const handleErrorAlertOpen = () => setOpenErrorAlert(true);
  const [errTitle, setErrTitle] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (!network?.id) {
      setErrTitle('No network found!');
      setErrMsg('You have no network. Please add your first network');
      handleErrorAlertOpen();
      return;
    }
    if (!location?.id) {
      setErrTitle('No location found!');
      setErrMsg('You have no location. Please add your first location');
      handleErrorAlertOpen();
      return;
    }
    if (!khoraCount) {
      setErrTitle('No Khôra found!');
      setErrMsg('You have no Khôras. Please register your first Khôras');
      handleErrorAlertOpen();
      return;
    }
    return () => {
      setErrTitle('');
      setErrMsg('');
    };
  }, [network, location, khoraCount]);

  const getCropPlanList = useCallback(async (locationID) => {
    try {
      const res = await axios.get(`/crop_plan/main/all/${locationID}`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getAutoCropPlans = useCallback(async () => {
    try {
      const res = await axios.get(`/crop_plan/auto`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getAdvancedCropPlans = useCallback(async () => {
    try {
      const res = await axios.get(`/crop_plan/advanced`);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getAllGrowingPlanList = useCallback(async () => {
    try {
      const res = await axios.get('/growing_plan/main');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getSeedListHavingGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/list/seed/exist_growing_plan');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getCuttingListHavingGrowingPlan = useCallback(async () => {
    try {
      const res = await axios.get('/list/cutting/exist_growing_plan');
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getAvailableTrayInfoCropModeling = useCallback(async (locationID) => {
    try {
      const res = await axios.get('khora_setup/shelf/tray/' + locationID);
      return res?.data;
    } catch (err) {
      return {
        growing: {
          total: 0,
          in_use: 0,
          remaining: 0
        },
        seeding: {
          total: 0,
          in_use: 0,
          remaining: 0
        }
      };
    }
  }, []);

  const setGrowInterest = useCallback((growInterest) => {
    dispatch({
      type: GROW_INTEREST,
      payload: {
        growInterest
      }
    });
  }, []);

  const setGapManage = useCallback((gapManage) => {
    dispatch({
      type: GAP_MANAGE,
      payload: {
        gapManage
      }
    });
  }, []);

  const setGrowingMode = useCallback((growingMode) => {
    dispatch({
      type: GROW_MODE,
      payload: {
        growingMode
      }
    });
  }, []);

  const setPlacePlant = useCallback((placePlant) => {
    dispatch({
      type: PLACE_PLANT,
      payload: {
        placePlant
      }
    });
  }, []);

  const setBufferPercentage = useCallback((bufferPercentage) => {
    dispatch({
      type: BUFFER_PERCENTAGE,
      payload: {
        bufferPercentage
      }
    });
  }, []);

  const setKEG = useCallback((KEG) => {
    dispatch({
      type: KEG_VALUE,
      payload: {
        KEG
      }
    });
  }, []);

  const setStartDate = useCallback((startDate) => {
    dispatch({
      type: START_DATE,
      payload: {
        startDate
      }
    });
  }, []);

  const setDuration = useCallback((duration) => {
    dispatch({
      type: DURATION,
      payload: {
        duration
      }
    });
  }, []);

  const setStep = useCallback((step) => {
    dispatch({
      type: CROP_MODELING_STEP,
      payload: {
        step
      }
    });
  }, []);

  const setSelectedPlantList = useCallback((selectedPlantList) => {
    dispatch({
      type: SELECTED_PLANTS,
      payload: {
        selectedPlantList
      }
    });
  }, []);

  const setSelectedGrowingPlanList = useCallback((selectedGrowingPlanList) => {
    dispatch({
      type: SELECTED_GROWING_PLANS,
      payload: {
        selectedGrowingPlanList
      }
    });
  }, []);

  const setAssignedShelves = useCallback((assignedShelves) => {
    dispatch({
      type: ASSIGNED_SHELVES,
      payload: {
        assignedShelves
      }
    });
  }, []);

  const setAssignedTrays = useCallback((assignedTrays) => {
    dispatch({
      type: ASSIGNED_TRAYS,
      payload: {
        assignedTrays
      }
    });
  }, []);

  const setAssignedSeedings = useCallback((assignedSeedings) => {
    dispatch({
      type: ASSIGNED_SEEDINGS,
      payload: {
        assignedSeedings
      }
    });
  }, []);

  const setCurrentShelvesForManual = useCallback((currentShelvesForManual) => {
    dispatch({
      type: CURRENT_SHELVES_FOR_MANUAL,
      payload: {
        currentShelvesForManual
      }
    });
  }, []);

  const setAddedGrowingPlanID = useCallback((addedGrowingPlanID) => {
    dispatch({
      type: ADDED_GROWING_PLAN_ID,
      payload: {
        addedGrowingPlanID
      }
    });
  }, []);

  const setAddedGrowingPlans = useCallback((addedGrowingPlans) => {
    dispatch({
      type: ADDED_GROWING_PLANS,
      payload: {
        addedGrowingPlans
      }
    });
  }, []);

  useEffect(() => {
    let growingPlanFlag = false;
    let seedFlag = false;
    let cuttingFlag = false;
    getAllGrowingPlanList().then((growingPlanList) => {
      growingPlanFlag = true;
      dispatch({
        type: GROWINGPLAN_INITIALIZE,
        payload: {
          growingPlanList,
          isInitialized: growingPlanFlag && seedFlag && cuttingFlag
        }
      });
    });
    getSeedListHavingGrowingPlan().then((seedList) => {
      seedFlag = true;
      const _seedList = seedList?.map((item) => {
        return { ...item, growing_plan: item?.growing_plans?.[0], growing_plan_id: item?.growing_plans?.[0]?.id };
      });
      dispatch({
        type: SEED_INITIALIZE,
        payload: {
          seedList: _seedList,
          isInitialized: growingPlanFlag && seedFlag && cuttingFlag
        }
      });
    });
    getCuttingListHavingGrowingPlan().then((cuttingList) => {
      cuttingFlag = true;
      const _cuttingList = cuttingList?.map((item) => {
        return { ...item, growing_plan: item?.growing_plans?.[0], growing_plan_id: item?.growing_plans?.[0]?.id };
      });
      dispatch({
        type: CUTTING_INITIALIZE,
        payload: {
          cuttingList: _cuttingList,
          isInitialized: growingPlanFlag && seedFlag && cuttingFlag
        }
      });
    });
    return () => {
      growingPlanFlag = false;
      seedFlag = false;
      cuttingFlag = false;
    };
  }, [getAllGrowingPlanList, getSeedListHavingGrowingPlan, getCuttingListHavingGrowingPlan]);

  useEffect(() => {
    setGrowInterest(userSetup?.grow_interest);
  }, [userSetup?.grow_interest, setGrowInterest]);

  useEffect(() => {
    setBufferPercentage(userSetup.buffer_percentage);
  }, [userSetup.buffer_percentage, setBufferPercentage]);

  useEffect(() => {
    getShelfStatus(location?.id).then((res) => {
      dispatch({
        type: TOTAL_ACTIVE_KHORAS,
        payload: {
          activeShelfCount: parseInt(khoraCount * shelf?.shelf_count + accessory?.shelf_count - res?.seeding?.length - res?.off?.length),
          offCount: res?.off?.length,
          seedingCount: res?.seeding?.length
        }
      });
    });
    getAvailableTrayInfoCropModeling(location?.id).then((res) => {
      dispatch({
        type: TOTAL_ACTIVE_REMAINING_SEEDING_TRAY,
        payload: {
          activeSeedingTrayCount: res?.seeding?.remaining
        }
      });
    });
    // eslint-disable-next-line
  }, [location?.id, khoraCount, shelf?.shelf_count, accessory?.shelf_count]);

  // calcuate shelves and trays in manual crop plan
  useEffect(() => {
    const shelfCount = state.currentShelvesForManual?.reduce((shelfCount, shelf) => {
      let flag = 0;
      for (const index in shelf?.tray) {
        if (shelf?.tray[index]?.length > 0) flag = 1;
      }
      return shelfCount + flag;
    }, 0);
    setAssignedShelves(shelfCount);
  }, [state.currentShelvesForManual, setAssignedShelves]);

  const initializeStates = useCallback(() => {
    setGrowInterest(userSetup?.grow_interest);
    setPlacePlant(PlacePlant.Same);
    setGrowingMode(GrowingMode.Full);
    setDuration(180);
    setBufferPercentage(userSetup.buffer_percentage);
    setKEG(0);
    setStartDate(new Date());
    setStep(1);
    setAssignedShelves(0);
    dispatch({
      type: ESTIMATE_PARAMS,
      payload: {
        estimateParams: []
      }
    });
    setSelectedGrowingPlanList([]);
    setSelectedPlantList([]);
  }, [
    userSetup.buffer_percentage,
    userSetup?.grow_interest,
    setGrowInterest,
    setGrowingMode,
    setPlacePlant,
    setDuration,
    setBufferPercentage,
    setKEG,
    setStartDate,
    setStep,
    setAssignedShelves,
    setSelectedGrowingPlanList,
    setSelectedPlantList
  ]);

  // auto crop modeling
  const startAutoCropModeling = useCallback(async (locationID, values) => {
    try {
      const res = await axios.post(`/crop_plan/auto/start/${locationID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const saveAutoCropPlan = useCallback(async (locationID, values) => {
    try {
      const res = await axios.post(`/crop_plan/auto/save/${locationID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const loadAutoCropModeltoTempDB = useCallback(async (modelID, importFlag = false) => {
    try {
      const res = await axios.post(`/crop_plan/auto/load/${modelID}`, { import: importFlag });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateAutoCropModel = useCallback(async (modelID, values) => {
    try {
      const res = await axios.post(`/crop_plan/auto/update/${modelID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deletePlantsFromAutoCropModel = useCallback(async (locationID, values) => {
    try {
      const res = await axios.post(`/crop_plan/auto/remove_plant/${locationID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneAutoCropModel = useCallback(async (modelID, values) => {
    try {
      const res = await axios.post(`/crop_plan/auto/clone/${modelID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteAutoCropModel = useCallback(async (modelID) => {
    try {
      const res = await axios.delete(`/crop_plan/auto/${modelID}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneManualCropModel = useCallback(async (modelID, values) => {
    try {
      const res = await axios.post(`/crop_plan/manual/clone/${modelID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteManualCropModel = useCallback(async (modelID) => {
    try {
      const res = await axios.delete(`/crop_plan/manual/${modelID}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getManualCropModel = useCallback(async (locationID, modelID) => {
    try {
      const res = await axios.get(`/crop_plan/manual/${locationID}/${modelID}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getGrowingGroupType = useCallback(
    (growing_plan_id) => {
      const selectedPlan = state.growingPlanList.find((item) => parseInt(item?.id) === parseInt(growing_plan_id));
      if (selectedPlan) {
        return getPlantGroupTypeOfGrowingPlan(selectedPlan);
      }
      return 0;
    },
    [state.growingPlanList]
  );

  const setGrowingGroupPerShelf = useCallback((growingGroupPerShelf) => {
    dispatch({
      type: GROWING_GROUP_PER_SHELF,
      payload: {
        growingGroupPerShelf
      }
    });
  }, []);

  // Advanced Crop plan
  const validationCheckToSelectGrowingPlans = useCallback(() => {
    if (parseFloat(state.bufferPercentage) >= 100) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: 'Space buffer percentage value must be less than 100',
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
      return false;
    }
    if (parseFloat(state.KEG) >= 100) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: 'Khôra efficiency gains value must be less than 100',
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
      return false;
    }
    if (!state.startDate) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: 'Modeling start date is required',
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
      return false;
    }
    if (isNaN(Date.parse(state.startDate))) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: 'Invalid date time format',
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
      return false;
    }
    // Get timestamp only for date not including time
    const now = new Date();
    const date = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    // Compare considering the local time zone
    if (Date.parse(state.startDate) < Date.parse(date) + now.getTimezoneOffset() * 60 * 1000) {
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: 'You cannot select the date earlier than now',
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
      return false;
    }
    return true;
  }, [state.KEG, state.bufferPercentage, state.startDate, dispatchForSnackbar]);

  const startAdvancedCropModeling = useCallback(async (locationID, values) => {
    try {
      const res = await axios.post(`/crop_plan/advanced/start/${locationID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const saveAdvancedCropPlan = useCallback(async (locationID, values) => {
    try {
      const res = await axios.post(`/crop_plan/advanced/save/${locationID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const loadAdvancedCropModeltoTempDB = useCallback(async (modelID, importFlag = false) => {
    try {
      const res = await axios.post(`/crop_plan/advanced/load/${modelID}`, { import: importFlag });
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateAdvancedCropModel = useCallback(async (modelID, values) => {
    try {
      const res = await axios.post(`/crop_plan/advanced/update/${modelID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deletePlantsFromAdvancedCropModel = useCallback(async (locationID, values) => {
    try {
      const res = await axios.post(`/crop_plan/advanced/remove_plan/${locationID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const cloneAdvancedCropModel = useCallback(async (modelID, values) => {
    try {
      const res = await axios.post(`/crop_plan/advanced/clone/${modelID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const deleteAdvancedCropModel = useCallback(async (modelID) => {
    try {
      const res = await axios.delete(`/crop_plan/advanced/${modelID}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  // common functions

  const getEstimateParams = useCallback(async (values) => {
    try {
      const res = await axios.post('/crop_plan/main/params', values);
      dispatch({
        type: ESTIMATE_PARAMS,
        payload: {
          estimateParams: res?.data
        }
      });
      return res?.data;
    } catch (err) {
      dispatch({
        type: ESTIMATE_PARAMS,
        payload: {
          estimateParams: []
        }
      });
      return [];
    }
  }, []);

  const getRateByGrowingMethod = useCallback(
    (growing_method, days_from_seeding_khora, age_to_market) => {
      const period =
        state.growInterest === GrowInterestMethod.CleanHands
          ? parseInt(days_from_seeding_khora) - parseInt(age_to_market)
          : parseInt(days_from_seeding_khora);
      if (parseInt(growing_method) !== GrowingMethod.Continuous) return 1;
      const availableMaxRate = Math.max(parseInt((state.duration - period) / 7) + 1, 0);
      return Math.min(availableMaxRate, Math.ceil(period / 7));
    },
    [state.growInterest, state.duration]
  );

  const getRateByTransplantMethod = useCallback(
    (growing_plan_id, transplant_method) => {
      const matchingItem = state.estimateParams?.find((item) => item?.growing_plan_id === growing_plan_id);
      const startValue = matchingItem?.start_count ?? 0;
      const harvestValue = matchingItem?.harvest_count ?? 0;
      let ret = 1;
      if (parseInt(transplant_method) === 2) {
        ret = startValue / harvestValue;
      } else {
        ret = 1;
      }
      return ret;
    },
    [state.estimateParams]
  );

  const calculatePlantsGrown = useCallback(
    (growing_plan_id, current_place_plant, assignment_method, volume, params = null) => {
      if (!volume) return '';
      const matchingItem = params
        ? params?.find((item) => item?.growing_plan_id === growing_plan_id)
        : state.estimateParams?.find((item) => item?.growing_plan_id === growing_plan_id);
      const plantsPerShelf = matchingItem?.start_count ?? 0;
      let ret = 0;
      if (current_place_plant === PlacePlant.Same) {
        // Shelf by Shelf
        if (assignment_method === AssignmentMethod.NumberOf) {
          // Shelf by Shelf, Number of Shelves
          ret = volume;
        } else {
          // Shelf by Shelf, Shelf per Percentage
          ret = Math.round((parseInt(state.activeShelfCount) * parseFloat(volume)) / 100);
        }
      } else {
        // Tray by Tray,
        if (assignment_method === AssignmentMethod.NumberOf) {
          //Tray by Tray, Number of trays
          ret = volume;
        } else {
          //Tray by Tray, Tray per Percentage
          ret = Math.round((parseInt(plantsPerShelf) * parseInt(state.activeShelfCount) * parseFloat(volume)) / 100);
        }
      }
      return ret;
    },
    [state.estimateParams, state.activeShelfCount]
  );

  const calculatePercentGrown = useCallback(
    (growing_plan_id, current_place_plant, assignment_method, volume, params = null) => {
      if (!volume) return '';
      const matchingItem = params
        ? params?.find((item) => item?.growing_plan_id === growing_plan_id)
        : state.estimateParams?.find((item) => item?.growing_plan_id === growing_plan_id);
      const plantsPerShelf = matchingItem?.start_count ?? 0;

      let ret = 0;
      if (current_place_plant === PlacePlant.Same) {
        // Shelf by Shelf
        if (assignment_method === AssignmentMethod.NumberOf) {
          // Shelf by Shelf, Number of Shelves
          ret = volume;
          ret = Math.round((parseFloat(volume) / parseInt(state.activeShelfCount)) * 10000) / 100;
        } else {
          // Shelf by Shelf, Shelf per Percentage
          ret = volume;
        }
      } else {
        // Tray by Tray,
        if (assignment_method === AssignmentMethod.NumberOf) {
          //Tray by Tray, Number of trays
          ret = Math.round((parseFloat(volume) / parseInt(state.activeShelfCount) / plantsPerShelf) * 10000) / 100;
        } else {
          //Tray by Tray, Tray per Percentage
          ret = volume;
        }
      }
      return ret;
    },
    [state.estimateParams, state.activeShelfCount]
  );

  const getUnitLabel = useCallback((current_place_plant, growing_method, groupType, volume) => {
    if (parseInt(current_place_plant) === PlacePlant.Same) {
      if (parseInt(growing_method) === GrowingMethod.Once) {
        return parseInt(volume) === 1 ? ' Shelf' : ' Shelves';
      } else if (parseInt(growing_method) === GrowingMethod.Repeat) {
        return parseInt(volume) === 1 ? ' Shelf Repeated' : ' Shelves Repeated';
      } else {
        return parseInt(volume) === 1 ? ' Shelf per Week' : ' Shelves per Week';
      }
    } else {
      if (parseInt(growing_method) === GrowingMethod.Once) {
        return (
          (groupType === GroupType.Microgreen ? ' Tray' : groupType === GroupType.Mushroom ? ' Full Shelf Tray' : ' Plant') +
          (parseInt(volume) === 1 ? '' : 's')
        );
      } else if (parseInt(growing_method) === GrowingMethod.Repeat) {
        return (
          (groupType === GroupType.Microgreen ? ' Tray' : groupType === GroupType.Mushroom ? ' Full Shelf Tray' : ' Plant') +
          (parseInt(volume) === 1 ? '' : 's') +
          ' Repeated'
        );
      } else {
        return (
          (groupType === GroupType.Microgreen ? ' Tray' : groupType === GroupType.Mushroom ? ' Full Shelf Tray' : ' Plant') +
          (parseInt(volume) === 1 ? '' : 's') +
          ' per Week'
        );
      }
    }
  }, []);

  const getTotalPlantsGrown = useCallback(
    (growing_plan_id, current_place_plant, growing_method, assignment_method, volume, groupType, params = null) => {
      const ret = calculatePlantsGrown(growing_plan_id, current_place_plant, assignment_method, volume, params);
      if (!ret || parseInt(ret) === 0) return { amount: '', percent: '' };
      return {
        amount: ret + getUnitLabel(current_place_plant, growing_method, groupType, ret),
        percent: calculatePercentGrown(growing_plan_id, current_place_plant, assignment_method, volume, params) + '%'
      };
    },
    [calculatePercentGrown, calculatePlantsGrown, getUnitLabel]
  );

  const getMinMaxCalcForAuto = useCallback(
    (activeIndex) => {
      if (activeIndex >= state.selectedPlantList?.length) return {};
      const activeItem = state.selectedPlantList[activeIndex];
      const buffer = Math.round((parseInt(state.activeShelfCount) * parseFloat(state.bufferPercentage || 0)) / 100);
      let assigned = 0;
      let minVal = 0;
      let maxVal = 0;
      let requiredCount = 0; // number of required units
      let minimumRequiredCount = 1;
      let minimumRequiredArea = 1;

      state.selectedPlantList?.forEach((item, index) => {
        const growing_method_rate = getRateByGrowingMethod(item?.growing_method, item?.days_from_seeding_khora, item?.age_to_market);
        const transplant_method_rate = getRateByTransplantMethod(item?.growing_plan_id, 2);
        if (index === activeIndex) {
          minimumRequiredCount = parseInt(Math.ceil(transplant_method_rate) * growing_method_rate);
          minimumRequiredArea = transplant_method_rate * growing_method_rate;
          return;
        }
        const curValue =
          Math.ceil(
            parseInt(
              calculatePlantsGrown(
                item?.growing_plan_id,
                1,
                item?.assignment_method,
                item?.assignment_method === AssignmentMethod.NumberOf ? item?.amount : item?.crop_percentage
              ) || 0
            ) * transplant_method_rate
          ) * growing_method_rate;
        assigned += curValue;
      });
      const remainingCount = Math.max(state.activeShelfCount - buffer - assigned, 0);
      if (remainingCount >= minimumRequiredCount) {
        if (activeItem?.assignment_method === AssignmentMethod.NumberOf) {
          minVal = 1;
          maxVal = parseInt(remainingCount / minimumRequiredArea);
        } else {
          minVal = Math.ceil((0.5 / state.activeShelfCount) * 10000) / 100;
          maxVal = Math.min(
            100,
            (Math.ceil(((parseInt(remainingCount / minimumRequiredArea) + 0.5) / state.activeShelfCount) * 10000) - 1) / 100
          );
        }
      } else {
        requiredCount = minimumRequiredCount - remainingCount;
      }

      return { minVal, maxVal, requiredCount };
    },
    [
      state.activeShelfCount,
      state.bufferPercentage,
      state.selectedPlantList,
      calculatePlantsGrown,
      getRateByGrowingMethod,
      getRateByTransplantMethod
    ]
  );

  // This function is only for tray-by-tray mode
  // 0 means success, other mean more required number of shelves
  const checkPlacingValidation = useCallback(
    (growingPlanList) => {
      const eps = 1e-6;
      const total = parseInt(state.activeShelfCount);
      const buffer = Math.round((parseInt(state.activeShelfCount) * parseFloat(state.bufferPercentage || 0)) / 100);
      let remainArray = [];
      let numberOfShelves = total - buffer;
      let numberOfAssignedTrays = 0;
      for (const plan of growingPlanList) {
        let amount =
          calculatePlantsGrown(
            plan?.id,
            state.placePlant,
            plan?.assignment_method,
            plan?.assignment_method === AssignmentMethod.NumberOf ? plan?.amount : plan?.crop_percentage
          ) || 0;
        if (!amount) continue;
        const matchingItem = state.estimateParams?.find((e) => e?.growing_plan_id === plan?.id);
        const harvestNumberOfCubesPerShelf = matchingItem?.harvest_count ?? 0;
        if (!harvestNumberOfCubesPerShelf) continue;
        const plantTypeByContainer = matchingItem?.plant_type_by_container ?? PlantTypeByContainer.Small;
        const groupId = (plan?.is_seed ? plan?.seed?.plant_group_id : plan?.cutting?.plant_group_id) ?? 0;
        const days_from_seeding_khora = plan?.is_seed ? plan?.seed?.days_from_seeding_khora : plan?.cutting?.harvest_flower_day;
        const age_to_market = plan?.is_seed ? plan?.seed?.age_to_market : 0;
        const growing_method_rate = getRateByGrowingMethod(plan?.growing_method, days_from_seeding_khora, age_to_market);
        amount *= growing_method_rate;
        const removeIdxArray = [];
        remainArray.forEach((item, idx, thisArray) => {
          if (item.ptc !== plantTypeByContainer) return;
          if (item.group && groupId && item.group != groupId) return;
          const numberOfCubes = parseInt(item.space * harvestNumberOfCubesPerShelf + eps);
          if (!numberOfCubes || !amount) return;
          const catchAmount = Math.min(amount, numberOfCubes);
          amount -= catchAmount;
          const reducedSpace = item.space - catchAmount / harvestNumberOfCubesPerShelf;
          if (reducedSpace < eps) {
            removeIdxArray.push(idx);
            numberOfAssignedTrays += 4 - parseInt((1 - item.space) * 4);
          } else {
            numberOfAssignedTrays += parseInt((1 - reducedSpace) * 4) - parseInt((1 - item.space) * 4);
          }
          if (!item.group && groupId) item.group = groupId;
          item.space = reducedSpace;
          thisArray[idx] = item;
        });
        remainArray = remainArray.filter((item, idx) => !removeIdxArray.includes(idx));
        if (amount) {
          const requiredShelves = parseInt(Math.ceil(amount / harvestNumberOfCubesPerShelf));
          if (requiredShelves > numberOfShelves) {
            numberOfAssignedTrays += parseInt(Math.ceil((amount * 4) / harvestNumberOfCubesPerShelf));
            return [requiredShelves - numberOfShelves, numberOfAssignedTrays];
          }
          numberOfShelves -= requiredShelves;
          numberOfAssignedTrays += requiredShelves * 4;
          if (requiredShelves * harvestNumberOfCubesPerShelf - amount > 0) {
            numberOfAssignedTrays -= parseInt(
              Math.ceil(((requiredShelves * harvestNumberOfCubesPerShelf - amount) / harvestNumberOfCubesPerShelf) * 4)
            );
            remainArray.push({
              ptc: plantTypeByContainer,
              group: groupId,
              space: (requiredShelves * harvestNumberOfCubesPerShelf - amount) / harvestNumberOfCubesPerShelf
            });
          }
        }
      }
      return [0, numberOfAssignedTrays];
    },
    [state.placePlant, state.activeShelfCount, state.bufferPercentage, state.estimateParams, calculatePlantsGrown, getRateByGrowingMethod]
  );

  const getMinMaxCalcForAdvanced = useCallback(
    (activeIndex) => {
      if (activeIndex >= state.selectedGrowingPlanList?.length) {
        return {};
      }
      const activeItem = state.selectedGrowingPlanList[activeIndex];

      let assigned = 0;
      let minVal = 0;
      let maxVal = 0;
      let requiredCount = 0; // number of required units
      if (state.placePlant === PlacePlant.Same) {
        const total = parseInt(state.activeShelfCount);
        const buffer = Math.round((parseInt(state.activeShelfCount) * parseFloat(state.bufferPercentage || 0)) / 100);
        let minimumRequiredCount = 1;
        let minimumRequiredArea = 1;

        state.selectedGrowingPlanList?.forEach((item, index) => {
          const days_from_seeding_khora = item?.is_seed ? item?.seed?.days_from_seeding_khora : item?.cutting?.harvest_flower_day;
          const age_to_market = item?.is_seed ? item?.seed?.age_to_market : 0;
          const growingMethodRate = getRateByGrowingMethod(item?.growing_method, days_from_seeding_khora, age_to_market);
          const transplantMethodRate = getRateByTransplantMethod(item?.id, 2);
          if (index === activeIndex) {
            minimumRequiredCount = parseInt(Math.ceil(transplantMethodRate) * growingMethodRate);
            minimumRequiredArea = transplantMethodRate * growingMethodRate;
          }
          if (index === activeIndex) return;
          const curValue =
            Math.ceil(
              parseInt(
                calculatePlantsGrown(
                  item?.id,
                  state.placePlant,
                  item?.assignment_method,
                  item?.assignment_method === AssignmentMethod.NumberOf ? item?.amount : item?.crop_percentage
                ) || 0
              ) * transplantMethodRate
            ) * growingMethodRate;
          assigned += curValue;
        });
        const remainingCount = Math.max(total - buffer - assigned, 0);
        if (remainingCount >= minimumRequiredCount) {
          if (activeItem?.assignment_method === AssignmentMethod.NumberOf) {
            minVal = 1;
            maxVal = parseInt(remainingCount / minimumRequiredArea);
          } else {
            minVal = Math.ceil((0.5 / total) * 10000) / 100;
            maxVal = Math.min(100, (Math.ceil(((parseInt(remainingCount / minimumRequiredArea) + 0.5) / total) * 10000) - 1) / 100);
          }
        } else {
          requiredCount = minimumRequiredCount - remainingCount;
        }
      } else if (state.placePlant === PlacePlant.Different) {
        const total = state.activeShelfCount * 4;
        const tmpPlanList = JSON.parse(JSON.stringify(state.selectedGrowingPlanList));
        tmpPlanList[activeIndex].assignment_method = 1;
        tmpPlanList[activeIndex].amount = 1;
        const res = checkPlacingValidation(tmpPlanList);
        const matchingItem = state.estimateParams?.find((e) => e?.growing_plan_id === tmpPlanList[activeIndex]?.growing_plan_id);
        const activeStartNumberOfCubesPerShelf = matchingItem?.start_count ?? 0;
        if (res[0] > 0) {
          requiredCount = res[0];
        } else {
          let st = 1,
            en = state.activeShelfCount * 72 + 1;
          while (st + 1 < en) {
            const md = parseInt((st + en) / 2);
            const tmpPlanList = JSON.parse(JSON.stringify(state.selectedGrowingPlanList));
            tmpPlanList[activeIndex].assignment_method = 1;
            tmpPlanList[activeIndex].amount = md;
            const res = checkPlacingValidation(tmpPlanList);
            if (res[0] === 0) st = md;
            else en = md;
          }
          if (activeItem?.assignment_method === AssignmentMethod.NumberOf) {
            minVal = 1;
            maxVal = st;
          } else {
            minVal = Math.ceil(((0.5 * 4) / total / activeStartNumberOfCubesPerShelf) * 10000) / 100;
            maxVal = Math.min(100, (Math.ceil((((st + 0.5) * 4) / total / activeStartNumberOfCubesPerShelf) * 10000) - 1) / 100);
          }
        }
      } else {
        return {};
      }

      return { minVal, maxVal, requiredCount };
    },
    [
      state.activeShelfCount,
      state.bufferPercentage,
      state.placePlant,
      state.selectedGrowingPlanList,
      state.estimateParams,
      calculatePlantsGrown,
      getRateByGrowingMethod,
      getRateByTransplantMethod,
      checkPlacingValidation
    ]
  );

  const getAssignedShelfCountForAuto = useCallback(
    (curEditPlantID, initialEditing = false) => {
      if (state.estimateParams?.length === 0) {
        return 0;
      }
      const buffer = Math.round((parseInt(state.activeShelfCount) * parseFloat(state.bufferPercentage || 0)) / 100);
      let assigned = 0;
      let curAssigned = 0;
      state.selectedPlantList?.forEach((item) => {
        const growing_method_rate = getRateByGrowingMethod(item?.growing_method, item?.days_from_seeding_khora, item?.age_to_market);
        const transplant_method_rate = getRateByTransplantMethod(item?.growing_plan_id, 2);
        const curValue =
          Math.ceil(
            parseInt(
              calculatePlantsGrown(
                item?.growing_plan_id,
                1,
                item?.assignment_method,
                item?.assignment_method === AssignmentMethod.NumberOf ? item?.amount : item?.crop_percentage
              ) || 0
            ) * transplant_method_rate
          ) * growing_method_rate;
        assigned += curValue;
        if (item?.growing_plan_id === curEditPlantID) curAssigned = curValue;
      });
      if (state.activeShelfCount - buffer - assigned < 0) {
        const newShelfNum = state.activeShelfCount - buffer - assigned + curAssigned;
        const tmpPlants = [...state.selectedPlantList];
        let msg = '';

        if (newShelfNum < 0) {
          msg = `You need ${Math.abs(newShelfNum)} ${
            Math.abs(newShelfNum) == 1 ? 'shelf' : 'shelves'
          } more.\nPlease select a smaller amount to grow.`;
          // tmpPlants?.forEach((item, idx, thisArray) => {
          //   item.amount = 0;
          //   item.crop_percentage = 0;
          //   item.total_plants = { amount: '', percent: '' };
          //   thisArray[idx] = item;
          // });
        } else {
          tmpPlants?.forEach((item, idx, thisArray) => {
            if (item?.growing_plan_id === curEditPlantID) {
              const growing_method_rate = getRateByGrowingMethod(item?.growing_method, item?.days_from_seeding_khora, item?.age_to_market);
              const transplant_method_rate = getRateByTransplantMethod(item?.growing_plan_id, 2);

              const matchingItem = state.estimateParams?.find((e) => e?.growing_plan_id === item?.growing_plan_id);
              const lastContainer = matchingItem?.last_container ?? '';
              const isExpandByTransplant = matchingItem?.start_count !== matchingItem?.harvest_count ?? false;

              if (item?.growing_method === GrowingMethod.Repeat && isExpandByTransplant) {
                // transplant
                msg = `The plant you selected will grow and be transplanted in a "${lastContainer}".\nWe are saving space for this the future growth.\nPlease select a smaller amount to grow.`;
              } else {
                msg = `You are trying to plant on ${curAssigned} shelves.\nPlease select a smaller amount to grow.`;
              }

              item.amount = parseInt(newShelfNum / growing_method_rate / transplant_method_rate);
              item.crop_percentage =
                item.amount == 0 ? 0 : Math.min(100, (Math.ceil(((item.amount + 0.5) / state.activeShelfCount) * 10000) - 1) / 100);
              item.total_plants = getTotalPlantsGrown(
                item?.growing_plan_id,
                1,
                item?.growing_method,
                item?.assignment_method,
                item?.assignment_method === AssignmentMethod.NumberOf ? item.amount : item.crop_percentage,
                item?.is_seed ? item?.seed?.plant_group?.type : item?.cutting?.plant_group?.type
              );
              thisArray[idx] = item;
            }
          });
        }
        if (initialEditing) {
          msg = `Shelf status in ${network?.name} - ${location?.name} has changed. This Crop Plan needs to be recalculated to the current available space.`;
        }
        setSelectedPlantList(tmpPlants);
        dispatchForSnackbar(
          openSnackbar({
            open: true,
            message: msg,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          })
        );
      }
      return assigned;
    },
    [
      network,
      location,
      state.activeShelfCount,
      state.bufferPercentage,
      state.estimateParams,
      state.selectedPlantList,
      calculatePlantsGrown,
      dispatchForSnackbar,
      getRateByGrowingMethod,
      getRateByTransplantMethod,
      getTotalPlantsGrown,
      setSelectedPlantList
    ]
  );

  const getAssignedShelfCountForAdvanced = useCallback(
    (curEditGrowingPlanID, initialEditing = false) => {
      if (state.estimateParams?.length === 0) {
        return 0;
      }
      const buffer = Math.round((parseInt(state.activeShelfCount) * parseFloat(state.bufferPercentage || 0)) / 100);
      let assigned = 0;
      let curAssigned = 0;
      state.selectedGrowingPlanList?.forEach((item) => {
        const days_from_seeding_khora = item?.is_seed ? item?.seed?.days_from_seeding_khora : item?.cutting?.harvest_flower_day;
        const age_to_market = item?.is_seed ? item?.seed?.age_to_market : 0;
        const growing_method_rate = getRateByGrowingMethod(item?.growing_method, days_from_seeding_khora, age_to_market);
        const transplant_method_rate = getRateByTransplantMethod(item?.id, 2);
        const curValue =
          Math.ceil(
            parseInt(
              calculatePlantsGrown(
                item?.id,
                state.placePlant,
                item?.assignment_method,
                item?.assignment_method === AssignmentMethod.NumberOf ? item?.amount : item?.crop_percentage
              ) || 0
            ) * transplant_method_rate
          ) * growing_method_rate;
        assigned += curValue;
        if (item?.id === curEditGrowingPlanID) curAssigned = curValue;
      });
      if (state.activeShelfCount - buffer - assigned < 0) {
        const newShelfNum = state.activeShelfCount - buffer - assigned + curAssigned;
        const tmpGrowingPlans = [...state.selectedGrowingPlanList];
        let msg = '';

        if (newShelfNum < 0) {
          msg = msg = `You need ${Math.abs(newShelfNum)} ${
            Math.abs(newShelfNum) == 1 ? 'shelf' : 'shelves'
          } more.\nPlease select a smaller amount to grow.`;
          // tmpGrowingPlans?.forEach((item, idx, thisArray) => {
          //   item.amount = 0;
          //   item.crop_percentage = 0;
          //   item.total_plants = { amount: '', percent: '' };
          //   thisArray[idx] = item;
          // });
        } else {
          tmpGrowingPlans?.forEach((item, idx, thisArray) => {
            if (item?.id === curEditGrowingPlanID) {
              const days_from_seeding_khora = item?.is_seed ? item?.seed?.days_from_seeding_khora : item?.cutting?.harvest_flower_day;
              const age_to_market = item?.is_seed ? item?.seed?.age_to_market : 0;
              const growing_method_rate = getRateByGrowingMethod(item?.growing_method, days_from_seeding_khora, age_to_market);
              const transplant_method_rate = getRateByTransplantMethod(item?.id, 2);

              const matchingItem = state.estimateParams?.find((e) => e?.growing_plan_id === item?.id);
              const lastContainer = matchingItem?.last_container ?? '';
              const isExpandByTransplant = matchingItem?.start_count !== matchingItem?.harvest_count ?? false;

              if (item?.growing_method === GrowingMethod.Repeat && isExpandByTransplant) {
                // transplant
                msg = `The plant you selected will grow and be transplanted in a "${lastContainer}".\nWe are saving space for this the future growth.\nPlease select a smaller amount to grow.`;
              } else {
                msg = `You are trying to plant on ${curAssigned} shelves.\nPlease select a smaller amount to grow.`;
              }

              item.amount = parseInt(newShelfNum / growing_method_rate / transplant_method_rate);
              item.crop_percentage =
                item.amount == 0 ? 0 : Math.min(100, (Math.ceil(((item.amount + 0.5) / state.activeShelfCount) * 10000) - 1) / 100);
              item.total_plants = getTotalPlantsGrown(
                item?.id,
                state.placePlant,
                item?.growing_method,
                item?.assignment_method,
                item?.assignment_method === AssignmentMethod.NumberOf ? item.amount : item.crop_percentage,
                item?.is_seed ? item?.seed?.plant_group?.type : item?.cutting?.plant_group?.type
              );
              thisArray[idx] = item;
            }
          });
        }
        if (initialEditing) {
          msg = `Shelf status in ${network?.name} - ${location?.name} has changed. This Crop Plan needs to be recalculated to the current available space.`;
        }
        setSelectedGrowingPlanList(tmpGrowingPlans);
        dispatchForSnackbar(
          openSnackbar({
            open: true,
            message: msg,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          })
        );
      }
      return assigned;
    },
    [
      network,
      location,
      state.activeShelfCount,
      state.bufferPercentage,
      state.estimateParams,
      state.placePlant,
      state.selectedGrowingPlanList,
      calculatePlantsGrown,
      dispatchForSnackbar,
      getRateByGrowingMethod,
      getRateByTransplantMethod,
      getTotalPlantsGrown,
      setSelectedGrowingPlanList
    ]
  );

  const getAssignedTrayCountForAdvanced = useCallback(
    (curEditGrowingPlanID, initialEditing = false) => {
      const res = checkPlacingValidation(state.selectedGrowingPlanList);
      if (res[0] === 0) return res[1];
      let curPlan = null;
      let activeIndex = -1;
      state.selectedGrowingPlanList.forEach((plan, idx) => {
        if (plan?.id === curEditGrowingPlanID) {
          curPlan = plan;
          activeIndex = idx;
        }
      });
      if (!curPlan) return res[1];

      let st = -1,
        en = state.activeShelfCount * 72 + 1;
      while (st + 1 < en) {
        const md = parseInt((st + en) / 2);
        const tmpPlanList = JSON.parse(JSON.stringify(state.selectedGrowingPlanList));
        tmpPlanList[activeIndex].assignment_method = 1;
        tmpPlanList[activeIndex].amount = md;
        const res = checkPlacingValidation(tmpPlanList);
        if (res[0] === 0) st = md;
        else en = md;
      }
      let msg = '';
      const tmpPlanList = JSON.parse(JSON.stringify(state.selectedGrowingPlanList));
      if (st < 0) {
        msg = 'Estimate calculation failed. Please select a smaller amount to grow.';
        // tmpPlanList?.forEach((item, idx, thisArray) => {
        //   item.amount = 0;
        //   item.crop_percentage = 0;
        //   item.total_plants = { amount: '', percent: '' };
        //   thisArray[idx] = item;
        // });
      } else {
        tmpPlanList[activeIndex].assignment_method = state.selectedGrowingPlanList[activeIndex].assignment_method;
        tmpPlanList[activeIndex].amount = st;
        const matchingItem = state.estimateParams?.find((e) => e?.growing_plan_id === curPlan?.id);
        const startNumberOfCubesPerShelf = matchingItem?.start_count ?? 0;
        const isExpandByTransplant = matchingItem?.start_count !== matchingItem?.harvest_count ?? false;
        const lastContainer = matchingItem?.last_container ?? '';
        tmpPlanList[activeIndex].crop_percentage =
          st == 0 ? 0 : Math.min(100, (Math.ceil(((st + 0.5) / state.activeShelfCount / startNumberOfCubesPerShelf) * 10000) - 1) / 100);

        tmpPlanList[activeIndex].total_plants = getTotalPlantsGrown(
          tmpPlanList[activeIndex]?.id,
          state.placePlant,
          tmpPlanList[activeIndex]?.growing_method,
          tmpPlanList[activeIndex]?.assignment_method,
          tmpPlanList[activeIndex]?.assignment_method === AssignmentMethod.NumberOf ? st : tmpPlanList[activeIndex]?.crop_percentage,
          tmpPlanList[activeIndex]?.is_seed
            ? tmpPlanList[activeIndex]?.seed?.plant_group?.type
            : tmpPlanList[activeIndex]?.cutting?.plant_group?.type
        );
        msg = isExpandByTransplant
          ? `The plant you selected will grow and be transplanted in a "${lastContainer}".\nWe are saving space for this the future growth.\nPlease select a smaller amount to grow.`
          : `You are trying to plant on ${res[1]} trays.\nPlease select a smaller amount to grow.`;
      }

      if (initialEditing) {
        msg = `Shelf status in ${network?.name} - ${location?.name} has changed. This Crop Plan needs to be recalculated to the current available space.`;
      }
      setSelectedGrowingPlanList(tmpPlanList);
      dispatchForSnackbar(
        openSnackbar({
          open: true,
          message: msg,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        })
      );
    },
    [
      network,
      location,
      state.activeShelfCount,
      state.estimateParams,
      state.selectedGrowingPlanList,
      state.placePlant,
      dispatchForSnackbar,
      setSelectedGrowingPlanList,
      checkPlacingValidation,
      getTotalPlantsGrown
    ]
  );

  const getAssignedSeedingTrayCountForAdvanced = useCallback(() => {
    let seedTrayCount = 0;
    state.selectedGrowingPlanList?.forEach((item) => {
      const matchingItem = state.estimateParams?.find((e) => e?.growing_plan_id === item?.id);
      const startValue = matchingItem?.start_count ?? 0;
      const seedValue = matchingItem?.seed_size ?? 0;
      const percentGerminate = parseFloat(matchingItem?.percent_germ ?? 0);

      if (!seedValue) {
        return 0;
      }
      let plantCount = 0;
      const days_from_seeding_khora = item?.is_seed ? item?.seed?.days_from_seeding_khora : item?.cutting?.harvest_flower_day;
      const age_to_market = item?.is_seed ? item?.seed?.age_to_market : 0;
      let growingMethodRate = getRateByGrowingMethod(item?.growing_method, days_from_seeding_khora, age_to_market);
      if (parseInt(state.placePlant) === PlacePlant.Same) {
        plantCount =
          parseInt(
            calculatePlantsGrown(
              item?.id,
              state.placePlant,
              item?.assignment_method,
              item?.assignment_method === AssignmentMethod.NumberOf ? item?.amount : item?.crop_percentage
            ) || 0
          ) *
          startValue *
          growingMethodRate;
      } else {
        plantCount =
          (calculatePlantsGrown(
            item?.id,
            state.placePlant,
            item?.assignment_method,
            item?.assignment_method === AssignmentMethod.NumberOf ? item?.amount : item?.crop_percentage
          ) || 0) * growingMethodRate;
      }
      seedTrayCount += (4 * ((plantCount / percentGerminate) * 100)) / seedValue;
    });

    return Math.ceil(seedTrayCount);
  }, [state.estimateParams, state.placePlant, state.selectedGrowingPlanList, calculatePlantsGrown, getRateByGrowingMethod]);

  const getLink4InfoForCropPlan = useCallback(async (id) => {
    try {
      const res = await axios.get(`crop_plan/link4/info/${id}`);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getFinancialModelForManualPlan = useCallback(async (locationID, values) => {
    try {
      const res = await axios.post(`crop_plan/manual/financial/${locationID}`, values);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const createManualCropPlan = useCallback(async (locationID, values) => {
    try {
      const res = await axios.put(`/crop_plan/manual/${locationID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const updateManualCropPlan = useCallback(async (locationID, planID, values) => {
    try {
      const res = await axios.post(`/crop_plan/manual/${locationID}/${planID}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const startSchedule = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/schedule/start/${id}`, values);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const stopSchedule = useCallback(async (id) => {
    try {
      const res = await axios.post(`/schedule/stop/${id}`);
      return { status: true, data: res?.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  const getShoppingList = useCallback(async (id, values) => {
    try {
      const res = await axios.post(`/shopping_list/model/${id}`, values);
      return res?.data;
    } catch (err) {
      return [];
    }
  }, []);

  const getCropPlanForSchedule = useCallback(async (ID) => {
    try {
      const res = await axios.get('/crop_plan/main/one/' + ID);
      return { status: true, data: res.data };
    } catch (err) {
      return { status: false, data: err?.message };
    }
  }, []);

  return (
    <CropPlanContext.Provider
      value={{
        ...state,
        getCropPlanList,
        getAutoCropPlans,
        getAdvancedCropPlans,
        getSeedListHavingGrowingPlan,
        getCuttingListHavingGrowingPlan,
        setSelectedPlantList,
        setSelectedGrowingPlanList,
        setGrowInterest,
        setGapManage,
        setGrowingMode,
        setPlacePlant,
        setBufferPercentage,
        setKEG,
        setStartDate,
        setDuration,
        setStep,
        setAssignedShelves,
        setAssignedTrays,
        setAssignedSeedings,
        setCurrentShelvesForManual,
        initializeStates,
        // auto crop modeling
        startAutoCropModeling,
        saveAutoCropPlan,
        loadAutoCropModeltoTempDB,
        updateAutoCropModel,
        cloneAutoCropModel,
        deleteAutoCropModel,
        deletePlantsFromAutoCropModel,
        // advanced crop modeling
        validationCheckToSelectGrowingPlans,
        startAdvancedCropModeling,
        saveAdvancedCropPlan,
        loadAdvancedCropModeltoTempDB,
        updateAdvancedCropModel,
        cloneAdvancedCropModel,
        deleteAdvancedCropModel,
        deletePlantsFromAdvancedCropModel,
        // manual crop modeling
        getFinancialModelForManualPlan,
        createManualCropPlan,
        updateManualCropPlan,
        cloneManualCropModel,
        deleteManualCropModel,
        getManualCropModel,
        setAddedGrowingPlanID,
        setAddedGrowingPlans,
        getGrowingGroupType,
        setGrowingGroupPerShelf,
        // common functions
        getEstimateParams,
        getRateByGrowingMethod,
        getRateByTransplantMethod,
        calculatePlantsGrown,
        calculatePercentGrown,
        getUnitLabel,
        getTotalPlantsGrown,
        getMinMaxCalcForAuto,
        getMinMaxCalcForAdvanced,
        getAssignedShelfCountForAuto,
        getAssignedShelfCountForAdvanced,
        getAssignedTrayCountForAdvanced,
        getAssignedSeedingTrayCountForAdvanced,
        // link4
        getLink4InfoForCropPlan,
        // schedule
        startSchedule,
        stopSchedule,
        getShoppingList,
        getCropPlanForSchedule
      }}
    >
      {children}
      <Modal open={openErrorAlert}>
        <AlertCard title={errTitle} sx={{ width: { xs: '90%', md: '640px' } }}>
          <CardContent>
            <Typography gutterBottom sx={theme.typography.p2}>
              {errMsg}
            </Typography>
          </CardContent>
          <Stack direction="row" spacing={3} justifyContent="center" sx={{ p: 2.5 }}>
            <Button variant="contained" size="large" onClick={() => navigate('/my_khora')}>
              Yes
            </Button>
          </Stack>
        </AlertCard>
      </Modal>
    </CropPlanContext.Provider>
  );
};

CropPlanProvider.propTypes = {
  children: PropTypes.node
};

export default CropPlanContext;
