import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { createContext, useReducer, useEffect, useState, useCallback } from 'react';
import plantPlacementReducer, { initialState } from 'store/reducers/_plant_placement';
import {
  PLANT_PLACEMENT_DATA,
  PLANT_PLACEMENT_TYPE,
  PLANT_PLACEMENT_SELECTED_LIST,
  PLANT_PLACEMENT_ALL_SHELVES,
  PLANT_PLACEMENT_INITIALIZE
} from 'store/reducers/actions';

import { CardContent, Stack, Button, Modal, Typography, useTheme } from '@mui/material';
import AlertCard from 'components/AlertCard';
import axios from 'utils/axios';
import { PlacementType } from 'utils/constants/plant';
// import { ShelfStatusString } from 'utils/constants/crop_plan';

import useSetup from 'hooks/useSetup';

const PlantPlacementContext = createContext(null);

export const PlantPlacementProvider = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(plantPlacementReducer, initialState);
  const { network, location, khoraCount, getShelvesForLocation } = useSetup();

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const handleErrorAlertOpen = () => setOpenErrorAlert(true);
  const [errTitle, setErrTitle] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const initializePlantPlacementState = useCallback(() => {
    dispatch({
      type: PLANT_PLACEMENT_INITIALIZE
    });
  }, []);

  useEffect(() => {
    if (!network?.id) {
      setErrTitle('No network found!');
      setErrMsg('You have no network. Please add your first network');
      handleErrorAlertOpen();
      initializePlantPlacementState();
      return;
    }
    if (!location?.id) {
      setErrTitle('No location found!');
      setErrMsg('You have no location. Please add your first location');
      handleErrorAlertOpen();
      initializePlantPlacementState();
      return;
    }
    if (!khoraCount) {
      setErrTitle('No Khôra found!');
      setErrMsg('You have no Khôras. Please register your first Khôras');
      handleErrorAlertOpen();
      initializePlantPlacementState();
      return;
    }
    return () => {
      setErrTitle('');
      setErrMsg('');
    };
  }, [network, location, khoraCount, initializePlantPlacementState]);

  const setPlacementType = useCallback((placementType) => {
    dispatch({
      type: PLANT_PLACEMENT_TYPE,
      payload: {
        placementType
      }
    });
  }, []);

  const setData = useCallback((data) => {
    dispatch({
      type: PLANT_PLACEMENT_DATA,
      payload: data
    });
  }, []);

  const setSelectedList = useCallback((selectedList) => {
    dispatch({
      type: PLANT_PLACEMENT_SELECTED_LIST,
      payload: { selectedList }
    });
  }, []);

  const setAllShelvesForPlantPlacement = useCallback((allShelves) => {
    dispatch({
      type: PLANT_PLACEMENT_ALL_SHELVES,
      payload: {
        allShelves
      }
    });
  }, []);

  const getShelvesForLocationForPlantPlacement = useCallback(
    async (locationID) => {
      return await getShelvesForLocation(locationID);
    },
    [getShelvesForLocation]
  );

  const getPlantPlacementData = useCallback(async (locationID) => {
    try {
      const res = await axios.get(`/plant_placement/${locationID}`);
      return {
        isRunning: res?.data?.is_running,
        plantingList: res?.data?.planting,
        harvestList: res?.data?.harvest,
        info: res?.data?.info,
        nextPlanting: res?.data?.next_planting,
        nextHarvest: res?.data?.next_harvest,
        curCropPlan: res?.data?.crop_plan
      };
    } catch (err) {
      return {
        isRunning: -1,
        plantingList: [],
        harvestList: [],
        info: {
          harvested_amount: 0,
          growing_amount: 0
        },
        nextPlanting: null,
        nextHarvest: null,
        curCropPlan: null
      };
    }
  }, []);

  useEffect(() => {
    if (state.placementType === PlacementType.Planting) {
      setSelectedList(state.plantingList);
    } else {
      setSelectedList(state.harvestList);
    }
  }, [state.harvestList, state.plantingList, state.placementType, setSelectedList]);

  return (
    <PlantPlacementContext.Provider
      value={{
        ...state,
        initializePlantPlacementState,
        setPlacementType,
        getShelvesForLocationForPlantPlacement,
        getPlantPlacementData,
        setData,
        setAllShelvesForPlantPlacement
      }}
    >
      {children}
      <Modal open={openErrorAlert}>
        <AlertCard title={errTitle} sx={{ width: { xs: '90%', md: '640px' } }}>
          <CardContent>
            <Typography gutterBottom sx={theme.typography.p2}>
              {errMsg}
            </Typography>
          </CardContent>
          <Stack direction="row" spacing={3} justifyContent="center" sx={{ p: 2.5 }}>
            <Button variant="contained" size="large" onClick={() => navigate('/my_khora')}>
              Yes
            </Button>
          </Stack>
        </AlertCard>
      </Modal>
    </PlantPlacementContext.Provider>
  );
};

PlantPlacementProvider.propTypes = {
  children: PropTypes.node
};

export default PlantPlacementContext;
