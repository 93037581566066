// third-party
import { FormattedMessage } from 'react-intl';
// icons
// import { DashboardOutlined } from '@ant-design/icons';
// const icons = { DashboardOutlined };

// icons
import ShoppingIcon from 'assets/icons/shopping/Shopping.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const shopping = {
  id: 'group-shopping',
  type: 'group',
  children: [
    {
      id: 'shopping',
      title: <FormattedMessage id="Shopping Lists" />,
      type: 'item',
      url: '/shopping',
      icon: ShoppingIcon
    }
  ]
};
const shopping_ = {
  id: 'group-shopping',
  type: 'group',
  children: [
    {
      id: 'shopping',
      title: <FormattedMessage id="Shopping Lists" />,
      type: 'collapse',
      icon: ShoppingIcon,
      children: [
        {
          id: 'shopping_',
          title: <FormattedMessage id="Shopping Lists" />,
          type: 'item',
          url: '/shopping'
        }
      ]
    }
  ]
};

const shopping_seeding = {
  id: 'group-shopping-seeding',
  type: 'group',
  children: [
    {
      id: 'shopping',
      title: <FormattedMessage id="Shopping & Seeding Lists" />,
      type: 'item',
      url: '/shopping',
      icon: ShoppingIcon
    }
  ]
};
const shopping_seeding_ = {
  id: 'group-shopping-seeding',
  type: 'group',
  children: [
    {
      id: 'shopping',
      title: <FormattedMessage id="Shopping & Seeding Lists" />,
      type: 'collapse',
      icon: ShoppingIcon,
      children: [
        {
          id: 'shopping_',
          title: <FormattedMessage id="Shopping & Seeding Lists" />,
          type: 'item',
          url: '/shopping'
        }
      ]
    }
  ]
};

export { shopping, shopping_, shopping_seeding, shopping_seeding_ };
