const Regions = [
  'North America',
  'South America',
  'Central America',
  'Caribbean',
  'Central & South Asia',
  'Northeastern Asia',
  'Southeastern Asia',
  'Australia and Oceania',
  'Northern Europe',
  'Southern Europe',
  'Eastern Europe',
  'Western Europe',
  'Middle East',
  'Northern Africa',
  'Southern Africa'
];

const AuthURL = ['login', 'register', 'forgot_password', 'reset_password', 'code_verification', 'email_verify'];

const Membership = {
  Free: 0,
  Seed: 1,
  Grow: 2,
  Bloom: 3,
  Harvest: 4,
  Master: 5
};

export { Regions, AuthURL, Membership };
