import { lazy } from 'react';
import { useRoutes, Outlet } from 'react-router-dom';
import GuestGuard from 'utils/route-guard/GuestGuard';

// project import
import Loadable from 'components/Loadable';
import LoginRoutes from './LoginRoutes';
import GuideRoutes from './GuideRoutes';
import LandingRoutes from './LandingRoutes';
import AuthRoutesGroup from './AuthRoutesGroup';
// error pages
const Error404 = Loadable(lazy(() => import('pages/maintenance/404')));
const Error500 = Loadable(lazy(() => import('pages/maintenance/500')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    LandingRoutes,
    AuthRoutesGroup,
    GuideRoutes,
    LoginRoutes,
    {
      path: '',
      element: (
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      ),
      children: [
        {
          path: '*',
          element: <Error404 />
        },
        {
          path: 'server_error',
          element: <Error500 />
        }
      ]
    }
  ]);
}
