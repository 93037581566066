// material-ui
import { alpha } from '@mui/material/styles';

// project import
import getColors from 'utils/getColors';

// ==============================|| ALERT - COLORS ||============================== //

function getColorStyle({ color, theme }) {
  const colors = getColors(theme, color);
  const { light, main } = colors;

  return {
    borderColor: alpha(light, 0.5),
    backgroundColor: light,
    '& .MuiAlert-icon': {
      color: main
    }
  };
}

// ==============================|| OVERRIDES - ALERT ||============================== //

export default function Alert(theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          ...theme.typography.p3m
        },
        // Refer to https://mui.com/material-ui/api/alert/ for overriding success and error styles
        standardSuccess: {
          backgroundColor: theme.palette.darkgreen.main
        },
        outlinedSuccess: {
          backgroundColor: theme.palette.darkgreen.main
        },
        filledSuccess: {
          backgroundColor: theme.palette.darkgreen.main
        },
        standardError: {
          backgroundColor: theme.palette.error.dark
        },
        outlinedError: {
          backgroundColor: theme.palette.error.dark
        },
        filledError: {
          backgroundColor: theme.palette.error.dark
        },
        icon: {
          fontSize: '1.125rem'
        },
        message: {
          padding: 0,
          marginTop: '6px'
        },
        filled: {
          color: theme.palette.grey[25]
        },
        border: {
          '&.MuiAlert-borderPrimary': getColorStyle({ color: 'primary', theme }),
          '&.MuiAlert-borderSecondary': getColorStyle({ color: 'secondary', theme }),
          '&.MuiAlert-borderError': getColorStyle({ color: 'error', theme }),
          '&.MuiAlert-borderSuccess': getColorStyle({ color: 'success', theme }),
          '&.MuiAlert-borderInfo': getColorStyle({ color: 'info', theme }),
          '&.MuiAlert-borderWarning': getColorStyle({ color: 'warning', theme })
        },
        action: {
          '& .MuiButton-root': {
            fontSize: '0.75rem',
            padding: '4px'
          },
          '& .MuiIconButton-root': {
            width: 'auto',
            height: 'auto',
            '& .MuiSvgIcon-root': {
              fontSize: '1.125rem'
            }
          }
        }
      }
    }
  };
}
